import React from 'react';
import { withStyles, Grid, Typography } from '@material-ui/core';
import styles from '../../../styles/styles';

const contactDetailsPreview = function ({ classes, contactDetails }) {
    return (
        <Grid container spacing={10} justify="flex-start">
            <Grid item xs={12}>
                {contactDetails && contactDetails.cellphoneNumber && (
                    <Grid container justify="flex-start">
                        <Grid item xs={6}>
                            <Typography variant="subtitle2" className={classes.customLabel}>
                                Cellphone Number
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography className={classes.customLabel}>
                                {contactDetails.cellphoneNumber}
                            </Typography>
                        </Grid>
                    </Grid>
                )}
                {contactDetails && contactDetails.workNumber && (
                    <Grid container justify="flex-start">
                        <Grid item xs={6}>
                            <Typography variant="subtitle2" className={classes.customLabel}>
                                Work Number
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography className={classes.customLabel}>
                                {contactDetails.workNumber}
                            </Typography>
                        </Grid>
                    </Grid>
                )}
                {contactDetails && contactDetails.homeNumber && (
                    <Grid container justify="flex-start">
                        <Grid item xs={6}>
                            <Typography variant="subtitle2" className={classes.customLabel}>
                                Home Number
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography className={classes.customLabel}>
                                {contactDetails.homeNumber}
                            </Typography>
                        </Grid>
                    </Grid>
                )}
                {contactDetails && contactDetails.telephoneNumber && (
                    <Grid container justify="flex-start">
                        <Grid item xs={6}>
                            <Typography variant="subtitle2" className={classes.customLabel}>
                                Telephone Number
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography className={classes.customLabel}>
                                {contactDetails.telephoneNumber}
                            </Typography>
                        </Grid>
                    </Grid>
                )}
                {contactDetails && contactDetails.faxNumber && (
                    <Grid container justify="flex-start">
                        <Grid item xs={6}>
                            <Typography variant="subtitle2" className={classes.customLabel}>
                                Fax Number
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography className={classes.customLabel}>
                                {contactDetails.faxNumber}
                            </Typography>
                        </Grid>
                    </Grid>
                )}
                {contactDetails && contactDetails.emailAddress && (
                    <Grid container justify="flex-start">
                        <Grid item xs={6}>
                            <Typography variant="subtitle2" className={classes.customLabel}>
                                Email Address
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography className={classes.customLabel}>
                                {contactDetails.emailAddress}
                            </Typography>
                        </Grid>
                    </Grid>
                )}
                {contactDetails && contactDetails.preferredMethodOfCommunication && (
                    <Grid container justify="flex-start">
                        <Grid item xs={6}>
                            <Typography variant="subtitle2" className={classes.customLabel}>
                                Preferred Method of Communication
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography className={classes.customLabel}>
                                {contactDetails.preferredMethodOfCommunication}
                            </Typography>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

export const ContactDetailsPreview = withStyles(styles)(contactDetailsPreview);
