import { withStyles, Typography, Grid } from '@material-ui/core';
import styles from '../../../styles/styles';
import React from 'react';
import { Condition, FixedWidthButtonGroup, FixedWidthRadioGroup, FixedWidthRadioOption, OutlinedTextField, True, WhenFieldChanges } from '../../../forms';
import classNames from 'classnames';
import { requiredValidator } from '../../../forms/validations';
import { NavigationButton } from '../../common/navigation/navigation-button';

const policyDetails = function ({
    classes,
    form,
    editable,
    index,
    stepsLength,
    handleBack,
    handleNext,
}) {
    return (
        <React.Fragment>
            {editable && <Grid item xs={12} lg={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
            </Grid>}
            <Grid item xs={12} lg={12} container justify="flex-start">
                {editable && <Typography className={classes.activeStepperCircle}>{index + 1}</Typography> }
                <Typography className={classes.stepHeader}> Policy Details</Typography>
            </Grid>
            <Grid item xs={12}>
                <OutlinedTextField
                    name={`claimant.policyDetails.policyNumber`}
                    label="Policy Number"
                    disabled={!editable}
                    validate={requiredValidator}
                />
            </Grid>
            <Grid item xs={12}>
                <FixedWidthButtonGroup
                    label="Are you the policy owner?"
                    name="claimant.policyDetails.isOwner"
                    options={['Yes', 'No']}
                    disabled={!editable}
                    validate={requiredValidator}
                />
            </Grid>

            <Condition when={`claimant.policyDetails.isOwner`} is="No">
                <True>
                    <Grid item xs={12}>
                        <FixedWidthRadioGroup
                            name={`claimant.policyDetails.claimingCapacity`}
                            label="In what capacity are you claiming as?"
                            labelprefixed="true"
                            style={{ flexDirection: 'column' }}
                            disabled={!editable}
                            validate={requiredValidator}
                        >
                            <FixedWidthRadioOption value="Financial Advisor" label="As a financial advisor"/>
                            <FixedWidthRadioOption value="Relative" label="As a relative" />
                        </FixedWidthRadioGroup>
                    </Grid>
                    <WhenFieldChanges
                        field={`claimant.policyDetails.isOwner`}
                        set={`claimingCapacity`}
                        to={null}
                    />
                </True>
            </Condition>

            <Grid item xs={12} className={classes.navigationGroup}>
                <NavigationButton
                    color="primary"
                    className={classNames(
                        classes.button,
                        classes.buttonAlignment,
                        classes.fullWidthButton
                    )}
                    onClick={() => handleNext(form)}
                    id="policy-details-continue"
                    label="Continue"
                />
            </Grid>
            <Grid item xs={12} className={classes.navigationGroup}>
                <NavigationButton
                    className={classNames(
                        classes.buttonSecondary,
                        classes.buttonAlignment,
                        classes.fullWidthButton
                    )}
                    onClick={() => handleBack(form)}
                    id="policy-details-back"
                    label="Previous Section"
                />
            </Grid>
        </React.Fragment>
    );
};


export const PolicyDetails = withStyles(styles)(policyDetails);
