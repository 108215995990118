import React from 'react';
import uuid from 'uuid/v1';
import { Typography, withStyles } from '@material-ui/core';
import informationIcon from '../../styles/icons/information.svg';
import styles from '../../styles/styles';
import { ClaimStepper } from '../claim/claim-stepper';
import { HorizontalStepper } from '../common/horizontal-stepper';
import { ApplicationProcess } from './application-process';
import { ClaimantDetails } from './claimant-details/claimant-details';
import { DeceasedDetails } from './deceased-details/deceased-details';
import { Declaration } from './declaration';
import { Introduction } from './introduction';
import { Summary } from './summary';
import { ClaimTypes } from '../../shared/lookups';

export const raDeathComponents = [
    {
        name: 'Introduction',
        information:
            'We outline the documents we need to process your claim, as well as any additional documents which might be relevant.',
        component: Introduction,
    },
    {
        name: 'Deceased Details',
        information: 'We will need personal details about the deceased.',
        component: DeceasedDetails,
    },
    {
        name: 'Claimant Details',
        information: 'We will require personal details about the claimants.',
        component: ClaimantDetails,
    },
    {
        name: 'Summary',
        information: 'This is a chance to review everything before you submit.',
        component: Summary,
    },
    {
        name: 'Declaration',
        information: 'We will need you to to confirm that you agree with our terms and conditions',
        component: Declaration,
    },
];

const defaultInitialValues = {
    deceasedDetails: {
        policyNumbers: [undefined],
    },
    claimants: [
        {
            claimantId: uuid(),
        },
    ],
    claimType: ClaimTypes.RADeathClaim,
};

function claimHeader({ classes, activeStepIndex, components, stepThrough, updateStepIndexAll }) {
    if (activeStepIndex === 0) {
        return null;
    }

    return (
        <React.Fragment>
            <ApplicationProcess sectionIndex={activeStepIndex} />
            <HorizontalStepper
                components={components}
                activeIndex={activeStepIndex}
                stepThrough={stepThrough}
                updateIndex={updateStepIndexAll}
            />
            <SectionNotification
                classes={classes}
                activeStepIndex={activeStepIndex}
                length={components.length}
            />
        </React.Fragment>
    );
}

function SectionNotification({ classes, activeStepIndex, length }) {
    if (activeStepIndex === length - 1) {
        return null;
    }

    const notification =
        activeStepIndex === 3
            ? 'Please check and ensure all the information you provided is correct.'
            : 'Please complete all fields marked with a *';

    return (
        <div className={classes.requiredInformation}>
            <img src={informationIcon} alt="information" />
            <Typography className={classes.requiredInformationText}>{notification}</Typography>
        </div>
    );
}

export const RetirementAnnuityClaim = withStyles(styles)(() => (
    <ClaimStepper defaultInitialValues={defaultInitialValues} headerComponent={claimHeader} />
));
