import { Grid, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import styles from '../../../styles/styles';

function introductionHeader({ classes, claim, policy }) {
    return (
        <Grid item xs={12}>
            <Typography className={classes.componentHeader}>Submit a {claim} Claim</Typography>
            <Typography className={classes.componentIntro}>Introduction</Typography>
            <Typography className={classes.componentBody}>
                {claim === 'Living Lifestyle' ? (
                    <>
                        We understand this is a difficult time for people who have experienced an unfortunate life event.
                        Some of the things we ask for may seem insensitive, but we need certain information to process
                        the claim.
                    </>
                ) : (
                    <>
                        We understand this is a difficult time for people who have experienced the loss of
                        someone close to them. Some of the things we ask for may seem insensitive, but we
                        need certain information to process the claim.
                    </>
                )}
                <br />
                <br />
                About the online claim process
                <br />
                <br />
                You can send one claim for more than one {policy} policy.
                <br />
                <br />
                Our aim is to help you through the process by making it easy to send the information
                and documents we need.
            </Typography>
            <ul className={classes.componentBody}>
                <li>
                    We will explain what to do at every step, including which documents to upload.
                </li>
                <li>
                    You don’t have to do everything at once – your changes are saved as you go, so
                    you can come back at any time to complete the process.
                </li>
                <li>This helps the process go quickly.</li>
            </ul>
            <Typography className={classes.componentBody}>
                In order for your claim to be processed, you will need to submit the relevant
                requirements needed to support your claim. All requirements must be uploaded before
                your online claim form can be submitted. In addition to the standard requirements, a
                request may be made for additional requirements after your claim has been submitted.
            </Typography>
        </Grid>
    );
}

export const IntroductionHeader = withStyles(styles)(introductionHeader);
