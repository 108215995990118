import { Grid, Typography, withStyles } from '@material-ui/core';
import * as React from 'react';
import { getAddressLine, getLabel } from '../../../../shared/helper-functions';
import { addressTypes } from '../../../../shared/lookups';
import styles from '../../../../styles/styles';
import { LabelValue } from '../../../common/label-value';

const postalAddress = function ({ classes, claimant }) {
    if (!claimant || !claimant.individual) {
        return null;
    }

    return (
        <Grid container justify="flex-start">
            <Grid item xs={12}>
                <Typography className={classes.whiteTitle}>Postal Address</Typography>
            </Grid>
            {claimant.individual.sameAsResidential ? (
                <Typography className={classes.customLabel}>Same As Residential</Typography>
            ) : (
                <React.Fragment>
                    <LabelValue
                        label="Address Type"
                        value={getLabel(claimant.individual.postalAddressType, addressTypes())}
                    />
                    <LabelValue
                        label={getAddressLine(claimant.individual.postalAddressType)}
                        value={claimant.individual.postalAddressLineOne}
                    />
                    <LabelValue
                        label="Address Line 2"
                        value={claimant.individual.postalAddressLineTwo}
                    />
                    <LabelValue
                        label="Address Line 3"
                        value={claimant.individual.postalAddressLineThree}
                    />
                    <LabelValue
                        label="Town/Suburb"
                        value={claimant.individual.postalAddressSuburb}
                    />
                    <LabelValue label="City" value={claimant.individual.postalAddressTown} />
                    <LabelValue label="Postal Code" value={claimant.individual.postalCode} />
                    <LabelValue
                        label="Province"
                        value={claimant.individual.postalAddressProvince}
                    />
                    <LabelValue label="Country" value={claimant.individual.postalAddressCountry} />
                </React.Fragment>
            )}
        </Grid>
    );
};

export const PostalAddress = withStyles(styles)(postalAddress);
