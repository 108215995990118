import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { getSameAsName } from '../../../../shared/helper-functions';
import styles from '../../../../styles/styles';
import { ContactDetailsPreview } from '../../common/contact-details-preview';
import { LabelValue } from '../../../common/label-value';

const contactDetails = ({ claimant, claimants }) => {
    const individual = claimant.individual;
    return (
        <React.Fragment>
            <Grid item xs={12} style={{ marginTop: '24px' }}>
                <Typography variant="h6">Contact Details</Typography>
                <hr />
            </Grid>
            {!individual.contactsSameAs || individual.contactsSameAs === 'No' ? (
                <ContactDetailsPreview contactDetails={individual} />
            ) : (
                <LabelValue
                    label="Same as"
                    value={
                        individual.relationshipToLifeAssured === 'MinorChild'
                            ? getSameAsName(claimants, claimant.guardian, individual.guardianSameAs)
                            : getSameAsName(
                                  claimants,
                                  claimant.curator,
                                  claimant.curator.curatorSameAs
                              )
                    }
                />
            )}
        </React.Fragment>
    );
};

export const ContactDetailsSummary = withStyles(styles)(contactDetails);
