import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { loadingBarReducer } from 'react-redux-loading-bar';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { confirmationDialogReducer, initialDialogDetails } from '../forms/confirmation-dialog';
import { formModalReducer, initialModalState } from '../forms/form-modal/reducer';
import { loadingScreenReducer } from '../forms/loading-screen/loading-screen-reducer';
import history from '../routing/history';
import { authenticationReducer } from '../authentication';
import { claimSelectionReducer } from '../pages/dashboard';
import { uploadsInProgressReducer } from '../forms/uploads-in-progess/reducer';
import { lookupReducer } from '../lookups';

export const initialState = {
    formModal: initialModalState,
    confirmationDialog: initialDialogDetails,
};

const rootReducer = combineReducers({
    loadingBar: loadingBarReducer,
    toastr: toastrReducer,
    confirmationDialog: confirmationDialogReducer,
    formModal: formModalReducer,
    router: connectRouter(history),
    authentication: authenticationReducer,
    claimSelection: claimSelectionReducer,
    lookups: lookupReducer,
    loadingScreenState: loadingScreenReducer,
    uploadsInProgress: uploadsInProgressReducer,
});

export default rootReducer;
