const charRegex = /[A-Za-z -]/;

export const LastNameMask = value => {
    const nameMask = [charRegex];
    return nameMask.concat(
        value
            .split('')
            .slice(1)
            .filter(c => charRegex.test(c))
            .map(c => charRegex)
    );
};
