import { PolicyHolderSelection } from './policyholder-selection';
import { ContactDetails } from './contact-details';
import { PersonalDetails } from './personal-details';
import { EmploymentDetails } from './employment-details';
import {  PolicyHolderAddressDetails } from './address-details/address-details';
import { EntityDetails } from './entity-details';


export const entityComponents = [
    {
        name: 'Policyholder Type',
        component: PolicyHolderSelection,
        description: 'Specify policyholder type',
    },
    {
        name: 'Policyholder Details',
        component: EntityDetails,
        description: 'Provide all policyholder details',
    },
    {
        name: 'Address Details',
        description: 'Provide policyholder address details',
        component: PolicyHolderAddressDetails,
    },

    {
        name: 'Contact Details',
        description: 'Provide policyholder contact details',
        component: ContactDetails,
    },

];

export const individualComponents = [
    {
        name: 'Policyholder Type',
        component: PolicyHolderSelection,
        description: 'Specify policyholder type',
    },
    {
        name: 'Policyholder Details',
        description: 'Provide all policyholder details',
        component: PersonalDetails,
    },
    {
        name: 'Address Details',
        description: 'Provide policyholder address details',
        component: PolicyHolderAddressDetails,
    },
    {
        name: 'Contact Details',
        description: 'Provide policyholder contact details',
        component: ContactDetails,
    },
    {
        name: 'Employment Details',
        description: 'Provide policy holder employment details',
        component: EmploymentDetails,
    },
];


export const defaultComponents = [
    {
        name: 'Policyholder Type',
        component: PolicyHolderSelection,
        description: 'Specify policyholder type',
    },
];

export const getComponents = policyholder => {
    if (policyholder) {
        if (policyholder.policyHolderType === 'Individual') {
            return individualComponents;
        }
        if (policyholder.policyHolderType === 'Company') {
            return entityComponents;
        }
    }
    return defaultComponents;
};
