import _ from 'lodash';
import { toastr } from 'react-redux-toastr';
import { DateTime } from 'luxon';
import { idOptions, idOptionsWithForeign } from './lookups';
import { useSelector } from 'react-redux';
import { getLookups } from '../lookups';

const { env } = require('../env/env');

export const uploadDefaults = {
    uploadUrl: `${env.REACT_APP_API_URL}/upload-files`,
    fileLimit: 20 * 1024 * 1024,
    multiple: true,
};

export function sortDropdownWithLastItemAs(lastItem) {
    return function specialSorter(a, b) {
        if (a.label === lastItem) return 1;
        if (b.label === lastItem) return -1;
        return a.label.localeCompare(b.label);
    };
}

export const remove = (fields, index) => {
    if (fields.length > 1) {
        fields.remove(index);
    } else {
        toastr.error('Error removing entry', 'You musts at least have one entry', { timeOut: 0 });
    }
};

export function getDisplayDate(date, format = 'dd/MM/yyyy') {
    if (!date) {
        return undefined;
    }
    var newDate = new Date(date);
    if (!isNaN(newDate.getTime())) {
        return DateTime.fromJSDate(newDate).toFormat(format);
    }
    return '';
}

export function getIdentityType(type) {
    if (type === 'Passport' || type === 'RsaId') {
        const option = idOptions.find(id => id.value === type);
        return option && option.label;
    } else {
        const option = idOptionsWithForeign.find(id => id.value === type);
        return option ? option.label : type;
    }
}

export const currencyFormat = value => {
    const currencyFormatter = new Intl.NumberFormat('en-ZA', {
        style: 'currency',
        currency: 'ZAR',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
    return currencyFormatter.format(value || 0);
};

export function convertToNumber(value) {
    if (!value) {
        return undefined;
    }

    const cleanValue = value.replace(/[^\d.]/g, '');

    return parseFloat(cleanValue);
}

export function getMajorClaimants(claimants, current) {
    const minorClaimants = ['MinorChild', 'MajorChild'];
    const possibleParents = [];
    if (claimants && Array.isArray(claimants)) {
        claimants.forEach(claimant => {
            if (
                claimant.claimantId !== current.claimantId &&
                claimant.claimantType === 'Individual' &&
                !minorClaimants.includes(claimant.individual.relationshipToLifeAssured)
            ) {
                possibleParents.push({
                    label: `${claimant.individual.firstName} ${claimant.individual.lastName}`,
                    value: claimant.claimantId,
                });
            }
        });
    }

    possibleParents.push({ value: 'Other', label: 'Other' });

    return possibleParents;
}

export function getPossibleGuardians(claimants) {
    let guardians = [];

    if (claimants) {
        claimants.forEach(claimant => {
            if (claimant && claimant.claimantType === 'Individual') {
                if (
                    claimant.individual &&
                    claimant.individual.relationshipToLifeAssured !== 'MinorChild' &&
                    claimant.individual.firstName &&
                    claimant.individual.lastName
                ) {
                    guardians.push({
                        value: claimant.claimantId,
                        label: `${claimant.individual.firstName} ${claimant.individual.lastName}`,
                    });
                }
            }
        });
    }

    return guardians;
}

export function getClaimant(claimants, claimantId) {
    if (claimantId) {
        if (claimants && Array.isArray(claimants)) {
            const claimant = claimants.find(c => c.claimantId === claimantId);
            if (claimant && claimant.claimantId === claimantId) {
                return `${claimant.individual.firstName} ${claimant.individual.lastName}`;
            }
        }
    }
    return '';
}

export function getLabel(value, codes) {
    if (value && codes) {
        const code = codes.find(id => id.value === value);
        if (code) {
            return code.label;
        }
    }
    return value;
}

export function convertToUpper(value) {
    if (!value) {
        return undefined;
    }

    return _.toUpper(value);
}

export function prettifiedErrors(data) {
    var result = {};
    function recurse(cur, prop) {
        if (Object(cur) !== cur) {
            result[prop] = cur;
        } else if (Array.isArray(cur)) {
            for (var i = 0, l = cur.length; i < l; i++) recurse(cur[i], prop + '[' + i + ']');
            if (l === 0) result[prop] = [];
        } else {
            var isEmpty = true;
            for (var p in cur) {
                isEmpty = false;
                recurse(cur[p], prop ? prop + '.' + p : p);
            }
            if (isEmpty && prop) result[prop] = {};
        }
    }
    recurse(data, '');
    const prettifiedErrorList = Object.entries(result).map(([label, value]) => {
        const labelParts = label.replace(/([a-z](?=[A-Z]))/g, '$1 ').split('.');
        const cleanedLabel = labelParts
            .splice(labelParts.length - 1, labelParts.length)
            .join(' ')
            .toLowerCase()
            .replace('an fa', 'an FA');
        return {
            label: cleanedLabel.charAt(0).toUpperCase() + cleanedLabel.slice(1),
            value: value,
        };
    });
    return prettifiedErrorList;
}

export function CalculateAge(birthday) {
    const ageDifMs = Date.now() - new Date(birthday).getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export function isMinor(dob) {
    const years = CalculateAge(dob);
    return years < 18;
}

export function isAdult(dob) {
    const years = CalculateAge(dob);
    return years > 17;
}

export function isBoxOrBag(addressType) {
    return addressType === 'PostBox' || addressType === 'PrivateBag';
}

export function defaultCountry(type) {
    if (type === 'Foreign') {
        return null;
    }
    return 'South Africa';
}

export function getCommunicationMethods(values) {
    const methods = [];
    if (
        (values.homeNumber && values.homeNumber !== '+') ||
        (values.workNumber && values.workNumber !== '+') ||
        (values.telephoneNumber && values.telephoneNumber !== '+')
    ) {
        methods.push({ value: 'Telephone', label: 'Telephone' });
    }
    if (values.cellphoneNumber && values.cellphoneNumber !== '+') {
        methods.push({ value: 'Cellphone', label: 'Cellphone' });
    }
    if (values.emailAddress) {
        methods.push({ value: 'Email', label: 'Email' });
    }
    return methods;
}

export function getAddressLine(addressType) {
    if (addressType === 'PostBox') {
        return 'PO Box';
    }
    if (addressType === 'PrivateBag') {
        return 'Private Bag';
    }
    return 'Address Line 1';
}

export function getSouthAfricanAddress() {
    return {
        addressType: 'Street',
        country: 'South Africa',
    };
}

export function GetCitySuburb(address) {
    // @ts-ignore
    const { postalCodesBox, postalCodesStreet } = useSelector(state => getLookups(state));
    if (address) {
        const postalCodes = postalCodesStreet
            .concat(...postalCodesBox)
            .find(c => c.value === address.postalCode || c.value === address.addressPostalCode);
        if (postalCodes) {
            const postalCodeDesc = postalCodes.label.split(' - ');
            if (postalCodeDesc.length > 1) {
                return { city: postalCodeDesc[1], suburb: postalCodeDesc[0] };
            }
            return { city: postalCodeDesc[0] };
        }
    }
    return { ...address };
}

export function titleCase(value) {
    if (!value) {
        return '';
    }
    return _.startCase(_.toLower(value));
}

export function getFullName(details) {
    if (!details) {
        return '';
    }

    return titleCase(
        [details.title, details.firstName, details.lastName]
            .filter(value => !!value && value !== '')
            .join(' ')
    );
}

export const getSameAsName = (claimants, details, sameAsId) => {
    return sameAsId === 'Other'
        ? `${details.firstName} ${details.lastName}`
        : getClaimant(claimants, sameAsId);
};
