const charRegex = /[A-Za-z '-]/;

export const FullNameMask = value => {
    const fullnameMask = [charRegex];
    return fullnameMask.concat(
        value
            .split('')
            .slice(1)
            .filter(c => charRegex.test(c))
            .map(c => charRegex)
    );
};
