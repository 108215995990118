import * as React from 'react';
import { Grid, Paper, Typography, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import { OutlinedTextField } from '../../../forms';
import { FixedWidthFileUpload } from '../../../forms/form-components/fixed-width-file-upload';
import {
    EmailMask,
    FirstNameMask,
    LastNameMask,
    TelephoneNumberMask,
} from '../../../forms/input-masks';
import { conditionalRequired, emailFormatValidator } from '../../../forms/validations';
import styles from '../../../styles/styles';
import { NavigationButton } from '../../common/navigation/navigation-button';
import { VerticalStepper } from '../../common/vertical-stepper';

const Executor = withStyles(styles)(function ({
    classes,
    handleBack,
    handleNext,
    index,
    editable,
    stepsLength,
    form,
}) {
    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
                <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                <Typography className={classes.stepHeader}>Executor Details</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography style={{ fontSize: '14px', letterSpacing: 0, lineHeight: '20px' }}>
                    Executor is the legal term for referring to the person (or people) nominated in
                    your will to carry out the directives you set out in your will when you die
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <OutlinedTextField
                    name={`executorsDetails.firstName`}
                    mask={FirstNameMask}
                    validate={conditionalRequired(
                        values =>
                            (values.executorsDetails && values.executorsDetails.lastName) ||
                            (values.executorsDetails && values.executorsDetails.contactNumber) ||
                            (values.executorsDetails && values.executorsDetails.email) ||
                            (values.executorsDetails &&
                                values.executorsDetails.letterOfExecutorship)
                    )}
                    disabled={!editable}
                />
                <OutlinedTextField
                    name={`executorsDetails.lastName`}
                    label="Surname"
                    validate={conditionalRequired(
                        values =>
                            (values.executorsDetails && values.executorsDetails.firstName) ||
                            (values.executorsDetails && values.executorsDetails.contactNumber) ||
                            (values.executorsDetails && values.executorsDetails.email) ||
                            (values.executorsDetails &&
                                values.executorsDetails.letterOfExecutorship)
                    )}
                    mask={LastNameMask}
                    disabled={!editable}
                />
                <OutlinedTextField
                    name={`executorsDetails.contactNumber`}
                    validate={conditionalRequired(
                        values =>
                            ((values.executorsDetails && values.executorsDetails.firstName) ||
                                (values.executorsDetails && values.executorsDetails.lastName) ||
                                (values.executorsDetails &&
                                    values.executorsDetails.letterOfExecutorship)) &&
                            values.executorsDetails &&
                            !values.executorsDetails.email &&
                            !values.executorsDetails.contactNumber
                    )}
                    mask={TelephoneNumberMask}
                    disabled={!editable}
                />
                <OutlinedTextField
                    name={`executorsDetails.email`}
                    validate={
                        conditionalRequired(
                            values =>
                                ((values.executorsDetails && values.executorsDetails.firstName) ||
                                    (values.executorsDetails && values.executorsDetails.lastName) ||
                                    (values.executorsDetails &&
                                        values.executorsDetails.letterOfExecutorship)) &&
                                values.executorsDetails &&
                                !values.executorsDetails.email &&
                                !values.executorsDetails.contactNumber
                        ) && emailFormatValidator
                    }
                    mask={EmailMask}
                    disabled={!editable}
                />
            </Grid>
            <Grid item xs={12}>
                <FixedWidthFileUpload
                    name={`executorsDetails.letterOfExecutorship`}
                    label="Upload Letter of Executorship"
                    validate={conditionalRequired(
                        values =>
                            (values.executorsDetails && values.executorsDetails.firstName) ||
                            (values.executorsDetails && values.executorsDetails.contactNumber) ||
                            (values.executorsDetails && values.executorsDetails.email)
                    )}
                    readonly={!editable}
                />
            </Grid>
            <Grid item xs={12} className={classes.navigationGroup}>
                <NavigationButton
                    color="primary"
                    className={classNames(
                        classes.button,
                        classes.buttonAlignment,
                        classes.fullWidthButton
                    )}
                    onClick={() => handleNext(form)}
                    id="executor-details-continue"
                    label="Continue"
                />
            </Grid>
            <Grid item xs={12} className={classes.navigationGroup}>
                <NavigationButton
                    className={classNames(
                        classes.buttonSecondary,
                        classes.buttonAlignment,
                        classes.fullWidthButton
                    )}
                    onClick={() => handleBack(form)}
                    id="executor-details-back"
                    label="Previous Section"
                />
            </Grid>
        </React.Fragment>
    );
});

const verticalComponents = [
    {
        name: 'Executor Details',
        description:
            'The Letter of Executorship is obtained from the Office of the Master of the High Court.',
        component: Executor,
    },
];

const executorDetails = function ({ classes, form, handleBack, handleSubmit, ...rest }) {
    return (
        <Paper className={[classes.shadedPaper, classes.paperShadowLess].join(' ')}>
            <Grid container spacing={10} justify="flex-start">
                <Grid item xs={12} lg={12}>
                    <VerticalStepper
                        {...rest}
                        verticalComponents={verticalComponents}
                        verticalIndexName={'verticalStepIndex'}
                        form={form}
                        handleBack={handleBack}
                        handleSubmit={handleSubmit}
                    />
                </Grid>
            </Grid>
        </Paper>
    );
};

export const ExecutorDetails = withStyles(styles)(executorDetails);
