import * as React from 'react';
import { Button, Grid, Paper, withStyles } from '@material-ui/core';
import { Print } from '@material-ui/icons';
import styles from '../../../styles/styles';
import { NavigationButton } from '../../common/navigation/navigation-button';
import { DeceasedDetailsSummary } from './deceased-details-summary';
import { ExecutorDetailsSummary } from './executor-details-summary';
import { ClaimantDetailsSummary } from './claimant-details';

const summary = function ({ classes, form, handleBack, handleSubmit }) {
    const values = form.getState().values;
    return (
        <Paper className={[classes.shadedPaper, classes.paperShadowLess].join(' ')}>
            <Grid sx={12} sm={12}>
                <DeceasedDetailsSummary formValues={values} />
                <ClaimantDetailsSummary formData={values} />
                <ExecutorDetailsSummary executorsDetails={values.executorsDetails} />
            </Grid>

            <Grid item xs={12}>
                <Button
                    variant="contained"
                    className={classes.printButton}
                    id="add-insurance-company"
                    style={{display: 'none'}}
                >
                    <Print /> Print
                </Button>
            </Grid>
            <Grid container justify="flex-end" spacing={1} className={classes.continueAndPreviousButton} style={{marginTop: '3%'}}>
                <Grid item xs={5.5}>
                    <NavigationButton
                        className={classes.buttonSecondary}
                        onClick={() => handleBack(form)}
                        id="summary-back"
                        fullWidth
                        style={{
                            height: '48px',
                            width: '176px',
                        }}
                        label="Previous Section"
                    />
                </Grid>
                <Grid item xs={5.5}>
                    <NavigationButton
                        color="primary"
                        className={classes.button}
                        onClick={() => {
                            sessionStorage.setItem('canGoToNextPage', 'true');
                            handleSubmit(form);
                        }}
                        id="summary-continue"
                        fullWidth
                        style={{
                            height: '48px',
                            width: '176px',
                            marginRight: '-4px',
                        }}
                        label="Continue"
                    />
                </Grid>
            </Grid>
        </Paper>
    );
};
export const Summary = withStyles(styles)(summary);
