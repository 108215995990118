import * as React from 'react';
import { Button } from '@material-ui/core';
import { connect } from 'react-redux';

const navigationButton = function ({ id, label, uploadInProgress, ...rest }) {
    return (
        <Button {...rest} disabled={uploadInProgress || rest.disabled} id={id} variant="contained">
            {label}
        </Button>
    );
};

const mapStateToProps = state => ({
    uploadInProgress: state && state.uploadsInProgress && state.uploadsInProgress.uploadsCount > 0,
});

export const NavigationButton = connect(mapStateToProps, {})(navigationButton);
