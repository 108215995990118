import React from 'react';
import { withStyles, Typography, Grid } from '@material-ui/core';
import {
    Condition,
    FixedWidthButtonGroup,
    OutlinedDatePicker,
    OutlinedSearchSelectField,
    OutlinedTextField,
    True,
    WhenFieldChanges,
} from '../../../forms';
import { FirstNameMask, InitialsMask, LastNameMask, NumberMask } from '../../../forms/input-masks';
import { requiredValidator } from '../../../forms/validations';
import { isAdult, isMinor } from '../../../shared/helper-functions';
import { MaritalStatuses, MedicalAidSchemes } from '../../../shared/lookups';
import styles from '../../../styles/styles';
import { DeceasedNavigation } from '../../common/navigation/deceased-navigation';
import { IdentityDetails } from '../../common/identity-details';

const personalDetails = function ({
    classes,
    handleBack,
    handleBackStepper,
    handleNext,
    index,
    editable,
    stepsLength,
    form,
}) {
    const prefix = 'deceasedDetails.';
    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
                <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                <Typography className={classes.stepHeader}>Personal Details</Typography>
            </Grid>
            <Grid item xs={12}>
                <OutlinedTextField
                    name={`${prefix}firstName`}
                    label="First Name"
                    mask={FirstNameMask}
                    validate={requiredValidator}
                    labelprefixed="true"
                    disabled={!editable}
                />

                <OutlinedTextField
                    name={`${prefix}surname`}
                    label="Surname"
                    validate={requiredValidator}
                    labelprefixed="true"
                    mask={LastNameMask}
                    disabled={!editable}
                />
                <OutlinedTextField
                    name={`${prefix}initials`}
                    label="Initials"
                    validate={requiredValidator}
                    labelprefixed="true"
                    mask={InitialsMask}
                    disabled={!editable}
                />
                <IdentityDetails
                    includeGender={false}
                    includeForeignTypes={false}
                    prefix={prefix}
                    editable={editable}
                />
                <Condition when={`${prefix}dateOfBirth`} passes={isAdult}>
                    <True>
                        <OutlinedSearchSelectField
                            name={`${prefix}maritalStatus`}
                            options={MaritalStatuses}
                            label="Marital Status"
                            validate={requiredValidator}
                            labelprefixed="true"
                            disabled={!editable}
                        />
                    </True>
                </Condition>
            </Grid>
            <WhenFieldChanges
                field={`${prefix}dateOfBirth`}
                is={isMinor}
                set={`${prefix}maritalStatus`}
                to={null}
            />
            <WhenFieldChanges
                field={`${prefix}causeOfDeath.wasDeceasedEmployed`}
                set={`${prefix}causeOfDeath.occupationAtDeath`}
                to={null}
            />
            <WhenFieldChanges
                field={`${prefix}causeOfDeath.wasDeceasedEmployed`}
                set={`${prefix}causeOfDeath.employerAtDeath`}
                to={null}
            />
            <WhenFieldChanges
                field={`${prefix}maritalStatus`}
                set={`${prefix}dateOfMarriage`}
                to={null}
            />
            <WhenFieldChanges
                field={`${prefix}causeOfDeath.condition`}
                set={`${prefix}causeOfDeath.otherCondition`}
                to={null}
            />
            <WhenFieldChanges
                field={`${prefix}maritalStatus`}
                set={`${prefix}dateOfMarriage`}
                to={null}
            />
            <WhenFieldChanges
                field={`${prefix}maritalStatus`}
                set={`${prefix}dateOfDivorce`}
                to={null}
            />
            <WhenFieldChanges
                field={`${prefix}medicalAid.scheme`}
                set={`${prefix}medicalAid.otherScheme`}
                to={null}
            />
            <Condition when={`${prefix}dateOfBirth`} passes={isAdult}>
                <True>
                    <Condition when={`${prefix}maritalStatus`} is={['Married', 'Widowed']}>
                        <True>
                            <Grid item xs={12}>
                                <OutlinedDatePicker
                                    name={`${prefix}dateOfMarriage`}
                                    label="Date of Marriage"
                                    disableFuture
                                    disabled={!editable}
                                />
                            </Grid>
                        </True>
                    </Condition>
                    <Condition when={`${prefix}maritalStatus`} is={'Divorced'}>
                        <True>
                            <Grid item xs={12}>
                                <OutlinedDatePicker
                                    name={`${prefix}dateOfDivorce`}
                                    label="Date Of Divorce"
                                    disabled={!editable}
                                    disableFuture
                                />
                            </Grid>
                        </True>
                    </Condition>
                    <OutlinedTextField
                        name={`${prefix}taxReferenceNumber`}
                        label="Tax Reference Number"
                        disabled={!editable}
                        mask={NumberMask}
                    />
                    <FixedWidthButtonGroup
                        label="Was the deceased employed at the date of death ?"
                        name={`${prefix}causeOfDeath.wasDeceasedEmployed`}
                        options={['Yes', 'No']}
                        disabled={!editable}
                    />
                </True>
            </Condition>
            <Condition when={`${prefix}causeOfDeath.wasDeceasedEmployed`} is="Yes">
                <True>
                    <OutlinedTextField
                        name={`${prefix}causeOfDeath.occupationAtDeath`}
                        label="Deceased’s occupation at date of death"
                        validate={requiredValidator}
                        disabled={!editable}
                        labelprefixed="true"
                    />
                    <OutlinedTextField
                        name={`${prefix}causeOfDeath.employerAtDeath`}
                        label="Deceased’s employer at date of death"
                        validate={requiredValidator}
                        disabled={!editable}
                        labelprefixed="true"
                    />
                </True>
            </Condition>
            <OutlinedSearchSelectField
                name={`${prefix}medicalAid.scheme`}
                options={MedicalAidSchemes}
                disabled={!editable}
                label="Medical Aid Scheme"
            />
            <Condition when={`${prefix}medicalAid.scheme`} is="Other">
                <True>
                    <OutlinedTextField
                        name="deceasedDetails.medicalAid.otherScheme"
                        label="Medical aid scheme (Other)"
                        disabled={!editable}
                    />
                </True>
            </Condition>
            <OutlinedTextField
                name={`${prefix}medicalAid.number`}
                label="Medical Aid Number"
                disabled={!editable}
            />
            <DeceasedNavigation
                form={form}
                handleBack={handleBack}
                handleBackStepper={handleBackStepper}
                handleNext={handleNext}
                prefix="deceased-person"
            />
        </React.Fragment>
    );
};

export const PersonalDetails = withStyles(styles)(personalDetails);
