import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { Condition, True, FixedWidthButtonGroup } from '../../../../forms';
import styles from '../../../../styles/styles';
import { ClaimantNavigation } from '../../../common/navigation/claimant-navigation';
import { CommonAddress } from './common-address';
import { requiredValidator } from '../../../../forms/validations';
import classNames from 'classnames';
import { NavigationButton } from '../../../common/navigation/navigation-button';

const addressDetails = function ({
    classes,
    form,
    index,
    handleBack,
    handleBackStepper,
    handleNext,
    editable,
    stepsLength,
    claimantIndex,
}) {
    const policyholder = form.getState().values.policyholder;
    const lifeAssured = form.getState().values.lifeAssured;
    const isEditable = lifeAssured && lifeAssured.PersonalDetails.sameAsPolicyHolder !== 'Yes';
    if (!isEditable && policyholder && policyholder.individual && policyholder.individual.addressDetails) {
        lifeAssured.addressDetails = policyholder.individual.addressDetails;
    }


    return (
        <Grid container spacing={10} justify="flex-start">
            <Grid item xs={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
                <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                <True>
                    <Typography className={classes.stepHeader}>Residential Address Details</Typography>
                </True>
            </Grid>
            <Grid item xs={12}>
            </Grid>
            <Grid item xs={12}>
                <CommonAddress
                    editable={isEditable}
                    prefix={'lifeAssured.addressDetails.'}
                    isResidential
                />
            </Grid>

            <Grid item xs={12} style={{ marginTop: '12px', marginBottom: '8px' }}>
                <Typography className={classes.contentHeader}>Postal address details</Typography>
            </Grid>
            <Grid item xs={12}>
                <FixedWidthButtonGroup
                    label="Postal address same as residential address?"
                    name={'lifeAssured.addressDetails.sameAsResidential'}
                    options={['Yes', 'No']}
                    disabled={!isEditable}
                    validate={requiredValidator}
                    labelprefixed="true"
                />
            </Grid>
            <Condition when={'lifeAssured.addressDetails.sameAsResidential'} is="No">
                <True>
                    <Grid item xs={12}>
                        <CommonAddress
                            editable={isEditable}
                            index={claimantIndex}
                            prefix={'lifeAssured.addressDetails.postal'}
                            isResidential
                            disabled
                        />
                    </Grid>
                </True>
            </Condition>

            <ClaimantNavigation
                form={form}
                handleBackStepper={handleBackStepper}
                handleNext={handleNext}
                prefix={`claimant-${claimantIndex}-address`}
            />
            <Grid item xs={12} className={classes.navigationGroup}>
                <NavigationButton
                    className={classNames(
                        classes.buttonSecondary,
                        classes.buttonAlignment,
                        classes.fullWidthButton
                    )}
                    onClick={() => handleBack(form)}
                    id="policy-details-back"
                    label="Previous Section"
                />
            </Grid>
        </Grid>
    );
};

export const LifeAssuredAddressDetails = withStyles(styles)(addressDetails);
