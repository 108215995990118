import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { Condition, OutlinedPhoneField, OutlinedTextField, True } from '../../../forms';
import { EmailMask, TelephoneNumberMask } from '../../../forms/input-masks';
import {
    emailFormatValidator,
    emailFormatValidatorRequiredField,
    requiredValidator,
    phoneNumberRequiredValidator
} from '../../../forms/validations';
import { isMinor } from '../../../shared/helper-functions';
import styles from '../../../styles/styles';
import { ClaimantNavigation } from '../../common/navigation/claimant-navigation';
import { NavigationButton } from '../../common/navigation/navigation-button';
import classNames from 'classnames';

const contactDetails = function ({
    classes,
    form,
    index,
    handleBack,
    handleNext,
    handleBackStepper,
    editable,
    stepsLength,
    claimantIndex,
}) {
    const prefix = 'policyholder.individual.contactDetails.';
    return (
        <Grid container spacing={10} justify="flex-start">
            <Grid item xs={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
                <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                <Typography className={classes.stepHeader}>Contact Details</Typography>
            </Grid>

            <Grid item xs={12} lg={12}>
                <Grid item xs={12} md={12} xl={12}>
                    <OutlinedPhoneField
                        name={`${prefix}homeTelephoneNumber`}
                        label="Home Telephone Number"
                        mask={TelephoneNumberMask}
                        disabled={!editable}
                    />
                </Grid>
                <Grid item xs={12} md={12} xl={12}>
                    <OutlinedPhoneField
                        name={`${prefix}workTelephoneNumber`}
                        label="Work Telephone Number"
                        mask={TelephoneNumberMask}
                        disabled={!editable}
                    />
                </Grid>
                <Grid item xs={12} md={12} xl={12}>
                    <OutlinedPhoneField
                        name={`${prefix}cellphoneNumber`}
                        label="Cellphone Number"
                        mask={TelephoneNumberMask}
                        labelprefixed={true}
                        disabled={!editable}
                        validate={phoneNumberRequiredValidator}
                    />
                </Grid>
                <Grid item xs={12} md={12} xl={12}>
                    <OutlinedPhoneField
                        name={`${prefix}faxNumber`}
                        label="Fax Number"
                        mask={TelephoneNumberMask}
                        disabled={!editable}
                    />
                </Grid>
                <Grid item xs={12} md={12} xl={12}>
                    <OutlinedTextField
                        name={`${prefix}emailAddress`}
                        label="Email Address"
                        mask={EmailMask}
                        validate={emailFormatValidatorRequiredField}
                        disabled={!editable}
                        labelprefixed={true}
                    />
                </Grid>
            </Grid>
            <Condition when={`${prefix}dateOfBirth`} passes={isMinor}>
                <True>
                    <Grid item xs={12} md={12} xl={12}>
                        <OutlinedTextField
                            name={`${prefix}guardian.workPhone`}
                            label="Work phone number"
                            mask={TelephoneNumberMask}
                            disabled={!editable}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} xl={12}>
                        <OutlinedTextField
                            name={`${prefix}guardian.cellphoneNumber`}
                            label="Cellphone number"
                            mask={TelephoneNumberMask}
                            validate={requiredValidator}
                            disabled={!editable}
                            labelprefixed={true}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} xl={12}>
                        <OutlinedTextField
                            name={`${prefix}guardian.emailAddress`}
                            label="Email address"
                            type="email"
                            mask={EmailMask}
                            validate={emailFormatValidator}
                            disabled={!editable}
                            labelprefixed={true}
                        />
                    </Grid>
                </True>
            </Condition>
            <ClaimantNavigation
                form={form}
                handleBackStepper={handleBackStepper}
                handleNext={handleNext}
                prefix={`claimant-${claimantIndex}-contact`}
            />
            <Grid item xs={12} className={classes.navigationGroup}>
                <NavigationButton
                    className={classNames(
                        classes.buttonSecondary,
                        classes.buttonAlignment,
                        classes.fullWidthButton
                    )}
                    onClick={() => handleBack(form)}
                    id="policy-details-back"
                    label="Previous Section"
                />
            </Grid>
        </Grid>
    );
};

export const ContactDetails = withStyles(styles)(contactDetails);
