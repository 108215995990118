import { Typography, withStyles } from '@material-ui/core';
import React from 'react';
import { getSouthAfricanAddress, isMinor } from '../../shared/helper-functions';
import { ClaimTypes } from '../../shared/lookups';
import informationIcon from '../../styles/icons/information.svg';
import styles from '../../styles/styles';
import { ClaimStepper } from '../claim/claim-stepper';
import { HorizontalStepper } from '../common/horizontal-stepper';
import { ApplicationProcess } from './application-process';
import { ClaimantDetails } from './claimant-details/claimant-details';
import { DeceasedDetails } from './deceased-details/deceased-details';
import { Declaration } from './declaration/declaration';
import { Introduction } from './introduction';
import { PolicyOwner } from './policy-owner/policy-owner';
import { Summary } from './summary/summary';

const defaultComponents = [
    {
        name: 'Introduction',
        information:
            'We outline the documents we need to process your claim, as well as any additional document which might be relevant.',
        component: Introduction,
    },
    {
        name: 'Deceased Details',
        information: 'We will need personal details about the Deceased.',
        component: DeceasedDetails,
    },
    {
        name: 'Summary',
        information: 'This is a chance to review everything before you submit.',
        component: Summary,
    },
    {
        name: 'Declaration',
        information: 'We will need you to confirm that you agree with our terms and conditions.',
        component: Declaration,
    },
];

export const extendedComponents = [
    ...defaultComponents.slice(0, 2),
    {
        name: 'Policy Owner',
        information: 'We will need personal details about the Policy Owner.',
        component: PolicyOwner,
    },
    {
        name: 'Claimant Details',
        information: 'We will need personal details about the Claimant.',
        component: ClaimantDetails,
    },
    ...defaultComponents.slice(2),
];

const ownerComponents = [
    ...defaultComponents.slice(0, 2),
    {
        name: 'Policy Owner',
        information: 'We will need personal details about the Policy Owner.',
        component: PolicyOwner,
    },
    ...defaultComponents.slice(2),
];

const claimantComponents = [
    ...defaultComponents.slice(0, 2),
    {
        name: 'Claimant Details',
        information: 'We will need personal details about the Claimant.',
        component: ClaimantDetails,
    },
    ...defaultComponents.slice(2),
];

export function isBeneficiary(claim) {
    return claim && claim.deceasedDetails && claim.deceasedDetails.isOwner === 'No';
}

export function funeralComponents(claim) {
    if (isBeneficiary(claim)) {
        return claimantComponents;
    }
    return ownerComponents;
}

const defaultInitialValues = { claimType: ClaimTypes.FuneralClaim };

export function validateFuneralClaim(form) {
    const deceasedDetails = form.deceasedDetails || {};
    return {
        ...form,
        deceasedDetails: {
            ...deceasedDetails,
            policyNumbers: deceasedDetails.policyNumbers || [undefined],
            addressDetails: deceasedDetails.addressDetails || getSouthAfricanAddress(),
            funeralParlour: {
                ...deceasedDetails.funeralParlour,
                addressDetails:
                    (deceasedDetails.funeralParlour &&
                        deceasedDetails.funeralParlour.addressDetails) ||
                    getSouthAfricanAddress(),
            },
        },
        policyOwner: deceasedDetails.isOwner === 'Yes' ? form.policyOwner : null,
        claimants:
            deceasedDetails.isOwner === 'No' ? getUpdatedFuneralClaimants(form.claimants) : null,
    };
}

function getUpdatedFuneralClaimants(claimants) {
    const updatedClaimants = [];
    if (claimants) {
        claimants.forEach(claimant => {
            const dateOfBirth = claimant.personalDetails && claimant.personalDetails.dateOfBirth;
            const updatedClaimant = {
                ...claimant,
                guardianDetails:
                    dateOfBirth && isMinor(dateOfBirth) ? claimant.guardianDetails : null,
            };
            updatedClaimants.push(updatedClaimant);
        });
    }
    return updatedClaimants;
}

function claimHeader({ classes, activeStepIndex, components, stepThrough, updateStepIndexAll }) {
    if (activeStepIndex === 0) {
        return null;
    }

    return (
        <React.Fragment>
            <ApplicationProcess sectionIndex={activeStepIndex} />
            <HorizontalStepper
                components={components}
                activeIndex={activeStepIndex}
                stepThrough={stepThrough}
                updateIndex={updateStepIndexAll}
            />
            <SectionNotification
                classes={classes}
                activeStepIndex={activeStepIndex}
                length={components.length}
            />
        </React.Fragment>
    );
}

function SectionNotification({ classes, activeStepIndex, length }) {
    if (activeStepIndex === length - 1) {
        return null;
    }

    const notification =
        activeStepIndex === 3
            ? 'Please check and ensure all the information you provided is correct.'
            : 'Please complete all fields marked with a *';

    return (
        <div className={classes.requiredInformation}>
            <img src={informationIcon} alt="information" />
            <Typography className={classes.requiredInformationText}>{notification}</Typography>
        </div>
    );
}

export const FuneralClaim = withStyles(styles)(() => (
    <ClaimStepper defaultInitialValues={defaultInitialValues} headerComponent={claimHeader} />
));
