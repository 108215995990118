import React from 'react';
import { withStyles, Typography, Grid } from '@material-ui/core';
import styles from '../../../styles/styles';
import { ContactDetailsView } from '../common/contact-details-view';
import { DeceasedNavigation } from '../../common/navigation/deceased-navigation';

const contactDetails = function ({
    classes,
    form,
    handleBack,
    handleBackStepper,
    handleNext,
    index,
    stepsLength,
    editable,
}) {
    const prefix = 'deceasedDetails.contactDetails.';
    return (
        <Grid container spacing={10} justify="flex-start">
            <Grid item xs={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
                <Grid item xs={12} style={{ display: 'flex' }}>
                    <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>{' '}
                    <Typography className={classes.stepHeader}>Contact Details</Typography>
                </Grid>
                <Grid item xs={12}>
                    <ContactDetailsView prefix={prefix} editable={editable} />
                </Grid>
            </Grid>
            <DeceasedNavigation
                form={form}
                handleBack={handleBack}
                handleBackStepper={handleBackStepper}
                handleNext={handleNext}
                prefix="deceased-contact"
            />
        </Grid>
    );
};

export const ContactDetails = withStyles(styles)(contactDetails);
