import * as React from 'react';
import {
    Chip,
    FormHelperText,
    Grid,
    MenuItem,
    TextField,
    Typography,
    withStyles,
} from '@material-ui/core';
import Select from 'react-select';
import { callAllFunctions, getComponentId, nameToLabel, processMetaForErrors } from '../..';
import { getValidatedLabel } from '../../form-component-utilities/label-validator/label-validator';
import styles from '../styles';
import './override.css';

const NoOptionsMessage = props => {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.noOptionsMessage}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
};

const inputComponent = ({ inputRef, ...props }) => {
    return (
        <div
            ref={ref => {
                // @ts-ignore
                inputRef(ref ? ref.inputElement : null);
            }}
            {...props}
        />
    );
};

function Control({ showError, value, shaded, id }) {
    return function _Control(props) {
        return (
            <TextField
                id={id}
                fullWidth
                error={showError}
                variant="outlined"
                InputProps={{
                    inputComponent,
                    inputProps: {
                        className: props.selectProps.classes.input,
                        ref: props.innerRef,
                        children: props.children,
                        ...props.innerProps,
                    },
                    classes: {
                        root: shaded
                            ? props.selectProps.classes.cssOutlinedInputShaded
                            : props.selectProps.classes.cssOutlinedInput,
                        focused: props.selectProps.classes.cssFocused,
                    },
                    inputMode: 'numeric',
                }}
                value={value}
            />
        );
    };
}

const Option = props => {
    return (
        <MenuItem
            buttonRef={props.innerRef}
            selected={props.isFocused}
            component="div"
            style={{
                fontWeight: props.isSelected ? 500 : 400,
            }}
            {...props.innerProps}
        >
            {props.children}
        </MenuItem>
    );
};

const ValueContainer = props => {
    return (
        <Grid container spacing={1}>
            {props.children}
        </Grid>
    );
};

function MultiValue(props) {
    return (
        <Grid item>
            <Chip
                color="secondary"
                variant="outlined"
                tabIndex={-1}
                label={props.children}
                onDelete={event => {
                    props.removeProps.onClick(event);
                    props.removeProps.onMouseDown(event);
                }}
            />
        </Grid>
    );
}

function _SearchSelectField(props) {
    const {
        input,
        meta,
        id,
        label,
        helperText,
        options,
        onChange,
        disabled,
        hideLabel,
        shaded,
        placeholder,
        matchPos,
        ...rest
    } = props;
    const { name, value } = input;
    const { errorMessage, showError } = processMetaForErrors(meta);
    const _id = getComponentId({ id, name });
    const components = {
        Option,
        Control: Control({ showError, value, shaded, id: _id }),
        NoOptionsMessage,
        MultiValue,
        ValueContainer,
    };
    return (
        <div className={props.classes.textField}>
            {!hideLabel && (
                <Typography
                    className={props.classes.cssLabel}
                    style={showError ? { color: '#cd003d' } : {}}
                >
                    {!label
                        ? getValidatedLabel(rest, nameToLabel({ label, name }))
                        : getValidatedLabel(rest, label)}
                </Typography>
            )}
            <Select
                {...rest}
                id={_id}
                name={name}
                isDisabled={disabled}
                options={options}
                matchPos={matchPos}
                components={components}
                value={transformFromValue(options, value)}
                onChange={op => callAllFunctions(onChange, input.onChange)(transformToValue(op))}
                placeholder={`Select ${placeholder || nameToLabel({ label, name }).toLowerCase()}`}
                classNamePrefix="select"
                isClearable
            />
            {(showError || !!helperText) && (
                // @ts-ignore
                <FormHelperText error={showError} component="pre">
                    {showError ? errorMessage : helperText}
                </FormHelperText>
            )}
        </div>
    );
}

function transformFromValue(options, value) {
    if (options) {
        return options.filter(op => op.value === value)[0];
    }
    return undefined;
}

function transformToValue(option) {
    return (option || { value: undefined }).value;
}

export const SearchSelectField = withStyles(styles)(_SearchSelectField);
