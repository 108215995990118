import { Grid, withStyles } from '@material-ui/core';
import React from 'react';
import downloadIcon from '../../../styles/icons/download.svg';
import styles from '../../../styles/styles';

function dowloadFile({ classes, filename, url }) {
    return (
        <Grid item xs={12} className={classes.emptyBlock}>
            <span className={classes.pointerCursor} onClick={() => window.open(url)}>
                <span className={classes.downloadIcon}>
                    <img src={downloadIcon} alt="download" />
                </span>
                <span className={classes.downloadText}>{`Download ${filename}`}</span>
            </span>
        </Grid>
    );
}

export const DownloadFile = withStyles(styles)(dowloadFile);
