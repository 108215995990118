import { env } from '../../env/env';
import axios from '../../http-request';
import { LOAD_UNIVERSAL_BRANCHES, LOAD_BANK_NAMES } from './types';

export function loadBankBranchCodesAction(bankName) {
    return async () => {
        try {
            const response = await axios.get(
                `${env.REACT_APP_API_URL}/lookups/bankBrachCodes?bankName=${bankName}`
            );
            return response.data;
        } catch (err) {
            return [];
        }
    };
}

export const loadUniversalBranchesAction = () => {
    return async dispatch => {
        await axios.get(`${env.REACT_APP_API_URL}/lookups/UniversalBranches`).then(response => {
            dispatch({
                type: LOAD_UNIVERSAL_BRANCHES,
                lookups: response.data,
            });
        });
        return;
    };
};

export const loadBankNamesAction = () => {
    return async dispatch => {
        axios.get(`${env.REACT_APP_API_URL}/lookups/bankNames`).then(response => {
            dispatch({ type: LOAD_BANK_NAMES, lookups: response.data });
        });
        return;
    };
};

export * from './types';
