import { UPLOADS_IN_PROGRESS } from './types';

export const uploadInProgressActionCreator = count => ({
    type: UPLOADS_IN_PROGRESS,
    payload: count,
});

export const uploadInProgressThunk = count => {
    return async dispatch => {
        dispatch(uploadInProgressActionCreator(count));
        return {};
    };
};
