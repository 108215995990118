import * as React from 'react';
import { Grid, withStyles } from '@material-ui/core';
import styles from '../../../../styles/styles';
import { getAddressLine, getLabel } from '../../../../shared/helper-functions';
import { addressTypes } from '../../../../shared/lookups';
import { LabelValue } from '../../../common/label-value';

const addressDetailsSummary = function ({ addressDetails }) {
    if (!addressDetails) {
        return null;
    }

    return (
        <Grid container justify="flex-start">
            <LabelValue
                label="Address Type"
                value={getLabel(addressDetails.addressType, addressTypes())}
            />
            <LabelValue
                label={getAddressLine(addressDetails.addressType)}
                value={addressDetails.addressLineOne}
            />
            <LabelValue label="Address Line 2" value={addressDetails.addressLineTwo} />
            <LabelValue label="Address Line 3" value={addressDetails.addressLineThree} />
            <LabelValue
                label="Town/Suburb"
                value={
                    addressDetails.townSuburb ? addressDetails.townSuburb : addressDetails.suburb
                }
            />
            <LabelValue
                label="City"
                value={addressDetails.city ? addressDetails.city : addressDetails.town}
            />
            <LabelValue
                label="Postal Code"
                value={
                    addressDetails.postalCode
                        ? addressDetails.postalCode
                        : addressDetails.addressPostalCode
                }
            />
            <LabelValue label="Province" value={addressDetails.province} />
            <LabelValue label="Country" value={addressDetails.country} />
            <LabelValue
                label="Proof Of Address Document"
                value={addressDetails.ProofOfAddressDocument}
                isFile={true}
            />
        </Grid>
    );
};

export const AddressDetailsSummary = withStyles(styles)(addressDetailsSummary);
