import React from 'react';
import { withStyles, Typography, Grid } from '@material-ui/core';
import styles from '../../../styles/styles';
import { BankingDetailsView } from '../../common/banking-details';
import { DeceasedNavigation } from '../../common/navigation/deceased-navigation';

const bankingDetails = function ({
    classes,
    form,
    handleBack,
    handleBackStepper,
    handleNext,
    index,
    stepsLength,
    editable,
}) {
    const prefix = 'deceasedDetails.bankingDetails.';
    return (
        <Grid container spacing={10} justify="flex-start">
            <Grid item xs={12} lg={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
                <Grid item xs={12} lg={12} style={{ display: 'flex' }}>
                    <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>{' '}
                    <Typography className={classes.stepHeader}>Banking Details</Typography>
                </Grid>
                <BankingDetailsView editable={editable} prefix={prefix} />
            </Grid>
            <DeceasedNavigation
                form={form}
                handleBack={handleBack}
                handleBackStepper={handleBackStepper}
                handleNext={handleNext}
                prefix="deceased-banking"
            />
        </Grid>
    );
};

export const BankingDetails = withStyles(styles)(bankingDetails);
