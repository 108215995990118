import React from 'react';
import { withStyles, Typography, Grid } from '@material-ui/core';
import { Condition, True, WhenFieldChanges } from '../../../forms';
import { FixedWidthCheckBoxField } from '../../../forms';
import styles from '../../../styles/styles';
import { AddressDetails } from '../common/address-details';
import { DeceasedNavigation } from '../../common/navigation/deceased-navigation';

const deceasedAddressDetails = function ({
    classes,
    form,
    handleBack,
    handleBackStepper,
    handleNext,
    index,
    stepsLength,
    editable,
}) {
    return (
        <Grid item xs={12} container spacing={10} justify="flex-start">
            <Grid item xs={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
                <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                <Typography className={classes.stepHeader}>Address Details</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography className={classes.contentHeader}>Last Residential Address</Typography>
            </Grid>
            <Grid item xs={12}>
                <AddressDetails
                    editable={editable}
                    prefix="deceasedDetails.residentialAddress."
                    isResidential={true}
                />
            </Grid>
            <Grid item xs={12} style={{ marginTop: '20px', marginBottom: '16px' }}>
                <Typography variant="inherit" className={classes.contentHeader}>
                    Last Postal Address
                </Typography>
            </Grid>
            <Grid item xs={12} style={{ marginLeft: '18px' }}>
                <FixedWidthCheckBoxField
                    name="deceasedDetails.postalAddressDifferent"
                    label="Different to Residential Address"
                    disabled={!editable}
                />
                <WhenFieldChanges
                    field="deceasedDetails.postalAddressDifferent"
                    set="deceasedDetails.postalAddress"
                    to={null}
                />
            </Grid>
            <Condition when="deceasedDetails.postalAddressDifferent" is={true}>
                <True>
                    <Grid item xs={12}>
                        <AddressDetails
                            editable={editable}
                            prefix="deceasedDetails.postalAddress."
                            isResidential={false}
                        />
                    </Grid>
                </True>
            </Condition>
            <Grid item xs={12} style={{ marginTop: '20px', marginBottom: '16px' }}>
                <Typography variant="inherit" className={classes.contentHeader}>
                    Last Business Address
                </Typography>
                <Typography>This must be a physical address</Typography>
            </Grid>
            <Grid item xs={12} style={{ marginLeft: '16px' }}>
                <FixedWidthCheckBoxField
                    name="deceasedDetails.businessAddressDifferent"
                    label="Different to Residential Address"
                    disabled={!editable}
                />
                <WhenFieldChanges
                    field="deceasedDetails.businessAddressDifferent"
                    set="deceasedDetails.businessAddress"
                    to={null}
                />
            </Grid>
            <Condition when="deceasedDetails.businessAddressDifferent" is={true}>
                <True>
                    <Grid item xs={12}>
                        <AddressDetails
                            editable={editable}
                            prefix="deceasedDetails.businessAddress."
                            isResidential={true}
                        />
                    </Grid>
                </True>
            </Condition>
            <DeceasedNavigation
                form={form}
                handleBack={handleBack}
                handleBackStepper={handleBackStepper}
                handleNext={handleNext}
                prefix="deceased-address"
            />
        </Grid>
    );
};

export const DeceasedAddressDetails = withStyles(styles)(deceasedAddressDetails);
