import * as React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { OutlinedSearchSelectField } from '../../../forms';
import { PolicyHolderTypes } from '../../../shared/lookups';
import styles from '../../../styles/styles';
import classNames from 'classnames';
import { NavigationButton } from '../../common/navigation/navigation-button';
import { ClaimantNavigation } from '../../common/navigation/claimant-navigation';
import { requiredValidator } from '../../../forms/validations';

const policyHolderSelection = function ({
    classes,
    form,
    index,
    editable,
    handleBack,
    handleNext,
    handleBackStepper,
}) {
    const prefix = 'policyholder.';

    return (
        <React.Fragment>
            <Grid container spacing={10} justify="flex-start">
                <Grid item xs={12} style={{ display: 'flex' }}>
                    <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                    <Typography className={classes.stepHeader}>Policyholder Details</Typography>
                </Grid>
                <Grid item xs={12} lg={12}>
                    <OutlinedSearchSelectField
                        name={`${prefix}policyHolderType`}
                        id={`${prefix}policyHolderType`}
                        options={PolicyHolderTypes}
                        label="Policyholder Type"
                        validate={requiredValidator}
                        disabled={!editable}
                        labelprefixed="true"
                    />
                    <ClaimantNavigation
                        form={form}
                        handleBackStepper={handleBackStepper}
                        handleNext={handleNext}
                        prefix={'policyholder-selection'}
                        index={index}
                    />

                    <Grid item xs={12} className={classes.navigationGroup}>
                        <NavigationButton
                            className={classNames(
                                classes.buttonSecondary,
                                classes.buttonAlignment,
                                classes.fullWidthButton
                            )}
                            onClick={() => handleBack(form)}
                            id="policy-details-back"
                            label="Previous Section"
                        />
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export const PolicyHolderSelection = withStyles(styles)(policyHolderSelection);
