import { CLOSE_DIALOG, OPEN_DIALOG } from '.';

export const initialDialogDetails = {
    dialogOpen: false,
    dialogContentDetails: {},
};

export function confirmationDialogReducer(state = initialDialogDetails, action) {
    switch (action.type) {
        case OPEN_DIALOG:
            return action.payload;
        case CLOSE_DIALOG:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
}
