import { ClaimantSelection } from './claimant-selection';
import { ContactDetails } from './contact-details';
import { PersonalDetails } from './personal-details';
import { FinancialDetails } from './financial-details';
import { ClaimantAddressDetails } from './address-details/address-details';
import { EntityDetails } from './entity-details';

export const entityComponents = [
    {
        name: 'Claimant Type',
        component: ClaimantSelection,
        description: 'In what capacity are you claiming',
    },
    {
        name: 'Entity Details',
        component: EntityDetails,
        description: 'Provide all relevant information',
    },
    {
        name: 'Contact Details',
        description: 'All required claimant contact details',
        component: ContactDetails,
    },
    {
        name: 'Address Details',
        description: 'All relevant claimant address details',
        component: ClaimantAddressDetails,
    },
    {
        name: 'Financial Details',
        description: 'Please provide tax & bank account details',
        component: FinancialDetails,
    },
];

export const defaultComponents = [
    {
        name: 'Claimant Type',
        description: 'In what capacity are you claiming',
        component: ClaimantSelection,
    },
];

export const individualComponents = [
    {
        name: 'Claimant Type',
        description: 'In what capacity are you claiming',
        component: ClaimantSelection,
    },
    {
        name: 'Personal Details',
        description: 'Provide all relevant information',
        component: PersonalDetails,
    },
    {
        name: 'Contact Details',
        description: 'All required claimant contact details',
        component: ContactDetails,
    },
    {
        name: 'Address Details',
        description: 'All relevant claimant address details',
        component: ClaimantAddressDetails,
    },
    {
        name: 'Financial Details',
        description: 'Please provide tax & bank account details',
        component: FinancialDetails,
    },
];

export const getComponents = claimant => {
    if (claimant) {
        if (claimant.claimantType === 'Individual') {
            return individualComponents;
        }
        if (claimant.claimantType === 'Entity') {
            return entityComponents;
        }
    }
    return defaultComponents;
};
