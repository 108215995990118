import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { Condition, OutlinedTextField, True } from '../../../forms';
import { TelephoneNumberMask } from '../../../forms/input-masks';
import {
    emailFormatValidator,
    emailFormatValidatorRequiredField,
    requiredValidator,
} from '../../../forms/validations';
import { isAdult, isMinor } from '../../../shared/helper-functions';
import styles from '../../../styles/styles';
import { ClaimantNavigation } from '../../common/navigation/claimant-navigation';

const contactDetails = function ({
    classes,
    form,
    index,
    handleNext,
    handleBackStepper,
    editable,
    stepsLength,
    claimantIndex,
}) {
    const prefix = `claimants[${claimantIndex}].`;
    return (
        <Grid container spacing={10} justify="flex-start">
            <Grid item xs={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
                <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                <Typography className={classes.stepHeader}>Contact Details</Typography>
            </Grid>
            <Condition when={`${prefix}.claimantType`} is="Individual">
                <True>
                    <Condition when={`${prefix}individual.dateOfBirth`} passes={isAdult}>
                        <True>
                            <Grid item xs={12} lg={12}>
                                <Grid item xs={12} md={12} xl={12}>
                                    <OutlinedTextField
                                        name={`${prefix}individual.workPhone`}
                                        label="Work Phone Number"
                                        mask={TelephoneNumberMask}
                                        disabled={!editable}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12} xl={12}>
                                    <OutlinedTextField
                                        name={`${prefix}individual.cellphoneNumber`}
                                        label="Cellphone Number"
                                        mask={TelephoneNumberMask}
                                        validate={requiredValidator}
                                        disabled={!editable}
                                        labelprefixed="true"
                                    />
                                </Grid>
                                <Grid item xs={12} md={12} xl={12}>
                                    <OutlinedTextField
                                        name={`${prefix}individual.emailAddress`}
                                        label="Email Address"
                                        type="email"
                                        validate={emailFormatValidatorRequiredField}
                                        disabled={!editable}
                                        labelprefixed="true"
                                    />
                                </Grid>
                            </Grid>
                        </True>
                    </Condition>
                    <Condition when={`${prefix}individual.dateOfBirth`} passes={isMinor}>
                        <True>
                            <Grid item xs={12} md={12} xl={12}>
                                <OutlinedTextField
                                    name={`${prefix}guardian.workPhone`}
                                    label="Work phone number"
                                    mask={TelephoneNumberMask}
                                    disabled={!editable}
                                />
                            </Grid>
                            <Grid item xs={12} md={12} xl={12}>
                                <OutlinedTextField
                                    name={`${prefix}guardian.cellphoneNumber`}
                                    label="Cellphone number"
                                    mask={TelephoneNumberMask}
                                    validate={requiredValidator}
                                    disabled={!editable}
                                    labelprefixed="true"
                                />
                            </Grid>
                            <Grid item xs={12} md={12} xl={12}>
                                <OutlinedTextField
                                    name={`${prefix}guardian.emailAddress`}
                                    label="Email address"
                                    type="email"
                                    validate={emailFormatValidator}
                                    disabled={!editable}
                                    labelprefixed="true"
                                />
                            </Grid>
                        </True>
                    </Condition>
                </True>
            </Condition>
            <Condition when={`${prefix}.claimantType`} is="Entity">
                <True>
                    <Grid item xs={12} lg={12}>
                        <Grid item xs={12} md={12} xl={12}>
                            <OutlinedTextField
                                name={`${prefix}entity.cellphoneNumber`}
                                label="Contact Number"
                                disabled={!editable}
                                labelprefixed="true"
                                validate={requiredValidator}
                                mask={TelephoneNumberMask}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} xl={12}>
                            <OutlinedTextField
                                name={`${prefix}entity.emailAddress`}
                                label="Email Address"
                                disabled={!editable}
                                labelprefixed="true"
                                validate={emailFormatValidatorRequiredField}
                            />
                        </Grid>
                    </Grid>
                </True>
            </Condition>
            <ClaimantNavigation
                form={form}
                handleBackStepper={handleBackStepper}
                handleNext={handleNext}
                prefix={`claimant-${claimantIndex}-contact`}
            />
        </Grid>
    );
};

export const ContactDetails = withStyles(styles)(contactDetails);
