import React from 'react';
import { withStyles } from '@material-ui/core';
import styles from '../../../styles/styles';
import axios from '../../../http-request';

export const SelectedExistingClaim = withStyles(styles)(function SelectedExistingClaim({
    goToReferencedClaims,
}) {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let referenceId = params.get('referenceId');
    let referenceSource = params.get('referenceSource');

    if (referenceId && referenceSource) {
        const { env } = require('../../../env/env');
        const url = `${env.REACT_APP_API_URL}/claim/${referenceId}/${referenceSource}`;
        try {
            axios.get(url).then(function (result) {
                const claimsList = result.data.items;
                goToReferencedClaims(claimsList);
            });
        } catch (err) {
            return;
        }
    }

    return <React.Fragment />;
});
