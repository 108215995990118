import { withStyles } from '@material-ui/core';
import React from 'react';
import {
    Condition,
    False,
    OutlinedSearchSelectField,
    OutlinedTextField,
    True,
    When,
    WhenFieldChanges,
} from '../../../../forms';
import { FixedWidthFileUpload } from '../../../../forms/form-components/fixed-width-file-upload';
import { NumberMask } from '../../../../forms/input-masks';
import { conditionalRequired, requiredValidator } from '../../../../forms/validations';
import { defaultCountry, isBoxOrBag } from '../../../../shared/helper-functions';
import { addressTypes, Countries, ProofOfAddress, Provinces } from '../../../../shared/lookups';
import styles from '../../../../styles/styles';

const commonAddress = ({ editable, prefix, isResidential, shaded = false, ...rest }) => {
    const { index, proofOfAddress } = rest;
    const postalCodeProperty = isResidential ? `${prefix}PostalCode` : `${prefix}Code`;
    const addressLine3 = isResidential ? 'residentialAddressLineThree' : 'postalAddressLineThree';
    return (
        <React.Fragment>
            <OutlinedSearchSelectField
                name={`${prefix}AddressType`}
                label="Address Type"
                labelprefixed="true"
                options={addressTypes(isResidential)}
                validate={requiredValidator}
                disabled={!editable}
                shaded={shaded}
            />
            <WhenFieldChanges
                field={`${prefix}AddressType`}
                set={`${prefix}AddressSuburb`}
                to={null}
            />
            <WhenFieldChanges
                field={`${prefix}AddressType`}
                set={`${prefix}AddressTown`}
                to={null}
            />
            <WhenFieldChanges field={`${prefix}AddressType`} set={postalCodeProperty} to={null} />
            <WhenFieldChanges
                field={`${prefix}AddressType`}
                set={`${prefix}AddressCountry`}
                to={defaultCountry}
            />
            <WhenFieldChanges
                field={`${prefix}AddressType`}
                becomes="Foreign"
                set={`${prefix}AddressProvince`}
                to={null}
            />
            <WhenFieldChanges
                field={`${prefix}AddressType`}
                condition={isBoxOrBag}
                set={`${prefix}AddressLineOne`}
                to={null}
            />
            <WhenFieldChanges
                field={`${prefix}AddressType`}
                condition={isBoxOrBag}
                set={`${prefix}AddressLineTwo`}
                to={null}
            />
            <WhenFieldChanges
                field={`${prefix}AddressType`}
                condition={isBoxOrBag}
                set={`${prefix}AddressLineThree`}
                to={null}
            />
            <Condition when={`${prefix}AddressType`} hasValue>
                <True>
                    <Condition when={`${prefix}AddressType`} matchesChild>
                        <When is="PrivateBag">
                            <OutlinedTextField
                                name={`${prefix}AddressLineOne`}
                                label="Private Bag"
                                labelprefixed="true"
                                validate={requiredValidator}
                                disabled={!editable}
                                shaded={shaded}
                            />
                        </When>
                        <When is="PostBox">
                            <OutlinedTextField
                                name={`${prefix}AddressLineOne`}
                                label="PO Box Number"
                                labelprefixed="true"
                                validate={requiredValidator}
                                disabled={!editable}
                                shaded={shaded}
                                mask={NumberMask}
                            />
                        </When>
                    </Condition>
                    <Condition when={`${prefix}AddressType`} is={['Street', 'Foreign']}>
                        <True>
                            <OutlinedTextField
                                name={`${prefix}AddressLineOne`}
                                label="Address Line 1"
                                labelprefixed="true"
                                validate={requiredValidator}
                                disabled={!editable}
                                shaded={shaded}
                            />
                            <OutlinedTextField
                                name={`${prefix}AddressLineTwo`}
                                label="Address Line 2"
                                disabled={!editable}
                                shaded={shaded}
                                validate={conditionalRequired(
                                    values =>
                                        values &&
                                        values.claimants[index] &&
                                        values.claimants[index].individual &&
                                        values.claimants[index].individual[addressLine3]
                                )}
                            />
                            <OutlinedTextField
                                name={`${prefix}AddressLineThree`}
                                label="Address Line 3"
                                disabled={!editable}
                                shaded={shaded}
                            />
                        </True>
                    </Condition>
                    <OutlinedTextField
                        name={`${prefix}AddressSuburb`}
                        label="Town/Suburb"
                        labelprefixed="true"
                        validate={requiredValidator}
                        disabled={!editable}
                        shaded={shaded}
                    />
                    <OutlinedTextField
                        name={`${prefix}AddressTown`}
                        label="City"
                        labelprefixed="true"
                        validate={requiredValidator}
                        disabled={!editable}
                        shaded={shaded}
                    />
                    <Condition when={`${prefix}AddressType`} is={'Foreign'}>
                        <True>
                            <OutlinedTextField
                                name={postalCodeProperty}
                                label="Postal Code"
                                disabled={!editable}
                                shaded={shaded}
                            />
                            <OutlinedSearchSelectField
                                name={`${prefix}AddressCountry`}
                                label="Country"
                                labelprefixed="true"
                                options={Countries}
                                validate={requiredValidator}
                                disabled={!editable}
                                shaded={shaded}
                            />
                        </True>
                        <False>
                            <OutlinedTextField
                                name={postalCodeProperty}
                                label="Postal Code"
                                labelprefixed="true"
                                validate={requiredValidator}
                                shaded={shaded}
                                mask={NumberMask}
                                disabled={!editable}
                            />
                            <OutlinedSearchSelectField
                                name={`${prefix}AddressProvince`}
                                label="Province"
                                labelprefixed="true"
                                options={Provinces}
                                validate={requiredValidator}
                                disabled={!editable}
                                shaded={shaded}
                            />
                            <OutlinedSearchSelectField
                                name={`${prefix}AddressCountry`}
                                label="Country"
                                labelprefixed="true"
                                options={Countries}
                                shaded={shaded}
                                disabled
                            />
                        </False>
                    </Condition>
                    {proofOfAddress && (
                        <React.Fragment>
                            <OutlinedSearchSelectField
                                name={`${prefix}proofOfAddress`}
                                label="Proof  of Address"
                                labelprefixed="true"
                                options={ProofOfAddress}
                                validate={requiredValidator}
                                disabled={!editable}
                                shaded={shaded}
                            />
                            <Condition when={`${prefix}proofOfAddress`} hasValue>
                                <True>
                                    <FixedWidthFileUpload
                                        name={`${prefix}ProofOfAddressDocument`}
                                        label="Upload proof of address"
                                        labelprefixed="true"
                                        validate={requiredValidator}
                                        readonly={!editable}
                                    />
                                </True>
                            </Condition>
                        </React.Fragment>
                    )}
                </True>
            </Condition>
        </React.Fragment>
    );
};

export const CommonAddress = withStyles(styles)(commonAddress);
