import { withStyles, Typography, Grid } from '@material-ui/core';
import styles from '../../../styles/styles';
import React from 'react';
import { Condition, True, WhenFieldChanges } from '../../../forms';
import { OutlinedTextField, FixedWidthButtonGroup } from '../../../forms';
import { FirstNameMask } from '../../../forms/input-masks';
import { requiredValidator } from '../../../forms/validations';
import { DeceasedNavigation } from '../../common/navigation/deceased-navigation';

const occupationDetails = function ({
    classes,
    handleBack,
    handleBackStepper,
    handleNext,
    form,
    index,
    stepsLength,
    editable,
}) {
    const prefix = 'deceasedDetails.occupationDetails.';
    return (
        <Grid container spacing={10} justify="flex-start">
            <Grid item xs={12} lg={12}>
                <Typography className={classes.utilSteps}>
                    {' '}
                    Step {index + 1} of {stepsLength}
                </Typography>
                <Grid item xs={12} lg={12} style={{ display: 'flex' }}>
                    <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                    <Typography className={classes.stepHeader}>Occupation Details</Typography>
                </Grid>

                <Grid item xs={12} lg={12}>
                    <FixedWidthButtonGroup
                        label="Was the deceased employed at the date of death?"
                        name={`${prefix}employmentStatus`}
                        options={['Yes', 'No']}
                        disabled={!editable}
                        validate={requiredValidator}
                        labelprefixed="true"
                    />
                </Grid>
                <WhenFieldChanges
                    field={`${prefix}employmentStatus`}
                    set={`${prefix}occupation`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${prefix}employmentStatus`}
                    set={`${prefix}employer`}
                    to={null}
                />
                <Condition when={`${prefix}employmentStatus`} is={'Yes'}>
                    <True>
                        <Grid item xs={12} lg={12}>
                            <OutlinedTextField
                                name={`${prefix}occupation`}
                                label="Occupation at Date of Death"
                                disabled={!editable}
                                mask={FirstNameMask}
                            />
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <OutlinedTextField
                                name={`${prefix}employer`}
                                label="Employer at Date of Death"
                                disabled={!editable}
                            />
                        </Grid>
                    </True>
                </Condition>
            </Grid>
            <DeceasedNavigation
                form={form}
                handleBack={handleBack}
                handleBackStepper={handleBackStepper}
                handleNext={handleNext}
                prefix="deceased-occupation"
            />
        </Grid>
    );
};

export const OccupationDetails = withStyles(styles)(occupationDetails);
