import * as React from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    Typography,
    withStyles,
} from '@material-ui/core';
import { getDisplayDate, getLabel } from '../../../shared/helper-functions';
import { NaturalCausesOfDeath, UnnaturalCausesOfDeath } from '../../../shared/lookups';
import styles from '../../../styles/styles';
import { AddressDetailsSummary } from '../../ra-death-claim/summary/common/address-details';
import { ExpandMore } from '@material-ui/icons';
import { LabelValue } from '../../common/label-value';

const deceasedDetailsSummary = function ({ classes, formValues }) {
    if (!formValues.deceasedDetails) {
        return null;
    }
    const deceasedDetails = formValues.deceasedDetails;
    return (
        <Accordion
            defaultExpanded={false}
            style={{
                margin: '-24px -16px 24px -16px',
                borderRadius: '4px',
            }}
        >
            <AccordionSummary
                className={[classes.whiteBackground, classes.whiteTitle].join(' ')}
                expandIcon={<ExpandMore />}
                style={{ borderRadius: '4px' }}
            >
                <Typography className={classes.previewIndex}>1</Typography>
                <Typography className={classes.contentHeader}>Deceased Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid sm={12} xs={12}>
                    <PolicyDetails policyNumbers={deceasedDetails.policyNumbers} />
                    <PersonalDetails personalDetails={deceasedDetails} />
                    <DetailsOfDeath
                        deceasedDetails={deceasedDetails}
                        deathCetificate={formValues.deathCertificate}
                        noticeOfDeath={formValues.noticeOfDeath}
                        policeStatement={formValues.policeStatement}
                    />
                    <DoctorsDetails classes={classes} doctorsVisits={formValues.doctorVisits} />
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

const PolicyDetails = ({ policyNumbers }) => {
    if (
        !policyNumbers ||
        (policyNumbers && policyNumbers.length <= 1 && policyNumbers[0] === null)
    ) {
        return null;
    }
    return (
        <Grid container justify="flex-start">
            <Grid item xs={12}>
                <Typography variant="h6">Policy Details</Typography>
                <hr />
            </Grid>
            {policyNumbers.map((policy, policyIndex) => {
                return (
                    <LabelValue
                        key={`policy${policyIndex}`}
                        label={`Policy Number ${policyIndex + 1}`}
                        value={policy}
                    />
                );
            })}
        </Grid>
    );
};

const PersonalDetails = function ({ personalDetails }) {
    return (
        <Grid container justify="flex-start">
            <Grid item xs={12}>
                <Typography variant="h6">Personal Details</Typography>
                <hr />
            </Grid>
            <LabelValue label="First Name" value={personalDetails.firstName} />
            <LabelValue label="Surname" value={personalDetails.surname} />
            <LabelValue label="Initials" value={personalDetails.initials} />
            <LabelValue
                label="Identification Type"
                value={personalDetails.idType === 'RsaId' ? 'South African ID' : 'Passport'}
            />
            {personalDetails.idType === 'RsaId' && (
                <LabelValue label="South African ID Number" value={personalDetails.idNumber} />
            )}
            {personalDetails.idType === 'Passport' && (
                <Grid container justify="flex-start">
                    <LabelValue label="Passport Number" value={personalDetails.idNumber} />
                    <LabelValue label="Country of Issue" value={personalDetails.passport.country} />
                    <LabelValue
                        label="Date of Issue"
                        value={getDisplayDate(personalDetails.passport.issued)}
                    />
                    <LabelValue
                        label="Date of Expiry"
                        value={getDisplayDate(personalDetails.passport.expiry)}
                    />
                </Grid>
            )}
            <LabelValue label="Date of Birth" value={getDisplayDate(personalDetails.dateOfBirth)} />
            <LabelValue label="Marital Status" value={personalDetails.maritalStatus} />
            <LabelValue
                label="Date Of Marriage"
                value={getDisplayDate(personalDetails.dateOfMarriage)}
            />
            <LabelValue
                label="Date of Divorce"
                value={getDisplayDate(personalDetails.dateOfDivorce)}
            />
            <LabelValue label="Tax Reference Number" value={personalDetails.taxReferenceNumber} />
            <LabelValue
                label="Medical Aid Scheme"
                value={
                    personalDetails.medicalAid &&
                    (personalDetails.medicalAid.scheme === 'Other'
                        ? personalDetails.medicalAid.otherScheme
                        : personalDetails.medicalAid.scheme)
                }
            />
            <LabelValue
                label="Medical Aid Number"
                value={personalDetails.medicalAid && personalDetails.medicalAid.number}
            />
        </Grid>
    );
};

const DetailsOfDeath = ({ deceasedDetails, deathCetificate, noticeOfDeath, policeStatement }) => {
    if (!deceasedDetails.causeOfDeath) {
        return null;
    }
    return (
        <Grid container justify="flex-start">
            <Grid item xs={12}>
                <Typography variant="h6">Details Of Death</Typography>
                <hr />
                <Grid container justify="flex-start">
                    <LabelValue
                        label="Date of Death"
                        value={getDisplayDate(deceasedDetails.causeOfDeath.dateOfDeath)}
                    />
                    <LabelValue
                        label="Place of Death"
                        value={deceasedDetails.causeOfDeath.placeOfDeath}
                    />
                    <LabelValue
                        label="Type of Death"
                        value={deceasedDetails.causeOfDeath.conditionType}
                    />
                    <LabelValue
                        label="Condition"
                        value={getLabel(
                            deceasedDetails.causeOfDeath.condition,
                            deceasedDetails.causeOfDeath.conditionType === 'Natural'
                                ? NaturalCausesOfDeath
                                : UnnaturalCausesOfDeath
                        )}
                    />
                    <LabelValue
                        label="Was the death reported to the police ?"
                        value={deceasedDetails.causeOfDeath.wasDeathReportedToPolice}
                    />
                    <LabelValue
                        label="Police Case Number"
                        value={deceasedDetails.causeOfDeath.policeCaseNumber}
                    />
                    <LabelValue label="Police Report" value={policeStatement} isFile={true} />
                    <LabelValue label="Death Cetificate" value={deathCetificate} isFile={true} />
                    <LabelValue label="Notice of Death" value={noticeOfDeath} isFile={true} />
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6">Occupation Details</Typography>
                <hr />
                <Grid container justify="flex-start">
                    <LabelValue
                        label="Was the deceased employed at the date of death ?"
                        value={deceasedDetails.causeOfDeath.wasDeceasedEmployed}
                    />
                    <LabelValue
                        label="Occupation at Death"
                        value={deceasedDetails.causeOfDeath.occupationAtDeath}
                    />
                    <LabelValue
                        label="Employer at Death"
                        value={deceasedDetails.causeOfDeath.employerAtDeath}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

const DoctorsDetails = ({ classes, doctorsVisits }) => {
    if (!doctorsVisits) {
        return null;
    }
    return (
        <Grid container justify="flex-start">
            <Grid item xs={12}>
                <Typography variant="h6">Doctors Details</Typography>
                <hr />
            </Grid>
            {doctorsVisits.map((doctersDetails, doctorsIndex) => {
                return (
                    <Grid container justify="flex-start" key={doctorsIndex}>
                        <Grid item xs={12}>
                            <Typography className={classes.whiteTitle}>
                                {`Doctor ${doctorsIndex + 1}`}
                            </Typography>
                        </Grid>
                        <LabelValue label="First Name" value={doctersDetails.doctorsName} />
                        <LabelValue label="Surname" value={doctersDetails.doctorsSurname} />
                        <LabelValue label="Initials" value={doctersDetails.doctorsInitials} />
                        <LabelValue
                            label="Contact No"
                            value={doctersDetails.doctorsContactDetails}
                        />
                        <AddressDetailsSummary addressDetails={doctersDetails} />
                        {doctersDetails.occurences &&
                            doctersDetails.occurences.map((visit, visitIndex) => {
                                return (
                                    <Grid container justify="flex-start" key={visitIndex}>
                                        <Grid item xs={12}>
                                            <Typography className={classes.whiteTitle}>
                                                {`Doctors Consultation ${visitIndex + 1}`}
                                            </Typography>
                                        </Grid>
                                        <LabelValue
                                            label="Date of Attendance"
                                            value={getDisplayDate(visit.dateOfAttendance)}
                                        />
                                        <LabelValue
                                            label="Reason for Consultation"
                                            value={visit.reason}
                                        />
                                    </Grid>
                                );
                            })}
                    </Grid>
                );
            })}
        </Grid>
    );
};

export const DeceasedDetailsSummary = withStyles(styles)(deceasedDetailsSummary);
