import { connect } from 'react-redux';
import { SelectedExistingClaim as selectedExistingClaim } from './selected-existing-claim';
import { showLoading, hideLoading } from '../../../forms/loading-screen/actions';
import { goToCaptureClaim, goToReferencedClaims } from '../claim-selection-actions';

const mapDispatchToProps = {
    showLoading,
    hideLoading,
    goToCaptureClaim,
    goToReferencedClaims,
};

const mapStateToProps = state => {
    const claimSelection = state.claimSelection || {};
    return {
        referencedClaims: claimSelection.referencedClaims,
        isOnlyReferencedClaims: claimSelection.isOnlyReferencedClaims,
    };
};

export const SelectedExistingClaim = connect(
    mapStateToProps,
    mapDispatchToProps
)(selectedExistingClaim);
