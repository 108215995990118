import React from 'react';
import { withStyles, Typography, Grid, Paper, Button } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import classNames from 'classnames';
import { FieldArray } from 'react-final-form-arrays';
import styles from '../../../styles/styles';
import { ExpansionPanel, OutlinedDatePicker, OutlinedTextField } from '../../../forms';
import { CurrencyMask, FirstNameMask, LastNameMask } from '../../../forms/input-masks';
import { convertToNumber, remove } from '../../../shared/helper-functions';
import { DeceasedNavigation } from '../../common/navigation/deceased-navigation';
import { requiredValidator } from '../../../forms/validations';

const otherInsurancePolicies = function ({
    classes,
    handleBack,
    handleBackStepper,
    handleNext,
    index,
    stepsLength,
    form,
    editable,
}) {
    const { push } = form.mutators;
    return (
        <Grid container spacing={10} justify="flex-start">
            <Grid item xs={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
                <Grid item xs={12} style={{ display: 'flex' }}>
                    <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                    <Typography className={classes.stepHeader}>Other Insurance Policies</Typography>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid item xs={12}>
                    <Typography className={classes.description}>
                        Please provide information of insurance policy & the beneficiaries of any
                        other policies owned by the deceased with other companies.
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: '28px' }}>
                <FieldArray name="deceasedDetails.insuranceCompany">
                    {({ fields }) =>
                        fields.map(insuranceCompanyRow(fields, classes, editable, form))
                    }
                </FieldArray>
                <Button
                    variant="contained"
                    className={classNames(classes.customButton, classes.fullWidthButton)}
                    onClick={() => push('deceasedDetails.insuranceCompany', undefined)}
                    id="add-insurance-company"
                    disabled={!editable}
                >
                    <AddCircle style={{ marginRight: '8px' }} /> Add Insurance Company
                </Button>
            </Grid>
            <DeceasedNavigation
                form={form}
                handleBack={handleBack}
                handleBackStepper={handleBackStepper}
                handleNext={handleNext}
                prefix="deceased-insurance"
            />
        </Grid>
    );
};
function insuranceCompanyRow(fields, classes, editable, form) {
    const { push } = form.mutators;
    return function _insuranceCompanyRow(insuranceCompany, companyIndex) {
        return (
            <Grid
                key={`company${companyIndex}`}
                container
                item
                xs={12}
                style={{ paddingBottom: '24px' }}
            >
                <ExpansionPanel
                    name={`company${companyIndex + 1}`}
                    className={classes.expensionPanel}
                    id={`company ${companyIndex + 1}`}
                    label={`Company ${companyIndex + 1}`}
                    key={`Company${companyIndex}`}
                    remove={() => remove(fields, companyIndex)}
                    deletable={true}
                >
                    <Paper className={classes.paperShadowLess}>
                        <OutlinedTextField
                            name={`deceasedDetails.insuranceCompany[${companyIndex}].InsurerName`}
                            id={`deceasedDetails.insuranceCompany[${companyIndex}].InsurerName`}
                            label={'Insurer Name'}
                            className={classes.expensionPanelComponents}
                            disabled={!editable}
                        />

                        <OutlinedTextField
                            name={`deceasedDetails.insuranceCompany[${companyIndex}].productType`}
                            id={`deceasedDetails.insuranceCompany[${companyIndex}].productType`}
                            label={'Product Type'}
                            className={classes.expensionPanelComponents}
                            disabled={!editable}
                        />

                        <OutlinedTextField
                            name={`deceasedDetails.insuranceCompany[${companyIndex}].policyNumber`}
                            id={`deceasedDetails.insuranceCompany[${companyIndex}].policyNumber`}
                            label={'Policy Number'}
                            className={classes.expensionPanelComponents}
                            disabled={!editable}
                        />

                        <OutlinedTextField
                            name={`deceasedDetails.insuranceCompany[${companyIndex}].sumAssured`}
                            id={`deceasedDetails.insuranceCompany[${companyIndex}].sumAssured`}
                            label={'Sum Assured'}
                            className={classes.expensionPanelComponents}
                            mask={CurrencyMask}
                            parse={convertToNumber}
                            disabled={!editable}
                        />
                        <OutlinedDatePicker
                            name={`deceasedDetails.insuranceCompany[${companyIndex}].issueDate`}
                            id={`deceasedDetails.insuranceCompany[${companyIndex}].issueDate`}
                            label={'Issue Date'}
                            disableFuture
                            className={classes.expensionPanelComponents}
                            disabled={!editable}
                        />
                        {fields.length > 0 && (
                            <FieldArray
                                name={`deceasedDetails.insuranceCompany[${companyIndex}].beneficiary`}
                            >
                                {({ fields }) =>
                                    fields.map(
                                        addBeneficiary(fields, classes, editable, companyIndex)
                                    )
                                }
                            </FieldArray>
                        )}

                        <Button
                            variant="contained"
                            className={classes.customButton}
                            onClick={() =>
                                push(
                                    `deceasedDetails.insuranceCompany[${companyIndex}].beneficiary`,
                                    undefined
                                )
                            }
                            id="add-insurance-company"
                            fullWidth
                            style={{
                                marginLeft: '1px',
                                minWidth: '336px',
                            }}
                            disabled={!editable}
                        >
                            <AddCircle style={{ marginRight: '8px' }} /> Add Beneficiary
                        </Button>
                    </Paper>
                </ExpansionPanel>
            </Grid>
        );
    };
}

function addBeneficiary(fields, classes, editable, companyIndex) {
    return function _addBeneficiary(beneficiary, beneficiaryIndex) {
        return (
            <Grid style={{ position: 'relative' }} key={`Beneficiary${beneficiaryIndex}`}>
                <ExpansionPanel
                    name={`beneficiary${beneficiaryIndex + 1}`}
                    id={`beneficiary${beneficiaryIndex + 1}`}
                    className={classes.expensionPanel}
                    style={{ marginLeft: '-16px', marginTop: '16px' }}
                    remove={() => remove(fields, beneficiaryIndex)}
                    deletable={true}
                >
                    <OutlinedTextField
                        name={`deceasedDetails.insuranceCompany[${companyIndex}].beneficiary[${beneficiaryIndex}].name`}
                        id={`deceasedDetails.insuranceCompany[${companyIndex}].beneficiary[${beneficiaryIndex}].name`}
                        label={'Name'}
                        className={classes.expensionPanelComponents}
                        disabled={!editable}
                        validate={requiredValidator}
                        labelprefixed="true"
                        mask={FirstNameMask}
                    />
                    <OutlinedTextField
                        name={`deceasedDetails.insuranceCompany[${companyIndex}].beneficiary[${beneficiaryIndex}].surname`}
                        id={`deceasedDetails.insuranceCompany[${companyIndex}].beneficiary[${beneficiaryIndex}].surname`}
                        label={'Surname'}
                        className={classes.expensionPanelComponents}
                        disabled={!editable}
                        validate={requiredValidator}
                        labelprefixed="true"
                        mask={LastNameMask}
                    />
                </ExpansionPanel>
            </Grid>
        );
    };
}

export const OtherInsurancePolicies = withStyles(styles)(otherInsurancePolicies);
