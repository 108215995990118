const digitRegex = /\d/;

export const TelephoneNumberMask = value => {
    if (value[0] === '+') {
        return internationalTelephoneNumberMask(value);
    }

    if (value[0] === '0') {
        return nationalTelephoneNumberMask(value);
    }

    return [/[+0]/];
};

const internationalTelephoneNumberMask = value => {
    const numberMask = [/\+/];
    return numberMask.concat(telephoneNumberMask(value.slice(1)));
};

const nationalTelephoneNumberMask = value => {
    const numberMask = [/0/];
    return numberMask.concat(telephoneNumberMask(value.slice(1)));
};

const telephoneNumberMask = value => {
    const numberMask = [];
    let digitCount = 0;

    // eslint-disable-next-line
    for (const char of value.split('').slice()) {
        if (digitRegex.test(char)) {
            numberMask.push(digitRegex);
            if (++digitCount === 15) {
                return numberMask;
            }
        }
    }

    return numberMask;
};
