import { HIDE_LOADING, SHOW_LOADING } from './loading-screen-types';

export const initialLoadingScreenState = {
    loadingCount: 0,
};

export function loadingScreenReducer(state = initialLoadingScreenState, action) {
    switch (action.type) {
        case SHOW_LOADING:
            return {
                loadingCount: state.loadingCount + 1,
            };
        case HIDE_LOADING:
            return {
                loadingCount: Math.max(0, state.loadingCount - 1),
            };
        default:
            return state;
    }
}
