import * as React from 'react';
import { Button, Grid, Paper, withStyles } from '@material-ui/core';
import { Print } from '@material-ui/icons';
import styles from '../../../styles/styles';
import { NavigationButton } from '../../common/navigation/navigation-button';

import { ClaimantSummary } from './claimant-details/claimant-summary';
import { PolicyHolderSummary } from './policyholder-details/policyholder-summary';
import { LifeAssuredSummary } from './life-assured-details/life-assured-summary';
import { PaymentSummary }  from './payment-details/payment-summary';

const summary = function ({ classes, form, handleBack, handleSubmit }) {
    return (
        <Paper className={[classes.shadedPaper, classes.paperShadowLess].join(' ')}>
            <ClaimantSummary />
            <br />
            <PolicyHolderSummary />
            <br />
            <LifeAssuredSummary />
            <br />
            <PaymentSummary />
            <Grid container spacing={4}>
                <Grid item xs={2} sm={8}>
                    <Button
                        variant="contained"
                        className={classes.printButton}
                        id="add-insurance-company"
                        fullWidth
                        style={{
                            height: '48px',
                            width: '176px',
                        }}
                    >
                        <Print /> Print
                    </Button>
                </Grid>
                <Grid item xs={2} sm={2}>
                    <NavigationButton
                        className={classes.buttonSecondary}
                        onClick={() => handleBack(form)}
                        id="summary-back"
                        fullWidth
                        style={{
                            height: '48px',
                            width: '176px',
                        }}
                        label="Previous Section"
                    />
                </Grid>
                <Grid item xs={2}>
                    <NavigationButton
                        color="primary"
                        className={classes.button}
                        onClick={() => {
                            sessionStorage.setItem('canGoToNextPage', 'true');
                            handleSubmit(form);
                        }}
                        id="summary-continue"
                        fullWidth
                        style={{
                            height: '48px',
                            width: '176px',
                            marginRight: '-4px',
                        }}
                        label="Continue"
                    />
                </Grid>
            </Grid>
        </Paper>
    );
};

export const Summary = withStyles(styles)(summary);
