import { Grid, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import styles from '../../../styles/styles';
import { FuneralAddressDetails } from './funeral-address-details';

const addressDetails = function ({ classes, prefix = '', isWorkAddress = false, ...rest }) {
    const { index, stepsLength, editable } = rest;
    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
                <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                <Typography className={classes.stepHeader}>{`${
                    isWorkAddress ? 'Work' : 'Residential'
                } Address Details`}</Typography>
            </Grid>
            <FuneralAddressDetails editable={editable} prefix={prefix} />
        </React.Fragment>
    );
};

export const AddressDetails = withStyles(styles)(addressDetails);
