import * as React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import { OutlinedSearchSelectField } from '../../../forms';
import { ClaimantTypes } from '../../../shared/lookups';
import styles from '../../../styles/styles';
import { NavigationButton } from '../../common/navigation/navigation-button';

const claimantSelection = function ({
    classes,
    form,
    index,
    editable,
    handleNext,
    stepsLength,
    claimantIndex,
}) {
    const prefix = `claimants[${claimantIndex}].`;

    return (
        <Grid container spacing={10} justify="flex-start">
            <Grid item xs={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
                <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                <Typography className={classes.stepHeader}>Claimant Type</Typography>
            </Grid>
            <Grid item xs={12} lg={12}>
                <OutlinedSearchSelectField
                    name={`${prefix}claimantType`}
                    id="claimantType"
                    options={ClaimantTypes}
                    label="Claimant type"
                    disabled={!editable}
                />
            </Grid>
            <Grid item xs={12} className={classes.navigationGroup}>
                <NavigationButton
                    color="primary"
                    className={classNames(
                        classes.button,
                        classes.buttonAlignment,
                        classes.fullWidthButton
                    )}
                    onClick={() => handleNext(form)}
                    id={`claimant-sel-continue-${claimantIndex}`}
                    fullWidth
                    label="Continue"
                />
            </Grid>
        </Grid>
    );
};

export const ClaimantSelection = withStyles(styles)(claimantSelection);
