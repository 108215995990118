import * as React from 'react';
import { Grid, Paper, Typography, withStyles } from '@material-ui/core';
import styles from '../../styles/styles';
import { DownloadFile } from '../common/introduction/download-file';
import { IntroductionHeader } from '../common/introduction/introduction-header';
import { StepDisclaimer } from '../common/introduction/step-disclaimer';
import { StepsInformation } from '../common/introduction/steps-information';
import { IntroNavigation } from '../common/navigation/intro-navigation';
import { raDeathComponents } from './retirement-annuity-claim';

const { env } = require('../../env/env');

const introduction = function ({ classes, editable, form, handleSubmit }) {
    return (
        <Paper className={[classes.shadedPaper, classes.paperShadowLess].join(' ')}>
            <Grid container spacing={0} justify="flex-start">
                <IntroductionHeader claim="Retirement Annuity Death" policy="retirement" />
                <Grid item xs={12} className={classes.emptyBlock}>
                    <Typography className={classes.componentBody}>
                        Declaration of Dependency
                        <br />
                        Applicable to financial dependants
                    </Typography>
                </Grid>
                <Grid item xs={12} className={classes.emptyBlock}>
                    <Typography className={classes.componentBody}>
                        Section 37C of the Pension Funds Act governs the way in which the Trustees
                        of the Lifestyle Retirement Annuity Fund, the Lifestyle Retirement Preserver
                        Pension Fund and the Lifestyle Retirement Preserver Provident Fund are
                        required to distribute Death benefits between dependants and nominees. To
                        enable the Trustees of the Fund to consider payment to a dependant in fair
                        proportions should the claim be paid, we require the dependant(s) to
                        complete this form.
                    </Typography>
                </Grid>
                <DownloadFile url={env.RA_Checklist_URL} filename="Claim requirements checklist" />
                <StepsInformation components={raDeathComponents} />
                <StepDisclaimer name="Deceased Details" />
                <Grid item xs={12} className={classes.emptyBlock}>
                    <div className={classes.headingNoBg}>1. Death Certificate</div>
                    <div className={classes.bodyNoBg}>Issued by the Department of Home Affairs</div>
                    <hr />
                </Grid>

                <Grid item xs={12} className={classes.emptyBlock}>
                    <div className={classes.headingNoBg}>2. Notice of Death </div>
                    <div className={classes.bodyNoBg}>
                        Issued by the doctor who certified the Death or by the undertaker
                    </div>
                    <hr />
                </Grid>
                <Grid item xs={12} className={classes.emptyBlock}>
                    <div className={classes.headingNoBg}>3. Police Statement</div>
                    <div className={classes.bodyNoBg}>
                        Only needed in the event of unnatural Death, completed by investigating
                        officer
                    </div>
                    <hr />
                </Grid>
                <Grid item xs={12} className={classes.emptyBlock}>
                    <div className={classes.headingNoBg}>4. Accident Report</div>
                    <div className={classes.bodyNoBg}>
                        Only needed in the event of unnatural Death
                    </div>
                    <hr />
                </Grid>
                <Grid item xs={12} className={classes.emptyBlock}>
                    <div className={classes.headingNoBg}>5. Marriage Certificate</div>
                    <div className={classes.bodyNoBg}>
                        If the deceased was married at the time of Death we require a copy of the
                        Marriage Certificate
                    </div>
                    <hr />
                </Grid>
                <Grid item xs={12} className={classes.emptyBlock}>
                    <div className={classes.headingNoBg}>6. Divorce Decree</div>
                    <div className={classes.bodyNoBg}>
                        If the deceased was previously married, copies of all Divorce Decree’s of
                        the previous marriages
                    </div>
                    <hr />
                </Grid>
                <Grid item xs={12} className={classes.emptyBlock}>
                    <div className={classes.headingNoBg}>7. Extract of Medical History</div>
                    <div className={classes.bodyNoBg}>
                        Personal medical attendant's report, if a policy is less than 3 years
                    </div>
                    <hr />
                </Grid>
                <Grid item xs={12} className={classes.emptyBlock}>
                    <div className={classes.headingNoBg}>8. Last Will and Testament</div>
                    <div className={classes.bodyNoBg}>
                        Copy of the Last Will & Testament, if there is no will an affidavit
                        confiming this
                    </div>
                    <hr />
                </Grid>
                <StepDisclaimer name="Claimant Details" />

                <Grid item xs={12} className={classes.emptyBlock}>
                    <div className={classes.headingNoBg}>
                        1. Identity document, ID card, passport or unabridged birth certificate
                    </div>
                    <div className={classes.bodyNoBg}>
                        A certified copy to be provided by all claimants - if it is an ID card, a
                        copy of the back and the front of the ID smart card must be provided or a
                        copy of the <b>unabridged birth certificate</b> if claimant is a minor
                    </div>
                    <hr />
                </Grid>

                <Grid item xs={12} className={classes.emptyBlock}>
                    <div className={classes.headingNoBg}>2. Proof of Residence</div>
                    <div className={classes.bodyNoBg}>We require one of the following :</div>
                    <div>
                        <ul>
                            <li className={classes.listItem}>A Utility Bill</li>
                            <ul>
                                <li className={classes.subListItem}>less than three months old</li>
                            </ul>
                        </ul>
                        <ul>
                            <li className={classes.listItem}>A Lease or Rental Agreement</li>
                            <ul>
                                <li className={classes.subListItem}>less than a year old</li>
                            </ul>
                        </ul>
                        <ul>
                            <li className={classes.listItem}>
                                A Municipal Rates and Taxes Invoice
                            </li>
                            <ul>
                                <li className={classes.subListItem}>less than three months old</li>
                            </ul>
                        </ul>
                        <ul>
                            <li className={classes.listItem}>A Telephone Account</li>
                            <ul>
                                <li className={classes.subListItem}>land-line or cell phone</li>
                                <li className={classes.subListItem}>less than three months old</li>
                            </ul>
                        </ul>
                        <ul>
                            <li className={classes.listItem}>
                                A Retail Account Statement or Invoice
                            </li>
                            <ul>
                                <li className={classes.subListItem}>less than three months old</li>
                            </ul>
                        </ul>
                        <ul>
                            <li className={classes.listItem}>An Official Tax Return</li>
                            <ul>
                                <li className={classes.subListItem}>less than a year old</li>
                            </ul>
                        </ul>
                        <ul>
                            <li className={classes.listItem}>
                                An official Tax Assessment or Official Correspondence from the Local
                                Revenue Services
                            </li>
                            <ul>
                                <li className={classes.subListItem}>less than 12 months old</li>
                            </ul>
                        </ul>
                        <ul>
                            <li className={classes.listItem}>
                                A Valid Television License Document
                            </li>
                            <ul>
                                <li className={classes.subListItem}>less than a year old</li>
                            </ul>
                        </ul>
                        <ul>
                            <li className={classes.listItem}>A Bank Statement</li>
                            <ul>
                                <li className={classes.subListItem}>
                                    any bank, excluding Standard Bank
                                </li>
                            </ul>
                        </ul>
                        <ul>
                            <li className={classes.listItem}>
                                Confirmation from a Tribal Aauthority or Municipal Councilor
                            </li>
                        </ul>
                        <ul>
                            <li className={classes.listItem}>
                                Affidavit from an Aauthorised Police Official
                            </li>
                        </ul>
                    </div>
                    <hr />
                </Grid>
                <Grid item xs={12} className={classes.emptyBlock}>
                    <div className={classes.headingNoBg}>3. Proof of Guardianship/Caregiver</div>
                    <div className={classes.bodyNoBg}>
                        If the claimant is a minor we require <b>proof of guardianship </b>
                        or a <b>letter from a registered social worker</b>
                    </div>
                    <hr />
                </Grid>

                <Grid item xs={12} className={classes.emptyBlock}>
                    <div className={classes.headingNoBg}>
                        4. Guardian’s Identity document, ID card, passport
                    </div>
                    <div className={classes.bodyNoBg}>
                        If the claimant is a minor, a certified copy to be provided by the guardian
                        - if it is an ID card, a copy of the back and the front of the ID smart card
                        must be provided
                    </div>
                    <hr />
                </Grid>

                <Grid item xs={12} className={classes.emptyBlock}>
                    <div className={classes.headingNoBg}>5. Letter of Curatorship</div>
                    <div className={classes.bodyNoBg}>
                        If the claimant is a major and disabled & unable to take care of themselves
                        we require a letter of curatorship
                    </div>
                    <hr />
                </Grid>

                <Grid item xs={12} className={classes.emptyBlock}>
                    <div className={classes.headingNoBg}>
                        6. Curator’s Identity document, ID card, passport
                    </div>
                    <div className={classes.bodyNoBg}>
                        If the claimant is a major and disabled, a certified copy to be provided by
                        the curator - if it is an ID card, a copy of the back and the front of the
                        ID smart card must be provided
                    </div>
                    <hr />
                </Grid>
                <Grid item xs={12} className={classes.emptyBlock}>
                    <div className={classes.headingNoBg}>7. Letter of Executorship</div>
                    <div className={classes.bodyNoBg}>
                        This is obtained from the Office of the Master of the High Court.
                    </div>
                    <hr />
                </Grid>
                <Grid item xs={12} className={classes.emptyBlock}>
                    <div className={classes.headingNoBg}>8. Proof of Studies</div>
                    <div className={classes.bodyNoBg}>
                        If the claimant is a major and a student proof of registration at education
                        facility, approximate fees and details of study
                    </div>
                    <hr />
                </Grid>
                <Grid item xs={12} className={classes.emptyBlock}>
                    <div className={classes.headingNoBg}>9. Exchange Form</div>
                    <div className={classes.bodyNoBg}>
                        Required if you have you officially emigrated
                    </div>
                    <hr />
                </Grid>
                <Grid item xs={12} className={classes.emptyBlock}>
                    <div className={classes.headingNoBg}>10. Entity Documentation</div>
                    <div className={classes.bodyNoBg}>
                        If the claimant is an entity, we require the following, but is not limited
                        to :
                    </div>
                    <div>
                        <ul>
                            <li className={classes.listItem}>Closed Corporation</li>
                            <ul>
                                <li className={classes.subListItem}>CK1 Document</li>
                            </ul>
                        </ul>
                        <ul>
                            <li className={classes.listItem}>Foreign Company</li>
                            <ul>
                                <li className={classes.subListItem}>Company Resolution</li>
                                <li className={classes.subListItem}>
                                    ID Documents of the Members of the Company
                                </li>
                            </ul>
                        </ul>
                        <ul>
                            <li className={classes.listItem}>Foreign Trust</li>
                            <ul>
                                <li className={classes.subListItem}>
                                    Trust Resolution or Trust Deed
                                </li>
                                <li className={classes.subListItem}>
                                    Letter of Authority reflecting all trustee names
                                </li>
                            </ul>
                        </ul>
                        <ul>
                            <li className={classes.listItem}>Non Profit Organisation</li>
                            <ul>
                                <li className={classes.subListItem}>CK1 Document</li>
                            </ul>
                        </ul>
                        <ul>
                            <li className={classes.listItem}>Personal Liability Company</li>
                            <ul>
                                <li className={classes.subListItem}>Company Resolution</li>
                                <li className={classes.subListItem}>
                                    ID Documents of the Members of the Company
                                </li>
                            </ul>
                        </ul>
                        <ul>
                            <li className={classes.listItem}>Private Company</li>
                            <ul>
                                <li className={classes.subListItem}>Company Resolution</li>
                                <li className={classes.subListItem}>
                                    ID Documents of the Members of the Company
                                </li>
                            </ul>
                        </ul>
                        <ul>
                            <li className={classes.listItem}>Public Company</li>
                            <ul>
                                <li className={classes.subListItem}>Company Resolution</li>
                                <li className={classes.subListItem}>
                                    ID Documents of the Members of the Company
                                </li>
                            </ul>
                        </ul>
                        <ul>
                            <li className={classes.listItem}> State Owned Company</li>

                            <ul>
                                <li className={classes.subListItem}>Company Resolution</li>
                                <li className={classes.subListItem}>
                                    ID Documents of the Members of the Company
                                </li>
                            </ul>
                        </ul>
                        <ul>
                            <li className={classes.listItem}>Trust</li>
                            <ul>
                                <li className={classes.subListItem}>
                                    Trust Resolution or Trust Deed
                                </li>
                                <li className={classes.subListItem}>
                                    Letter of Authority reflecting all trustee names
                                </li>
                            </ul>
                        </ul>
                    </div>
                    <hr />
                </Grid>
                <Grid item xs={12} className={classes.grayTitle}>
                    <Typography className={classes.grayTitleText}>
                        Track the progress of your Claim
                    </Typography>
                </Grid>
                <div className={classes.bodyNoBg} style={{ marginTop: '15px' }}>
                    Once you’ve submitted the claim, you’ll get an email from Liberty to confirm we
                    have received it. We will keep you updated on the progress of the claim by email
                    and you can log in here again to see the progress of your claim or upload any
                    outstanding documentation.
                </div>
                <DownloadFile url={env.RA_Checklist_URL} filename="Claim requirements checklist" />
                <IntroNavigation editable={editable} form={form} handleSubmit={handleSubmit} />
            </Grid>
        </Paper>
    );
};

export const Introduction = withStyles(styles)(introduction);
