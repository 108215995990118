import { connect } from 'react-redux';
import { initiateLogin, loginPageProps } from '../../authentication';
import { LoginPage } from './login';
import { push } from 'connected-react-router';

function mapStateToProps(state) {
    return loginPageProps(state);
}

const searchParams = new URLSearchParams(window.location.search);
const returnUrl = searchParams && searchParams.has('returnUrl')
    && searchParams.get('returnUrl').length > 0
    ? searchParams.get('returnUrl') : '/dashboard';

push(returnUrl);

const mapDispatchToProps = {
    initiateLogin,
    goToClaimSelection: () => push(returnUrl),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginPage);
