import React from 'react';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';

export const DocumentViewer = function ({ documents }) {
    return (
        <DocViewer
            documents={documents}
            pluginRenderers={DocViewerRenderers}
            config={{
                header: {
                    disableHeader: documents.length < 2,
                    disableFileName: true,
                },
            }}
        />
    );
};
