import * as React from 'react';
import {
    Grid,
    Card,
    CardActions,
    CardContent,
    Button,
    withStyles,
    Typography,
} from '@material-ui/core';
import styles from '../../../styles/styles';

export const NewClaimSelection = withStyles(styles)(function NewClaimSelection({
    classes,
    goToCaptureClaim,
}) {
    return (
        <Grid container alignItems="stretch" className={classes.marginTop3}>
            <Grid item xs={12}>
                <Typography className={classes.bodySubHeading2}>Claim forms</Typography>
            </Grid>
            {renderClaimFormSelection({
                clickAction: () => goToCaptureClaim(undefined, 'Death'),
                description:
                    'This claim is for the death benefit due to beneficiaries on investment and insurance policies, subject to the validity of the claim.',
                title: 'Death claims',
                subheader: 'Non-retirement products',
                buttonId: 'CaptureDeathClaim',
            })}
            {renderClaimFormSelection({
                clickAction: () => goToCaptureClaim(undefined, 'RADeath'),
                description:
                    'This claim is for the death benefit due to beneficiaries on retirement annuity, pension and provident preserver policies, subject to the validity of the claim.',
                title: 'Retirement Annuity Death claims',
                subheader: 'Retirement products',
                buttonId: 'CaptureRaClaim',
            })}
            {renderClaimFormSelection({
                clickAction: () => goToCaptureClaim(undefined, 'Funeral'),
                description:
                    'On the death of the life assured, a funeral benefit is paid out to the Policy Owner or nominated beneficiary/ies, subject to the validity of the claim.',
                title: 'Funeral claims',
                buttonId: 'CaptureFuneralClaim',
            })}
            {renderClaimFormSelection({
                clickAction: () => goToCaptureClaim(undefined, 'Living Lifestyle Claims'),
                description:
                    'This claim is for a life assured who meets the benefit criteria of a critical illness, dread disease and/or disability covered under the benefit categories in a policy\'s terms and conditions',
                title: 'Living Lifestyle Claims',
                buttonId: 'CaptureLivingLifestyleClaim',
            })}
        </Grid>
    );

    function renderClaimFormSelection({
        clickAction,
        title,
        description,
        buttonId,
        subheader = undefined,
    }) {
        return (
            <Grid item xs={12}>
                <Card className={classes.card}>
                    <CardContent>
                        <Typography className={classes.cardSubHeading}>{title}</Typography>
                        <Typography className={classes.cardSubHeadingSubtle}>
                            {subheader}
                        </Typography>
                        <div
                            className={classes.cardBody}
                            dangerouslySetInnerHTML={{ __html: description }}
                        ></div>
                    </CardContent>
                    <CardActions className={classes.cardActions}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={clickAction}
                            id={buttonId}
                            className={classes.buttonSecondary}
                        >
                            Start claim
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
        );
    }
});
