import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { Condition, True, False, WhenFieldChanges } from '../../../../forms';
import { FixedWidthCheckBoxField } from '../../../../forms';
import styles from '../../../../styles/styles';
import { ClaimantNavigation } from '../../../common/navigation/claimant-navigation';
import { CommonAddress } from './common-address';
import { EntityAddress } from './entity-address';

const addressDetails = function ({
    classes,
    form,
    index,
    handleBackStepper,
    handleNext,
    editable,
    stepsLength,
    claimantIndex,
}) {
    const prefix = `claimants[${claimantIndex}]`;
    return (
        <Grid container spacing={10} justify="flex-start">
            <Grid item xs={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
                <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                <Condition when={`${prefix}.claimantType`} is="Individual">
                    <True>
                        <Typography className={classes.stepHeader}>Address Details</Typography>
                    </True>
                    <False>
                        <Typography className={classes.stepHeader}>Business Address</Typography>
                    </False>
                </Condition>
            </Grid>
            <Condition when={`${prefix}.claimantType`} is="Individual">
                <True>
                    <Grid item xs={12}>
                        <Typography className={classes.contentHeader}>
                            Residential address
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <CommonAddress
                            editable={editable}
                            index={claimantIndex}
                            prefix={`${prefix}.individual.residential`}
                            isResidential
                        />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '12px', marginBottom: '8px' }}>
                        <Typography className={classes.contentHeader}>Postal address</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FixedWidthCheckBoxField
                            name={`${prefix}.individual.sameAsResidential`}
                            label="Same as residential address"
                            style={{ marginLeft: '40px' }}
                            disabled={!editable}
                        />
                    </Grid>
                    <WhenFieldChanges
                        field={`${prefix}.individual.sameAsResidential`}
                        is={true}
                        set={`${prefix}.individual.postalAddressType`}
                        to={null}
                    />
                    <WhenFieldChanges
                        field={`${prefix}.individual.sameAsResidential`}
                        is={true}
                        set={`${prefix}.individual.postalAddressLineOne`}
                        to={null}
                    />
                    <WhenFieldChanges
                        field={`${prefix}.individual.sameAsResidential`}
                        is={true}
                        set={`${prefix}.individual.postalAddressLineTwo`}
                        to={null}
                    />
                    <WhenFieldChanges
                        field={`${prefix}.individual.sameAsResidential`}
                        is={true}
                        set={`${prefix}.individual.postalAddressLineThree`}
                        to={null}
                    />
                    <WhenFieldChanges
                        field={`${prefix}.individual.sameAsResidential`}
                        is={true}
                        set={`${prefix}.individual.postalAddressSuburb`}
                        to={null}
                    />
                    <WhenFieldChanges
                        field={`${prefix}.individual.sameAsResidential`}
                        is={true}
                        set={`${prefix}.individual.postalAddressTown`}
                        to={null}
                    />
                    <WhenFieldChanges
                        field={`${prefix}.individual.sameAsResidential`}
                        is={true}
                        set={`${prefix}.individual.postalCode`}
                        to={null}
                    />
                    <WhenFieldChanges
                        field={`${prefix}.individual.sameAsResidential`}
                        is={true}
                        set={`${prefix}.individual.postalAddressProvince`}
                        to={null}
                    />
                    <WhenFieldChanges
                        field={`${prefix}.individual.sameAsResidential`}
                        is={true}
                        set={`${prefix}.individual.postalAddressCountry`}
                        to={null}
                    />
                    <Condition when={`${prefix}.individual.sameAsResidential`}>
                        <False>
                            <Grid item xs={12}>
                                <CommonAddress
                                    editable={editable}
                                    index={claimantIndex}
                                    prefix={`${prefix}.individual.postal`}
                                />
                            </Grid>
                        </False>
                    </Condition>
                </True>
                <False>
                    <EntityAddress
                        editable={editable}
                        prefix={prefix}
                        claimantIndex={claimantIndex}
                    />
                </False>
            </Condition>
            <ClaimantNavigation
                form={form}
                handleBackStepper={handleBackStepper}
                handleNext={handleNext}
                prefix={`claimant-${claimantIndex}-address`}
            />
        </Grid>
    );
};

export const ClaimantAddressDetails = withStyles(styles)(addressDetails);
