import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { Field } from 'react-final-form';
import { connect } from 'react-redux';
import {
    FixedWidthRadioGroup,
    FixedWidthRadioOption,
    OutlinedTextField,
    VirtualizedSearchSelectField,
    WhenFieldChanges,
} from '../../forms';
import { NumberMask } from '../../forms/input-masks';
import { requiredValidator } from '../../forms/validations';
import { getLookups, loadBankBranchCodesAction } from '../../lookups';

const BankAccount = ({
    bankNames,
    editable,
    loadBankBranchCodesAction,
    prefix,
    universalBranches,
}) => {
    const [branchCodes, setBranchCodes] = useState([]);
    const [loadBranches, setLoadBranches] = useState(false);
    const [loadingBranches, setLoadingBranches] = useState(false);
    const getBranches = bankName => {
        if (loadBranches && !loadingBranches) {
            setLoadingBranches(true);
            setBranchCodes([]);
            loadBankBranchCodesAction(bankName)
                .then(result => {
                    setBranchCodes(result.bankBranchCodes);
                })
                .finally(() => {
                    setLoadBranches(false);
                    setLoadingBranches(false);
                });
        }
    };
    return (
        <React.Fragment>
            <Grid item xs={12}>
                <VirtualizedSearchSelectField
                    shaded={false}
                    name={`${prefix}bankName`}
                    label="Bank Name"
                    labelprefixed="true"
                    options={bankNames}
                    disabled={!editable}
                    validate={requiredValidator}
                    matchPos="start"
                    matchProp="label"
                />
            </Grid>
            <Grid item xs={12}>
                <OutlinedTextField
                    name={`${prefix}accountNumber`}
                    label="Account Number"
                    disabled={!editable}
                    mask={NumberMask}
                    labelprefixed="true"
                    validate={requiredValidator}
                />
            </Grid>
            <Field
                name={`${prefix}bankName`}
                subscription={{ value: true }}
                component={({ input: { value: bankName } }) => {
                    const universalBranch = universalBranches.find(uc => uc.bankName === bankName);
                    if (universalBranch) {
                        const currentBranch =
                            branchCodes && branchCodes.find(cb => cb.bankName === bankName);
                        if (!currentBranch) {
                            setBranchCodes([universalBranch]);
                        }
                    } else {
                        getBranches(bankName);
                    }

                    return (
                        <Grid item xs={12} lg={12}>
                            <VirtualizedSearchSelectField
                                name={`${prefix}branchCode`}
                                options={branchCodes}
                                label="Branch Name"
                                validate={requiredValidator}
                                disabled={universalBranch && branchCodes.length === 1}
                                labelprefixed="true"
                                matchPos="start"
                                matchProp="label"
                            />
                        </Grid>
                    );
                }}
            />
            <WhenFieldChanges
                field={`${prefix}bankName`}
                set={`${prefix}branchCode`}
                to={bankName => {
                    const universalBranch = universalBranches.find(uc => uc.bankName === bankName);
                    if (universalBranch) {
                        return universalBranch.value;
                    }
                    setLoadBranches(true);
                    return null;
                }}
            />
            <Grid item xs={12}>
                <OutlinedTextField
                    name={`${prefix}branchCode`}
                    label="Branch Code"
                    mask={NumberMask}
                    labelprefixed="true"
                    validate={requiredValidator}
                    disabled
                />
            </Grid>
            <Grid item xs={12}>
                <OutlinedTextField
                    name={`${prefix}specialInstructions`}
                    label="Special Instructions"
                    multiline
                />
            </Grid>
            <Grid item xs={12}>
                <FixedWidthRadioGroup
                    name={`${prefix}accountType`}
                    label="Account Type"
                    labelprefixed="true"
                    style={{ flexDirection: 'column' }}
                    disabled={!editable}
                    validate={requiredValidator}
                >
                    <FixedWidthRadioOption value="Current" />
                    <FixedWidthRadioOption value="Savings" />
                    <FixedWidthRadioOption value="Transmission" />
                </FixedWidthRadioGroup>
            </Grid>
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    const { bankNames, universalBranches } = getLookups(state);
    return {
        bankNames,
        universalBranches,
    };
};

const mapDispatchToProps = { loadBankBranchCodesAction };

export const BankAccountDetailsView = connect(mapStateToProps, mapDispatchToProps)(BankAccount);
