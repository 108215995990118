import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from '../../../../styles/styles';
import { LabelValue } from '../../../common/label-value';

const studentScholarDetails = ({ individual }) => {
    if (individual.studentScholar !== 'Student' && individual.studentScholar !== 'Scholar') {
        return null;
    }
    return (
        <React.Fragment>
            <Grid item xs={12} style={{ marginTop: '24px' }}>
                <Typography variant="h6">{individual.studentScholar} Details</Typography>
                <hr />
            </Grid>
            <LabelValue label="Current Year Grade" value={individual.currentYearGrade} />
            <LabelValue label="School Years Left" value={individual.schoolYearsLeft} />
            <LabelValue label="Institution" value={individual.institution} />
            <LabelValue label="Course Type" value={individual.courseType} />
            <LabelValue label="Course Name" value={individual.courseName} />
            <LabelValue label="Proof Of Studies" value={individual.proofOfStudies} isFile={true} />
        </React.Fragment>
    );
};

export const StudentScholarSummary = withStyles(styles)(studentScholarDetails);
