import { withStyles, Typography, Grid } from '@material-ui/core';
import styles from '../../../styles/styles';
import React from 'react';
import { DeceasedNavigation } from '../../common/navigation/deceased-navigation';
import { OutlinedDatePicker, OutlinedPhoneField, OutlinedTextField } from '../../../forms';
import { requiredValidator } from '../../../forms/validations';
import { TelephoneNumberMask } from '../../../forms/input-masks';
import { FuneralAddressDetails } from '../common/funeral-address-details';

const funeralParlour = function ({
    classes,
    handleBack,
    handleBackStepper,
    handleNext,
    form,
    index,
    stepsLength,
    editable,
}) {
    const prefix = 'deceasedDetails.funeralParlour.';
    return (
        <Grid container justify="flex-start">
            <Grid item xs={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
                <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                <Typography className={classes.stepHeader}>Funeral Parlour Details</Typography>
            </Grid>
            <Grid item xs={12}>
                <OutlinedDatePicker
                    name={`${prefix}dateOfFuneral`}
                    validate={requiredValidator}
                    labelprefixed="true"
                    disabled={!editable}
                />
            </Grid>
            <Grid item xs={12}>
                <OutlinedTextField
                    name={`${prefix}name`}
                    label="Funeral Parlour Name"
                    validate={requiredValidator}
                    disabled={!editable}
                    labelprefixed="true"
                />
            </Grid>
            <Grid item xs={12}>
                <OutlinedPhoneField
                    name={`${prefix}telephoneNumber`}
                    label="Contact Number"
                    validate={requiredValidator}
                    mask={TelephoneNumberMask}
                    disabled={!editable}
                    labelprefixed="true"
                />
            </Grid>
            <Grid item xs={12} style={{ marginTop: '12px', marginBottom: '8px' }}>
                <Typography className={classes.contentHeader}>Address Details</Typography>
            </Grid>
            <FuneralAddressDetails editable={editable} prefix={`${prefix}addressDetails.`} />
            <DeceasedNavigation
                form={form}
                handleBack={handleBack}
                handleBackStepper={handleBackStepper}
                handleNext={handleNext}
                prefix="deceased-funeral-parlour"
            />
        </Grid>
    );
};

export const FuneralParlour = withStyles(styles)(funeralParlour);
