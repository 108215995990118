import { Grid, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import styles from '../../../../styles/styles';
import { LabelValue } from '../../../common/label-value';

const contactDetails = function ({ contactDetails }) {
    if (!contactDetails) {
        return null;
    }

    return (
        <Grid container justify="flex-start">
            <Grid item xs={12} style={{ marginTop: '24px' }}>
                <Typography variant="h6">Contact Details</Typography>
                <hr />
            </Grid>
            <LabelValue label="Home Number" value={contactDetails.homeNumber} />
            <LabelValue label="Work Number" value={contactDetails.workNumber} />
            <LabelValue label="Cellphone Number" value={contactDetails.cellphoneNumber} />
            <LabelValue label="E-mail Address" value={contactDetails.emailAddress} />
        </Grid>
    );
};

export const ContactDetails = withStyles(styles)(contactDetails);
