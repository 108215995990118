import * as React from 'react';
import { Paper, withStyles } from '@material-ui/core';
import styles from '../../../styles/styles';
import { VerticalStepper } from '../../common/vertical-stepper';
import { getComponents } from './payment-information';

const paymentDetails = function ({ classes, editable, form, handleBack, handleSubmit,  ...rest }) {
    const vertComponents = getComponents();

    return (
        <Paper className={[classes.shadedPaper, classes.paperShadowLess].join(' ')}>
            <VerticalStepper
                {...rest}
                editable={editable}
                form={form}
                verticalComponents={vertComponents}
                verticalIndexName={`verticalStepIndex`}
                handleBack={handleBack}
                handleSubmit={handleSubmit}
            />
        </Paper>
    );
};

export const PaymentDetails = withStyles(styles)(paymentDetails);
