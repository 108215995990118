import * as React from 'react';
import { Grid, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import { NavigationButton } from './navigation-button';
import styles from '../../../styles/styles';

const claimantNavigation = ({ classes, prefix, ...rest }) => {
    const { form, handleBackStepper, handleNext, index } = rest;
    return (
        <Grid item xs={12} className={classes.navigationGroup}>
            <NavigationButton
                className={classNames(classes.buttonSecondary, classes.buttonAlignment, (index === 0) ? classes.disableButton : '')}
                onClick={() => handleBackStepper(form)}
                id={`${prefix}-back-button`}
                label="Back"
            />
            <NavigationButton
                color="primary"
                className={classNames(classes.button)}
                onClick={() => handleNext(form)}
                id={`${prefix}-continue-button`}
                label="Save and continue"
            />
        </Grid>
    );
};

export const ClaimantNavigation = withStyles(styles)(claimantNavigation);
