import { EntityDetails } from './entity-details';
import { EntityAuthorisedPersonDetails } from './authorised-person-details';
import { EntityAddressDetails } from './entity-address-details';
import { ClaimantSelection } from './claimant-selection';
import { PersonalDetails } from './personal-details';
import { GuardianDetails } from './guardian-details';
import { ContactDetails } from './contact-details';
import { ClaimantAddressDetails } from './address-details';
import { FinancialDetails } from './financial-details';
import { TaxInformation } from './tax-information';
import { CuratorDetails } from './curator-details';
import { OtherInformation } from './other-information';
import { EntityContactDetails } from './entity-contact-details';

export const entityComponents = [
    {
        name: 'Claimant Selection',
        description: 'In what capacity are you claiming',
        component: ClaimantSelection,
    },
    {
        name: 'Entity Details',
        component: EntityDetails,
        description: 'Provide all relevant information',
    },
    {
        name: `Authorised Person's Details`,
        component: EntityAuthorisedPersonDetails,
        description: 'Details of the person authorised to sign on behalf of the company',
    },
    {
        name: 'Business Address',
        component: EntityAddressDetails,
        description: 'Physical address of the company',
    },
    {
        name: 'Contact Details',
        component: EntityContactDetails,
        description: 'Contact details of the company',
    },
    {
        name: 'Other Information',
        component: OtherInformation,
        description: 'Claimant other information',
    },
];

export const defaultComponents = [
    {
        name: 'Claimant Selection',
        description: 'In what capacity are you claiming',
        component: ClaimantSelection,
    },
];

export const minorChildComponents = [
    {
        name: 'Claimant Selection',
        description: 'In what capacity are you claiming',
        component: ClaimantSelection,
    },
    {
        name: 'Personal Details',
        description: 'Provide all relevant information',
        component: PersonalDetails,
    },
    {
        name: 'Guardian Details',
        description: 'Please complete this section if you are the guardian of the minor',
        component: GuardianDetails,
    },
    {
        name: 'Contact Details',
        description: 'All relevant claimant contact details',
        component: ContactDetails,
    },
    {
        name: 'Address Details',
        description: 'All relevant claimant address details',
        component: ClaimantAddressDetails,
    },
    {
        name: 'Financial Details',
        description: 'Please provide a basic income & expenditure breakdown',
        component: FinancialDetails,
    },
    {
        name: 'Other Information',
        component: OtherInformation,
        description: 'Claimant other information',
    },
];

export const individualComponents = [
    {
        name: 'Claimant Selection',
        description: 'In what capacity are you claiming',
        component: ClaimantSelection,
    },
    {
        name: 'Personal Details',
        description: 'Provide all relevant information',
        component: PersonalDetails,
    },
    {
        name: 'Tax Information',
        description: 'This is a SARS  requirement',
        component: TaxInformation,
    },
    {
        name: 'Curator Details',
        description: 'Please complete this section if you are currently under curatorship',
        component: CuratorDetails,
    },
    {
        name: 'Contact Details',
        description: 'All relevant claimant contact details',
        component: ContactDetails,
    },
    {
        name: 'Address Details',
        description: 'All relevant claimant address details',
        component: ClaimantAddressDetails,
    },
    {
        name: 'Financial Details',
        description: 'Please provide a basic income & expenditure breakdown',
        component: FinancialDetails,
    },
    {
        name: 'Other Information',
        component: OtherInformation,
        description: 'Claimant other information',
    },
];

export const getComponents = claimant => {
    if (claimant) {
        if (claimant.claimantType === 'Individual') {
            if (
                claimant.individual &&
                claimant.individual.relationshipToLifeAssured &&
                claimant.individual.relationshipToLifeAssured === 'MinorChild'
            ) {
                return minorChildComponents;
            } else {
                return individualComponents;
            }
        }
        if (claimant.claimantType === 'Entity') {
            return entityComponents;
        }
    }
    return defaultComponents;
};
