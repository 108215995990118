import React from 'react';
import { requiredValidator } from '../../../forms/validations';
import { Button, Paper, Grid, Typography, withStyles } from '@material-ui/core';
import styles from '../../../styles/styles';
import { FixedWidthFileUpload } from '../../../forms/form-components/fixed-width-file-upload';
import {
    Condition,
    ExpansionPanel,
    False,
    FixedWidthButtonGroup,
    FixedWidthRadioGroup,
    FixedWidthRadioOption,
    OutlinedSearchSelectField,
    OutlinedTextField,
    True,
    WhenFieldChanges,
} from '../../../forms';
import { MaintenanceType, Frequency, ExpenseIncomeTypes } from '../../../shared/lookups';
import { FieldArray } from 'react-final-form-arrays';
import { AddCircle } from '@material-ui/icons';
import classNames from 'classnames';
import {
    CurrencyMask,
    FirstNameMask,
    InitialsMask,
    LastNameMask,
} from '../../../forms/input-masks';
import { convertToNumber } from '../../../shared/helper-functions';
import { ContactDetailsView } from '../common/contact-details-view';
import { ClaimantNavigation } from '../../common/navigation/claimant-navigation';

const financialDetails = function ({
    classes,
    form,
    index,
    handleBackStepper,
    handleNext,
    editable,
    stepsLength,
    claimantIndex,
}) {
    const prefix = `claimants[${claimantIndex}].individual.`;
    const { push } = form.mutators;
    return (
        <Grid container spacing={10} justify="flex-start">
            <Grid item xs={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
                <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                <Typography className={classes.stepHeader}>Financial Details</Typography>
            </Grid>
            <Grid item xs={12} lg={12}>
                <WhenFieldChanges
                    field={`${prefix}isDependent`}
                    becomes="No"
                    set={`${prefix}proofOfDependency`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${prefix}isDependent`}
                    becomes="No"
                    set={`claimants[${claimantIndex}].individual.expenses`}
                    to={null}
                />
                <Condition when={`${prefix}relationshipToLifeAssured`} is={['ExSpouse']}>
                    <True>
                        <FixedWidthRadioGroup
                            name={`${prefix}isMaintenance`}
                            label="Did the claimant receive maintenance?"
                            validate={requiredValidator}
                            labelprefixed="true"
                            disabled={!editable}
                            className={classes.radioStyle}
                        >
                            <FixedWidthRadioOption value="Yes" />
                            <FixedWidthRadioOption value="No" />
                        </FixedWidthRadioGroup>
                    </True>
                </Condition>
                <FixedWidthButtonGroup
                    label="Was the claimant dependent on the deceased member?"
                    name={`${prefix}isDependent`}
                    options={['Yes', 'No']}
                    validate={requiredValidator}
                    labelprefixed="true"
                    disabled={!editable}
                />

                <Condition when={`${prefix}isDependent`} is="Yes">
                    <True>
                        <FixedWidthFileUpload
                            name={`${prefix}proofOfDependency`}
                            label="Upload Proof of Dependency"
                            readonly={!editable}
                        />
                        <Grid item xs={12} lg={12} style={{ paddingTop: '24px' }}>
                            Please provide us with a basic income & expenditure breakdown.
                            <FieldArray name={`${prefix}expenses`}>
                                {({ fields }) =>
                                    fields.map(
                                        expenseIncomeRow(fields, classes, editable, claimantIndex)
                                    )
                                }
                            </FieldArray>
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <Button
                                variant="contained"
                                className={classNames(
                                    classes.customButton,
                                    classes.fullWidthButton
                                )}
                                onClick={() => push(`${prefix}expenses`, undefined)}
                                id="add-expense"
                                disabled={!editable}
                            >
                                <AddCircle style={{ marginRight: '8px' }} /> Add an Income / Expense
                            </Button>
                        </Grid>
                    </True>
                </Condition>
                <Condition when={`${prefix}relationshipToLifeAssured`} is="MinorChild">
                    <False>
                        <Grid item xs={12} style={{ display: 'flex' }}>
                            <Typography className={classes.contentHeader}>
                                Financial Adviser's Details
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <FixedWidthButtonGroup
                                name={`claimants[${claimantIndex}].hasFinancialAdvisor`}
                                label="Do you have a Financial Advisor who can explain your investment options to you?"
                                options={['Yes', 'No']}
                                disabled={!editable}
                                labelprefixed="true"
                            />
                            <WhenFieldChanges
                                field={`claimants[${claimantIndex}].hasFinancialAdvisor`}
                                becomes="No"
                                set={`claimants[${claimantIndex}].financialAdvisor`}
                                to={null}
                            />
                            <WhenFieldChanges
                                field={`claimants[${claimantIndex}].hasFinancialAdvisor`}
                                set={`claimants[${claimantIndex}].consentToLibertyAdvisor`}
                                to={null}
                            />
                            <Condition
                                when={`claimants[${claimantIndex}].hasFinancialAdvisor`}
                                is="Yes"
                            >
                                <True>
                                    <OutlinedTextField
                                        name={`claimants[${claimantIndex}].financialAdvisor.firstName`}
                                        mask={FirstNameMask}
                                        disabled={!editable}
                                        validate={requiredValidator}
                                        labelprefixed="true"
                                    />

                                    <OutlinedTextField
                                        name={`claimants[${claimantIndex}].financialAdvisor.lastName`}
                                        label="Surname"
                                        mask={LastNameMask}
                                        disabled={!editable}
                                        validate={requiredValidator}
                                        labelprefixed="true"
                                    />
                                    <OutlinedTextField
                                        name={`claimants[${claimantIndex}].financialAdvisor.initials`}
                                        disabled={!editable}
                                        mask={InitialsMask}
                                        validate={requiredValidator}
                                        labelprefixed="true"
                                    />
                                    <ContactDetailsView
                                        prefix={`claimants[${claimantIndex}].financialAdvisor.contactDetails.`}
                                        editable={editable}
                                        hasCommucationPreference
                                        entity
                                    />
                                </True>
                            </Condition>
                            <Condition
                                when={`claimants[${claimantIndex}].hasFinancialAdvisor`}
                                is="No"
                            >
                                <True>
                                    <FixedWidthButtonGroup
                                        label="Would you like us to ask a Liberty Financial Adviser to contact you about your investment options?"
                                        name={`claimants[${claimantIndex}].consentToLibertyAdvisor`}
                                        options={['Yes', 'No']}
                                        validate={requiredValidator}
                                        labelprefixed="true"
                                        disabled={!editable}
                                    />
                                </True>
                            </Condition>
                        </Grid>
                    </False>
                </Condition>
            </Grid>
            <ClaimantNavigation
                form={form}
                handleBackStepper={handleBackStepper}
                handleNext={handleNext}
                prefix={`claimant-${claimantIndex}-financial`}
            />
        </Grid>
    );
};

function expenseIncomeRow(fields, classes, editable, claimantIndex) {
    return function row(expenses, expenseIndex) {
        const prefix = `claimants[${claimantIndex}].individual.expenses[${expenseIndex}].`;
        return (
            <Grid key={`expense${expenseIndex}`} container item xs={12}>
                <ExpansionPanel
                    name={`expense${expenseIndex + 1}`}
                    style={{
                        margin: '20px -8px 20px -8px',
                        width: '368px',
                    }}
                    id={`expense${expenseIndex + 1}`}
                    label={`Expense / Income ${expenseIndex + 1}`}
                    key={`expense${expenseIndex}`}
                    remove={() => fields.remove(expenseIndex)}
                    deletable={true}
                >
                    <Paper className={classes.paperShadowLess}>
                        <OutlinedSearchSelectField
                            name={`${prefix}type`}
                            options={ExpenseIncomeTypes}
                            className={classes.expensionPanelComponents}
                            label="Type"
                            disabled={!editable}
                            validate={requiredValidator}
                            labelprefixed="true"
                        />
                        <OutlinedSearchSelectField
                            name={`${prefix}description`}
                            options={MaintenanceType}
                            className={classes.expensionPanelComponents}
                            label="Description"
                            disabled={!editable}
                            validate={requiredValidator}
                            labelprefixed="true"
                        />
                        <Condition when={`${prefix}description`} is="Other">
                            <True>
                                <OutlinedTextField
                                    name={`${prefix}descriptionDetails`}
                                    className={classes.expensionPanelComponents}
                                    validate={requiredValidator}
                                    labelprefixed="true"
                                    label="Details"
                                    disabled={!editable}
                                />
                            </True>
                        </Condition>
                        <OutlinedTextField
                            name={`${prefix}incomeExpenditure`}
                            className={classes.expensionPanelComponents}
                            validate={requiredValidator}
                            labelprefixed="true"
                            label="Monthly Rand Amount"
                            mask={CurrencyMask}
                            parse={convertToNumber}
                            disabled={!editable}
                        />

                        <OutlinedTextField
                            name={`${prefix}contribution`}
                            validate={requiredValidator}
                            labelprefixed="true"
                            className={classes.expensionPanelComponents}
                            label="Deceased contribution (in Rands)"
                            mask={CurrencyMask}
                            parse={convertToNumber}
                            disabled={!editable}
                        />
                        <OutlinedSearchSelectField
                            name={`${prefix}frequency`}
                            options={Frequency}
                            label="Frequency of Deceased contribution"
                            className={classes.expensionPanelComponents}
                            disabled={!editable}
                            validate={requiredValidator}
                            labelprefixed="true"
                        />
                    </Paper>
                    <hr />
                </ExpansionPanel>
            </Grid>
        );
    };
}

export const FinancialDetails = withStyles(styles)(financialDetails);
