import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { Condition, True, False, WhenFieldChanges } from '../../../forms';
import { FixedWidthCheckBoxField, FixedWidthButtonGroup } from '../../../forms';
import styles from '../../../styles/styles';
import { AddressDetails } from '../common/address-details';
import { ClaimantNavigation } from '../../common/navigation/claimant-navigation';

const addressDetails = function ({
    classes,
    form,
    index,
    handleBackStepper,
    handleNext,
    editable,
    stepsLength,
    claimantIndex,
}) {
    const prefix = `claimants[${claimantIndex}].`;
    return (
        <Grid container spacing={10} justify="flex-start">
            <Grid item xs={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
                <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                <Typography className={classes.stepHeader}>Address Details</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography className={classes.contentHeader}>Residential Address</Typography>
            </Grid>
            <Grid item xs={12}>
                <WhenFieldChanges
                    field={`${prefix}addressSameAs`}
                    becomes="Yes"
                    set={`${prefix}residentialAddress.`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${prefix}addressSameAs`}
                    becomes="Yes"
                    set={`${prefix}postalAddress.`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${prefix}addressSameAs`}
                    becomes="Yes"
                    set={`${prefix}postalAddressDifferent`}
                    to={null}
                />
                <Condition when={`${prefix}individual.relationshipToLifeAssured`} is="MinorChild">
                    <True>
                        <FixedWidthButtonGroup
                            label="Same As Guardian Details"
                            name={`${prefix}addressSameAs`}
                            options={['Yes', 'No']}
                            disabled={!editable}
                        />
                    </True>
                    <False>
                        <Condition when={`claimants[${claimantIndex}].underCuratorship`} is="Yes">
                            <True>
                                <FixedWidthButtonGroup
                                    label="Same As Curator's Details"
                                    name={`${prefix}addressSameAs`}
                                    options={['Yes', 'No']}
                                    disabled={!editable}
                                />
                            </True>
                            <False>
                                <Address classes={classes} editable={editable} prefix={prefix} />
                            </False>
                        </Condition>
                    </False>
                </Condition>
                <Condition when={`${prefix}addressSameAs`} is="No">
                    <True>
                        <Address classes={classes} editable={editable} prefix={prefix} />
                    </True>
                </Condition>
            </Grid>
            <ClaimantNavigation
                form={form}
                handleBackStepper={handleBackStepper}
                handleNext={handleNext}
                prefix={`claimant-${claimantIndex}-address`}
            />
        </Grid>
    );
};

const Address = ({ classes, editable, prefix }) => {
    return (
        <React.Fragment>
            <AddressDetails
                editable={editable}
                prefix={`${prefix}residentialAddress.`}
                isResidential
                proofOfAddress
            />
            <Grid item xs={12} style={{ marginTop: '20px' }}>
                <Typography className={classes.contentHeader}>Postal Address</Typography>
            </Grid>
            <Grid item xs={12}>
                <FixedWidthCheckBoxField
                    name={`${prefix}postalAddressDifferent`}
                    label="Different to Residential Address"
                    disabled={!editable}
                />
                <WhenFieldChanges
                    field={`${prefix}postalAddressDifferent`}
                    set={`${prefix}postalAddress`}
                    to={null}
                />
            </Grid>
            <Condition when={`${prefix}postalAddressDifferent`} is={true}>
                <True>
                    <Grid item xs={12}>
                        <AddressDetails
                            editable={editable}
                            prefix={`${prefix}postalAddress.`}
                            isResidential={false}
                        />
                    </Grid>
                </True>
            </Condition>
        </React.Fragment>
    );
};

export const ClaimantAddressDetails = withStyles(styles)(addressDetails);
