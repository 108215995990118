import { Grid, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import { getLabel } from '../../../../shared/helper-functions';
import { allRelationships, Titles } from '../../../../shared/lookups';
import styles from '../../../../styles/styles';
import { IdentitySummary } from '../../../common/identity-details-summary';
import { LabelValue } from '../../../common/label-value';

const personalDetails = function ({ personalDetails }) {
    if (!personalDetails) {
        return null;
    }

    return (
        <Grid container justify="flex-start">
            <Grid item xs={12}>
                <Typography variant="h6">Personal Details</Typography>
                <hr />
            </Grid>
            <LabelValue
                label="Relationship To Deceased"
                value={getLabel(personalDetails.relationshipToLifeAssured, allRelationships())}
            />
            <LabelValue
                label="Relationship Description"
                value={personalDetails.relationshipToLifeAssuredDescription}
            />
            <LabelValue label="Title" value={getLabel(personalDetails.title, Titles)} />
            <LabelValue label="Initials" value={personalDetails.initials} />
            <LabelValue label="First Name" value={personalDetails.firstName} />
            <LabelValue label="Second Name" value={personalDetails.secondName} />
            <LabelValue label="Surname" value={personalDetails.lastName} />
            <IdentitySummary identityDetails={personalDetails} />
            <LabelValue label="Employed" value={personalDetails.employed} />
            <LabelValue label="Name Of Employer" value={personalDetails.nameOfEmployer} />
        </Grid>
    );
};

export const PersonalDetails = withStyles(styles)(personalDetails);
