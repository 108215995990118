import { withStyles, Typography, Grid } from '@material-ui/core';
import React from 'react';
import styles from '../../../styles/styles';
import {
    Condition,
    FixedWidthButtonGroup,
    OutlinedSearchSelectField,
    OutlinedTextField,
    True,
    WhenFieldChanges,
} from '../../../forms';
import { requiredValidator } from '../../../forms/validations';
import { NaturalRelationships } from '../../../shared/lookups';
import { PersonDetails } from '../common/person-details';
import { FixedWidthFileUpload } from '../../../forms/form-components/fixed-width-file-upload';

const personalDetails = function ({ classes, prefix = '', ...rest }) {
    const { editable, index, stepsLength } = rest;
    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
                <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                <Typography className={classes.stepHeader}>Personal Details</Typography>
            </Grid>
            <Grid item xs={12}>
                <OutlinedSearchSelectField
                    name={`${prefix}relationshipToLifeAssured`}
                    options={NaturalRelationships}
                    label="Relationship to Deceased"
                    validate={requiredValidator}
                    labelprefixed="true"
                    disabled={!editable}
                />
                <WhenFieldChanges
                    field={`${prefix}relationshipToLifeAssured`}
                    set={`${prefix}relationshipToLifeAssuredDescription`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${prefix}relationshipToLifeAssured`}
                    condition={rel => rel !== 'Executor'}
                    set={`${prefix}proofOfExecutorship`}
                    to={null}
                />
            </Grid>
            <Condition when={`${prefix}relationshipToLifeAssured`} is="Other">
                <True>
                    <Grid item xs={12}>
                        <OutlinedTextField
                            name={`${prefix}relationshipToLifeAssuredDescription`}
                            label="Description"
                            validate={requiredValidator}
                            labelprefixed="true"
                            disabled={!editable}
                        />
                    </Grid>
                </True>
            </Condition>
            <Condition when={`${prefix}relationshipToLifeAssured`} is="Executor">
                <True>
                    <Grid item xs={12}>
                        <FixedWidthFileUpload
                            name={`${prefix}proofOfExecutorship`}
                            validate={requiredValidator}
                            labelprefixed="true"
                            readonly={!editable}
                        />
                    </Grid>
                </True>
            </Condition>
            <PersonDetails editable={editable} prefix={prefix} />
            <Grid item xs={12}>
                <FixedWidthButtonGroup
                    label="Employed"
                    name={`${prefix}employed`}
                    options={['Yes', 'No']}
                    disabled={!editable}
                    validate={requiredValidator}
                    labelprefixed="true"
                />
            </Grid>
            <WhenFieldChanges
                field={`${prefix}employed`}
                becomes="No"
                set={`${prefix}nameOfEmployer`}
                to={null}
            />
            <Condition when={`${prefix}employed`} is="Yes">
                <True>
                    <Grid item xs={12}>
                        <OutlinedTextField
                            name={`${prefix}nameOfEmployer`}
                            disabled={!editable}
                            validate={requiredValidator}
                            labelprefixed="true"
                        />
                    </Grid>
                </True>
            </Condition>
        </React.Fragment>
    );
};

export const PersonalDetails = withStyles(styles)(personalDetails);
