import { withStyles, Typography, Grid } from '@material-ui/core';
import React from 'react';
import styles from '../../../styles/styles';
import { DeceasedNavigation } from '../../common/navigation/deceased-navigation';
import { PersonDetails } from '../common/person-details';

const personalDetails = function ({
    classes,
    handleBack,
    handleBackStepper,
    handleNext,
    form,
    index,
    stepsLength,
    editable,
}) {
    const prefix = 'deceasedDetails.personalDetails.';
    return (
        <Grid container justify="flex-start">
            <Grid item xs={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
                <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                <Typography className={classes.stepHeader}>Personal Details</Typography>
            </Grid>
            <PersonDetails editable={editable} prefix={prefix} />
            <DeceasedNavigation
                form={form}
                handleBack={handleBack}
                handleBackStepper={handleBackStepper}
                handleNext={handleNext}
                prefix="deceased-personal-details"
            />
        </Grid>
    );
};

export const PersonalDetails = withStyles(styles)(personalDetails);
