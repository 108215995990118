import { PersonalDetails } from '../life-assured/personal-details/personal-details';
import { LifeAssuredAddressDetails } from '../life-assured/address-details/address-details';
import { ContactDetails } from '../life-assured/contact-details/contact-details';
import  MedicalInformation from './medical-information/medical-information';
import { MedicalAidDetails } from './medical-aid-details/medical-aid-details';
import { OtherDetails } from './other-details/other-details';

export const defaultComponents = [
    {
        name: 'Personal Details',
        description: 'Provide all life assured personal details',
        component: PersonalDetails,
    },
    {
        name: 'Address Details',
        description: 'Provide all address details',
        component: LifeAssuredAddressDetails,
    },
    {
        name: 'Contact Details',
        description: 'Provide all contact details',
        component: ContactDetails,
    },
    {
        name: 'Medical Information',
        description: 'Provide medical information details',
        component: MedicalInformation,
    },
    {
        name: 'Medical Aid Details',
        description: 'Provide medical aid details',
        component: MedicalAidDetails,
    },
    {
        name: 'Other Details',
        description: 'Provide all other details',
        component: OtherDetails,
    },
];

export const getComponents = () => {
    return defaultComponents;
};
