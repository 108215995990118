import { routerMiddleware } from 'connected-react-router';
import { loadingBarMiddleware } from 'react-redux-loading-bar';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './root-reducer';

function create(history) {
    const windowIfDefined = typeof window === 'undefined' ? null : window;
    // @ts-ignore
    const composeEnhancer = windowIfDefined.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const store = createStore(
        rootReducer,
        composeEnhancer(applyMiddleware(thunk, loadingBarMiddleware(), routerMiddleware(history)))
    );
    return store;
}
export default create;
