import * as React from 'react';
import {
    Accordion,
    Grid,
    Typography,
    withStyles,
    AccordionSummary,
    AccordionDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styles from '../../../styles/styles';
import { AddressDetailsSummary } from './common/address-details';
import { BankingDetailsSummary } from './common/banking-details';
import { getDisplayDate, getLabel } from '../../../shared/helper-functions';
import { ContactDetailsPreview } from '../common/contact-details-preview';
import { Titles, NaturalCausesOfDeath, UnnaturalCausesOfDeath } from '../../../shared/lookups';
import { LabelValue } from '../../common/label-value';
import { IdentitySummary } from '../../common/identity-details-summary';

const deceaseDetailsSummary = function ({ classes, formData }) {
    const deceasedDetails = formData && formData.deceasedDetails;
    if (!deceasedDetails) {
        return null;
    }
    return (
        <Accordion
            defaultExpanded={false}
            style={{
                margin: '-24px -16px 24px -16px',
                borderRadius: '4px',
            }}
        >
            <AccordionSummary
                className={[classes.whiteBackground, classes.whiteTitle].join(' ')}
                expandIcon={<ExpandMoreIcon />}
                style={{ borderRadius: '4px' }}
            >
                <Typography className={classes.previewIndex}>1</Typography>
                <Typography className={classes.contentHeader}>Deceased Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid sm={12} xs={12}>
                    <Grid item xs={12}>
                        <Typography variant="h6">Policy Details</Typography>
                        <hr />
                    </Grid>
                    {deceasedDetails.policyNumbers &&
                            deceasedDetails.policyNumbers.map((policy, policyIndex) => {
                                return (
                                    <Grid xs={12} sm={12} key={policyIndex}>
                                        <LabelValue
                                            label={`Policy Number ${policyIndex + 1}`}
                                            value={policy || '-'}
                                        />
                                    </Grid>
                                );
                            })}
                    {/* Personal Details Starts here */}
                    <Grid xs={12} sm={12}>
                        <PersonalDetails
                            classes={classes}
                            personalDetails={deceasedDetails.personalDetails}
                        />
                    </Grid>

                    {/* Contact details starts here  */}
                    <Grid item xs={12} style={{ marginTop: '24px' }}>
                        {deceasedDetails.contactDetails && (
                            <Grid>
                                <Typography variant="h6">Contact Details</Typography>
                                <hr />
                                <Grid xs={12} sm={12}>
                                    <ContactDetailsPreview
                                        contactDetails={deceasedDetails.contactDetails}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                    {/* Address details starts here  */}
                    <Grid xs={12} sm={12}>
                        <Address classes={classes} deceasedDetails={deceasedDetails} />
                        {/* Occupation details starts here  */}
                        <OccupationDetails occupationDetails={deceasedDetails.occupationDetails} />
                        {/* Banking details starts here  */}
                        <BankingDetailsSummary bankingDetails={deceasedDetails.bankingDetails} />
                        {/* Financial details starts here  */}
                        <FinancialDetails financialDetails={deceasedDetails.financialDetails} />
                        {/* Details of death starts here  */}
                        <DetailsOfDeath detailsOfDeath={deceasedDetails.detailsOfDeath} />
                        {/* Other Insurance Policies starts here  */}
                        <OtherInsuranceCompanies
                            classes={classes}
                            insuranceCompanies={deceasedDetails.insuranceCompany}
                        />
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

const PersonalDetails = ({ classes, personalDetails }) => {
    if (!personalDetails) {
        return null;
    }

    return (
        <React.Fragment>
            <Grid item xs={12} style={{ marginTop: '24px' }}>
                <Typography variant="h6">Personal Details</Typography>
                <hr />
            </Grid>
            <Grid sm={12} xs={12}>
                <LabelValue label="Title" value={getLabel(personalDetails.title, Titles)} />
                <LabelValue label="First Name" value={personalDetails.firstName} />
                <LabelValue label="Second Name" value={personalDetails.secondName} />
                <LabelValue label="Surname" value={personalDetails.lastName} />
                <LabelValue label="Initials" value={personalDetails.initials} />
                <LabelValue label="Gender" value={personalDetails.gender} />
                <IdentitySummary identityDetails={personalDetails} />
                <LabelValue label="Tax Reference Number" value={personalDetails.taxReferenceNumber} />
                <LabelValue label="Marital Status" value={personalDetails.maritalStatus} />
                <LabelValue
                    label="Date Of Marriage"
                    value={getDisplayDate(personalDetails.dateOfMarriage)}
                />
                <LabelValue
                    label="Marriage Certificate"
                    value={personalDetails.marriageCertificate}
                    isFile
                />
                <PreviousMarriage classes={classes} personalDetails={personalDetails} />
                <LabelValue
                    label="Date Of Divorce"
                    value={getDisplayDate(personalDetails.dateOfDivorce)}
                />
                <LabelValue label="Divorce Decree" value={personalDetails.divorceDecree} isFile />
            </Grid>
        </React.Fragment>
    );
};

const PreviousMarriage = ({ classes, personalDetails }) => {
    return (
        <React.Fragment>
            <LabelValue
                label="Other than the current marital status, was the deceased previously married?"
                value={personalDetails.previouslyMarried}
            />
            {personalDetails.previousMarriage &&
                personalDetails.previousMarriage.map((marriage, marriageIndex) => {
                    return (
                        <Grid container justify="flex-start" key={marriageIndex}>
                            <Grid item xs={12}>
                                <Typography className={classes.whiteTitle}>
                                    {`Marriage ${marriageIndex + 1}`}
                                </Typography>
                            </Grid>
                            <Grid sm={12} xs={12}>
                                <LabelValue
                                    label="Date of Marriage"
                                    value={getDisplayDate(marriage.date)}
                                />
                                <LabelValue
                                    label="Divorce Date"
                                    value={getDisplayDate(marriage.divorceDate)}
                                />
                                <LabelValue
                                    label="Marriage Certificate"
                                    value={marriage.marriageCertificate}
                                    isFile
                                />
                                <LabelValue
                                    label="Divorce Decree"
                                    value={marriage.divorceDecree}
                                    isFile
                                />
                            </Grid>
                        </Grid>
                    );
                })}
        </React.Fragment>
    );
};

const Address = ({ classes, deceasedDetails }) => {
    return (
        <React.Fragment>
            <Grid item xs={12} style={{ marginTop: '24px' }}>
                <Typography variant="h6">Address Details</Typography>
                <hr />
            </Grid>
            <Grid sm={12} xs={12}>
                <AddressDetails
                    classes={classes}
                    addressDetails={deceasedDetails.residentialAddress}
                    title="Last Residential Address"
                />
                <AddressDetails
                    classes={classes}
                    addressDetails={deceasedDetails.postalAddress}
                    title="Last Postal Address"
                />
                <AddressDetails
                    classes={classes}
                    addressDetails={deceasedDetails.businessAddress}
                    title="Last Business Address"
                />
            </Grid>
        </React.Fragment>
    );
};

const AddressDetails = ({ classes, title, addressDetails }) => {
    if (!addressDetails) {
        return null;
    }
    return (
        <Grid item container justify="flex-start">
            <Typography className={classes.whiteTitle}>{title}</Typography>
            <AddressDetailsSummary addressDetails={addressDetails} />
        </Grid>
    );
};

const OccupationDetails = ({ occupationDetails }) => {
    if (!occupationDetails) {
        return null;
    }
    return (
        <React.Fragment>
            <Grid item xs={12} style={{ marginTop: '24px' }}>
                <Typography variant="h6">Occupation Details</Typography>
                <hr />
            </Grid>
            <Grid sm={12} xs={12}>
                <LabelValue
                    label="Was the deceased employed at date of death"
                    value={occupationDetails.employmentStatus}
                />
                <LabelValue label="Occupation at Date of Death" value={occupationDetails.occupation} />
                <LabelValue label="Employer at Date of Death" value={occupationDetails.employer} />
            </Grid>
        </React.Fragment>
    );
};

const FinancialDetails = ({ financialDetails }) => {
    if (!financialDetails) {
        return null;
    }

    return (
        <React.Fragment>
            <Grid item xs={12} style={{ marginTop: '24px' }}>
                <Typography variant="h6">Financial Details</Typography>
                <hr />
            </Grid>
            <Grid sm={12} xs={12}>
                <LabelValue
                    label="Has the deceased member ever been insolvent, or are any any sequestration proceeding pending or conteplated?"
                    value={financialDetails.insolventStatus}
                />
                <LabelValue
                    label="Date of Occurance"
                    value={getDisplayDate(financialDetails.insolventDateOfOccurence)}
                />
                <LabelValue label="Reason" value={financialDetails.insolventReason} />
                <LabelValue
                    label="Was the estate of the deceased member insolvent at the time of death?"
                    value={financialDetails.estateInsolventStatus}
                />
                <LabelValue
                    label="Date of Occurence"
                    value={getDisplayDate(financialDetails.estateInsolventDateOfOccurence)}
                />
                <LabelValue label="Reason" value={financialDetails.estateInsolventReason} />
            </Grid>
        </React.Fragment>
    );
};

const DetailsOfDeath = ({ detailsOfDeath }) => {
    if (!detailsOfDeath) {
        return null;
    }
    return (
        <React.Fragment>
            <Grid item xs={12} style={{ marginTop: '24px' }}>
                <Typography variant="h6">Details of Death</Typography>
                <hr />
            </Grid>
            <Grid sm={12} xs={12}>
                <LabelValue label="Date of Death" value={getDisplayDate(detailsOfDeath.dateOfDeath)} />
                <LabelValue label="Place of Death" value={detailsOfDeath.placeOfDeath} />
                <LabelValue label="Type of Death" value={detailsOfDeath.conditionType} />
                <LabelValue
                    label="Condition"
                    value={getLabel(
                        detailsOfDeath.condition,
                        detailsOfDeath.conditionType === 'Natural'
                            ? NaturalCausesOfDeath
                            : UnnaturalCausesOfDeath
                    )}
                />
                <LabelValue
                    label="Was the death reported to the police"
                    value={detailsOfDeath.reportedToPolice}
                />
                <LabelValue label="Case Number" value={detailsOfDeath.caseNumber} />
                <LabelValue label="Police Report" value={detailsOfDeath.policeReport} isFile />
                <LabelValue label="Death Certificate" value={detailsOfDeath.deathCertificate} isFile />
                <LabelValue label="Notice of Death" value={detailsOfDeath.noticeOfDeath} isFile />
                <LabelValue
                    label="Has Last Will and Testament"
                    value={detailsOfDeath.hasLastWillAndTestament}
                />
                <LabelValue label="Date of Will" value={getDisplayDate(detailsOfDeath.dateOfWill)} />
                <LabelValue
                    label="Last Will and Testament"
                    value={detailsOfDeath.willAndTestamentAffidavit}
                    isFile
                />
            </Grid>
        </React.Fragment>
    );
};

const OtherInsuranceCompanies = ({ classes, insuranceCompanies }) => {
    if (!insuranceCompanies) {
        return null;
    }
    return (
        <React.Fragment>
            <Grid item xs={12} style={{ marginTop: '24px' }}>
                <Typography variant="h6">Other Insurance Policies</Typography>
            </Grid>
            {insuranceCompanies.map((company, companyIndex) => {
                return (
                    <React.Fragment key={companyIndex}>
                        <Grid item xs={12}>
                            <hr />
                            <Typography className={classes.whiteTitle}>
                                Company {companyIndex + 1}
                            </Typography>
                        </Grid>
                        <Grid sm={12} xs={12}>
                            <LabelValue label="Insurer Name" value={company.InsurerName} />
                            <LabelValue label="Product Type" value={company.productType} />
                            <LabelValue label="Policy Number" value={company.policyNumber} />
                            <LabelValue label="Sum Assured" value={company.sumAssured} />
                            <LabelValue label="Issue Date" value={getDisplayDate(company.issueDate)} />
                            <OtherInsuranceBeneficiaries
                                classes={classes}
                                beneficiaries={company.beneficiary}
                            />
                        </Grid>
                    </React.Fragment>
                );
            })}
        </React.Fragment>
    );
};

const OtherInsuranceBeneficiaries = ({ classes, beneficiaries }) => {
    if (!beneficiaries || beneficiaries.length === 0) {
        return null;
    }
    return beneficiaries.map((beneficiary, beneficiaryId) => {
        return (
            <React.Fragment key={`other-ins-ben-${beneficiaryId}`}>
                <Grid item xs={12}>
                    <Typography className={classes.whiteTitle}>
                        {`Beneficiary ${beneficiaryId + 1}`}
                    </Typography>
                </Grid>
                <Grid sm={12} xs={12}>
                    <LabelValue label="Name" value={beneficiary.name} />
                    <LabelValue label="Surname" value={beneficiary.surname} />
                </Grid>
            </React.Fragment>
        );
    });
};

export const DeceasedDetailsSummary = withStyles(styles)(deceaseDetailsSummary);
