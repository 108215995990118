import { telephoneNumber } from '../../validation/shared-regex';

export function regexValidator(regex, message, required = false) {
    return function validateRegex(value) {
        if (!required && (value === undefined || value === null)) {
            return undefined;
        }
        if (typeof value !== 'string') {
            return message;
        }

        const _value = value.trim();
        if (_value.match(regex)) {
            return undefined;
        }

        return message;
    };
}

export const emailFormatValidator = regexValidator(
    '([a-zA-Z0-9_\\-\\.]+)@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.)|(([a-zA-Z0-9\\-]+\\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})',
    'Please enter a valid email address'
);
export const emailFormatValidatorRequiredField = regexValidator(
    '([a-zA-Z0-9_\\-\\.]+)@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.)|(([a-zA-Z0-9\\-]+\\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})',
    'Please enter a valid email address',
    true
);
export const phoneFormatValidator = regexValidator(
    telephoneNumber(),
    'Please enter a valid phone number'
);
