import { Grid, Typography, withStyles } from '@material-ui/core';
import _ from 'lodash';
import * as React from 'react';
import { getDisplayDate, getLabel } from '../../../shared/helper-functions';
import { allRelationships, EntityTypes, Titles, TypeSuffixes } from '../../../shared/lookups';
import styles from '../../../styles/styles';
import { IdentitySummary } from '../../common/identity-details-summary';
import { LabelValue } from '../../common/label-value';
import { ContactDetailsPreview } from '../common/contact-details-preview';
import { AddressDetailsSummary } from './common/address-details';
import { OtherInformationSummary } from './common/other-information';

const entityDetailsView = function ({ claimant }) {
    const entity = claimant && claimant.entity;
    const authorisedPerson = entity && entity.authorisedPerson;
    return (
        <React.Fragment>
            {/* Claimant Details Starts Here */}
            <Grid item xs={12} style={{ marginTop: '24px' }}>
                <Typography variant="h6">Claimant Type</Typography>
                <hr />
            </Grid>
            <LabelValue label="Claimant Type" value={claimant.claimantType} />
            <LabelValue
                label="Relationship to Life Assured"
                value={getLabel(entity.relationshipToLifeAssured, allRelationships())}
            />
            <LabelValue label="In what capacity are you claiming" value={claimant.claimCapacity} />
            {/* Entity Details Starts Here */}
            <Grid item xs={12} style={{ marginTop: '24px' }}>
                <Typography variant="h6">Claimant Details</Typography>
                <hr />
            </Grid>
            <LabelValue label="Entity Type" value={getLabel(entity.entityType, EntityTypes)} />
            <LabelValue label="Entity Suffix" value={getLabel(entity.entitySuffix, TypeSuffixes)} />
            <LabelValue label="Entity Name" value={entity.entityName} />
            <LabelValue
                label="Entity Registration Number"
                value={entity.entityRegistrationNumber}
            />
            <LabelValue
                label="Date of Incoporation"
                value={getDisplayDate(entity.dateOfIncorporation)}
            />
            <LabelValue label="Country of Incoporation" value={entity.countryOfIncorporation} />
            <LabelValue label="Tax Status" value={entity.taxStatus} />
            <LabelValue label="Tax Number" value={entity.entityTaxNumber} />
            <LabelValue label="Country of Tax Residence" value={entity.countryOfTaxResidence} />
            <LabelValue label="Document Type" value={_.startCase(entity.trustDocumentType)} />
            <LabelValue label="Trust Resolution" value={entity.trustResolution} isFile />
            <LabelValue label="Trust Deed" value={entity.trustDeed} isFile />
            <LabelValue
                label="Trust Letter of Authority"
                value={entity.trustLetterOfAuthority}
                isFile
            />
            <LabelValue label="NPO Legal Document" value={entity.npoLegalDocument} isFile />
            <LabelValue label="CK1 Document" value={entity.ck1Document} isFile />
            <LabelValue label="Company Resolution" value={entity.companyResolution} isFile />
            <LabelValue label="Member's ID Document" value={entity.membersIdDocument} isFile />
            {/* Authorized Person Details Starts Here */}
            <Grid item xs={12} style={{ marginTop: '24px' }}>
                <Typography variant="h6">Authorized Person's Details</Typography>
                <hr />
            </Grid>
            <LabelValue label="Title" value={getLabel(authorisedPerson.title, Titles)} />
            <LabelValue label="Initials" value={authorisedPerson.initials} />
            <LabelValue label="First Name" value={authorisedPerson.firstName} />
            <LabelValue label="Second Name" value={authorisedPerson.secondName} />
            <LabelValue label="Surname" value={authorisedPerson.lastName} />
            <IdentitySummary identityDetails={authorisedPerson} />
            <LabelValue label="Gender" value={authorisedPerson.gender} />
            <LabelValue label="Nationality" value={authorisedPerson.nationality} />
            <LabelValue label="Maritial Status" value={authorisedPerson.maritalStatus} />
            {/* Business Address Starts Here */}
            <Grid item xs={12} style={{ marginTop: '24px' }}>
                <Typography variant="h6">Business Address</Typography>
                <hr />
            </Grid>
            <Grid xs={12} sm={12}>
                <AddressDetailsSummary addressDetails={entity.address} />
            </Grid>

            {/* Contact Details Starts Here */}
            <Grid item xs={12} style={{ marginTop: '24px' }}>
                <Typography variant="h6">Contact Details</Typography>
                <hr />
            </Grid>
            <Grid xs={12} sm={12}>
                <ContactDetailsPreview contactDetails={entity} />
                <OtherInformationSummary claimant={claimant} />
            </Grid>

        </React.Fragment>
    );
};

export const EntityDetailsSummary = withStyles(styles)(entityDetailsView);
