import { withStyles, Grid } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import styles from '../../../styles/styles';
import { NavigationButton } from '../../common/navigation/navigation-button';
import { PersonalDetails as CommonPersonalDetails } from '../common/personal-details';

const personalDetails = function ({ classes, ...rest }) {
    const { handleNext, form, claimantIndex } = rest;
    const prefix = `claimants[${claimantIndex}].personalDetails.`;
    return (
        <Grid container justify="flex-start">
            <CommonPersonalDetails {...rest} prefix={prefix} />
            <Grid item xs={12} style={{ paddingTop: '32px' }}>
                <NavigationButton
                    color="primary"
                    className={classNames(
                        classes.button,
                        classes.buttonAlignment,
                        classes.fullWidthButton
                    )}
                    onClick={() => handleNext(form)}
                    id="personal-details-continue"
                    label="Continue"
                />
            </Grid>
        </Grid>
    );
};

export const PersonalDetails = withStyles(styles)(personalDetails);
