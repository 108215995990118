import { Grid, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import styles from '../../../../styles/styles';
import { LabelValue } from '../../../common/label-value';
import { AddressDetails } from '../common/address-details';

const occupationDetails = function ({ occupationDetails }) {
    if (!occupationDetails) {
        return null;
    }
    return (
        <Grid container justify="flex-start">
            <Grid item xs={12} style={{ marginTop: '24px' }}>
                <Typography variant="h6">Occupation Details</Typography>
                <hr />
            </Grid>
            <LabelValue
                label="What was the deceased occupation status?"
                value={occupationDetails.status}
            />
            <LabelValue label="Occupation at date of death" value={occupationDetails.occupation} />
            {occupationDetails.status === 'Employed' && (
                <React.Fragment>
                    <LabelValue label="Employer Name" value={occupationDetails.institution} />
                    <Grid item xs={12} style={{ marginTop: '24px' }}>
                        <Typography variant="h6">Contact Details</Typography>
                        <hr />
                    </Grid>
                    <LabelValue label="Manager's Name" value={occupationDetails.contactPerson} />
                    <LabelValue label="Work Number" value={occupationDetails.telephoneNumber} />
                </React.Fragment>
            )}
            {occupationDetails.status === 'Student' && (
                <React.Fragment>
                    <LabelValue label="Scholar Number" value={occupationDetails.studentNumber} />
                    <LabelValue label="Institution Name" value={occupationDetails.institution} />
                    <Grid item xs={12} style={{ marginTop: '24px' }}>
                        <Typography variant="h6">Contact Details</Typography>
                        <hr />
                    </Grid>
                    <LabelValue
                        label="Contact Person's Name"
                        value={occupationDetails.contactPerson}
                    />
                    <LabelValue
                        label="Institution's Number"
                        value={occupationDetails.telephoneNumber}
                    />
                </React.Fragment>
            )}
            {occupationDetails.status === 'Scholar' && (
                <React.Fragment>
                    <LabelValue label="Scholar Number" value={occupationDetails.studentNumber} />
                    <LabelValue label="School Name" value={occupationDetails.institution} />
                    <Grid item xs={12} style={{ marginTop: '24px' }}>
                        <Typography variant="h6">Contact Details</Typography>
                        <hr />
                    </Grid>
                    <LabelValue label="Principal's Name" value={occupationDetails.contactPerson} />
                    <LabelValue label="School's Number" value={occupationDetails.telephoneNumber} />
                </React.Fragment>
            )}
            {occupationDetails.status !== 'Unemployed' && (
                <AddressDetails addressDetails={occupationDetails.addressDetails} />
            )}
        </Grid>
    );
};

export const OccupationDetails = withStyles(styles)(occupationDetails);
