import React, { useState } from 'react';
import {
    Button,
    Card,
    CardContent,
    Divider,
    Grid,
    Typography,
    withStyles,
} from '@material-ui/core';
import startsWith from 'lodash/startsWith';
import { DateTime } from 'luxon';
import axios from '../../../http-request';
import styles from '../../../styles/styles';
import { ExistingClaimStatus } from './existing-claim-status';
import { ClaimTypes } from '../../../shared/lookups';

const { env } = require('../../../env/env');

export const ExistingClaimSelection = withStyles(styles)(function ExistingClaimSelection({
    classes,
    hideLoading,
    isOnlyReferencedClaims,
    goToCaptureClaim,
    referencedClaims,
    showLoading,
}) {
    const [existingClaims, setExistingClaims] = useState([]);
    const [existingClaimMetaInfos, setClaimMetaInfo] = useState([]);
    const [loaded, setLoaded] = useState(false);

    if (!loaded) {
        // if we are only interested in referenced claims, do not make a call to get the current user's claims
        if (!isOnlyReferencedClaims)
            setImmediate(() => {
                const callback = items => {
                    getClaimMetaInfo({ setClaimMetaInfo, items });
                };
                searchForExistingClaims({
                    showLoading,
                    hideLoading,
                    setExistingClaims,
                    setLoaded,
                    callback,
                });
            });
    }

    if (!isOnlyReferencedClaims && existingClaims.length === 0) {
        return null;
    }

    const items = [];

    if (!isOnlyReferencedClaims) {
        existingClaims.forEach(claim => {
            const claimInfo = (existingClaimMetaInfos || []).find(
                metaInfo =>
                    claim.claimReference != null &&
                    metaInfo.claimReference === claim.claimReference.referenceId &&
                    metaInfo.formId === claim._id
            );

            items.push(renderExistingClaim(claim, claimInfo));
        });
    } else {
        if (referencedClaims.length === 1) {
            goToCaptureClaim(referencedClaims[0]);
        } else if (referencedClaims.length > 1) {
            referencedClaims.forEach((claim, cIndex) => {
                items.push(renderAgentClaim(claim, cIndex));
            });
        }
    }

    return (
        <Grid container spacing={10} alignItems="stretch">
            <Grid item xs={12}>
                <Typography className={classes.bodySubHeading2}>Claims</Typography>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <CardContent style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                        <Grid container>{items}</Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );

    function renderDeceasedDetails(claim) {
        if (claim && claim.deceasedDetails) {
            if (
                claim.claimType === ClaimTypes.RADeathClaim ||
                claim.claimType === ClaimTypes.FuneralClaim
            ) {
                const person = claim.deceasedDetails.personalDetails || {};
                return `${person.firstName || ''} ${person.lastName || ''}`;
            }

            return `${claim.deceasedDetails.firstName || claim.lifeAssured?.PersonalDetails?.lifeAssuredFirstName || ''} ${
                claim.deceasedDetails.surname || claim.lifeAssured?.PersonalDetails?.lifeAssuredSurname || ''
            }`;
        }
    }

    function renderExistingClaim(claim, claimInfo) {
        const updateLoadedStatus = value => {
            setLoaded(value);
        };
        return (
            <React.Fragment key={claim._id}>
                <Grid container item xs={12} style={{ marginLeft: '16px' }}>
                    <Grid item lg={2} md={2} style={{ overflowX: 'auto' }}>
                        <Typography className={classes.cardLabel}>Start date</Typography>
                    </Grid>
                    <Grid item lg={3} md={3} style={{ overflowX: 'auto' }}>
                        <Typography className={classes.cardLabel}>Claim for</Typography>
                    </Grid>
                    <Grid item lg={3} md={3} style={{ overflowX: 'auto' }}>
                        <Typography className={classes.cardLabel}>Claim type</Typography>
                    </Grid>
                    <Grid item lg={2} md={2} style={{ overflowX: 'auto' }}>
                        <Typography className={classes.cardLabel}>Claim reference #</Typography>
                    </Grid>
                    <Grid item lg={2} md={2} style={{ overflowX: 'auto' }}>
                        <Typography className={classes.cardLabel}>Status</Typography>
                    </Grid>
                </Grid>
                <Grid
                    item
                    xs={12}
                    container
                    style={{
                        alignItems: 'center',
                        backgroundColor: '#F0F1F4',
                        height: '50px',
                        paddingLeft: '16px',
                    }}
                >
                    <Grid item lg={2} md={2} style={{ overflowX: 'auto' }}>
                        <Typography className={classes.cardValue}>
                            {DateTime.fromISO(claim.versionInfo.dateCreated).toLocaleString(
                                DateTime.DATE_SHORT
                            )}
                        </Typography>
                    </Grid>
                    <Grid item lg={3} md={3} style={{ overflowX: 'auto' }}>
                        <Typography className={classes.cardValue}>
                            {renderDeceasedDetails(claim)}
                        </Typography>
                    </Grid>
                    <Grid item lg={3} md={3} style={{ overflowX: 'auto' }}>
                        <Typography className={classes.cardValue}>{claim.claimType}</Typography>
                    </Grid>
                    <Grid item lg={2} md={2} style={{ overflowX: 'auto' }}>
                        <Typography className={classes.cardValue}>
                            {claim.claimReference ? claim.claimReference.referenceId : ''}
                        </Typography>
                    </Grid>
                    <Grid item lg={2} md={2} style={{ overflowX: 'auto' }}>
                        <Typography className={classes.cardValue}>{claim.status}</Typography>
                    </Grid>
                </Grid>
                {claim.status === 'Submitted' && (
                    <ExistingClaimStatus
                        claimInfo={claimInfo}
                        setLoaded={value => updateLoadedStatus(value)}
                        claimType={claim.claimType || ''}
                    />
                )}
                <Grid item xs={12} className={classes.cardButton}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => goToCaptureClaim(claim)}
                        className={classes.button}
                    >
                        {claim.status === 'Submitted' ? 'View claim' : 'Continue claim'}
                    </Button>
                </Grid>
            </React.Fragment>
        );
    }

    function renderAgentClaim(claim, cIndex) {
        return (
            <React.Fragment>
                {cIndex !== 0 && (
                    <Grid item xs={12}>
                        <Divider style={{ marginBottom: '16px' }} />
                    </Grid>
                )}
                <Grid container style={{ marginLeft: '16px' }}>
                    <Grid item lg={3} md={3} style={{ overflowX: 'auto' }}>
                        <Typography className={classes.cardLabel}>Type</Typography>
                        <Typography className={classes.cardValue}>{claim.claimType}</Typography>
                    </Grid>
                    <Grid item lg={3} md={3} style={{ overflowX: 'auto' }}>
                        <Typography className={classes.cardLabel}>Life Assured</Typography>
                        <Typography className={classes.cardValue}>
                            {renderDeceasedDetails(claim)}
                        </Typography>
                    </Grid>
                    <Grid item lg={2} md={2} style={{ overflowX: 'auto' }}>
                        <Typography className={classes.cardLabel}>Date initiated</Typography>
                        <Typography className={classes.cardValue}>
                            {DateTime.fromISO(claim.versionInfo.dateCreated).toLocaleString(
                                DateTime.DATE_SHORT
                            )}
                        </Typography>
                    </Grid>
                    <Grid item lg={2} md={2} style={{ overflowX: 'auto' }}>
                        <Typography className={classes.cardLabel}>Initiated by</Typography>
                        <Typography className={classes.cardValue}>
                            {claim.versionInfo.createdBy.firstName}{' '}
                            {claim.versionInfo.createdBy.lastName}
                        </Typography>
                    </Grid>
                    <Grid item lg={2} md={2} style={{ overflowX: 'auto' }}>
                        <Typography className={classes.cardLabel}>Reference</Typography>
                        <Typography className={classes.cardValue}>
                            {claim.claimReference ? claim.claimReference.referenceId : ''}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} className={classes.cardButton}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => goToCaptureClaim(claim)}
                        className={classes.button}
                    >
                        {claim.status === 'Saved' ? 'Continue' : 'View'}
                    </Button>
                </Grid>
            </React.Fragment>
        );
    }
});

async function searchForExistingClaims({
    showLoading,
    hideLoading,
    setExistingClaims,
    setLoaded,
    callback,
}) {
    showLoading();
    const url = `${env.REACT_APP_API_URL}/claim-form`;
    let response;
    try {
        response = await axios.get(url);
    } catch (err) {
        console.error(err);
        hideLoading();
        return;
    }
    setLoaded(true);
    hideLoading();
    if (startsWith(response.status.toString(), '2')) {
        setExistingClaims(response.data.items);
        callback(response.data.items);
    }
}

async function getClaimMetaInfo({ setClaimMetaInfo, items }) {
    const data = (items || [])
        .filter(
            claim => claim.status === 'Submitted' && claim.claimReference && claim.claimReference
        )
        .map(cf => {
            return {
                ...cf.claimReference,
                formId: cf._id,
            };
        });
    const url = `${env.REACT_APP_API_URL}/claim-meta-info`;

    try {
        const response = await axios.post(url, data);
        setClaimMetaInfo(response.data);
    } catch (err) {
        console.info(err);
        return;
    }
}
