import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from '../../../styles/styles';
import { ContactDetailsView } from '../common/contact-details-view';
import { Condition, False, FixedWidthButtonGroup, True, WhenFieldChanges } from '../../../forms';
import { requiredValidator } from '../../../forms/validations';
import { ClaimantNavigation } from '../../common/navigation/claimant-navigation';

const contactDetails = function ({
    classes,
    form,
    index,
    handleNext,
    handleBackStepper,
    editable,
    stepsLength,
    claimantIndex,
}) {
    const prefix = `claimants[${claimantIndex}].individual.`;

    return (
        <Grid container spacing={10} justify="flex-start">
            <Grid item xs={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
                <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                <Typography className={classes.stepHeader}>Contact Details</Typography>
            </Grid>
            <Grid item xs={12} lg={12}>
                <Condition when={`${prefix}relationshipToLifeAssured`} is="MinorChild">
                    <True>
                        <FixedWidthButtonGroup
                            label="Same As Guardian Details"
                            name={`${prefix}contactsSameAs`}
                            options={['Yes', 'No']}
                            disabled={!editable}
                        />
                    </True>
                    <False>
                        <Condition when={`claimants[${claimantIndex}].underCuratorship`} is="Yes">
                            <True>
                                <FixedWidthButtonGroup
                                    label="Same As Curator Details"
                                    name={`${prefix}contactsSameAs`}
                                    options={['Yes', 'No']}
                                    disabled={!editable}
                                    validate={requiredValidator}
                                    labelprefixed="true"
                                />
                            </True>
                            <False>
                                <ContactDetailsView
                                    prefix={prefix}
                                    hasCommucationPreference={true}
                                    editable={editable}
                                />
                            </False>
                        </Condition>
                    </False>
                </Condition>

                <WhenFieldChanges
                    field={`${prefix}contactsSameAs`}
                    set={`${prefix}cellphoneNumber`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${prefix}contactsSameAs`}
                    set={`${prefix}workNumber`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${prefix}contactsSameAs`}
                    set={`${prefix}homeNumber`}
                    to={null}
                />

                <WhenFieldChanges
                    field={`${prefix}contactsSameAs`}
                    set={`${prefix}emailAddress`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`claimants[${claimantIndex}].underCuratorship`}
                    becomes="No"
                    set={`${prefix}contactsSameAs`}
                    to={null}
                />
                <Condition when={`${prefix}contactsSameAs`} is="No">
                    <True>
                        <ContactDetailsView
                            prefix={prefix}
                            hasCommucationPreference={true}
                            editable={editable}
                        />
                    </True>
                </Condition>
            </Grid>
            <ClaimantNavigation
                form={form}
                handleBackStepper={handleBackStepper}
                handleNext={handleNext}
                prefix={`claimant-${claimantIndex}-contact`}
            />
        </Grid>
    );
};

export const ContactDetails = withStyles(styles)(contactDetails);
