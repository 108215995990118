import * as React from 'react';
import { Grid, Paper, Typography, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import { Condition, FixedWidthCheckBoxField, True, False } from '../../../forms';
import styles from '../../../styles/styles';
import { NavigationButton } from '../../common/navigation/navigation-button';

const declaration = function ({ classes, form, handleBack, handleSubmit, editable }) {

    return (
        <Paper
            className={classNames(classes.shadedPaper, classes.paperShadowLess)}
            style={{ padding: 0 }}
        >
            <Grid container spacing={0} justify="flex-start">
                <Grid item xs={12} className={classes.grayTitle}>
                    <Typography className={classes.grayTitleText}>
                        Living Lifestyle Claim Declaration
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography className={classes.paragraph}>
                     I as the claimant(s), claim the benefits of the policy(ies).
                    </Typography>
                    <Typography className={classes.paragraph}>
                    I agree that:
                    </Typography>
                    <ul className={classes.paragraph}>
                        <li>
                        My/our personal details relating to this claim may be shared by the trustees with other claimants who
                        may have interest in these benefits I/We understand that this information is disclosed to such claimants
                        as they may have interest in how trustees make their recommendations.
                        </li>
                        <li>
                        Any written statements, affidavits and supporting documents provided in support of this claim will form
                        part of this claim. The supply of this form or of any other forms is not an admission by Liberty that there
                        was assurance in force on the life of the deceased member or waiver of any of liberty's rights or defense
                        law.
                        </li>
                        <li>
                        Any benefits payable in respect of this claim will be forfeited if I/We, or anyone
                        acting on my/our behalf or with my/our knowledge, have withheld
                        any material facts or submitted any false information in respect of the claim.
                        </li>
                        <li>
                        Upon payment by Liberty of the benefits claimed by me/us, Liberty will be released from all liability
                        in respect of such benefits Information on unpaid or unclaimed benefits.
                        </li>
                    </ul>
                    <Typography className={classes.paragraph}>
                        Once the benefits have been paid, I agree that Liberty Group Limited (and
                        its successors are no longer responsible for any other claims or liabilities
                        relating to these policy/ies benefits.
                    </Typography>
                </Grid>
                <Grid item xs={12} className={classes.grayTitle}>
                    <Typography className={classes.grayTitleText}>
                        Information on unpaid or unclaimed benefits
                    </Typography>
                </Grid>
                <Grid item xs={12} className={classes.paragraph}>
                    <Typography>
                        It is the responsibility of members to ensure that Liberty always has up to
                        date contact information (including that of any potential beneficiary).
                        <br />
                        <br />
                        Where Liberty becomes aware that benefits are payable, we will seek to
                        communicate at the last address provided to us.
                        <br />
                        <br />
                        If this is unsuccessful, Liberty will take reasonable steps to find those
                        who are entitled to the benefits, which steps may entail the appointment by
                        Liberty of external trace agents.
                        <br />
                        <br />
                        I/We consent to Liberty appointing an external tracing agent and providing
                        them with the necessary. A tracing and management fee as determined at the
                        time of tracing may be deducted by Liberty from the benefits payable.
                        <br />
                        <br />
                        <b>
                            Note that in certain circumstances, an additional amount may be payable
                            by Liberty in relation to any late payment.
                        </b>
                    </Typography>
                </Grid>
                <Grid item xs={12} className={classes.grayTitle}>
                    <Typography className={classes.grayTitleText}>
                        Acceptance of Terms and Conditions
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    container
                    justify="flex-start"
                    style={{ padding: 0, marginTop: '24px' }}
                >
                    <Grid item style={{ padding: 0, width: '5%', marginLeft: '1px' }}>
                        <FixedWidthCheckBoxField
                            name="agreedToTermsAndConditions"
                            hideLabel
                            disabled={!editable}
                        />
                    </Grid>
                    <Grid
                        item
                        style={{ padding: 0, width: '95%', marginLeft: '-8px', paddingTop: '4px' }}
                    >
                        <span style={{ fontSize: '16px', lineHeight: '20px' }}>
                            I declare that the answers and statements are true to the best of my
                            knowledge and belief, and that I have withheld no material fact.
                        </span>
                    </Grid>
                </Grid>
                <Grid container justify="flex-end" item xs={12} className={classes.navigationGroup}>
                    <NavigationButton
                        className={classes.buttonSecondary}
                        onClick={() => handleBack(form)}
                        id="previous-section"
                        style={{ width: '213px' }}
                        label="Previous Section"
                    />
                    <Condition
                        when={'agreedToTermsAndConditions'}
                        is={true}
                    >
                        <True>
                            <NavigationButton
                                id="submit"
                                color="primary"
                                className={classes.button}
                                disabled={false}
                                onClick={() => {
                                    sessionStorage.setItem('canGoToNextPage', 'true');
                                    handleSubmit(form);
                                }}
                                label={editable ? 'Submit' : 'Return To Dashboard'}
                            />
                        </True>
                        <False>
                            <NavigationButton
                                id="submit"
                                color="primary"
                                className={classes.button}
                                disabled={true}
                                onClick={() => {
                                    sessionStorage.setItem('canGoToNextPage', 'true');
                                    handleSubmit(form);
                                }}
                                label={editable ? 'Submit' : 'Return To Dashboard'}
                            />
                        </False>
                    </Condition>

                </Grid>
            </Grid>
        </Paper>
    );
};

export const Declaration = withStyles(styles)(declaration);
