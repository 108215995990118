import { Grid, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import { getLabel } from '../../../../shared/helper-functions';
import { addressTypes } from '../../../../shared/lookups';
import styles from '../../../../styles/styles';
import { LabelValue } from '../../../common/label-value';

const addressDetails = function ({ addressDetails }) {
    if (!addressDetails) {
        return null;
    }

    return (
        <Grid container justify="flex-start">
            <Grid item xs={12} style={{ marginTop: '24px' }}>
                <Typography variant="h6">Address Details</Typography>
                <hr />
            </Grid>
            <LabelValue
                label="Address Type"
                value={getLabel(addressDetails.addressType, addressTypes())}
            />
            <LabelValue label="Address Line 1" value={addressDetails.addressLineOne} />
            <LabelValue label="Address Line 2" value={addressDetails.addressLineTwo} />
            <LabelValue label="Address Line 3" value={addressDetails.addressLineThree} />
            <LabelValue label="Town/Suburb" value={addressDetails.townSuburb} />
            <LabelValue label="City" value={addressDetails.city} />
            <LabelValue label="Postal Code" value={addressDetails.postalCode} />
            <LabelValue label="Province" value={addressDetails.province} />
        </Grid>
    );
};

export const AddressDetails = withStyles(styles)(addressDetails);
