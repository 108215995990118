import { sortDropdownWithLastItemAs } from './helper-functions';
import _ from 'lodash';

export const MaritalStatuses = [
    'Engaged',
    'Divorced',
    'Married',
    'Separated',
    'Single',
    'Widowed',
].map(name => {
    return { value: name, label: name };
});

export const MedicalAidSchemes = [
    { value: 'AECI Medical Aid Society', label: 'AECI Medical Aid Society' },
    { value: 'Alliance-Midmed Medical Scheme', label: 'Alliance-Midmed Medical Scheme' },
    { value: 'Anglo Medical Scheme', label: 'Anglo Medical Scheme' },
    { value: 'Anglovaal Group Medical Scheme', label: 'Anglovaal Group Medical Scheme' },
    { value: 'Bankmed', label: 'Bankmed' },
    { value: 'Barloworld Medical Scheme', label: 'Barloworld Medical Scheme' },
    { value: 'Bestmed Medical Scheme', label: 'Bestmed Medical Scheme' },
    { value: 'BMW Employees Medical Aid Society', label: 'BMW Employees Medical Aid Society' },
    { value: 'Bonitas Medical Fund', label: 'Bonitas Medical Fund' },
    { value: 'Bonitas Medical Fund/Liberty', label: 'Bonitas Medical Fund/Liberty' },
    { value: 'BP Medical Aid Society', label: 'BP Medical Aid Society' },
    {
        value: 'Building & Construction Industry Medical Aid Fund',
        label: 'Building & Construction Industry Medical Aid Fund',
    },
    {
        value: 'Chartered Accountants (SA) Medical Aid Fund (CAMAF)',
        label: 'Chartered Accountants (SA) Medical Aid Fund (CAMAF)',
    },
    { value: 'Compcare Wellness Medical Scheme', label: 'Compcare Wellness Medical Scheme' },
    { value: 'De Beers Benefit Society', label: 'De Beers Benefit Society' },
    { value: 'Discovery Health Medical Scheme', label: 'Discovery Health Medical Scheme' },
    { value: 'Engen Medical Benefit Fund', label: 'Engen Medical Benefit Fund' },

    { value: 'Fedhealth Medical Scheme', label: 'Fedhealth Medical Scheme' },
    {
        value: 'Fishing Industry Medical Scheme (Fishmed)',
        label: 'Fishing Industry Medical Scheme (Fishmed)',
    },
    { value: 'Food Workers Medical Benefit Fund', label: 'Food Workers Medical Benefit Fund' },
    { value: 'Genesis Medical Scheme', label: 'Genesis Medical Scheme' },
    { value: 'Glencore Medical Scheme', label: 'Glencore Medical Scheme' },
    {
        value: 'Golden Arrow Employees Medical Benefit Fund',
        label: 'Golden Arrow Employees Medical Benefit Fund',
    },
    {
        value: 'Government Employees Medical Scheme (GEMS)',
        label: 'Government Employees Medical Scheme (GEMS)',
    },
    {
        value: 'Grintek Electronics Medical Aid Scheme',
        label: 'Grintek Electronics Medical Aid Scheme',
    },
    { value: 'Horizon Medical Scheme', label: 'Horizon Medical Scheme' },
    { value: 'Hosmed Medical Aid Scheme', label: 'Hosmed Medical Aid Scheme' },
    { value: 'Impala Medical Plan', label: 'Impala Medical Plan' },
    { value: 'Imperial Group Medical Scheme', label: 'Imperial Group Medical Scheme' },
    { value: 'Keyhealth', label: 'Keyhealth' },
    { value: 'LA-Health Medical Scheme', label: 'LA-Health Medical Scheme' },
    { value: 'Libcare Medical Scheme', label: 'Libcare Medical Scheme' },
    { value: 'Lonmin Medical Scheme', label: 'Lonmin Medical Scheme' },
    { value: 'Makoti Medical Scheme', label: 'Makoti Medical Scheme' },
    { value: 'Malcor Medical Scheme', label: 'Malcor Medical Scheme' },
    { value: 'Massmart Health Plan', label: 'Massmart Health Plan' },
    { value: 'MBMed Medical Aid Fund', label: 'MBMed Medical Aid Fund' },
    { value: 'Medihelp', label: 'Medihelp' },
    { value: 'Medimed Medical Scheme', label: 'Medimed Medical Scheme' },
    { value: 'Medipos Medical Scheme', label: 'Medipos Medical Scheme' },
    { value: 'Medshield Medical Scheme', label: 'Medshield Medical Scheme' },
    { value: 'Metropolitan Medical Scheme', label: 'Metropolitan Medical Scheme' },
    { value: 'Momentum Health', label: 'Momentum Health' },
    { value: 'Motohealth Care', label: 'Motohealth Care' },
    { value: 'Naspers Medical Fund', label: 'Naspers Medical Fund' },
    { value: 'Nedgroup Medical Aid Scheme', label: 'Nedgroup Medical Aid Scheme' },
    { value: 'Netcare Medical Scheme', label: 'Netcare Medical Scheme' },
    { value: 'Old Mutual Staff Medical Aid Fund', label: 'Old Mutual Staff Medical Aid Fund' },
    { value: 'Parmed Medical Aid Scheme', label: 'Parmed Medical Aid Scheme' },
    { value: 'PG Group Medical Scheme', label: 'PG Group Medical Scheme' },
    { value: 'Pick n Pay Medical Scheme', label: 'Pick n Pay Medical Scheme' },
    { value: 'Platinum Health', label: 'Platinum Health' },
    { value: 'Profmed', label: 'Profmed' },
    { value: 'Quantum Medical Aid Society', label: 'Quantum Medical Aid Society' },
    { value: 'Rand Water Medical Scheme', label: 'Rand Water Medical Scheme' },
    { value: 'Remedi Medical Aid Scheme', label: 'Remedi Medical Aid Scheme' },
    { value: 'Resolution Health Medical Scheme', label: 'Resolution Health Medical Scheme' },
    { value: 'Retail Medical Scheme', label: 'Retail Medical Scheme' },
    { value: 'Rhodes University Medical Scheme', label: 'Rhodes University Medical Scheme' },
    { value: 'SABC Medical Aid Scheme', label: 'SABC Medical Aid Scheme' },

    { value: 'SAMWUMed', label: 'SAMWUMed' },
    { value: 'Sasolmed', label: 'Sasolmed' },
    { value: 'Sedmed', label: 'Sedmed' },
    { value: 'Selfmed Medical Scheme', label: 'Selfmed Medical Scheme' },
    { value: 'Sisonke Health Medical Scheme', label: 'Sisonke Health Medical Scheme' },
    { value: 'Sizwe Medical Fund', label: 'Sizwe Medical Fund' },
    {
        value: 'South African Breweries Medical Aid Scheme (SABMAS)',
        label: 'South African Breweries Medical Aid Scheme (SABMAS)',
    },
    {
        value: 'South African Police Service Medical Scheme (POLMED)',
        label: 'South African Police Service Medical Scheme (POLMED)',
    },
    { value: 'Spectramed', label: 'Spectramed' },
    { value: 'Suremed Health', label: 'Suremed Health' },

    { value: 'TFG Medical Aid Scheme', label: 'TFG Medical Aid Scheme' },
    { value: 'Thebemed', label: 'Thebemed' },
    { value: 'Tiger Brands Medical Scheme', label: 'Tiger Brands Medical Scheme' },
    { value: 'Topmed Medical Scheme', label: 'Topmed Medical Scheme' },
    { value: 'Transmed Medical Fund', label: 'Transmed Medical Fund' },
    { value: 'Tsogo Sun Group Medical Scheme', label: 'Tsogo Sun Group Medical Scheme' },
    { value: 'Umvuzo Health Medical Scheme', label: 'Umvuzo Health Medical Scheme' },
    {
        value: 'University of Kwa-Zulu Natal Medical Scheme',
        label: 'University of Kwa-Zulu Natal Medical Scheme',
    },
    {
        value: 'University of the Witwatersrand, Johannesburg Staff Medical Aid Fund',
        label: 'University of the Witwatersrand, Johannesburg Staff Medical Aid Fund',
    },
    {
        value: 'Witbank Coalfields Medical Aid Scheme',
        label: 'Witbank Coalfields Medical Aid Scheme',
    },
    { value: 'Wooltru Healthcare Fund', label: 'Wooltru Healthcare Fund' },
    { value: 'Other', label: 'Other' },
];

export const Frequency = [
    { value: 'Annually', label: 'Annually' },
    { value: 'Bi-Annually', label: 'Bi-Annually' },
    { value: 'Monthly', label: 'Monthly' },
    { value: 'Quarterly', label: 'Quarterly' },
];

export const ExpenseIncomeTypes = [
    { value: 'Expense', label: 'Expense' },
    { value: 'Income', label: 'Income' },
];

export const MaintenanceType = [
    { value: 'Accommodation', label: 'Accommodation' },
    { value: 'Bond', label: 'Bond' },
    { value: 'Clothes', label: 'Clothes' },
    { value: 'Entertainment', label: 'Entertainment' },
    { value: 'Insurance', label: 'Insurance' },
    { value: 'MedicalAid', label: 'Medical aid' },
    { value: 'Rental', label: 'Rental' },
    { value: 'StudentFees', label: 'Student fees' },
    { value: 'Salary', label: 'Salary' },
    { value: 'Transport', label: 'Transport' },
    { value: 'Other', label: 'Other' },
];

export const ClaimantTypes = [
    { value: 'Entity', label: 'Entity' },
    { value: 'Individual', label: 'Individual' },
];

export const PolicyHolderTypes = [
    { value: 'Company', label: 'Company' },
    { value: 'Individual', label: 'Individual' },
];

export const AccountHolderRelationships = [
    { value: 'Joint', label: 'Joint' },
    { value: 'Own', label: 'Own' },
    { value: 'Other', label: 'Other'}
];

export const PaymentBeneficiary = [
    { value: 'Policyholder', label: 'Policyholder' },
    { value: 'Premium Payer', label: 'Premium Payer' },
    { value: 'Cessionary', label: 'Cessionary' },
];

export const EntityTypes = [
    { value: 'CloseCorporation', label: 'Close Corporation' },
    { value: 'ForeignCompany', label: 'Foreign Company' },
    { value: 'ForeignTrust', label: 'Foreign Trust' },
    { value: 'NonProfitOrganisation', label: 'Non Profit Organisation' },
    { value: 'PersonalLiabilityCompany', label: 'Personal Liability Company' },
    { value: 'PrivateCompany', label: 'Private Company' },
    { value: 'PublicCompany', label: 'Public Company' },
    { value: 'StateOwnedCompany', label: 'State Owned Company' },
    { value: 'Trust', label: 'Trust' },
];

export const TypeSuffixes = [
    { value: 'CC', label: 'CC' },
    { value: 'Incorporated', label: 'Incorporated' },
    { value: 'LTD', label: 'LTD' },
    { value: 'NPC', label: 'NPC' },
    { value: 'PTY_LTD', label: '(PTY) LTD' },
    { value: 'SOC', label: 'SOC' },
];

export const Countries = [
    { value: 'Afghanistan', label: 'Afghanistan' },
    { value: 'Aland Islands', label: 'Aland Islands' },
    { value: 'Albania', label: 'Albania' },
    { value: 'Algeria', label: 'Algeria' },
    { value: 'American Samoa', label: 'American Samoa' },
    { value: 'Andorra', label: 'Andorra' },
    { value: 'Angola', label: 'Angola' },
    { value: 'Anguilla', label: 'Anguilla' },
    { value: 'Antarctica', label: 'Antarctica' },
    { value: 'Antigua and Bermuda', label: 'Antigua and Bermuda' },
    { value: 'Argentina', label: 'Argentina' },
    { value: 'Armenia', label: 'Armenia' },
    { value: 'Aruba', label: 'Aruba' },
    { value: 'Ascension Island', label: 'Ascension Island' },
    { value: 'Australia', label: 'Australia' },
    { value: 'Austria', label: 'Austria' },
    { value: 'Azerbaijan', label: 'Azerbaijan' },
    { value: 'Bahamas', label: 'Bahamas' },
    { value: 'Bahrain', label: 'Bahrain' },
    { value: 'Bangladesh', label: 'Bangladesh' },
    { value: 'Barbados', label: 'Barbados' },
    { value: 'Belarus', label: 'Belarus' },
    { value: 'Belgium', label: 'Belgium' },
    { value: 'Belize', label: 'Belize' },
    { value: 'Benin', label: 'Benin' },
    { value: 'Bermuda', label: 'Bermuda' },
    { value: 'Bhutan', label: 'Bhutan' },
    { value: 'Bolivia', label: 'Bolivia' },
    { value: 'Bosnia and Herzegovinia', label: 'Bosnia and Herzegovinia' },
    { value: 'Botswana', label: 'Botswana' },
    { value: 'Bouvet Island', label: 'Bouvet Island' },
    { value: 'Brazil', label: 'Brazil' },
    { value: 'British and Indian Territory', label: 'British and Indian Territory' },
    { value: 'Brunei Darussalam', label: 'Brunei Darussalam' },
    { value: 'Bulgaria', label: 'Bulgaria' },
    { value: 'Burkina Faso', label: 'Burkina Faso' },
    { value: 'Burma', label: 'Burma' },
    { value: 'Burundi', label: 'Burundi' },
    { value: 'Cambodia', label: 'Cambodia' },
    { value: 'Cameroon', label: 'Cameroon' },
    { value: 'Canada', label: 'Canada' },
    { value: 'Cape Verde', label: 'Cape Verde' },
    { value: 'Cayman Islands', label: 'Cayman Islands' },
    { value: 'Central African Republic', label: 'Central African Republic' },
    { value: 'Chad', label: 'Chad' },
    { value: 'Chile', label: 'Chile' },
    { value: 'China', label: 'China' },
    { value: 'Christmas Island', label: 'Christmas Island' },
    { value: 'Cocos (Keeling) Islands', label: 'Cocos (Keeling) Islands' },
    { value: 'Colombia', label: 'Colombia' },
    { value: 'Comoros', label: 'Comoros' },
    { value: 'Congo, Republic of (Brazzaville)', label: 'Congo, Republic of (Brazzaville)' },
    { value: 'Cook Islands', label: 'Cook Islands' },
    { value: 'Costa Rica', label: 'Costa Rica' },
    { value: 'Croatia', label: 'Croatia' },
    { value: 'Cuba', label: 'Cuba' },
    { value: 'Cyprus', label: 'Cyprus' },
    { value: 'Czech Republic', label: 'Czech Republic' },
    {
        value: 'Democratic Republic of the Congo(Kinshasa)',
        label: 'Democratic Republic of the Congo(Kinshasa)',
    },
    { value: 'Denmark', label: 'Denmark' },
    { value: 'Djibouti', label: 'Djibouti' },
    { value: 'Dominica', label: 'Dominica' },
    { value: 'Dominican Republic', label: 'Dominican Republic' },
    { value: 'East Timor Timor - Leste', label: 'East Timor Timor - Leste' },
    { value: 'Ecuador', label: 'Ecuador' },
    { value: 'Egypt', label: 'Egypt' },
    { value: 'El Salvador', label: 'El Salvador' },
    { value: 'Equatorial Guinea', label: 'Equatorial Guinea' },
    { value: 'Eritrea', label: 'Eritrea' },
    { value: 'Estonia', label: 'Estonia' },
    { value: 'Ethiopia', label: 'Ethiopia' },
    { value: 'Falkand Islands', label: 'Falkand Islands' },
    { value: 'Faroe Islands', label: 'Faroe Islands' },
    { value: 'Fiji', label: 'Fiji' },
    { value: 'Finland', label: 'Finland' },
    { value: 'France', label: 'France' },
    { value: 'French Guiana', label: 'French Guiana' },
    { value: 'French Metropolitan', label: 'French Metropolitan' },
    { value: 'French Polinesia', label: 'French Polinesia' },
    { value: 'French Southern Territories', label: 'French Southern Territories' },
    { value: 'Gabon', label: 'Gabon' },
    { value: 'Gambia', label: 'Gambia' },
    { value: 'Georgia', label: 'Georgia' },
    { value: 'Germany', label: 'Germany' },
    { value: 'Ghana', label: 'Ghana' },
    { value: 'Gibraltar', label: 'Gibraltar' },
    { value: 'Great Britain', label: 'Great Britain' },
    { value: 'Greece', label: 'Greece' },
    { value: 'Greenland', label: 'Greenland' },
    { value: 'Grenada', label: 'Grenada' },
    { value: 'Guadeloupe', label: 'Guadeloupe' },
    { value: 'Guam', label: 'Guam' },
    { value: 'Guatemala', label: 'Guatemala' },
    { value: 'Guernsey', label: 'Guernsey' },
    { value: 'Guinea - Bissau', label: 'Guinea - Bissau' },
    { value: 'Guinea', label: 'Guinea' },
    { value: 'Guyana', label: 'Guyana' },
    { value: 'Haiti', label: 'Haiti' },
    { value: 'Heard and Mc Donald Island', label: 'Heard and Mc Donald Island' },
    { value: 'Holy See', label: 'Holy See' },
    { value: 'Honduras', label: 'Honduras' },
    { value: 'Hong Kong', label: 'Hong Kong' },
    { value: 'Hungary', label: 'Hungary' },
    { value: 'Iceland', label: 'Iceland' },
    { value: 'India', label: 'India' },
    { value: 'Indonesia', label: 'Indonesia' },
    { value: 'Iran (Islamic Republic of)', label: 'Iran (Islamic Republic of)' },
    { value: 'Iraq', label: 'Iraq' },
    { value: 'Ireland', label: 'Ireland' },
    { value: 'Isle of Man', label: 'Isle of Man' },
    { value: 'Israel', label: 'Israel' },
    { value: 'Italy', label: 'Italy' },
    { value: 'Ivory Coast', label: 'Ivory Coast' },
    { value: 'Jamaica', label: 'Jamaica' },
    { value: 'Japan', label: 'Japan' },
    { value: 'Jersey', label: 'Jersey' },
    { value: 'Jordan', label: 'Jordan' },
    { value: 'Kazakhstan', label: 'Kazakhstan' },
    { value: 'Kenya', label: 'Kenya' },
    { value: 'Kiribati', label: 'Kiribati' },
    {
        value: 'Korea, Democratic Peoples Rep (North Korea)',
        label: 'Korea, Democratic Peoples Rep (North Korea)',
    },
    { value: 'Korea, Republic of (South Korea)', label: 'Korea, Republic of (South Korea)' },
    { value: 'Kuwait', label: 'Kuwait' },
    { value: 'Kyrgyzstan', label: 'Kyrgyzstan' },
    { value: 'Lao, Peoples Democratic Republic', label: 'Lao, Peoples Democratic Republic' },
    { value: 'Latvia', label: 'Latvia' },
    { value: 'Lebanon', label: 'Lebanon' },
    { value: 'Lesotho', label: 'Lesotho' },
    { value: 'Liberia', label: 'Liberia' },
    { value: 'Libya', label: 'Libya' },
    { value: 'Liechtenstein', label: 'Liechtenstein' },
    { value: 'Lithuania', label: 'Lithuania' },
    { value: 'Luxembourg', label: 'Luxembourg' },
    { value: 'Macau', label: 'Macau' },
    { value: 'Macedonia', label: 'Macedonia' },
    { value: 'Madagascar', label: 'Madagascar' },
    { value: 'Malawi', label: 'Malawi' },
    { value: 'Malaysia', label: 'Malaysia' },
    { value: 'Maldives', label: 'Maldives' },
    { value: 'Mali', label: 'Mali' },
    { value: 'Malta', label: 'Malta' },
    { value: 'Marshall Islands', label: 'Marshall Islands' },
    { value: 'Martinique', label: 'Martinique' },
    { value: 'Mauritania', label: 'Mauritania' },
    { value: 'Mauritius', label: 'Mauritius' },
    { value: 'Mayanmar, Burma', label: 'Mayanmar, Burma' },
    { value: 'Mayotte', label: 'Mayotte' },
    { value: 'Mexico', label: 'Mexico' },
    { value: 'Miconesia, Federal States of', label: 'Miconesia, Federal States of' },
    { value: 'Moldova, Republic of', label: 'Moldova, Republic of' },
    { value: 'Monaco', label: 'Monaco' },
    { value: 'Mongolia', label: 'Mongolia' },
    { value: 'Montenegro', label: 'Montenegro' },
    { value: 'Montserrat', label: 'Montserrat' },
    { value: 'Morocco', label: 'Morocco' },
    { value: 'Mozambique', label: 'Mozambique' },
    { value: 'Myanmar', label: 'Myanmar' },
    { value: 'Namibia', label: 'Namibia' },
    { value: 'Nauru', label: 'Nauru' },
    { value: 'Nepal', label: 'Nepal' },
    { value: 'Netherlands Antilles', label: 'Netherlands Antilles' },
    { value: 'Netherlands', label: 'Netherlands' },
    { value: 'New Caledonia', label: 'New Caledonia' },
    { value: 'New Zealand', label: 'New Zealand' },
    { value: 'Nicaragua', label: 'Nicaragua' },
    { value: 'Niger', label: 'Niger' },
    { value: 'Nigeria', label: 'Nigeria' },
    { value: 'Niue', label: 'Niue' },
    { value: 'Norfolk Island', label: 'Norfolk Island' },
    { value: 'Northern Mariana Islands', label: 'Northern Mariana Islands' },
    { value: 'Norway', label: 'Norway' },
    { value: 'Oman', label: 'Oman' },
    { value: 'Pakistan', label: 'Pakistan' },
    { value: 'Palau', label: 'Palau' },
    { value: 'Palestinian National Authority', label: 'Palestinian National Authority' },
    { value: 'Panama', label: 'Panama' },
    { value: 'Papua New Guinea', label: 'Papua New Guinea' },
    { value: 'Paraguay', label: 'Paraguay' },
    { value: 'Peru', label: 'Peru' },
    { value: 'Philippines', label: 'Philippines' },
    { value: 'Pitcairn Island', label: 'Pitcairn Island' },
    { value: 'Poland', label: 'Poland' },
    { value: 'Portugal', label: 'Portugal' },
    { value: 'Puerto Rico', label: 'Puerto Rico' },
    { value: 'Qatar', label: 'Qatar' },
    { value: 'Reunion Island', label: 'Reunion Island' },
    { value: 'Romania', label: 'Romania' },
    { value: 'Russian Federation', label: 'Russian Federation' },
    { value: 'Rwanda', label: 'Rwanda' },
    { value: 'Saint Barthelemy', label: 'Saint Barthelemy' },
    { value: 'Saint Helena', label: 'Saint Helena' },
    { value: 'Saint Kitts and Nevis', label: 'Saint Kitts and Nevis' },
    { value: 'Saint Lucia', label: 'Saint Lucia' },
    { value: 'Saint Vincent and the Grenadines', label: 'Saint Vincent and the Grenadines' },
    { value: 'Saint-Martin (French part)', label: 'Saint-Martin (French part)' },
    { value: 'Samoa', label: 'Samoa' },
    { value: 'San Marino', label: 'San Marino' },
    { value: 'Sao Tome and Principe', label: 'Sao Tome and Principe' },
    { value: 'Saudi Arabia', label: 'Saudi Arabia' },
    { value: 'Senegal', label: 'Senegal' },
    { value: 'Serbia', label: 'Serbia' },
    { value: 'Seychelles', label: 'Seychelles' },
    { value: 'Sierra Leone', label: 'Sierra Leone' },
    { value: 'Singapore', label: 'Singapore' },
    { value: 'Slovakia (Slovak Republic)', label: 'Slovakia (Slovak Republic)' },
    { value: 'Slovenia', label: 'Slovenia' },
    { value: 'Solomon Islands', label: 'Solomon Islands' },
    { value: 'Somalia', label: 'Somalia' },
    { value: 'South Africa', label: 'South Africa' },
    {
        value: 'South Georgia and South Sandwich Islands',
        label: 'South Georgia and South Sandwich Islands',
    },
    { value: 'South Sudan', label: 'South Sudan' },
    { value: 'Spain', label: 'Spain' },
    { value: 'Sri Lanka', label: 'Sri Lanka' },
    { value: 'St. Pierre and Miquelon', label: 'St. Pierre and Miquelon' },
    { value: 'Sudan', label: 'Sudan' },
    { value: 'Suriname', label: 'Suriname' },
    { value: 'Svabald and Jan Mayen Islands', label: 'Svabald and Jan Mayen Islands' },
    { value: 'Swaziland', label: 'Swaziland' },
    { value: 'Sweden', label: 'Sweden' },
    { value: 'Switzerland', label: 'Switzerland' },
    { value: 'Syria, Syrian Arab Republic', label: 'Syria, Syrian Arab Republic' },
    { value: 'Taiwan', label: 'Taiwan' },
    { value: 'Tajikistan', label: 'Tajikistan' },
    { value: 'Tanzania', label: 'Tanzania' },
    { value: 'Thailand', label: 'Thailand' },
    { value: 'Tibet', label: 'Tibet' },
    { value: 'Timor - Leste (East Timor)', label: 'Timor - Leste (East Timor)' },
    { value: 'Togo', label: 'Togo' },
    { value: 'Tokelau', label: 'Tokelau' },
    { value: 'Tonga', label: 'Tonga' },
    { value: 'Trinidad and Tobago', label: 'Trinidad and Tobago' },
    { value: 'Tunisia', label: 'Tunisia' },
    { value: 'Turkey', label: 'Turkey' },
    { value: 'Turkmenistan', label: 'Turkmenistan' },
    { value: 'Turks and Caicos Island', label: 'Turks and Caicos Island' },
    { value: 'Tuvalu', label: 'Tuvalu' },
    { value: 'U.S. Minor Outlying Islands', label: 'U.S. Minor Outlying Islands' },
    { value: 'Uganda', label: 'Uganda' },
    { value: 'Ukraine', label: 'Ukraine' },
    { value: 'United Arab Emirates', label: 'United Arab Emirates' },
    { value: 'United Kingdom', label: 'United Kingdom' },
    { value: 'United States', label: 'United States' },
    { value: 'Uruguay', label: 'Uruguay' },
    { value: 'Uzbekistan', label: 'Uzbekistan' },
    { value: 'Vanuatu', label: 'Vanuatu' },
    { value: 'Vatican City State (Holy See)', label: 'Vatican City State (Holy See)' },
    { value: 'Venezuela', label: 'Venezuela' },
    { value: 'Vietnam', label: 'Vietnam' },
    { value: 'Virgin Islands (British)', label: 'Virgin Islands (British)' },
    { value: 'Virgin Islands (U.S.)', label: 'Virgin Islands (U.S.)' },
    { value: 'Wallis and Futuna Islands', label: 'Wallis and Futuna Islands' },
    { value: 'Western Sahara', label: 'Western Sahara' },
    { value: 'Yemen', label: 'Yemen' },
    { value: 'Zaire (see Congo, Democratic)', label: 'Zaire (see Congo, Democratic)' },
    { value: 'Zambia', label: 'Zambia' },
    { value: 'Zimbabwe', label: 'Zimbabwe' },
];

export const TaxStatuses = [
    { value: 'Non Tax Paying', label: 'Non Tax Paying' },
    { value: 'Tax Paying', label: 'Tax Paying' },
];

export const NaturalCausesOfDeath = [
    { value: '12', label: 'Tuberculosis' },
    { value: '47', label: 'Meningitis' },
    { value: '62', label: 'Encephalitis' },
    { value: '70', label: 'Hepatitis' },
    { value: '99', label: 'Venereal Disease' },
    { value: '140', label: 'Cancer Of The Lip' },
    { value: '141', label: 'Cancer Of The Tongue' },
    { value: '142', label: 'Cancer Of The Salivary Glands' },
    { value: '143', label: 'Cancer Of The Gum' },
    { value: '145', label: 'Cancer Of The Mouth' },
    { value: '149', label: 'Cancer Of The Pharynx' },
    { value: '150', label: 'Cancer Of The Oesophagus' },
    { value: '151', label: 'Cancer Of  The Stomach' },
    { value: '152', label: 'Cancer Of The Small Intestine' },
    { value: '153', label: 'Cancer Of The Colon' },
    { value: '154', label: 'Cancer Of The Rectum' },
    { value: '155', label: 'Cancer Of The Liver' },
    { value: '156', label: 'Cancer Of The Gall-Bladder' },
    { value: '157', label: 'Cancer Of The Pancreas' },
    { value: '160', label: 'Cancer Of The Nasal Cavities Or Sinus' },
    { value: '161', label: 'Cancer Of The Larynx' },
    { value: '164', label: 'Cancer Of The Thymus' },
    { value: '165', label: 'Cancer Of The Lung' },
    { value: '166', label: 'Cancer Of The Trachea' },
    { value: '170', label: 'Cancer Of The Bone' },
    { value: '171', label: 'Cancer Of The Connective Soft Tissue' },
    { value: '172', label: 'Cancer Of The Skin' },
    { value: '174', label: 'Cancer Of The Breast' },
    { value: '179', label: 'Cancer Of The Uterus' },
    { value: '180', label: 'Cancer Of The Cervix' },
    { value: '181', label: 'Cancer Of The Placenta' },
    { value: '183', label: 'Cancer Of  The Ovary' },
    { value: '185', label: 'Cancer Of The Prostate' },
    { value: '186', label: 'Cancer Of The Testis' },
    { value: '187', label: 'Cancer Of The Penis' },
    { value: '188', label: 'Cancer Of The Bladder' },
    { value: '189', label: 'Cancer Of The Kidney' },
    { value: '190', label: 'Cancer Of The Eye' },
    { value: '191', label: 'Cancer Of The Brain' },
    { value: '193', label: 'Cancer Of The Thyroid' },
    { value: '196', label: 'Cancer Of The Lymph Nodes' },
    { value: '208', label: 'Leukaemia' },
    { value: '234', label: 'Cancer' },
    { value: '243', label: 'Hypothyroidism' },
    { value: '246', label: 'Disorders Of Thyroid' },
    { value: '250', label: 'Diabetes' },
    { value: '251', label: 'Disorders Of Pancreatic' },
    { value: '269', label: 'Malnutrition' },
    { value: '285', label: 'Anaemia' },
    { value: '311', label: 'Depression' },
    { value: '319', label: 'Mental Retardation' },
    { value: '331', label: 'Demientia / Alzheimers' },
    { value: '343', label: 'Cerebral Palsy' },
    { value: '344', label: 'Paralysis' },
    { value: '348', label: 'Brain Tumour' },
    { value: '359', label: 'Muscular Dystrophy' },
    { value: '398', label: 'Rheumatic Fever' },
    { value: '401', label: 'Hypertension' },
    { value: '410', label: 'Heart Attack / Myocardial Infarction' },
    { value: '414', label: 'Ischaemic Heart Disease' },
    { value: '428', label: 'Heart Failure / Cardiac Failure' },
    { value: '437', label: 'Stroke / Cerebral Infarction' },
    { value: '467', label: 'COVID-19' },
    { value: '486', label: 'Pneumonia' },
    { value: '496', label: 'Chronic Airways Obstruction' },
    { value: '508', label: 'Respiratory Failure' },
    { value: '536', label: 'Disorders Of The Stomach / Ulcers' },
    { value: '542', label: 'Appendicitis' },
    { value: '550', label: 'Hernia' },
    { value: '571', label: 'Liver Disease & Cirrhosis' },
    { value: '586', label: 'Renal Failure' },
    { value: '593', label: 'Kidney Failure' },
    { value: '646', label: 'Complications Of Pregnancy' },
    { value: '714', label: 'Rheumatoid Arthritis' },
    { value: '715', label: 'Osteoarthrosis' },
    { value: '799', label: 'Other' },
].sort(sortDropdownWithLastItemAs('Other'));

export const UnnaturalCausesOfDeath = [
    { value: '798', label: 'Other' },
    { value: '806', label: 'Train Accident' },
    { value: '811', label: 'Motor Vehicle Accident' },
    { value: '826', label: 'Bicycle Accident' },
    { value: '829', label: 'Motor Bike Accident' },
    { value: '835', label: 'Drowning' },
    { value: '837', label: 'Burning' },
    { value: '844', label: 'Aeroplane, Helicopter Accident' },
    { value: '849', label: 'Mine Accident' },
    { value: '858', label: 'Drug Overdose' },
    { value: '860', label: 'Alchohol Overdose' },
    { value: '880', label: 'Falling Down Stairs' },
    { value: '889', label: 'Murder / Homicide' },
    { value: '899', label: 'Accident Caused By Fire' },
    { value: '907', label: 'Struck By Lightening' },
    { value: '910', label: 'Accidental Drowning' },
    { value: '959', label: 'Suicide / Self Inflicted Injury' },
    { value: '960', label: 'Assault / Rape' },
    { value: '963', label: 'Strangulation' },
].sort(sortDropdownWithLastItemAs('Other'));

export function addressTypes(isResidential) {
    let types = [
        { value: 'Street', label: 'RSA Street Address' },
        { value: 'Foreign', label: 'Foreign' },
    ];

    if (!isResidential) {
        types = [
            ...types,
            { value: 'PostBox', label: 'RSA Post box' },
            { value: 'PrivateBag', label: 'RSA Private bag' },
        ];
    }

    return types.sort(sortDropdownWithLastItemAs('Other'));
}

export const Provinces = [
    { value: 'Eastern Cape', label: 'Eastern Cape' },
    { value: 'Free State', label: 'Free State' },
    { value: 'Gauteng', label: 'Gauteng' },
    { value: 'KwaZulu-Natal', label: 'KwaZulu-Natal' },
    { value: 'Limpopo', label: 'Limpopo' },
    { value: 'Mpumalanga', label: 'Mpumalanga' },
    { value: 'North West', label: 'North West' },
    { value: 'Northern Cape', label: 'Northern Cape' },
    { value: 'Western Cape', label: 'Western Cape' },
];

export const Titles = [
    { value: 'MR', label: 'Mr' },
    { value: 'MRS', label: 'Mrs' },
    { value: 'MISS', label: 'Miss' },
    { value: 'DR', label: 'Dr' },
    { value: 'PROF', label: 'Prof' },
    { value: 'MS', label: 'Ms' },
    { value: 'ADJUTANT', label: 'Adjutant' },
    { value: 'ADJUTANT GENERAL', label: 'Adjutant General' },
    { value: 'ADMIRAL', label: 'Admiral' },
    { value: 'ADVOCATE', label: 'Advocate' },
    { value: 'BROTHER', label: 'Brother' },
    { value: 'BRIGADIER', label: 'Brigadier' },
    { value: 'BRIGADIER GENERAL', label: 'Brigadier General' },
    { value: 'CAPTAIN', label: 'Captain' },
    { value: 'THE HONOURABLE CHIEF JUSTICE', label: 'The Honourable Chief Justice' },
    { value: 'COMMANDER', label: 'Commander' },
    { value: 'COLONEL', label: 'Colonel' },
    { value: 'COMMANDANT', label: 'Commandant' },
    { value: 'DIRECTOR GENERAL', label: 'Director General' },
    { value: 'HIS EXCELLENCY', label: 'His Excellency' },
    { value: 'HER EXCELLENCY', label: 'Her Excellency' },
    { value: 'FIELD-MARSHALL', label: 'Field-Marshall' },
    { value: 'GENERAL', label: 'General' },
    { value: 'GOVERNOR', label: 'Governor' },
    { value: 'HIS EMINENCE', label: 'His Eminence' },
    { value: 'HIS HIGHNESS', label: 'His Highness' },
    { value: 'HER ROYAL HIGHNESS', label: 'Her Royal Highness' },
    { value: 'HIS ROYAL HIGHNESS', label: 'His Royal Highness' },
    { value: 'HER SERENE HIGHNESS', label: 'Her Serene Highness' },
    { value: 'HIS SERENE HIGHNESS', label: 'His Serene Highness' },
    { value: 'INSPECTOR', label: 'Inspector' },
    { value: 'LANCE-CORPORAL', label: 'Lance-Corporal' },
    { value: 'LIEUTENANT', label: 'Lieutenant' },
    { value: 'LIEUTENANT-COLONEL', label: 'Lieutenant-Colonel' },
    { value: 'LIEUTENANT-COMMANDER', label: 'Lieutenant-Commander' },
    { value: 'LIEUTENANT-GENERAL', label: 'Lieutenant-General' },
    { value: 'LORD', label: 'Lord' },
    { value: 'MAJOR', label: 'Major' },
    { value: 'MAJOR-GENERAL', label: 'Major-General' },
    { value: 'MESDAMES', label: 'Mesdames' },
    { value: 'MESSIEURS', label: 'Messieurs' },
    { value: 'MONSIGNOR', label: 'Monsignor' },
    { value: 'MINISTER', label: 'Minister' },
    { value: 'QUARTERMASTER-GENERAL', label: 'Quartermaster-General' },
    { value: 'QUARTERMASTER-SERGEANT', label: 'Quartermaster-Sergeant' },
    { value: 'RABBI', label: 'Rabbi' },
    { value: 'REAR-ADMIRAL', label: 'Rear-Admiral' },
    { value: 'REVEREND', label: 'Reverend' },
    { value: 'THE RIGHT REVEREND', label: 'The Right Reverend' },
    { value: 'REGIMENTAL SERGEANT-MAJOR', label: 'Regimental Sergeant-Major' },
    { value: 'THE RIGHT HONOURABLE', label: 'The Right Honourable' },
    { value: 'SENATOR', label: 'Senator' },
    { value: 'SERGEANT', label: 'Sergeant' },
    { value: 'SERGEANT-MAJOR', label: 'Sergeant-Major' },
    { value: 'SIR', label: 'Sir' },
    { value: 'THEIR ROYAL HIGHNESSES', label: 'Their Royal Highnesses' },
    { value: 'FATHER', label: 'Father' },
    { value: 'DOCTORS', label: 'Doctors' },
    { value: 'MASTER', label: 'Master' },
    { value: 'SISTER', label: 'Sister' },
    { value: 'REVEREND', label: 'Reverend' },
    { value: 'PASTOR', label: 'Pastor' },
    { value: 'THE HONOURABLE', label: 'The Honourable' },
    { value: 'SUB-LIEUTENANT', label: 'Sub-Lieutenant' },
    { value: 'LADY', label: 'Lady' },
    { value: 'BISHOP', label: 'Bishop' },
    { value: 'COUNT', label: 'Count' },
    { value: 'COUNTESS', label: 'Countess' },
    { value: 'CHIEF', label: 'Chief' },
    { value: 'PARSON', label: 'Parson' },
    { value: 'JUDGE', label: 'Judge' },
    { value: 'BARON', label: 'Baron' },
    { value: 'PARSON', label: 'Parson' },
    { value: 'MISTER & MISSUS', label: 'Mister & Missus' },
    { value: 'WARRANT OFFICER', label: 'Warrant Officer' },
    { value: 'BARONESS', label: 'Baroness' },
    { value: 'VISCOUNT', label: 'Viscount' },
    { value: 'VISCOUNTESS', label: 'Viscountess' },
    { value: 'COMMISSIONER', label: 'Commissioner' },
];

export const idOptions = [
    { value: 'Passport', label: 'Passport' },
    { value: 'RsaId', label: 'South African ID' },
];

export const idOptionsWithForeign = [
    { value: 'RsaId', label: 'South African barcoded green ID book' },
    { value: 'RsaIdSmartCard', label: 'South African ID Smart Card' },
    { value: 'RsaIdDrivingLicence', label: 'South African Driving Licence' },
    { value: 'RsaIdTemporaryId', label: 'South African Temporary ID' },
    { value: 'RsaIdBirthCertificate', label: 'South African Birth Certificate' },
    { value: 'RsaPassport', label: 'South African Passport' },
    { value: 'ForeignPassport', label: 'Foreign Passport' },
    { value: 'ForeignIdBook', label: 'Foreign Identity Book' },
];

export const CommunicationMethods = [
    { value: 'Email', label: 'Email' },
    { value: 'Cellphone', label: 'Cellphone' },
    { value: 'Telephone', label: 'Telephone' },
];

export const ProofOfAddress = [
    { value: 'UtilityBill', label: 'A utility bill (less than 3 months old)' },
    { value: 'LeaseRentalAgreement', label: 'A lease/rental agreement (less than a year old)' },
    {
        value: 'MunicipalityInvoice',
        label: 'A municipal rates and taxes invoice (less than 3 months old)',
    },
    {
        value: 'TelephoneAccount',
        label: 'A telephone or cellphone account (less than 3 months old)',
    },
    {
        value: 'RetailAccount',
        label: 'A retail account statement/invoice (less than 3 months old)',
    },
    { value: 'TaxReturn', label: 'An official tax return (less than a year old)' },
    {
        value: 'TaxCorrespondence',
        label: 'An official tax assessment, from the local revenue services (less than a year old)',
    },
    { value: 'TelevisionLicense', label: 'A valid TV license document (less than a year old)' },
    { value: 'BankStatement', label: 'A bank statement (Except Standard Bank)' },
    {
        value: 'Confirmation',
        label: 'Confirmation from a tribal authority or municipal councilor',
    },
    { value: 'Affidavit', label: 'Affidavit from an authorised police official' },
];

export const Relationships = [
    { value: 'EstrangedSpouse', label: 'Estranged Spouse' },
    { value: 'ExSpouse', label: 'Ex Spouse' },
    { value: 'LifePartner', label: 'Life Partner' },
    { value: 'MajorChild', label: 'Major Child (18 years and older)' },
    { value: 'MinorChild', label: 'Minor Child (Younger than 18)' },
    { value: 'Parent', label: 'Parent' },
    { value: 'Relative', label: 'Relative' },
    { value: 'Spouse', label: 'Spouse' },
    { value: 'Other', label: 'Other' },
];

export const NaturalRelationships = [
    { value: 'Aunt', label: 'Aunt' },
    { value: 'Brother', label: 'Brother' },
    { value: 'Child', label: 'Child' },
    { value: 'Cousin', label: 'Cousin' },
    { value: 'Daughter', label: 'Daughter' },
    { value: 'Employer', label: 'Employer' },
    { value: 'EstrangedSpouse', label: 'Estranged Spouse' },
    { value: 'ExSpouse', label: 'Ex Spouse' },
    { value: 'Executor', label: 'Executor' },
    { value: 'Father', label: 'Father' },
    { value: 'Grandchild', label: 'Grandchild' },
    { value: 'Granddaughter', label: 'Granddaughter' },
    { value: 'Grandfather', label: 'Grandfather' },
    { value: 'Grandmother', label: 'Grandmother' },
    { value: 'Grandparent', label: 'Grandparent' },
    { value: 'Grandson', label: 'Grandson' },
    { value: 'Husband', label: 'Husband' },
    { value: 'MajorChild', label: 'Major Child (18 years and older)' },
    { value: 'MinorChild', label: 'Minor Child (Younger than 18)' },
    { value: 'Mother', label: 'Mother' },
    { value: 'Nephew', label: 'Nephew' },
    { value: 'Niece', label: 'Niece' },
    { value: 'Partner', label: 'Partner' },
    { value: 'Sister', label: 'Sister' },
    { value: 'Son', label: 'Son' },
    { value: 'Uncle', label: 'Uncle' },
    { value: 'Wife', label: 'Wife' },
    { value: 'Other', label: 'Other' },
];

export const OrganisationRelationships = [
    { value: 'Creditor', label: 'Creditor' },
    { value: 'Employer', label: 'Employer' },
    { value: 'Financial Advisor', label: 'Financial Advisor' },
    { value: 'Lawyer/Attorney', label: 'Lawyer/Attorney' },
    { value: 'Partner', label: 'Partner' },
    { value: 'Other', label: 'Other' },
];

export const EntityAssigneeRelationships = [
    { value: 'AuthorisedPerson', label: 'Authorised Person' },
    { value: 'Co-Director', label: 'Co-Director' },
    { value: 'Co-Shareholder', label: 'Co-Shareholder' },
    { value: 'Director', label: 'Director' },
    { value: 'FamilyTrust', label: 'Family Trust' },
    { value: 'Founder', label: 'Founder' },
    { value: 'OrganizationPartner', label: 'Organization Partner' },
    { value: 'OwnerOfAnOrganisation', label: 'Owner of an organisation' },
    { value: 'Shareholder', label: 'Shareholder' },
    { value: 'TrustFounder', label: 'Trust Founder' },
    { value: 'TrustFund', label: 'Trust Fund' },
    { value: 'Trust', label: 'Trust' },
    { value: 'Trustee', label: 'Trustee' },
];

export const RelationshipsChildren = [
    { value: 'AdoptedDaughter', label: 'Adopted Daughter' },
    { value: 'AdoptedSon', label: 'Adopted Son' },
    { value: 'Daughter', label: 'Daughter' },
    { value: 'FosterDaughter', label: 'Foster Daughter' },
    { value: 'FosterSon', label: 'Foster Son' },
    { value: 'Goddaughter', label: 'Goddaughter' },
    { value: 'Godson', label: 'Godson' },
    { value: 'Grand-Daughter', label: 'Grand-Daughter' },
    { value: 'Granddaughter-In-Law', label: 'Granddaughter-In-Law' },
    { value: 'Grand-Nephew', label: 'Grand-Nephew' },
    { value: 'Grand-Niece', label: 'Grand-Niece' },
    { value: 'Grand-Son', label: 'Grand-Son' },
    { value: 'Grandson-In-Law', label: 'Grandson-In-Law' },
    { value: 'GreatGranddaughter', label: 'Great Granddaughter' },
    { value: 'GreatGrandson', label: 'Great Grandson' },
    { value: 'Great-Grandnephew', label: 'Great-Grandnephew' },
    { value: 'Great-Nephew', label: 'Great-Nephew' },
    { value: 'Great-Niece', label: 'Great-Niece' },
    { value: 'Nephew', label: 'Nephew' },
    { value: 'Niece', label: 'Niece' },
    { value: 'Son', label: 'Son' },
    { value: 'Step-Daughter', label: 'Step-Daughter' },
    { value: 'Step-Granddaughter', label: 'Step-Granddaughter' },
    { value: 'Step-Grandson', label: 'Step-Grandson' },
    { value: 'Step-Son', label: 'Step-Son' },
];

export const RelationshipsParents = [
    { value: 'AdoptedFather', label: 'Adopted Father' },
    { value: 'AdoptedMother', label: 'Adopted Mother' },
    { value: 'Father', label: 'Father' },
    { value: 'FosterFather', label: 'Foster Father' },
    { value: 'FosterMother', label: 'Foster Mother' },
    { value: 'Mother', label: 'Mother' },
    { value: 'Step-Father', label: 'Step-Father' },
    { value: 'Step-Mother', label: 'Step-Mother' },
];

export const RelationshipsSpouses = [
    { value: 'Husband', label: 'Husband' },
    { value: 'Wife', label: 'Wife' },
];

export const RelationshipsRelatives = [
    { value: 'Aunt', label: 'Aunt' },
    { value: 'AdoptedBrother', label: 'Adopted Brother' },
    { value: 'AdoptedSister', label: 'Adopted Sister' },
    { value: 'Brother', label: 'Brother' },
    { value: 'Brother-In-Law', label: 'Brother-In-Law' },
    { value: 'Cousin', label: 'Cousin' },
    { value: 'Daughter-In-Law', label: 'Daughter-In-Law' },
    { value: 'Father-In-Law', label: 'Father-In-Law' },
    { value: 'FosterBrother', label: 'Foster Brother' },
    { value: 'FosterSister', label: 'Foster Sister' },
    { value: 'Grandfather', label: 'Grandfather' },
    { value: 'Grandmother', label: 'Grandmother' },
    { value: 'Grandnephew', label: 'Grandnephew' },
    { value: 'Half-Brother', label: 'Half-Brother' },
    { value: 'Half-Sister', label: 'Half-Sister' },
    { value: 'Mother-In-Law', label: 'Mother-In-Law' },
    { value: 'Sister', label: 'Sister' },
    { value: 'Sister-In-Law', label: 'Sister-In-Law' },
    { value: 'Son-In-Law', label: 'Son-In-Law' },
    { value: 'Step-Brother', label: 'Step-Brother' },
    { value: 'Step-Grandfather', label: 'Step-Grandfather' },
    { value: 'Step-Grandmother', label: 'Step-Grandmother' },
    { value: 'Step-Sister', label: 'Step-Sister' },
    { value: 'Step-Son', label: 'Step-Son' },
    { value: 'Uncle', label: 'Uncle' },
];

export const RelationshipsPartner = [{ value: 'Partner', label: 'Partner' }];

export const RelationshipsExSpouses = [
    {
        value: 'Ex-Husband',
        label: 'Ex-Husband',
    },
    { value: 'Ex-Wife', label: 'Ex-Wife' },
];

export const RelationshipsChildOf = [
    { value: 'Partner', label: 'Partner' },
    { value: 'ExSpouse', label: 'Ex-Spouse' },
    { value: 'EstrangedSpouse', label: 'Estranged Spouse' },
];

export const RelationshipsOther = [
    { value: 'Curator', label: 'Curator' },
    { value: 'Employer', label: 'Employer' },
    { value: 'Executor', label: 'Executor' },
    { value: 'Fiance(Male)', label: 'Fiance (Male)' },
    { value: 'Fiancee(Female)', label: 'Fiancee (Female)' },
    { value: 'Friend', label: 'Friend' },
    { value: 'Guardian', label: 'Guardian' },
    { value: 'Lawyer/attorney', label: 'Lawyer/attorney' },
];

export const Gender = [
    {value: 'Male', label: 'Male'},
    {value: 'Female', label: 'Female' }
];

export function allRelationships() {
    return _.union(
        NaturalRelationships,
        OrganisationRelationships,
        EntityAssigneeRelationships,
        Relationships,
        RelationshipsChildren,
        RelationshipsSpouses,
        RelationshipsExSpouses,
        RelationshipsPartner,
        RelationshipsRelatives,
        RelationshipsParents,
        RelationshipsOther
    );
}

export const ClaimTypes = {
    DeathClaim: 'Death Claims - Non-Retirement Products',
    RADeathClaim: 'RA Death Claims - Retirement Products',
    FuneralClaim: 'Funeral Claims',
    LivingLifestyle: 'Living Lifestyle',
    LivingLifestyleClaim: 'Living Lifestyle Claims',
};


export const Certifiers = [
    { value: 'Doctor', label: 'Doctor' },
    { value: 'TribalChief', label: 'Tribal Chief' },
    { value: 'Induna', label: 'Induna' },
];
