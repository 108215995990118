import * as React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { currencyFormat } from '../../../../shared/helper-functions';
import styles from '../../../../styles/styles';
import { LabelValue } from '../../../common/label-value';

const otherInformation = ({ classes, claimant }) => {
    if (!claimant || (!claimant.beneficiaryOfWill && !claimant.receivedOtherBenefits)) {
        return null;
    }
    return (
        <Grid style={{ marginTop: '24px' }} container justify="flex-start">
            <Grid item xs={12}>
                <Typography variant="h6">Other Information</Typography>
                <hr />
            </Grid>
            <Grid sm={12} xs={12}>
                <LabelValue
                    label="Is the dependant a beneficiary of the deceased will?"
                    value={claimant.beneficiaryOfWill}
                />
                {claimant.beneficiaryOfWill === 'Yes' && (
                    <LabelValue
                        label="Approximate value of inheritance"
                        value={currencyFormat(claimant.valueOfInheritance)}
                    />
                )}
            </Grid>

            {claimant.receivedOtherBenefits && (
                <Grid xs={12} sm={12}>

                    <LabelValue label=" Did the dependant receive any benefits other than those now becoming due
                            as a result of the death of the member from the lifestyle retirement
                            annuity fund, lifestyle retirement preserver pension fund, or lifestyle
                            retirement preserver provident fund (i.e. benefits from other
                            pension/provident, retirement funds, other insurance policies etc.)?" value={claimant.receivedOtherBenefits} />

                    {claimant.receivedOtherBenefits === 'Yes' && claimant.otherBenefits && (
                        <React.Fragment>
                            <LabelValue
                                label="Name of insurer/financial institution"
                                value={claimant.otherBenefits.insurer}
                            />
                            <LabelValue
                                label="Amount received"
                                value={currencyFormat(claimant.otherBenefits.amountReceived)}
                            />
                        </React.Fragment>
                    )}
                </Grid>
            )}
        </Grid>
    );
};

export const OtherInformationSummary = withStyles(styles)(otherInformation);
