import * as React from 'react';
import { Grid, Paper, Typography, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import { FixedWidthCheckBoxField } from '../../../forms';
import styles from '../../../styles/styles';
import { NavigationButton } from '../../common/navigation/navigation-button';

const declaration = function ({ classes, form, handleBack, handleSubmit, editable }) {
    return (
        <Paper
            className={classNames(classes.shadedPaper, classes.paperShadowLess)}
            style={{ padding: 0 }}
        >
            <Grid container spacing={0} justify="flex-start">
                <Grid item xs={12} className={classes.grayTitle}>
                    <Typography className={classes.grayTitleText}>
                        Death Claim Declaration
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography className={classes.paragraph}>
                        By sending this form as a claimant, or the legal guardian of a claimant who
                        is a minor, I confirm the following:{' '}
                    </Typography>
                    <ul className={classes.paragraph}>
                        <li>
                            I am making a claim for the benefits of the policy/ies listed in this
                            form
                        </li>
                        <li>I have a legal right to claim these benefits </li>
                        <li>
                            I understand that although Liberty sent this form to complete, this does
                            not mean I have a valid claim
                        </li>
                        <li>
                            I agree that any written statements, affidavits and documents provided
                            in support of this claim will form part of this claim
                        </li>
                        <li>
                            The information I have given on this form is true, to the best of my
                            knowledge and belief
                        </li>
                        <li>
                            I have not withheld information that could affect the payment of this
                            claim
                        </li>
                        <li>
                            If I, or anyone acting on my behalf or with my knowledge, have given
                            false information or withheld information, then I will not be entitled
                            to receive the benefits I am claiming for.
                        </li>
                    </ul>
                    <Typography className={classes.paragraph}>
                        Once the benefits have been paid, I agree that Liberty Group Limited (and
                        its successors) are no longer responsible for any other claims or
                        liabilities relating to these policies.
                    </Typography>
                </Grid>
                <Grid item xs={12} className={classes.grayTitle}>
                    <Typography className={classes.grayTitleText}>
                        Information on unpaid or unclaimed benefits
                    </Typography>
                </Grid>
                <Grid item xs={12} className={classes.paragraph}>
                    <Typography>
                        It is the responsibility of members to ensure that Liberty always has up to
                        date contact information (including that of any potential beneficiary).
                        <br />
                        <br />
                        Where Liberty becomes aware that benefits are payable, we will seek to
                        communicate at the last address provided to us.
                        <br />
                        <br />
                        If this is unsuccessful, Liberty will take reasonable steps to find those
                        who are entitled to the benefits, which steps may entail the appointment by
                        Liberty of external trace agents.
                        <br />
                        <br />
                        I/We consent to Liberty appointing an external tracing agent and providing
                        them with the necessary. A tracing and management fee as determined at the
                        time of tracing may be deducted by Liberty from the benefits payable.
                        <br />
                        <br />
                        <b>
                            Note that in certain circumstances, an additional amount may be payable
                            by Liberty in relation to any late payment.
                        </b>
                    </Typography>
                </Grid>
                <Grid item xs={12} className={classes.grayTitle}>
                    <Typography className={classes.grayTitleText}>
                        Acceptance of Terms and Conditions
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    container
                    justify="flex-start"
                    style={{ padding: 0, marginTop: '24px' }}
                >
                    <Grid item style={{ padding: 0, width: '5%', marginLeft: '1px' }}>
                        <FixedWidthCheckBoxField
                            name="agreedToTermsAndConditions"
                            hideLabel
                            disabled={!editable}
                        />
                    </Grid>
                    <Grid
                        item
                        style={{ padding: 0, width: '95%', marginLeft: '-8px', paddingTop: '4px' }}
                    >
                        <span style={{ fontSize: '16px', lineHeight: '20px' }}>
                            I declare that the answers and statements are true to the best of my
                            knowledge and belief, and that I have withheld no material fact.
                        </span>
                    </Grid>
                </Grid>
                <Grid container justify="flex-end" item xs={12} className={classes.navigationGroup}>
                    <NavigationButton
                        className={classes.buttonSecondary}
                        onClick={() => handleBack(form)}
                        id="previous-section"
                        style={{ width: '213px' }}
                        label="Previous Section"
                    />
                    <NavigationButton
                        id="submit"
                        color="primary"
                        className={classes.button}
                        onClick={() => {
                            sessionStorage.setItem('canGoToNextPage', 'true');
                            handleSubmit(form);
                        }}
                        label={editable ? 'Submit' : 'Return To Dashboard'}
                    />
                </Grid>
            </Grid>
        </Paper>
    );
};

export const Declaration = withStyles(styles)(declaration);
