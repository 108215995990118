import * as React from 'react';
import { Grid, Paper, withStyles, Typography, Button } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import { FieldArray } from 'react-final-form-arrays';
import uuid from 'uuid/v1';
import { CustomExpansionPanel } from '../../../forms';
import styles from '../../../styles/styles';
import { VerticalStepper } from '../../common/vertical-stepper';
import { NavigationButton } from '../../common/navigation/navigation-button';
import { getComponents } from './claimant-components';

const claimantDetails = function ({ classes, editable, form, handleBack, ...rest }) {
    const { push } = form.mutators;
    return (
        <Paper className={[classes.shadedPaper, classes.paperShadowLess].join(' ')}>
            <Grid container spacing={10} justify="flex-start">
                <Grid item xs={12} lg={12}>
                    <Typography className={classes.claimantTitle}>Claimant Details</Typography>
                </Grid>
                <Grid item xs={12} lg={12}>
                    <Typography className={classes.claimantSubTitle}>
                        Please complete the claimant details for each person claiming.
                    </Typography>
                </Grid>
            </Grid>
            <FieldArray name={`claimants`}>
                {({ fields }) =>
                    fields.map((field, index) => {
                        return (
                            <ClaimantSection
                                {...rest}
                                key={`claimant-section-${index}`}
                                classes={classes}
                                claimantIndex={index}
                                editable={editable}
                                fields={fields}
                                form={form}
                                handleBack={handleBack}
                            />
                        );
                    })
                }
            </FieldArray>
            <Grid container justify="flex-start">
                <Grid item xs={6} className={classes.leftAlign}>
                    {editable && (
                        <Button
                            variant="contained"
                            className={classes.customButton}
                            style={{
                                marginLeft: '-8px',
                                marginTop: '8px',
                                width: '206px',
                            }}
                            id="add-claimant"
                            onClick={() =>
                                push(`claimants`, {
                                    claimantId: uuid(),
                                })
                            }
                        >
                            <AddCircle style={{ marginRight: '8px' }} />
                            Add Claimant
                        </Button>
                    )}
                </Grid>
                <Grid item xs={6} className={classes.rightAlign}>
                    <NavigationButton
                        className={classes.buttonSecondary}
                        style={{ marginRight: '-8px' }}
                        onClick={() => handleBack(form)}
                        id="claimant-back"
                        fullWidth
                        label="Previous Section"
                    />
                </Grid>
            </Grid>
        </Paper>
    );
};

export const ClaimantSection = ({ classes, editable, fields, form, claimantIndex, ...rest }) => {
    const claimants = form.getState().values.claimants;
    return (
        <CustomExpansionPanel
            defaultExpanded={true}
            style={{ margin: '20px -8px 20px -8px', borderRadius: '4px' }}
            name={`claimant${claimantIndex + 1}`}
            id={`claimant${claimantIndex + 1}`}
            label={`Claimant ${claimantIndex + 1}`}
            remove={() => remove(fields, claimantIndex)}
            deletable={editable && fields.length > 1}
        >
            <Paper className={classes.paperShadowLess} style={{ marginTop: '32px' }}>
                <VerticalStepper
                    {...rest}
                    editable={editable}
                    verticalComponents={getComponents(claimants[claimantIndex])}
                    form={form}
                    claimantIndex={claimantIndex}
                    verticalIndexName={`claimantStep${claimantIndex}`}
                />
            </Paper>
        </CustomExpansionPanel>
    );
};

const remove = (fields, index) => {
    if (fields.length > 1) {
        for (let i = index; i < fields.length; i++) {
            const nextValue = sessionStorage.getItem(`claimantStep${i + 1}`);
            sessionStorage.setItem(`claimantStep${i}`, nextValue);
        }
        sessionStorage.setItem(`claimantStep${fields.length - 1}`, '0');
        fields.remove(index);
    }
};

export const ClaimantDetails = withStyles(styles)(claimantDetails);
