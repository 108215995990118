import { PaymentDetails } from './payment-details/payment-details';

export const defaultComponents = [
    {
        name: 'Payee Details',
        description: 'Provide all payee details',
        component: PaymentDetails,
    },

];

export const getComponents = () => {
    return defaultComponents;
};
