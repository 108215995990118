import { withStyles, Typography, Grid, Button, capitalize } from '@material-ui/core';
import styles from '../../../styles/styles';
import React from 'react';
import {
    Condition,
    ExpansionPanel,
    False,
    FixedWidthRadioGroup,
    FixedWidthRadioOption,
    OutlinedDatePicker,
    OutlinedSearchSelectField,
    OutlinedTextField,
    True,
    WhenFieldChanges,
} from '../../../forms';
import { requiredValidator } from '../../../forms/validations';
import { MaritalStatuses, Titles } from '../../../shared/lookups';
import { isAdult } from '../../../shared/helper-functions';
import { AddCircle } from '@material-ui/icons';
import { FieldArray } from 'react-final-form-arrays';
import { FirstNameMask, InitialsMask, LastNameMask, NumberMask } from '../../../forms/input-masks';
import { FixedWidthFileUpload } from '../../../forms/form-components/fixed-width-file-upload';
import classNames from 'classnames';
import { IdentityDetails } from '../../common/identity-details';
import { toastr } from 'react-redux-toastr';
import { DeceasedNavigation } from '../../common/navigation/deceased-navigation';

const personalDetails = function ({
    classes,
    handleBack,
    handleBackStepper,
    handleNext,
    index,
    editable,
    stepsLength,
    form,
}) {
    const { push } = form.mutators;

    const prefix = 'deceasedDetails.personalDetails.';
    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
                <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                <Typography className={classes.stepHeader}>Personal Details</Typography>
            </Grid>
            <Grid item xs={12}>
                <OutlinedSearchSelectField
                    name={`${prefix}title`}
                    options={Titles}
                    label="Title"
                    disabled={!editable}
                />
                <OutlinedTextField
                    name={`${prefix}firstName`}
                    label="First Name"
                    mask={FirstNameMask}
                    validate={requiredValidator}
                    labelprefixed="true"
                    disabled={!editable}
                />
                <OutlinedTextField
                    name={`${prefix}secondName`}
                    label="Second Name"
                    mask={FirstNameMask}
                    disabled={!editable}
                />
                <OutlinedTextField
                    name={`${prefix}lastName`}
                    label="Surname"
                    validate={requiredValidator}
                    labelprefixed="true"
                    mask={LastNameMask}
                    disabled={!editable}
                />
                <OutlinedTextField
                    name={`${prefix}initials`}
                    label="Initials"
                    validate={requiredValidator}
                    labelprefixed="true"
                    mask={InitialsMask}
                    disabled={!editable}
                />
                <IdentityDetails
                    includeGender={true}
                    includeForeignTypes={false}
                    prefix={prefix}
                    editable={editable}
                />

                <Condition when={`${prefix}dateOfBirth`} passes={isAdult}>
                    <True>
                        <OutlinedTextField
                            name={`${prefix}taxReferenceNumber`}
                            label="Tax Reference Number"
                            disabled={!editable}
                            mask={NumberMask}
                        />
                        <OutlinedSearchSelectField
                            name={`${prefix}maritalStatus`}
                            options={MaritalStatuses}
                            label="Marital Status"
                            validate={requiredValidator}
                            disabled={!editable}
                        />
                    </True>
                </Condition>

                <WhenFieldChanges
                    field={`${prefix}maritalStatus`}
                    set={`${prefix}dateOfMarriage`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${prefix}maritalStatus`}
                    set={`${prefix}dateOfMarriage`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${prefix}maritalStatus`}
                    set={`${prefix}marriageCertificate`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${prefix}maritalStatus`}
                    set={`${prefix}dateOfDivorce`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${prefix}maritalStatus`}
                    set={`${prefix}divorceDecree`}
                    to={null}
                />
            </Grid>

            <Condition when={`${prefix}maritalStatus`} is={['Married', 'Widowed']}>
                <True>
                    <Grid item xs={12}>
                        <OutlinedDatePicker
                            name={`${prefix}dateOfMarriage`}
                            label="Date of Marriage"
                            disableFuture
                            disabled={!editable}
                        />
                    </Grid>
                    <Condition when={`${prefix}dateOfMarriage`} hasValue>
                        <True>
                            <Grid item xs={12}>
                                <FixedWidthFileUpload
                                    name={`${prefix}marriageCertificate`}
                                    label="Upload Marriage Certificate"
                                    labelprefixed="true"
                                    validate={requiredValidator}
                                    readonly={!editable}
                                />
                            </Grid>
                        </True>
                    </Condition>
                </True>
            </Condition>
            <Condition when={`${prefix}maritalStatus`} is={'Divorced'}>
                <True>
                    <Grid item xs={12}>
                        <OutlinedDatePicker
                            name={`${prefix}dateOfDivorce`}
                            label="Date Of Divorce"
                            disabled={!editable}
                            disableFuture
                        />
                    </Grid>
                    <Condition when={`${prefix}dateOfDivorce`} hasValue>
                        <True>
                            <Grid item xs={12}>
                                <FixedWidthFileUpload
                                    name={`${prefix}divorceDecree`}
                                    label="Upload Divorce Decree"
                                    validate={requiredValidator}
                                    labelprefixed="true"
                                    readonly={!editable}
                                />
                            </Grid>
                        </True>
                    </Condition>
                </True>
            </Condition>

            <Condition when={`${prefix}dateOfBirth`} passes={isAdult}>
                <True>
                    <Grid item xs={12}>
                        <FixedWidthRadioGroup
                            name={`${prefix}previouslyMarried`}
                            labelprefixed="true"
                            validate={requiredValidator}
                            label="Other than the current marital status, was the deceased previously married?"
                            disabled={!editable}
                        >
                            <FixedWidthRadioOption value="Yes" />
                            <FixedWidthRadioOption value="No" />
                        </FixedWidthRadioGroup>
                    </Grid>
                </True>
            </Condition>

            <WhenFieldChanges
                field={`${prefix}previouslyMarried`}
                becomes="No"
                set={`${prefix}previousMarriage`}
                to={[]}
            />
            <Condition when={`${prefix}previouslyMarried`} is="Yes">
                <True>
                    <Grid item xs={12}>
                        <FieldArray name={`${prefix}previousMarriage`}>
                            {({ fields }) =>
                                fields.map(marriageDateNumberRow(fields, classes, editable, prefix))
                            }
                        </FieldArray>
                        <Button
                            variant="contained"
                            className={classNames(classes.customButton, classes.fullWidthButton)}
                            onClick={() => push(`${prefix}previousMarriage`, undefined)}
                            id="add-previous-marriage"
                            disabled={!editable}
                            fullWidth
                        >
                            <AddCircle style={{ marginRight: '8px' }} /> Add Marriage
                        </Button>
                    </Grid>
                </True>
            </Condition>
            <DeceasedNavigation
                form={form}
                handleBack={handleBack}
                handleBackStepper={handleBackStepper}
                handleNext={handleNext}
                prefix="deceased-per-det"
            />
        </React.Fragment>
    );
};

const removeEntry = (fields, index) => {
    if (fields.length > 1) {
        fields.remove(index);
    } else {
        toastr.error('Error removing entry', 'You must at least have one entry', {
            timeOut: 0,
        });
    }
};

function marriageDateNumberRow(fields, classes, editable, prefix) {
    return function _marriageDateRow(policyNumber, index) {
        return (
            <Grid key={`marriageDate${index}`} container item xs={12}>
                <ExpansionPanel
                    name={`previousMarriage${index + 1}`}
                    id={`previousMarriage ${index + 1}`}
                    label={`Marriage ${index + 1}`}
                    className={classes.expensionPanel}
                    remove={() => removeEntry(fields, index)}
                    deletable={fields.length > 1 ? true : false}
                >
                    <OutlinedDatePicker
                        name={`${prefix}previousMarriage[${index}].date`}
                        id={`${prefix}previousMarriage[${index}].date`}
                        label={'Date of Marriage'}
                        className={classes.expensionPanelComponents}
                        disableFuture
                        maxDateField={`${prefix}dateOfMarriage`}
                        disabled={!editable}
                    />
                    <Condition when={`${prefix}previousMarriage[${index}].date`} hasValue>
                        <True>
                            <Grid container item xs={12}>
                                <OutlinedDatePicker
                                    name={`${prefix}previousMarriage[${index}].divorceDate`}
                                    id={`${prefix}previousMarriage[${index}].divorceDate`}
                                    label={'Date of Divorce'}
                                    className={classes.expensionPanelComponents}
                                    disableFuture
                                    minDateField={`${prefix}marriageDate[${index}]`}
                                    disabled={!editable}
                                />
                            </Grid>
                            <Condition
                                when={`${prefix}previousMarriage[${index}].divorceDate`}
                                hasValue
                            >
                                <True>
                                    <FixedWidthFileUpload
                                        name={`${prefix}previousMarriage[${index}].divorceDecree`}
                                        label={capitalize('Upload Divorce Decree')}
                                        labelprefixed="true"
                                        validate={requiredValidator}
                                        readonly={!editable}
                                    />
                                </True>
                                <False>
                                    <FixedWidthFileUpload
                                        name={`${prefix}previousMarriage[${index}].marriageCertificate`}
                                        label={capitalize('Upload Marriage Certificate')}
                                        labelprefixed="true"
                                        validate={requiredValidator}
                                        readonly={!editable}
                                    />
                                </False>
                            </Condition>
                        </True>
                    </Condition>
                </ExpansionPanel>
            </Grid>
        );
    };
}

export const PersonalDetails = withStyles(styles)(personalDetails);
