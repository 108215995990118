import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { env } from '../../../../env/env';
import axios from '../../../../http-request';
import { openDialogAction } from '../../../confirmation-dialog';
import { DocumentViewer } from '../../document-viewer';

export function viewDocument(files) {
    return async dispatch => {
        try {
            const { documents, title } = await getDocuments(files);
            dispatch(
                openDialogAction({
                    content: {
                        title: title,
                        body: <DocumentViewer {...{ documents }} />,
                    },
                })
            );
        } catch {}
    };
}

async function getDocuments(uploadedfiles) {
    const documents = [];
    const documentNames = [];
    for (let i = 0; i < uploadedfiles.length; i++) {
        const response = await axios.post(`${env.REACT_APP_API_URL}/view-files`, uploadedfiles[i]);
        documents.push({ uri: response.data.downloadUrl });
        documentNames.push(uploadedfiles[i].name);
    }

    return { documents, title: getViewTitle(documentNames) };
}

function getViewTitle(documentNames) {
    if (documentNames.length < 2) {
        return <Typography>{documentNames[0]}</Typography>;
    }

    return (
        <Grid container>
            {documentNames.map((name, index) => (
                <Grid item xs={12} key={name}>
                    <Typography key={name}>{`Doc ${index + 1}: ${name}`}</Typography>
                </Grid>
            ))}
        </Grid>
    );
}
