import { withStyles, Typography, Grid, Button, Tooltip } from '@material-ui/core';
import styles from '../../../styles/styles';
import React from 'react';
import { DeleteOutline, AddCircle } from '@material-ui/icons';
import { FieldArray } from 'react-final-form-arrays';
import { FixedWidthButtonGroup, OutlinedTextField, WhenFieldChanges } from '../../../forms';
import classNames from 'classnames';
import { getSouthAfricanAddress, remove } from '../../../shared/helper-functions';
import InputAdornment from '@material-ui/core/InputAdornment';
import { conditionalRequired, requiredValidator } from '../../../forms/validations';
import { NavigationButton } from '../../common/navigation/navigation-button';
import { NumberMask } from '../../../forms/input-masks';

const policyDetails = function ({
    classes,
    form,
    editable,
    index,
    stepsLength,
    handleBack,
    handleNext,
}) {
    const { push } = form.mutators;
    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
            </Grid>
            <Grid item xs={12} container justify="flex-start">
                <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                <Typography className={classes.stepHeader}>Policy Details</Typography>
            </Grid>
            <Grid item xs={12}>
                <FixedWidthButtonGroup
                    label="Are you the policy owner?"
                    name="deceasedDetails.isOwner"
                    options={['Yes', 'No']}
                    disabled={!editable}
                    validate={requiredValidator}
                />
                <WhenFieldChanges
                    field="deceasedDetails.isOwner"
                    becomes="Yes"
                    set="policyOwner.addressDetails"
                    to={getSouthAfricanAddress}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography className={classes.description} style={{ marginTop: '32px' }}>
                    If this is for more than one Liberty policy, please add all the policy numbers
                    below
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <FieldArray name="deceasedDetails.policyNumbers" key={`policyNumber${index}`}>
                    {({ fields }) => fields.map(policyNumberRow(fields, classes, editable))}
                </FieldArray>
                <Button
                    variant="contained"
                    className={classes.customButton}
                    onClick={() => push('deceasedDetails.policyNumbers', undefined)}
                    id="add-policy-no"
                    fullWidth
                    style={{ marginLeft: '1px', width: '368px' }}
                    disabled={!editable}
                >
                    <AddCircle style={{ marginRight: '8px' }} /> Add Policy Number
                </Button>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: '32px' }}>
                <NavigationButton
                    color="primary"
                    className={classNames(
                        classes.button,
                        classes.buttonAlignment,
                        classes.fullWidthButton
                    )}
                    onClick={() => handleNext(form)}
                    id="policy-details-continue"
                    label="Continue"
                />
            </Grid>
            <Grid item xs={12} style={{ paddingTop: '32px' }}>
                <NavigationButton
                    className={classNames(
                        classes.buttonSecondary,
                        classes.buttonAlignment,
                        classes.fullWidthButton
                    )}
                    onClick={() => handleBack(form)}
                    id="policy-details-back"
                    label="Previous Section"
                />
            </Grid>
        </React.Fragment>
    );
};

function policyNumberRow(fields, classes, editable) {
    return function _policyNumberRow(policyNumber, index) {
        const policyLabel = `Policy Number ${index + 1}`;
        return (
            <React.Fragment key={`policyNumber${index}`}>
                <OutlinedTextField
                    name={`deceasedDetails.policyNumbers[${index}]`}
                    labelprefixed="true"
                    mask={NumberMask}
                    label={policyLabel}
                    disabled={!editable}
                    validate={conditionalRequired(
                        values =>
                            (values &&
                                values.deceasedDetails &&
                                values.deceasedDetails.policyNumbers &&
                                values.deceasedDetails.policyNumbers[index + 1] &&
                                values.deceasedDetails.policyNumbers[index + 1] !== undefined &&
                                values.deceasedDetails.policyNumbers[index + 1] !== null) ||
                            values.deceasedDetails.isOwner === 'Yes' ||
                            index > 0
                    )}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {fields.length > 1 && editable && (
                                    <Tooltip title={`Delete ${policyLabel}`}>
                                        <DeleteOutline
                                            onClick={() => remove(fields, index)}
                                            className={classes.policyDetailsDelete}
                                        />
                                    </Tooltip>
                                )}
                            </InputAdornment>
                        ),
                    }}
                />
            </React.Fragment>
        );
    };
}

export const PolicyDetails = withStyles(styles)(policyDetails);
