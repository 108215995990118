import { createStyles } from '@material-ui/core/styles';

export default function styles(theme) {
    return createStyles({
        dropZoneWrapper: {
            marginTop: '6px',
            maxWidth: '368px',
        },
        dropZoneSection: {
            height: '48px',
            maxWidth: '368px',
            borderRadius: '4px',
            border: '1px dashed #002B99',
            backgroundColor: '#DEECFF',
        },
        dropZoneInner: {
            cursor: 'pointer',
            height: '100%',
        },
        dropZoneDiv: { display: 'flex' },
        dropZoneDropHere: {
            margin: '16px',
            marginLeft: '8px',
            height: '16px',
            color: '#002B99',
            fontSize: '12px',
            fontWeight: 500,
            letterSpacing: '0.43px',
            lineHeight: '16px',
        },
        dropZoneCloud: {
            marginTop: '12px',
            marginLeft: '17px',
            color: '#002B99',
            width: '24px',
            height: '24px',
        },
        dropZoneLink: {
            textDecoration: 'underline',
            color: '-webkit-link',
            margin: 'auto',
        },
        deleteIcon: {
            color: '#FFFFFF',
            height: '38px',
            width: '38px',
            objectFit: 'contain',
            marginRight: '1px',
        },
        uploadButton: {
            textTransform: 'none',
            backgroundColor: '#4378fd',
        },
        uploadIcon: {
            marginRight: theme.spacing(1),
            marginBottom: '-5px',
        },
        uploadContainer: { maxWidth: '368px', marginTop: '12px' },
        helperText: {
            fontSize: '14px',
            lineHeight: '1.71',
            color: '#afb3c2',
        },
        captionText: {
            fontSize: '12px',
            letterSpacing: 0,
            lineHeight: '16px',
        },
        error: {
            color: theme.palette.error.main,
        },
        fileUploadComplete: {
            height: '2px',
            width: '100%',
            borderRadius: '1px',
            backgroundColor: '#36B37E',
            margin: '10px 0px 10px 0px',
        },
        fileName: {
            maxWidth: '245px',
            height: '20px',
            color: '#0D1325',
            fontSize: '12px',
            letterSpacing: 0,
            lineHeight: '20px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        },
        fileContainer: {
            maxWidth: '368px',
            minHeight: '97px',
            border: '1px solid #C5C7D2',
            borderRadius: '4px',
            backgroundColor: '#ffffff',
            marginTop: '12px',
        },
        file: {
            marginTop: '12.5px',
            marginBottom: '12.5px',
            marginLeft: '16.5px',
            marginRight: '16.5px',
        },
        uploadLabel: {
            fontSize: '14px',
            fontWeight: 600,
            letterSpacing: 0,
            lineHeight: '20px',
        },
        center: {
            margin: 'auto',
        },
        fileIcon: {
            color: '#002B99',
        },
        fileAction: {
            cursor: 'pointer',
            height: '24px',
            width: '24px',
            marginLeft: '4px',
        },
        viewIcon: {
            color: '#002B99',
            marginLeft: '3px',
        },
        viewAction: {
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            cursor: 'pointer',
        },
    });
}
