import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from '../../styles/styles';
import { StepImage } from './step-image';

const scrollToTop = function () {
    setTimeout(() => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }, 200);
};
const VerticalStepperSteps = function ({
    classes,
    editable,
    handleSubmit,
    handleBack,
    resetForm,
    setFormErrors,
    setErrors,
    verticalComponents,
    verticalIndexName,
    ...rest
}) {
    let verticalStepIndex = +sessionStorage.getItem(verticalIndexName);
    if (verticalStepIndex === -1 || verticalStepIndex > verticalComponents.length - 1) {
        verticalStepIndex = verticalComponents.length - 1;
        sessionStorage.setItem(verticalIndexName, `${verticalStepIndex}`);
    }
    let [activeStep, setActiveStep] = React.useState(verticalStepIndex || 0);

    const goToNextStep = form => {
        if (activeStep === verticalComponents.length - 1) {
            sessionStorage.setItem('canGoToNextPage', 'true');
            handleSubmit(form);
        } else {
            if (Object.keys(form.getState().errors).length > 0) {
                setFormErrors(form);
                form.submit();
                scrollToTop();
            } else {
                activeStep++;
                sessionStorage.setItem('canGoToNextPage', 'false');
                sessionStorage.setItem(verticalIndexName, `${activeStep}`);
                setErrors([]);
                form.submit();
                setActiveStep(activeStep);
            }
        }
    };

    const goToPreviousStep = form => {
        sessionStorage.setItem(verticalIndexName, `${activeStep - 1}`);
        setActiveStep(prevActiveStep => prevActiveStep - 1);
        resetForm(form);
        setErrors([]);
    };

    const goToPreviousSection = form => {
        sessionStorage.setItem(verticalIndexName, '0');
        sessionStorage.setItem('canGoToNextPage', 'false');
        handleBack(form);
    };

    const goToSelectedStep = step => () => {
        sessionStorage.setItem(verticalIndexName, `${step}`);
        setActiveStep(step);
        if (step < verticalComponents.length - 1) {
            sessionStorage.setItem('canGoToNextPage', 'false');
        } else {
            sessionStorage.setItem('canGoToNextPage', 'true');
        }
    };

    const VerticalComponent = verticalComponents[activeStep].component;
    return (
        <Grid container justify="flex-start">
            <Grid
                item
                spacing={0}
                className={classes.stepsContainer}
                container
                justify="flex-start"
                style={{
                    height: stepperHeight(verticalComponents.length),
                }}
            >
                {verticalComponents.map((component, stepIndex) => {
                    const stepNameClass =
                        stepIndex === activeStep ? classes.stepNameActive : classes.stepName;
                    const stepIconClass =
                        stepIndex < activeStep || !editable
                            ? classes.stepIconCompleted
                            : classes.stepIcon;
                    return (
                        <div key={stepIndex} className={classes.stepInfoContainer}>
                            <div className={classes.stepInfo}>
                                <Typography className={classes.stepDetails}>
                                    <span className={stepNameClass}>{component.name}</span>
                                    <br />
                                    <span className={classes.stepDescription}>
                                        {component.description}
                                    </span>
                                </Typography>
                                <div
                                    className={stepIconClass}
                                    onClick={
                                        stepIndex < activeStep || !editable
                                            ? goToSelectedStep(stepIndex)
                                            : () => {}
                                    }
                                >
                                    <StepImage
                                        currentIndex={stepIndex}
                                        activeIndex={activeStep}
                                        submitted={!editable}
                                    />
                                </div>
                            </div>
                        </div>
                    );
                })}
            </Grid>
            <Grid item className={classes.stepsContents} style={{ width: '60%' }}>
                <VerticalComponent
                    {...rest}
                    index={activeStep}
                    stepsLength={verticalComponents.length}
                    handleBack={goToPreviousSection}
                    handleBackStepper={goToPreviousStep}
                    handleNext={goToNextStep}
                    editable={editable}
                />
            </Grid>
        </Grid>
    );
};

export function stepperHeight(numberOfSteps) {
    return Number(numberOfSteps) * 80;
}

export const VerticalStepper = withStyles(styles)(VerticalStepperSteps);
