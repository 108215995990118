import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    Typography,
    withStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as React from 'react';
import { connect } from 'react-redux';
import { getDisplayDate } from '../../../../shared/helper-functions';
import styles from '../../../../styles/styles';
import { LabelValue } from '../../../common/label-value';

const paymentSummary = function ({ classes, payments }) {
    if (!payments || payments.length < 1) {
        return null;
    }
    return (
        <><Accordion
            style={{
                margin: '-24px -16px 24px -16px',
                borderRadius: '4px',
            }}
        >

            <AccordionSummary
                className={[classes.whiteBackground, classes.whiteTitle].join(' ')}
                expandIcon={<ExpandMoreIcon />}
                style={{ borderRadius: '4px' }}
            >
                <Typography className={classes.previewIndex}>4</Typography>
                <Typography className={classes.contentHeader}>Payment Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container justify="flex-start">
                    <Grid item xs={12}  style={{ marginTop: '24px' }}>
                        <PaymentDetails
                            key={'paymentSummary'}
                            payments={payments}
                            classes={classes}
                            index={0} />
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion></>
    );
};

const PaymentDetails = ({ payments, classes, index }) => {
    return (
        <Accordion>

            <AccordionDetails>
                <Grid container justify="flex-start">
                    <Grid item xs={12} style={{ marginTop: '24px' }}>
                        <Typography variant="h6">Payee Details</Typography>
                        <hr />
                    </Grid>
                    <React.Fragment>
                        <LabelValue label="Payment must be made into the account of" value={payments.paymentBeneficiary} />
                        <LabelValue label="Account holder Type" value={payments.policyHolderType} />
                        <LabelValue label="Bank Name" value={payments.bankName} />
                        <LabelValue label="Account Number" value={payments.accountNumber} />
                        <LabelValue label="Account Type" value={payments.accountType} />
                        <LabelValue label="Account holder relationship" value={payments.relationshipToAccountHolder} />
                        <LabelValue label="Branch Name" value={payments.branchName} />
                        <LabelValue label="Branch Code" value={payments.branchCode} />
                        <LabelValue label="Is the account holder a South African resident?" value={payments.isRSAResident} />

                        <LabelValue label="Full first names of account holder" value={payments.accountHolderFirstName} />
                        <LabelValue label="Surname" value={payments.surname} />
                        <LabelValue label="Id Number" value={payments.idNumber} />
                        <LabelValue label="Passport Number" value={payments.passportNumber} />
                        <LabelValue label="Country of Issue" value={payments.countryOfIssue} />
                        <LabelValue label="Date of Issue" value={payments.dateOfIssue} />
                        <LabelValue label="Date of Expiry" value={payments.dateOfExpiry} />
                        <LabelValue label="Country of Residence" value={payments.countryOfResidence} />
                        <LabelValue label="Relationship to Policyholder" value={payments.relationshipToPolicyHolder} />

                        <LabelValue label="Company Name" value={payments.companyName} />
                        <LabelValue label="Company Registration Number" value={payments.companyRegistrationNumber} />
                        <LabelValue label="Company Registration Date" value={getDisplayDate(payments.companyRegistrationDate)} />
                        <LabelValue label="Country of Incorporation" value={payments.countryOfIncorporation} />

                    </React.Fragment>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

const mapStateToProps = state => {
    const formData = (state && state.claimSelection) || {};
    return {
        payments: (formData.claim && formData.claim.Payment && formData.claim.Payment.Information) || [],
    };
};

export const PaymentSummary = connect(mapStateToProps, {})(withStyles(styles)(paymentSummary));
