export const BankNames = [
    { value: 'ABSA', label: 'Absa' },
    { value: 'ABSA-ITHALA', label: 'Absa-Ithala' },
    { value: 'AFRICAN BANK', label: 'African Bank' },
    { value: 'ALBARAKA BANK', label: 'Albaraka Bank' },
    { value: 'BANK OF CHINA', label: 'Bank Of China' },
    { value: 'TAI', label: 'Bank Of Taiwan' },
    { value: 'TRK', label: 'Bank Of Transkei' },
    { value: 'BANK WINDHOEK BEPERK', label: 'Bank Windhoek Beperk' },
    { value: 'BZ MUTUAL BANK', label: 'Bank Zero Mutual Bank' },
    { value: 'BANQUEINDO', label: 'Banque Indosuez South Africa' },
    { value: 'BARCLES', label: 'Barclays Bank Lesotho' },
    { value: 'BBS', label: 'Barclays Bank Swaziland' },
    { value: 'BIDVEST BANK LIMITED', label: 'Bidvest Bank Limited' },
    { value: 'BNP PARIBAS SA', label: 'Bnp Paribas Sa South Africa' },
    { value: 'CGB', label: 'Cape Of Good Hope Bank' },
    { value: 'CAPITEC BANK LIMITED', label: 'Capitec Bank Limited' },
    { value: 'CBS', label: 'Central Bank Of Swaziland' },
    { value: 'CCB', label: 'China Construction Bank' },
    { value: 'CITIBANK', label: 'Citibank' },
    { value: 'CITIZEN', label: 'Citizen Bank' },
    { value: 'COMNAB', label: 'Commercial Bank Of Namibia' },
    { value: 'COMMB', label: 'Community Bank' },
    { value: 'CAI', label: 'Credit Agricole Indosuez' },
    { value: 'DISCOVERY BANK LTD', label: 'Discovery Bank Ltd' },
    { value: 'FBC', label: 'Fbc Fidelity Bank Ltd' },
    { value: 'FINBOND EPE', label: 'Finbond Epe' },
    { value: 'FINBOND MUTUAL BANK', label: 'Finbond Mutual Bank' },
    { value: 'CORP', label: 'Firstcorp Merchant Bank Limite' },
    { value: 'FIRSTRAND BANK', label: 'Firstrand Bank' },
    { value: 'FIRSTNAM', label: 'Firstrand Bank Of Namibia' },
    { value: 'FBS', label: 'Firstrand Bank Of Swaziland' },
    { value: 'FutureBank', label: 'Future Bank Corp Ltd' },
    { value: 'GRINDROD', label: 'Grindrod Bank Limited' },
    { value: 'GROBANK LIMITED', label: 'Grobank Limited' },
    { value: 'HOBL', label: 'Habib Overseas Bank Limited' },
    { value: 'HBZ BANK LIMITED', label: 'Hbz Bank Limited' },
    { value: 'HSBC BANK', label: 'Hsbc Bank' },
    { value: 'ICICI BANK LIMITED', label: 'Icici Bank Limited' },
    { value: 'UNI', label: 'Inibank Limited' },
    { value: 'IBSA', label: 'International Bank Of Southern Africa Ltd' },
    { value: 'INVESTEC', label: 'Investec Bank' },
    { value: 'IDFC', label: 'Ithala Development Finance Corporation' },
    { value: 'ITH', label: 'Ithala(Standard Bank)' },
    { value: 'JP MORGAN', label: 'Jp Morgan Chase Bank Na Johann' },
    { value: 'LESO', label: 'Lesotho Bank' },
    { value: 'MEEG', label: 'Meeg Bank' },
    { value: 'MRCB', label: 'Mercantile Bank' },
    { value: 'MTN', label: 'Mtn Banking(Standard Bank)' },
    { value: 'NEDBANK', label: 'Nedbank' },
    { value: 'NLO', label: 'Nedbank Lesotho Ltd' },
    { value: 'NEDBOE', label: 'Nedbank Ltd Inc Boe Bank' },
    { value: 'NEDBANK NAMIBIA', label: 'Nedbank Namibia' },
    { value: 'NEDSWAZI', label: 'Nedbank Swaziland' },
    { value: 'NEDF', label: 'Nedfin Bank' },
    { value: 'NNA', label: 'Nna' },
    { value: 'No-match', label: 'No Match' },
    { value: 'MUTUAL', label: 'Old Mutual Bank' },
    { value: 'OLYMPUS MOBILE', label: 'Olympus Mobile' },
    { value: 'PEP', label: 'People Bank Ltd Inc Pep Bank' },
    { value: 'PBL', label: 'Peoples Bank Ltd Inc Nbs' },
    { value: 'PERMANENT BANK', label: 'Permanent Bank' },
    { value: 'RNBL', label: 'Rennies Bank Limited' },
    { value: 'RBS', label: 'Royal Bank Of Scotland' },
    { value: 'SA RESERVE', label: 'Sa Reserve' },
    { value: 'SAAM', label: 'Saambou Bank' },
    { value: 'SANT', label: 'Santambank' },
    { value: 'SASFIN BANK', label: 'Sasfin Bank' },
    { value: 'SOCIETE GENERALE', label: 'Societe Generale' },
    { value: 'SAPO', label: 'South African Post Office' },
    { value: 'STANDARD BANK', label: 'Standard Bank' },
    { value: 'STANBOP', label: 'Standard Bank Of Bophuthatswan' },
    { value: 'SBSZ', label: 'Standard Bank Swaziland' },
    { value: 'STDCHART', label: 'Standard Chartered Bank Sa' },
    { value: 'SLESOTHO', label: 'Standard Lesotho Bank' },
    { value: 'STDNAM', label: 'Standard Namibia Bank' },
    { value: 'STATE BANK OF INDIA', label: 'State Bank Of India' },
    { value: 'SWABO', label: 'Swa Building Society' },
    { value: 'SWADEV', label: 'Swaziland Dev And Savings Bank' },
    { value: 'TYME BANK LIMITED', label: 'Tyme Bank Limited' },
    { value: 'UBANK LTD', label: 'Ubank Ltd' },
    { value: 'UNIBA', label: 'Unibank' },
    { value: 'UTD', label: 'United Bank' },
    { value: 'VACANT (EX-BARCLAYS)', label: 'Vacant (Ex-Barclays)' },
    { value: 'VACANTEXLES', label: 'Vacant(Ex-Lesotho Bank)' },
    { value: 'VBS MUTUAL BANK', label: 'Vbs Mutual Bank' },
];

export function getBankName(code) {
    const bank = BankNames.find(b => b.value === code);
    if (bank) {
        return bank.label;
    }
    return code;
}