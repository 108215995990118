import * as React from 'react';

import { FormHelperText, RadioGroup, withStyles, Typography } from '@material-ui/core';
import {
    addNameToChildren,
    getComponentId,
    nameToLabel,
    processMetaForErrors,
} from '../../form-component-utilities';
import { getValidatedLabel } from '../../form-component-utilities/label-validator/label-validator';
import styles from '../styles';

const radioGroupField = ({
    classes,
    label,
    helperText,
    id,
    children,
    input,
    meta,
    disabled,
    ...rest
}) => {
    const { name, value } = input;
    const { errorMessage, showError } = processMetaForErrors(meta);
    const style = showError ? { color: '#cd003d' } : undefined;
    const childrenWithProps = addNameToChildren({ id, name, children, disabled, style });
    const _id = getComponentId({ id, name });

    const showHelperText = showError || !!helperText;
    const labelStyle = { ...style, marginBottom: '-12px' };
    const isVertical = rest.isvertical;
    return (
        <div className={classes.textField}>
            <Typography className={classes.cssLabel} style={labelStyle}>
                {!label
                    ? getValidatedLabel(rest, nameToLabel({ label, name }))
                    : getValidatedLabel(rest, label)}
            </Typography>
            <RadioGroup
                {...rest}
                className={isVertical ? classes.radioGroupSecondary: classes.radioGroup}
                id={_id}
                name={name}
                value={value}
                style={style}
            >
                {childrenWithProps}
            </RadioGroup>
            {showHelperText && (
                // @ts-ignore
                <FormHelperText error={showError} component="pre" style={{ marginTop: '-18px' }}>
                    {showError ? errorMessage : helperText}
                </FormHelperText>
            )}
        </div>
    );
};

export const RadioGroupField = withStyles(styles)(radioGroupField);
