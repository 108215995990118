import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { getSameAsName } from '../../../../shared/helper-functions';
import styles from '../../../../styles/styles';
import { AddressDetailsSummary } from '../common/address-details';
import { LabelValue } from '../../../common/label-value';

const addressDetails = ({ classes, claimant, claimants }) => {
    const individual = claimant.individual;
    return (
        <React.Fragment>
            <Grid item xs={12} style={{ marginTop: '24px' }}>
                <Typography variant="h6">Address Details</Typography>
                <hr />
            </Grid>
            {!claimant.addressSameAs || claimant.addressSameAs === 'No' ? (
                <React.Fragment>
                    <AddressView
                        classes={classes}
                        address={claimant.residentialAddress}
                        title="Residential Address"
                    />
                    <AddressView
                        classes={classes}
                        address={claimant.postalAddress}
                        title="Postal Address"
                    />
                </React.Fragment>
            ) : (
                <LabelValue
                    label="Same as"
                    value={
                        individual.relationshipToLifeAssured === 'MinorChild'
                            ? getSameAsName(claimants, claimant.guardian, individual.guardianSameAs)
                            : getSameAsName(
                                  claimants,
                                  claimant.curator,
                                  claimant.curator.curatorSameAs
                              )
                    }
                />
            )}
        </React.Fragment>
    );
};

const AddressView = ({ classes, address, title }) => {
    if (!address) {
        return null;
    }
    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Typography className={classes.whiteTitle}>{title}</Typography>
            </Grid>
            <AddressDetailsSummary addressDetails={address} />
        </React.Fragment>
    );
};

export const IndividualAddressSummary = withStyles(styles)(addressDetails);
