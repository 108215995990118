import * as React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from '../../../styles/styles';
import { Countries, MaritalStatuses, Titles } from '../../../shared/lookups';
import { requiredValidator } from '../../../forms/validations';
import { OutlinedSearchSelectField, OutlinedTextField } from '../../../forms';
import { FirstNameMask, InitialsMask, LastNameMask } from '../../../forms/input-masks';
import { IdentityDetails } from '../../common/identity-details';
import { ClaimantNavigation } from '../../common/navigation/claimant-navigation';

const entityAuthorisedPersonDetails = function ({
    classes,
    handleBackStepper,
    handleNext,
    form,
    editable,
    index,
    stepsLength,
    claimantIndex,
    ...rest
}) {
    return (
        <Grid container spacing={10} justify="flex-start">
            <Grid item xs={12} lg={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
                <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                <Typography className={classes.stepHeader}>
                    {`Authorised Person's Details`}
                </Typography>
            </Grid>
            <Grid item xs={12} lg={12}>
                <OutlinedSearchSelectField
                    shaded={false}
                    name={`claimants[${claimantIndex}].entity.authorisedPerson.title`}
                    disabled={!editable}
                    options={Titles}
                    validate={requiredValidator}
                    labelprefixed="true"
                />
            </Grid>
            <Grid item xs={12} lg={12}>
                <OutlinedTextField
                    name={`claimants[${claimantIndex}].entity.authorisedPerson.initials`}
                    mask={InitialsMask}
                    disabled={!editable}
                />
            </Grid>
            <Grid item xs={12} lg={12}>
                <OutlinedTextField
                    name={`claimants[${claimantIndex}].entity.authorisedPerson.firstName`}
                    label="First Name"
                    validate={requiredValidator}
                    labelprefixed="true"
                    disabled={!editable}
                    mask={FirstNameMask}
                />
            </Grid>
            <Grid item xs={12} lg={12}>
                <OutlinedTextField
                    name={`claimants[${claimantIndex}].entity.authorisedPerson.secondName`}
                    label="Second Name"
                    disabled={!editable}
                    mask={FirstNameMask}
                />
            </Grid>
            <Grid item xs={12} lg={12}>
                <OutlinedTextField
                    name={`claimants[${claimantIndex}].entity.authorisedPerson.lastName`}
                    label="Surname"
                    validate={requiredValidator}
                    labelprefixed="true"
                    disabled={!editable}
                    mask={LastNameMask}
                />
            </Grid>
            <IdentityDetails
                {...rest}
                includeForeignTypes={true}
                includeGender={true}
                prefix={`claimants[${claimantIndex}].entity.authorisedPerson.`}
                editable={editable}
                idDocumentRequired
            />
            <Grid item xs={12} lg={12}>
                <OutlinedSearchSelectField
                    name={`claimants[${claimantIndex}].entity.authorisedPerson.maritalStatus`}
                    options={MaritalStatuses}
                    disabled={!editable}
                    label="Marital Status"
                    shaded={false}
                />
            </Grid>

            <Grid item xs={12} lg={12}>
                <OutlinedSearchSelectField
                    name={`claimants[${claimantIndex}].entity.authorisedPerson.nationality`}
                    options={Countries}
                    disabled={!editable}
                    label="Nationality"
                    shaded={false}
                />
            </Grid>
            <ClaimantNavigation
                form={form}
                handleBackStepper={handleBackStepper}
                handleNext={handleNext}
                prefix={`claimant-${claimantIndex}-auth-person`}
            />
        </Grid>
    );
};

export const EntityAuthorisedPersonDetails = withStyles(styles)(entityAuthorisedPersonDetails);
