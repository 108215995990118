import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    Typography,
    withStyles,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import React from 'react';
import { connect } from 'react-redux';
import styles from '../../../../styles/styles';
import { AddressDetails } from '../common/address-details';
import { ContactDetails } from '../common/contact-details';
import { PaymentDetails } from '../common/payment-details';
import { PersonalDetails } from '../common/personal-details';

const ownerSummary = function ({ classes, policyOwner }) {
    if (!policyOwner) {
        return null;
    }

    return (
        <Accordion
            style={{
                margin: '-24px -16px 24px -16px',
                borderRadius: '4px',
            }}
        >
            <AccordionSummary
                className={[classes.whiteBackground, classes.whiteTitle].join(' ')}
                expandIcon={<ExpandMore />}
                style={{ borderRadius: '4px' }}
            >
                <Typography className={classes.previewIndex}>2</Typography>
                <Typography className={classes.contentHeader}>Policy Owner</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid sm={12} xs={12}>
                    <PersonalDetails personalDetails={policyOwner.personalDetails} />
                    <ContactDetails contactDetails={policyOwner.contactDetails} />
                    <AddressDetails addressDetails={policyOwner.addressDetails} />
                    <PaymentDetails bankingDetails={policyOwner.bankingDetails} />
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

const mapStateToProps = state => {
    const claimSelection = (state && state.claimSelection) || {};
    return {
        policyOwner: claimSelection.claim && claimSelection.claim.policyOwner,
    };
};

export const PolicyOwnerSummary = connect(mapStateToProps, {})(withStyles(styles)(ownerSummary));
