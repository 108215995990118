import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from '../../../styles/styles';
import {
    Condition,
    False,
    OutlinedSearchSelectField,
    OutlinedTextField,
    True,
} from '../../../forms';
import { requiredValidator } from '../../../forms/validations';
import { FirstNameMask, InitialsMask, LastNameMask, NumberMask } from '../../../forms/input-masks';
import { Gender, Titles } from '../../../shared/lookups';
import { ClaimantNavigation } from '../../common/navigation/claimant-navigation';
import { NavigationButton } from '../../common/navigation/navigation-button';
import classNames from 'classnames';

const personalDetails = function ({
    classes,
    form,
    index,
    handleNext,
    handleBackStepper,
    editable,
    stepsLength,
    handleBack
}) {
    const prefix = 'claimant.personalDetails.';

    return (
        <Grid container spacing={10} justify="flex-start">
            <Grid item xs={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
                <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                <Typography className={classes.stepHeader}>Personal Details</Typography>
            </Grid>
            <Grid item xs={12} lg={12}>
                <OutlinedSearchSelectField
                    name={`${prefix}title`}
                    label="Title"
                    validate={requiredValidator}
                    labelprefixed="true"
                    options={Titles}
                    disabled={!editable}
                />
                <OutlinedTextField
                    name={`${prefix}firstName`}
                    label="First Name"
                    mask={FirstNameMask}
                    validate={requiredValidator}
                    labelprefixed="true"
                    disabled={!editable}
                />

                <OutlinedTextField
                    name={`${prefix}surname`}
                    label="Surname"
                    validate={requiredValidator}
                    labelprefixed="true"
                    mask={LastNameMask}
                    disabled={!editable}
                />

                <Condition
                    when={'claimant.policyDetails.claimingCapacity'}
                    is="Financial Advisor"
                >
                    <True>
                        <OutlinedTextField
                            name={`${prefix}companyName`}
                            label="Company Name"
                            validate={requiredValidator}
                            labelprefixed="true"
                            disabled={!editable}
                        />
                    </True>
                    <True>
                        <OutlinedTextField
                            name={`${prefix}consultantCode`}
                            label="Consultant Code"
                            validate={requiredValidator}
                            mask={NumberMask}
                            labelprefixed="true"
                            disabled={!editable}
                        />
                    </True>
                    <False>
                        <OutlinedTextField
                            name={`${prefix}initials`}
                            label="Initials"
                            validate={requiredValidator}
                            labelprefixed="true"
                            mask={InitialsMask}
                            disabled={!editable}
                        />
                        <Grid item xs={12}>
                            <OutlinedSearchSelectField
                                name={`${prefix}gender`}
                                options={Gender}
                                validate={requiredValidator}
                                labelprefixed="true" />
                        </Grid>
                    </False>
                </Condition>
            </Grid>
            <ClaimantNavigation
                form={form}
                handleBackStepper={handleBackStepper}
                handleNext={handleNext}
                prefix={'claimant-person'}
            />
            <Grid item xs={12} className={classes.navigationGroup}>
                <NavigationButton
                    className={classNames(classes.buttonSecondary, classes.fullWidthButton)}
                    onClick={() => handleBack(form)}
                    id={`${prefix}-previous-button`}
                    label="Previous Section"
                />
            </Grid>
        </Grid>
    );
};

export const PersonalDetails = withStyles(styles)(personalDetails);
