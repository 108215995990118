import { Grid, withStyles } from '@material-ui/core';
import React from 'react';
import { Condition,  True, WhenFieldChanges } from '../../../../forms';
import { OutlinedSearchSelectField, OutlinedTextField } from '../../../../forms';
import { NumberMask } from '../../../../forms/input-masks';
import { requiredValidator } from '../../../../forms/validations';
import { defaultCountry, isBoxOrBag } from '../../../../shared/helper-functions';
import { addressTypes, Provinces } from '../../../../shared/lookups';
import styles from '../../../../styles/styles';

const doctorsAddress = function ({ classes, editable, prefix }) {
    return (
        <React.Fragment>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <OutlinedSearchSelectField
                        className={classes.expensionPanelComponents}
                        name={`${prefix}addressType`}
                        label="Address Type"
                        labelprefixed="true"
                        options={addressTypes(true)}
                        validate={requiredValidator}
                        disabled={true}
                    />
                </Grid>
                <WhenFieldChanges
                    field={`${prefix}addressType`}
                    set={`${prefix}postalCode`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${prefix}addressType`}
                    set={`${prefix}country`}
                    to={defaultCountry}
                />
                <WhenFieldChanges
                    field={`${prefix}addressType`}
                    becomes="Foreign"
                    set={`${prefix}province`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${prefix}addressType`}
                    condition={isBoxOrBag}
                    set={`${prefix}addressLineOne`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${prefix}addressType`}
                    condition={isBoxOrBag}
                    set={`${prefix}addressLineTwo`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${prefix}addressType`}
                    condition={isBoxOrBag}
                    set={`${prefix}addressLineThree`}
                    to={null}
                />
                <Condition when={`${prefix}addressType`} hasValue>
                    <True>
                        <Condition when={`${prefix}addressType`} is={['Street']}>
                            <True>
                                <Grid item xs={12} sm={6}>
                                    <OutlinedTextField
                                        className={classes.expensionPanelComponents}
                                        name={`${prefix}town`}
                                        label="City"
                                        labelprefixed="true"
                                        validate={requiredValidator}
                                        disabled={!editable}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <OutlinedTextField
                                        className={classes.expensionPanelComponents}
                                        name={`${prefix}addressLineOne`}
                                        label="Address Line 1"
                                        labelprefixed="true"
                                        validate={requiredValidator}
                                        disabled={!editable}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <OutlinedTextField
                                        className={classes.expensionPanelComponents}
                                        name={`${prefix}addressPostalCode`}
                                        label="Postal Code"
                                        labelprefixed="true"
                                        validate={requiredValidator}
                                        mask={NumberMask}
                                        disabled={!editable}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <OutlinedTextField
                                        className={classes.expensionPanelComponents}
                                        name={`${prefix}addressLineTwo`}
                                        label="Address Line 2"
                                        disabled={!editable}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <OutlinedSearchSelectField
                                        className={classes.expensionPanelComponents}
                                        name={`${prefix}province`}
                                        label="Province"
                                        labelprefixed="true"
                                        options={Provinces}
                                        validate={requiredValidator}
                                        disabled={!editable}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <OutlinedTextField
                                        className={classes.expensionPanelComponents}
                                        name={`${prefix}suburb`}
                                        label="Town/Suburb"
                                        labelprefixed="true"
                                        validate={requiredValidator}
                                        disabled={!editable}
                                    />
                                </Grid>

                            </True>
                        </Condition>
                    </True>
                </Condition>
            </Grid>
        </React.Fragment>

    );
};

export const DoctorsAddress = withStyles(styles)(doctorsAddress);
