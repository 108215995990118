import { Grid, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import { getDisplayDate, getLabel } from '../../../../shared/helper-functions';
import { NaturalCausesOfDeath, UnnaturalCausesOfDeath } from '../../../../shared/lookups';
import styles from '../../../../styles/styles';
import { LabelValue } from '../../../common/label-value';

const deathDetails = function ({ detailsOfDeath }) {
    if (!detailsOfDeath) {
        return null;
    }
    return (
        <Grid container justify="flex-start">
            <Grid item xs={12} style={{ marginTop: '24px' }}>
                <Typography variant="h6">Details Of Death</Typography>
                <hr />
            </Grid>
            <LabelValue label="Date of Death" value={getDisplayDate(detailsOfDeath.dateOfDeath)} />
            <LabelValue label="Place of Death" value={detailsOfDeath.placeOfDeath} />
            <LabelValue label="Cause of Death" value={detailsOfDeath.conditionType} />
            <LabelValue
                label="Condition"
                value={getLabel(
                    detailsOfDeath.condition,
                    NaturalCausesOfDeath.concat(UnnaturalCausesOfDeath)
                )}
            />
            <LabelValue label="Description" value={detailsOfDeath.description} />
            <LabelValue
                label="Was the death reported to the police?"
                value={detailsOfDeath.reportedToPolice}
            />
            <LabelValue label="Police Station" value={detailsOfDeath.policeStation} />
            <LabelValue label="police Case Number" value={detailsOfDeath.policeCaseNumber} />
            <LabelValue label="Upload Police Report" value={detailsOfDeath.policeStatement} />
            <LabelValue
                label="Upload Death Certificate"
                value={detailsOfDeath.deathCertificate}
                isFile
            />
        </Grid>
    );
};

export const DetailsOfDeathSummary = withStyles(styles)(deathDetails);
