import * as React from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    Typography,
    withStyles,
} from '@material-ui/core';
import styles from '../../../styles/styles';
import { ExpandMore } from '@material-ui/icons';
import { LabelValue } from '../../common/label-value';

const executorDetailsSummary = function ({ classes, executorsDetails }) {
    if (!executorsDetails) {
        return null;
    }
    return (
        <Accordion
            defaultExpanded={false}
            style={{
                margin: '24px -16px 24px -16px',
                borderRadius: '4px',
            }}
        >
            <AccordionSummary
                className={[classes.whiteBackground, classes.whiteTitle].join(' ')}
                expandIcon={<ExpandMore />}
                style={{ borderRadius: '4px' }}
            >
                <Typography className={classes.previewIndex}>3</Typography>
                <Typography className={classes.contentHeader}>Executor Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container justify="flex-start">
                    <Typography style={{ marginBottom: '16px' }}>
                        Executor is the legal term for referring to the person (or people) nominated
                        in your will to carry out the directives you set in your will when you die"
                        to this "Executor is the legal term for referring to the person (or people)
                        nominated in your will to carry out the directives you set in your will in
                        the event of death
                    </Typography>
                    <Grid sx={12} sm={12}>
                        <LabelValue label="Name" value={executorsDetails.firstName} />
                        <LabelValue label="Surname" value={executorsDetails.lastName} />
                        <LabelValue label="Email Address" value={executorsDetails.email} />
                        <LabelValue label="Contact Number" value={executorsDetails.contactNumber} />
                        <LabelValue
                            label="Copy of Letter of Executorship / Letter of Authority"
                            value={executorsDetails.letterOfExecutorship}
                            isFile={true}
                        />
                    </Grid>

                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

export const ExecutorDetailsSummary = withStyles(styles)(executorDetailsSummary);
