import * as React from 'react';
import { Grid, Paper, withStyles } from '@material-ui/core';
import styles from '../../../styles/styles';
import { VerticalStepper } from '../../common/vertical-stepper';
import { BankingDetails } from './banking-details';
import { ContactDetails } from './contact-details';
import { DeceasedAddressDetails } from './deceased-address-details';
import { DetailsOfDeath } from './details-of-death';
import { FinancialDetails } from './financial-details';
import { OccupationDetails } from './occupation-details';
import { OtherInsurancePolicies } from './other-insurance-policies';
import { PersonalDetails } from './personal-details';
import { PolicyDetails } from './policy-details';

const verticalComponents = [
    {
        name: 'Policy Details',
        description: 'Provide all Liberty policy numbers, if available',
        component: PolicyDetails,
    },
    {
        name: 'Personal Details',
        description: 'Personal details of the deceased',
        component: PersonalDetails,
    },
    {
        name: 'Contact Details',
        description: 'Contact details of the deceased, required for SARS purposes',
        component: ContactDetails,
    },
    {
        name: 'Address Details',
        description: 'Provide all address details for the deceased',
        component: DeceasedAddressDetails,
    },
    {
        name: 'Occupation Details',
        description: `Details of deceased's occupation`,
        component: OccupationDetails,
    },
    {
        name: 'Banking Details',
        description: 'Please provide banking details of the deceased',
        component: BankingDetails,
    },
    {
        name: 'Financial Details',
        description: 'Please provide financial details of the deceased',
        component: FinancialDetails,
    },
    {
        name: 'Details Of Death',
        description: 'Details of where & what the cause of death was',
        component: DetailsOfDeath,
    },
    {
        name: 'Other Insurance Policies',
        description: 'Policies held by other companies',
        component: OtherInsurancePolicies,
    },
];

const deceasedDetails = function ({ classes, form, handleBack, handleSubmit, ...rest }) {
    return (
        <Paper className={[classes.shadedPaper, classes.paperShadowLess].join(' ')}>
            <Grid container spacing={10} justify="flex-start">
                <Grid item xs={12} lg={12}>
                    <VerticalStepper
                        {...rest}
                        verticalComponents={verticalComponents}
                        verticalIndexName={'verticalStepIndex'}
                        form={form}
                        handleBack={handleBack}
                        handleSubmit={handleSubmit}
                    />
                </Grid>
            </Grid>
        </Paper>
    );
};

export const DeceasedDetails = withStyles(styles)(deceasedDetails);
