import React from 'react';
import { Typography, withStyles } from '@material-ui/core';
import styles from '../../../styles/styles';
import {
    FixedWidthRadioGroup,
    FixedWidthRadioOption,
    OutlinedDatePicker,
    OutlinedSearchSelectField,
    OutlinedTextField,
} from '../../../forms';
import { requiredValidator } from '../../../forms/validations';
import { InitialsMask } from '../../../forms/input-masks';
import { IdentityDetails } from '../../common/identity-details';
import { Condition, True, WhenFieldChanges } from '../../../forms';
import { Countries, MaritalStatuses, NaturalRelationships } from '../../../shared/lookups';
import { FixedWidthFileUpload } from '../../../forms/form-components/fixed-width-file-upload';

const guardianDetails = function ({ classes, editable, prefix }) {
    return (
        <React.Fragment>
            <Typography className={classes.subHeading2} style={{ marginTop: '30px' }}>
                Guardian Details
            </Typography>
            <OutlinedTextField
                name={`${prefix}.guardian.initials`}
                mask={InitialsMask}
                disabled={!editable}
            />
            <OutlinedTextField
                name={`${prefix}.guardian.firstName`}
                label="Name"
                disabled={!editable}
                labelprefixed="true"
                validate={requiredValidator}
            />
            <OutlinedTextField
                name={`${prefix}.guardian.surname`}
                label="Surname"
                disabled={!editable}
                labelprefixed="true"
                validate={requiredValidator}
            />
            <IdentityDetails
                prefix={`${prefix}.guardian.`}
                editable={editable}
                includeForeignTypes={true}
                includeGender={false}
            />
            <OutlinedSearchSelectField
                name={`${prefix}.guardian.maritalStatus`}
                options={MaritalStatuses}
                disabled={!editable}
                label="Marital status"
            />
            <Condition when={`${prefix}.guardian.maritalStatus`} is="Married">
                <True>
                    <OutlinedDatePicker
                        name={`${prefix}.guardian.dateOfMarriage`}
                        label="Date of marriage"
                        disableFuture
                        validate={requiredValidator}
                        disabled={!editable}
                        labelprefixed="true"
                    />
                </True>
            </Condition>
            <Condition when={`${prefix}.guardian.maritalStatus`} is="Divorced">
                <True>
                    <OutlinedDatePicker
                        name={`${prefix}.guardian.dateOfDivorce`}
                        label="Date of Divorce"
                        disableFuture
                        validate={requiredValidator}
                        disabled={!editable}
                        labelprefixed="true"
                    />
                </True>
            </Condition>
            <FixedWidthRadioGroup
                name={`${prefix}.guardian.isRSAResident`}
                label="Are you a South African resident?"
                validate={requiredValidator}
                disabled={!editable}
                labelprefixed="true"
            >
                <FixedWidthRadioOption value="Yes" disabled={!editable} />
                <FixedWidthRadioOption value="No" disabled={!editable} />
            </FixedWidthRadioGroup>
            <WhenFieldChanges
                field={`${prefix}.guardian.isRSAResident`}
                set={`${prefix}.guardian.countryOfResidence`}
                to={null}
            />
            <Condition when={`${prefix}.guardian.isRSAResident`} is="No">
                <True>
                    <OutlinedSearchSelectField
                        name={`${prefix}.guardian.countryOfResidence`}
                        options={Countries}
                        disabled={!editable}
                        label="Country of Residence"
                        validate={requiredValidator}
                        labelprefixed="true"
                    />
                </True>
            </Condition>
            <FixedWidthRadioGroup
                name={`${prefix}.guardian.officiallyEmigrated`}
                label="Have you officially emigrated?"
                validate={requiredValidator}
                labelprefixed="true"
            >
                <FixedWidthRadioOption value="Yes" disabled={!editable} />
                <FixedWidthRadioOption value="No" disabled={!editable} />
            </FixedWidthRadioGroup>
            <WhenFieldChanges
                field={`${prefix}.guardian.officiallyEmigrated`}
                set={`${prefix}.guardian.emigrationExchangeForm`}
                to={null}
            />
            <OutlinedSearchSelectField
                name={`${prefix}.guardian.relationshipToDeceased`}
                options={NaturalRelationships}
                disabled={!editable}
                label="Relationship to deceased/life assured?"
                labelprefixed="true"
                validate={requiredValidator}
            />
            <Condition when={`${prefix}.guardian.relationshipToDeceased`} is="Other">
                <True>
                    <OutlinedTextField
                        name={`${prefix}.guardian.relationshipToDeceasedOther`}
                        label="Please specify the nature of the relationship"
                        validate={requiredValidator}
                        disabled={!editable}
                        labelprefixed="true"
                    />
                </True>
            </Condition>

            <OutlinedSearchSelectField
                name={`${prefix}.guardian.relationshipToPolicyOwner`}
                options={NaturalRelationships}
                disabled={!editable}
                label="Relationship to policy owner"
                labelprefixed="true"
                validate={requiredValidator}
            />
            <Condition when={`${prefix}.guardian.relationshipToPolicyOwner`} is="Other">
                <True>
                    <OutlinedTextField
                        name={`${prefix}.guardian.relationshipToPolicyOwnerOther`}
                        label="Please specify the nature of the relationship"
                        validate={requiredValidator}
                        disabled={!editable}
                        labelprefixed="true"
                    />
                </True>
            </Condition>
            <FixedWidthFileUpload
                name={`${prefix}.guardian.proofOfGuardianship`}
                label="Proof of Guardianship/Letter from Social Worker"
                validate={requiredValidator}
                editable={editable}
                disabled={!editable}
            />

            <Condition when={`${prefix}.guardian.officiallyEmigrated`} is="Yes">
                <True>
                    <FixedWidthFileUpload
                        name={`${prefix}.guardian.emigrationExchangeForm`}
                        label="Upload Exchange Form"
                        validate={requiredValidator}
                        editable={editable}
                        disabled={!editable}
                    />
                </True>
            </Condition>
        </React.Fragment>
    );
};

export const GuardianDetails = withStyles(styles)(guardianDetails);
