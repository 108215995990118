import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { getClaimant, getLabel } from '../../../../shared/helper-functions';
import { allRelationships } from '../../../../shared/lookups';
import styles from '../../../../styles/styles';
import { ContactDetailsPreview } from '../../common/contact-details-preview';
import { AddressDetailsSummary } from '../common/address-details';
import { IdentitySummary } from '../../../common/identity-details-summary';
import { LabelValue } from '../../../common/label-value';

const curatorDetails = ({ classes, claimant, claimants }) => {
    if (!claimant.curator || claimant.individual.relationshipToLifeAssured === 'MinorChild') {
        return null;
    }

    const curator = claimant.curator;
    return (
        <React.Fragment>
            <Grid item xs={12} style={{ marginTop: '24px' }}>
                <Typography variant="h6">Curator Details</Typography>
                <hr />
            </Grid>
            <Grid container justify="flex-start">
                <LabelValue
                    label="Are you currently under curatorship?"
                    value={claimant.underCuratorship}
                />
                {curator.curatorSameAs === 'Other' ? (
                    <Grid container justify="flex-start">
                        <LabelValue label="Name" value={curator.firstName} />
                        <LabelValue label="Surname" value={curator.lastName} />
                        <LabelValue label="Initials" value={curator.initials} />
                        <LabelValue
                            label="Relationship to Life Assured"
                            value={getLabel(curator.relationshipToLifeAssured, allRelationships())}
                        />
                        <LabelValue
                            label="Relationship to Dependant"
                            value={getLabel(curator.relationshipToDependant, allRelationships())}
                        />
                        <IdentitySummary identityDetails={curator} />
                        <LabelValue label="Gender" value={curator.gender} />
                        <LabelValue label="Marital Status" value={curator.maritalStatus} />
                        <LabelValue
                            label="Letter of Curatorship"
                            value={curator.letterOfCuratorship}
                            isFile={true}
                        />
                        <Grid item xs={12}>
                            <Typography className={classes.whiteTitle}>Tax Infomation</Typography>
                        </Grid>
                        <LabelValue label="Tax Status" value={curator.taxStatus} />
                        <LabelValue
                            label="Tax Reference Number"
                            value={curator.taxReferenceNumber}
                        />
                        <LabelValue
                            label="Country of Tax Residence"
                            value={curator.countryOfTaxResidence}
                        />

                        <AddressView
                            classes={classes}
                            address={curator.residentialAddress}
                            title="Residential Address"
                        />
                        <AddressView
                            classes={classes}
                            address={curator.postalAddress}
                            title="Postal Address"
                        />
                        <Grid container justify="flex-start">
                            <Typography className={classes.whiteTitle}>Contact Details</Typography>
                            <ContactDetailsPreview contactDetails={curator.contactDetails} />
                        </Grid>
                    </Grid>
                ) : (
                    <LabelValue
                        label="Same as"
                        value={getClaimant(claimants, curator.curatorSameAs)}
                    />
                )}
            </Grid>
        </React.Fragment>
    );
};

const AddressView = ({ classes, address, title }) => {
    if (!address) {
        return null;
    }
    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Typography className={classes.whiteTitle}>{title}</Typography>
            </Grid>
            <AddressDetailsSummary addressDetails={address} />
        </React.Fragment>
    );
};

export const CuratorDetailsSummary = withStyles(styles)(curatorDetails);
