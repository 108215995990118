import React from 'react';
import { withStyles, Grid, Typography } from '@material-ui/core';
import styles from '../../../styles/styles';
import { LabelValue } from '../../common/label-value';

const financialDetailsSummary = function ({ classes, claimant }) {
    return (
        <Grid container justify="flex-start">
            <Grid item xs={12} style={{ marginTop: '24px' }}>
                <Typography variant="h6">Financial Details</Typography>
                <hr />
            </Grid>
            {claimant.taxReferenceNumber && (
                <React.Fragment>
                    <Grid item xs={12}>
                        <Typography className={classes.whiteTitle}>Tax Details</Typography>
                    </Grid>
                    <LabelValue label="Tax Reference Number" value={claimant.taxReferenceNumber} />
                </React.Fragment>
            )}
            <Grid item xs={12}>
                <Typography className={classes.whiteTitle}>Bank Details</Typography>
            </Grid>
            <LabelValue label="Account Holder" value={claimant.accountHolder} />
            <LabelValue label="Bank Name" value={claimant.bankName} />
            <LabelValue label="Account Number" value={claimant.accountNumber} />
            <LabelValue label="Bank Branch" value={claimant.branchName} />
            <LabelValue label="Bank Code" value={claimant.branchCode} />
            <LabelValue label="Special Instructions" value={claimant.specialInstructions} />
            <LabelValue label="Account Type" value={claimant.accountType} />
            <LabelValue
                label="Account Holder Relationship"
                value={claimant.accountHolderRelationship}
            />
            <LabelValue
                label="Account Holder Relationship"
                value={claimant.accountHolderRelationshipOther}
            />
            <LabelValue
                label="Proof of Bank Account"
                value={claimant.proofOfBankAccount}
                isFile={true}
            />
            <Grid item xs={12}>
                <Typography className={classes.whiteTitle}>Financial Adviser</Typography>
            </Grid>
            <LabelValue label="Do you have an FA?" value={claimant.doYouHaveAnFa} />
            {claimant.financialAdviser && (
                <React.Fragment>
                    <Grid sx={12} sm={12}>
                        <LabelValue label="First Name" value={claimant.financialAdviser.firstName} />
                        <LabelValue label="Surname" value={claimant.financialAdviser.lastName} />
                        <LabelValue label="Initials" value={claimant.financialAdviser.initials} />
                        <LabelValue label="Work Phone" value={claimant.financialAdviser.workPhone} />
                        <LabelValue label="E-mail Address" value={claimant.financialAdviser.email} />
                        <LabelValue
                            label="Cellphone Number"
                            value={claimant.financialAdviser.cellPhone}
                        />
                    </Grid>

                </React.Fragment>
            )}
            <Grid sx={12} sm={12}>
                <LabelValue
                    label="Would you like an FA to contact you?"
                    value={claimant.wouldYouAnFaToContactYou}
                />
            </Grid>
        </Grid>
    );
};

export const FinancialDetailsSummary = withStyles(styles)(financialDetailsSummary);
