import * as React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

function authRequired({ WrappedComponent, isLoggedIn, checkedIfLoggedIn, goToLogin }) {
    if (isLoggedIn) {
        return <WrappedComponent />;
    }
    if (checkedIfLoggedIn) {
        goToLogin();
    }
    return null;
}

function mapStateToProps({ authentication }) {
    return {
        checkedIfLoggedIn: authentication.checkedIfLoggedIn,
        isLoggedIn: !!authentication.account,
    };
}

// use the path structure registered in the staticRoutes, not the full URL
// also, include query strings for special cases
const pathName = window.location.pathname;
const queries = window.location.search;
const pathWithQueryStrings = pathName + queries;
let returnUrl = encodeURIComponent(pathWithQueryStrings.substr(1));

if (returnUrl.indexOf('returnUrl=') === -1) {
    returnUrl = `/?returnUrl=${returnUrl}`;
}

const mapDispatchToProps = {
    goToLogin: () => push(returnUrl),
};

const AuthRequired = connect(mapStateToProps, mapDispatchToProps)(authRequired);

export default function required(WrappedComponent, renderLoading) {
    return function () {
        return <AuthRequired WrappedComponent={WrappedComponent} />;
    };
}
