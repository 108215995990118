import * as React from 'react';

import { Field } from 'react-final-form';
import { FormControl } from '../form-control';
import { PhoneField } from '../phone-field/phone-field';

export const OutlinedPhoneField = ({ name, ...rest }) => {
    return (
        <FormControl>
            <Field 
                {...rest} 
                name={name} 
                // @ts-ignore
                component={PhoneField} 
            />
        </FormControl>
    );
};
