import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    Typography,
    withStyles,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import React from 'react';
import { connect } from 'react-redux';
import styles from '../../../../styles/styles';
import { AddressDetails } from '../common/address-details';
import { PersonalDetails } from '../common/personal-details';
import { OccupationDetails} from './occupation-details';
import { DetailsOfDeathSummary } from './death-details';
import { FuneralParlourSummary } from './funeral-parlour';
import {DeclarationSummary } from './declaration';
import { LabelValue } from '../../../common/label-value';

const deceasedDetailSummary = function ({ classes, deceasedDetails }) {
    if (!deceasedDetails) {
        return null;
    }
    return (
        <Accordion
            style={{
                margin: '-24px -16px 24px -16px',
                borderRadius: '4px',
            }}
        >
            <AccordionSummary
                className={[classes.whiteBackground, classes.whiteTitle].join(' ')}
                expandIcon={<ExpandMore />}
                style={{ borderRadius: '4px' }}
            >
                <Typography className={classes.previewIndex}>1</Typography>
                <Typography className={classes.contentHeader}>Deceased Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid xs={12} sm={12}>
                    {deceasedDetails.policyNumbers &&
                            deceasedDetails.policyNumbers.map((policy, policyIndex) => {
                                return (
                                    <React.Fragment key={policyIndex}>
                                        <Grid item xs={12} sm={12}>
                                            <LabelValue label={`Policy Number ${policyIndex + 1}`} value={policy} />
                                        </Grid>
                                    </React.Fragment>
                                );
                            })}
                    <Grid xs={12} sm={12}>
                        <PersonalDetails personalDetails={deceasedDetails.personalDetails} />
                        <AddressDetails addressDetails={deceasedDetails.addressDetails} />
                        <OccupationDetails occupationDetails={deceasedDetails.occupationDetails} />
                        <DetailsOfDeathSummary detailsOfDeath={deceasedDetails.detailsOfDeath} />
                        <DeclarationSummary declaration={deceasedDetails.declaration} />
                        <FuneralParlourSummary funeralParlour={deceasedDetails.funeralParlour}/>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

const mapStateToProps = state => {
    const claimSelection = (state && state.claimSelection) || {};
    return {
        deceasedDetails: claimSelection.claim && claimSelection.claim.deceasedDetails,
    };
};

export const DeceasedDeatailSummary = connect(mapStateToProps, {})(withStyles(styles)(deceasedDetailSummary));
