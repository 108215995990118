import { Grid, Tooltip, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import styles from '../../styles/styles';

function summaryValue({ classes, value }) {
    if (!value) {
        return null;
    }

    return (
        <React.Fragment>
            <Grid container justify="flex-start">
                <Grid container item xs={9}>
                    <React.Fragment>
                        <Grid item xs={8}>
                            <Typography className={classes.fileViewerName}>
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography className={classes.customLabel} style={{ wordWrap: 'break-word' }}>{value}</Typography>
                        </Grid>
                    </React.Fragment>
                </Grid>
                <Grid item xs={3}>
                    <Tooltip title="" style={{ cursor: 'pointer' }}>
                        <Grid
                            container
                            justify="flex-start"
                            id="summary-view-file"
                        >
                        </Grid>
                    </Tooltip>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export const SummaryValue = withStyles(styles)(summaryValue);
