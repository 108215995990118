import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from '../../../styles/styles';
import {
    Condition,
    False,
    FixedWidthButtonGroup,
    FixedWidthRadioGroup,
    FixedWidthRadioOption,
    OutlinedSearchSelectField,
    OutlinedTextField,
    True,
    WhenFieldChanges,
} from '../../../forms';
import { FixedWidthFileUpload } from '../../../forms/form-components/fixed-width-file-upload';
import { requiredValidator } from '../../../forms/validations';
import { Countries } from '../../../shared/lookups';
import { ClaimantNavigation } from '../../common/navigation/claimant-navigation';

const taxInformation = function ({
    classes,
    form,
    index,
    handleBackStepper,
    handleNext,
    editable,
    stepsLength,
    claimantIndex,
}) {
    const prefix = `claimants[${claimantIndex}].individual.`;

    return (
        <Grid container spacing={10} justify="flex-start">
            <Grid item xs={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
                <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                <Typography className={classes.stepHeader}>Tax Information</Typography>
            </Grid>
            <Grid item xs={12} lg={12}>
                <WhenFieldChanges
                    field={`${prefix}isEmployed`}
                    becomes="No"
                    set={`${prefix}occupation`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${prefix}isEmployed`}
                    becomes="No"
                    set={`${prefix}company`}
                    to={null}
                />
                <Condition when={`${prefix}relationshipToLifeAssured`} is="MinorChild">
                    <False>
                        <FixedWidthButtonGroup
                            label="Are you currently employed?"
                            name={`${prefix}isEmployed`}
                            options={['Yes', 'No']}
                            disabled={!editable}
                        />

                        <Condition when={`${prefix}isEmployed`} is="Yes">
                            <True>
                                <OutlinedTextField
                                    name={`${prefix}occupation`}
                                    disabled={!editable}
                                    validate={requiredValidator}
                                    labelprefixed="true"
                                />
                                <OutlinedTextField
                                    name={`${prefix}company`}
                                    disabled={!editable}
                                    validate={requiredValidator}
                                    labelprefixed="true"
                                />
                            </True>
                        </Condition>
                    </False>
                </Condition>
                <WhenFieldChanges
                    field={`${prefix}isRSAResident`}
                    becomes="Yes"
                    set={`${prefix}countryOfTaxResidence`}
                    to="South Africa"
                />
                <WhenFieldChanges
                    field={`${prefix}isRSAResident`}
                    set={`${prefix}countryOfResidence`}
                    to={null}
                />
                <FixedWidthRadioGroup
                    name={`${prefix}isRSAResident`}
                    label="Are you a South African resident?"
                    className={classes.radioGroupStyle}
                    disabled={!editable}
                >
                    <FixedWidthRadioOption value="Yes" />
                    <FixedWidthRadioOption value="No" />
                </FixedWidthRadioGroup>
                <Condition when={`${prefix}isRSAResident`} is="No">
                    <True>
                        <OutlinedSearchSelectField
                            name={`${prefix}countryOfResidence`}
                            label="Country of Residence"
                            options={Countries}
                            disabled={!editable}
                        />
                    </True>
                </Condition>

                <FixedWidthRadioGroup
                    name={`${prefix}officiallyEmigrated`}
                    label="Have you officially emigrated?"
                    disabled={!editable}
                >
                    <FixedWidthRadioOption value="Yes" />
                    <FixedWidthRadioOption value="No" />
                </FixedWidthRadioGroup>
                <WhenFieldChanges
                    field={`${prefix}officiallyEmigrated`}
                    becomes="No"
                    set={`${prefix}exchangeForm`}
                    to={null}
                />
                <Condition when={`${prefix}officiallyEmigrated`} is="Yes">
                    <True>
                        <FixedWidthFileUpload
                            name={`${prefix}exchangeForm`}
                            label="Upload Exchange Form"
                            validate={requiredValidator}
                            labelprefixed="true"
                            readonly={!editable}
                        />
                    </True>
                </Condition>
                <OutlinedSearchSelectField
                    name={`${prefix}countryOfTaxResidence`}
                    options={Countries}
                    label="Country of Tax Residence"
                    disabled={!editable}
                />
                <OutlinedTextField name={`${prefix}taxReferenceNumber`} disabled={!editable} />
            </Grid>
            <ClaimantNavigation
                form={form}
                handleBackStepper={handleBackStepper}
                handleNext={handleNext}
                prefix={`claimant-${claimantIndex}-tax-info`}
            />
        </Grid>
    );
};

export const TaxInformation = withStyles(styles)(taxInformation);
