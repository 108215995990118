import uuid from 'uuid/v1';
import { CLOSE_MODAL, OPEN_MODAL, UPDATE_CURRENT_MODAL_DATA } from './types';

export const initialModalState = {};

export const formModalReducer = (state = initialModalState, action) => {
    switch (action.type) {
        case OPEN_MODAL: {
            const openModalAction = action;

            return {
                activeModal: {
                    ...openModalAction.payload,
                    modalId: uuid(),
                    parentModal: state.activeModal,
                },
            };
        }
        case UPDATE_CURRENT_MODAL_DATA: {
            if (!!state.activeModal) {
                const updateModalDataAction = action;
                return {
                    ...state,
                    activeModal: {
                        ...state.activeModal,
                        modalData: {
                            ...state.activeModal.modalData,
                            ...updateModalDataAction.payload.modalData,
                        },
                    },
                };
            }
            return state;
        }
        case CLOSE_MODAL: {
            if (!!state.activeModal) {
                return {
                    ...state,
                    activeModal: state.activeModal.parentModal,
                };
            }
            return state;
        }
        default:
            return state;
    }
};
