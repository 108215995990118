import { Grid, Typography, withStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { Condition, OutlinedDatePicker, OutlinedSearchSelectField, OutlinedTextField, True, WhenFieldChanges } from '../../../../forms';
import { FirstNameMask, LastNameMask, NumberMask } from '../../../../forms/input-masks';
import { composeValidators, requiredValidator } from '../../../../forms/validations';
import styles from '../../../../styles/styles';
import { BankAccountDetailsView } from '../payment-details/bank-account-details';
import { validateRSAIdNumber } from '../../../common/identity-details';
import { Countries, PaymentBeneficiary, PolicyHolderTypes, Relationships } from '../../../../shared/lookups';
import { NavigationButton } from '../../../common/navigation/navigation-button';
import classNames from 'classnames';
import { ClaimantNavigation } from '../../../common/navigation/claimant-navigation';
import { BankingDetailsView } from './banking-details';

const PaymentDetailsView = ({
    classes,
    form,
    index,
    handleNext,
    handleBackStepper,
    editable,
    stepsLength,
    handleBack }) => {

    const [showHelperText, setShowHelperText] = useState(false);
    const [setHelperText, setDisplayHelperText] = useState('');
    const payerType = 'Premium Payer';

    const handlePaymentBeneficiaryChange = (value) => {
        if (value === 'Cessionary') {
            setShowHelperText(true);
            setDisplayHelperText('Further instructions must be obtained from cessionary');
        } else if (value === 'Premium Payer') {
            setShowHelperText(true);
            setDisplayHelperText('If not the same as Policyholder, permission should be obtained from Policyholder');
        } else {
            setShowHelperText(false);
        }

    };

    const prefix = 'Payment.Information.';
    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
                <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                <Typography className={classes.stepHeader}>Payee Details</Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography className={classes.claimantSubTitle}>
                        Payment may only be made to the Policyholder.
                        Payment can be made to the bank account which is
                        currently paying the premuims subject to the approval
                        of the policyholder.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <OutlinedSearchSelectField
                    name={`${prefix}paymentBeneficiary`}
                    id={`${prefix}paymentBeneficiary`}
                    options={PaymentBeneficiary}
                    label="Payment must be made into the account of"
                    validate={requiredValidator}
                    disabled={!editable}
                    labelprefixed="true"
                    onChange={(value) => handlePaymentBeneficiaryChange(value)}
                    helperText={showHelperText && (
                        <>
                            <span style={{ color: '#002B99' }}>&#9432;</span>
                            {setHelperText}
                        </>
                    )}
                />
                <WhenFieldChanges
                    field={`${prefix}paymentBeneficiary`}
                    set={`${prefix}accountNumber`}
                    to={''} />
                <WhenFieldChanges
                    field={`${prefix}paymentBeneficiary`}
                    set={`${prefix}bankName`}
                    to={''} />
                <WhenFieldChanges
                    field={`${prefix}paymentBeneficiary`}
                    set={`${prefix}branchCode`}
                    to={''} />
                <WhenFieldChanges
                    field={`${prefix}paymentBeneficiary`}
                    set={`${prefix}accountType`}
                    to={null} />
                <Condition when={`${prefix}paymentBeneficiary`} is="Policyholder">
                    <True>
                        <BankAccountDetailsView {...{ prefix, editable, }} />
                    </True>
                </Condition>
                <Condition when={`${prefix}paymentBeneficiary`} is="Premium Payer">
                    <True>

                        <OutlinedSearchSelectField
                            name={`${prefix}policyHolderType`}
                            id={`${prefix}policyHolderType`}
                            options={PolicyHolderTypes}
                            label="Account holder Type"
                            validate={requiredValidator}
                            disabled={!editable}
                            labelprefixed="true"
                        />
                        <WhenFieldChanges
                            field={`${prefix}policyHolderType`}
                            set={`${prefix}accountNumber`}
                            to={''} />
                        <WhenFieldChanges
                            field={`${prefix}policyHolderType`}
                            set={`${prefix}bankName`}
                            to={''} />
                        <WhenFieldChanges
                            field={`${prefix}policyHolderType`}
                            set={`${prefix}branchCode`}
                            to={''} />
                        <WhenFieldChanges
                            field={`${prefix}policyHolderType`}
                            set={`${prefix}accountType`}
                            to={''} />
                        <WhenFieldChanges
                            field={`${prefix}policyHolderType`}
                            set={`${prefix}branchName`}
                            to={''} />

                        <BankingDetailsView {...{prefix, editable, payerType}} />

                        <Condition when={`${prefix}policyHolderType`} is="Individual">
                            <True>
                                <OutlinedSearchSelectField
                                    name={`${prefix}isRSAResident`}
                                    options={[
                                        {
                                            value: 'Yes',
                                            label: 'Yes',
                                        },
                                        {
                                            value: 'No',
                                            label: 'No',
                                        },
                                    ]}
                                    disabled={!editable}
                                    label="Is the account holder a South African resident?"
                                    validate={requiredValidator}
                                    labelprefixed="true" />
                                <WhenFieldChanges
                                    field={`${prefix}isRSAResident`}
                                    set={`${prefix}accountHolderFirstName`}
                                    to={''} />
                                <WhenFieldChanges
                                    field={`${prefix}isRSAResident`}
                                    set={`${prefix}surname`}
                                    to={''} />
                                <WhenFieldChanges
                                    field={`${prefix}isRSAResident`}
                                    set={`${prefix}idNumber`}
                                    to={''} />
                                <WhenFieldChanges
                                    field={`${prefix}isRSAResident`}
                                    set={`${prefix}passportNumber`}
                                    to={''} />
                                <WhenFieldChanges
                                    field={`${prefix}isRSAResident`}
                                    set={`${prefix}countryOfIssue`}
                                    to={''} />
                                <WhenFieldChanges
                                    field={`${prefix}isRSAResident`}
                                    set={`${prefix}dateOfIssue`}
                                    to={''} />
                                <WhenFieldChanges
                                    field={`${prefix}isRSAResident`}
                                    set={`${prefix}dateOfExpiry`}
                                    to={''} />
                                <WhenFieldChanges
                                    field={`${prefix}isRSAResident`}
                                    set={`${prefix}countryOfResidence`}
                                    to={''} />
                                <WhenFieldChanges
                                    field={`${prefix}isRSAResident`}
                                    set={`${prefix}relationshipToPolicyHolder`}
                                    to={''} />

                                <Condition when={`${prefix}isRSAResident`} is="Yes">
                                    <True>
                                        <OutlinedTextField
                                            name={`${prefix}accountHolderFirstName`}
                                            label="Full first names of account holder"
                                            mask={FirstNameMask}
                                            validate={requiredValidator}
                                            labelprefixed="true"
                                            disabled={!editable} />
                                        <OutlinedTextField
                                            name={`${prefix}surname`}
                                            label="Surname"
                                            validate={requiredValidator}
                                            labelprefixed="true"
                                            mask={LastNameMask}
                                            disabled={!editable} />
                                        <OutlinedTextField
                                            name={`${prefix}idNumber`}
                                            label="ID Number"
                                            validate={composeValidators(requiredValidator, validateRSAIdNumber)}
                                            mask={NumberMask}
                                            disabled={!editable}
                                            labelprefixed="true" />
                                        <OutlinedSearchSelectField
                                            name={`${prefix}relationshipToPolicyHolder`}
                                            options={Relationships}
                                            label="Relationship to Policyholder"
                                            validate={requiredValidator}
                                            labelprefixed="true" />
                                    </True>
                                </Condition>
                                <Condition when={`${prefix}isRSAResident`} is="No">
                                    <True>
                                        <OutlinedTextField
                                            name={`${prefix}accountHolderFirstName`}
                                            label="Full first name of account holder"
                                            mask={FirstNameMask}
                                            validate={requiredValidator}
                                            labelprefixed="true"
                                            disabled={!editable} />
                                        <OutlinedTextField
                                            name={`${prefix}surname`}
                                            label="Surname"
                                            validate={requiredValidator}
                                            labelprefixed="true"
                                            mask={LastNameMask}
                                            disabled={!editable} />
                                        <OutlinedTextField
                                            name={`${prefix}passportNumber`}
                                            label="Passport Number"
                                            validate={requiredValidator}
                                            disabled={!editable}
                                            labelprefixed="true" />
                                        <OutlinedSearchSelectField
                                            name={`${prefix}countryOfIssue`}
                                            options={Countries}
                                            disabled={!editable}
                                            label="Country of Issue"
                                            validate={requiredValidator}
                                            labelprefixed="true" />
                                        <OutlinedDatePicker
                                            name={`${prefix}dateOfIssue`}
                                            validate={requiredValidator}
                                            labelprefixed="true"
                                            disabled={!editable}
                                            disableFuture />
                                        <OutlinedDatePicker
                                            name={`${prefix}dateOfExpiry`}
                                            validate={requiredValidator}
                                            labelprefixed="true"
                                            disabled={!editable} />
                                        <OutlinedSearchSelectField
                                            name={`${prefix}countryOfResidence`}
                                            options={Countries}
                                            disabled={!editable}
                                            label="Country of Residence"
                                            validate={requiredValidator}
                                            labelprefixed="true" />
                                        <OutlinedSearchSelectField
                                            name={`${prefix}relationshipToPolicyHolder`}
                                            options={Relationships}
                                            label="Relationship to Policyholder"
                                            validate={requiredValidator}
                                            labelprefixed="true" />
                                    </True>
                                </Condition>
                            </True>
                        </Condition>
                        <Condition when={`${prefix}policyHolderType`} is="Company">
                            <True>
                                <OutlinedTextField
                                    name={`${prefix}companyName`}
                                    label="Company Name"
                                    validate={requiredValidator}
                                    disabled={!editable}
                                    labelprefixed="true" />
                                <OutlinedTextField
                                    name={`${prefix}companyRegistrationNumber`}
                                    options={Countries}
                                    disabled={!editable}
                                    label="Company Registration Number"
                                    validate={requiredValidator}
                                    labelprefixed="true" />
                                <OutlinedDatePicker
                                    name={`${prefix}companyRegistrationDate`}
                                    validate={requiredValidator}
                                    label="Company Registration Date"
                                    labelprefixed="true"
                                    disabled={!editable}
                                    disableFuture />
                                <OutlinedSearchSelectField
                                    name={`${prefix}countryOfIncorporation`}
                                    options={Countries}
                                    disabled={!editable}
                                    label="Country of Incorporation"
                                    validate={requiredValidator}
                                    labelprefixed="true"
                                />
                            </True>
                        </Condition>
                    </True>
                </Condition>
                <Condition when={`${prefix}paymentBeneficiary`} is="Cessionary">
                    <True>
                    </True>
                </Condition>

            </Grid>
            <ClaimantNavigation
                form={form}
                handleBackStepper={handleBackStepper}
                handleNext={handleNext}
                prefix={prefix}
                index={index}
            />
            <Grid item xs={12} className={classes.navigationGroup}>
                <NavigationButton
                    className={classNames(classes.buttonSecondary, classes.fullWidthButton)}
                    onClick={() => handleBack(form)}
                    id={`${prefix}-previous-button`}
                    label="Previous Section"
                />
            </Grid>
        </React.Fragment>
    );
};

export const PaymentDetails = withStyles(styles)(PaymentDetailsView);
