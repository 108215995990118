import { withStyles, Typography, Grid } from '@material-ui/core';
import styles from '../../../styles/styles';
import React from 'react';
import { DeceasedNavigation } from '../../common/navigation/deceased-navigation';
import { requiredValidator } from '../../../forms/validations';
import {
    Condition,
    False,
    FixedWidthButtonGroup,
    OutlinedPhoneField,
    OutlinedSearchSelectField,
    OutlinedTextField,
    True,
    WhenFieldChanges,
} from '../../../forms';
import { Certifiers } from '../../../shared/lookups';
import { FixedWidthFileUpload } from '../../../forms/form-components/fixed-width-file-upload';
import {
    FirstNameMask,
    InitialsMask,
    LastNameMask,
    TelephoneNumberMask,
} from '../../../forms/input-masks';
import { FuneralAddressDetails } from '../common/funeral-address-details';
import { getSouthAfricanAddress } from '../../../shared/helper-functions';

const declarationForFuneral = function ({
    classes,
    handleBack,
    handleBackStepper,
    handleNext,
    form,
    index,
    stepsLength,
    editable,
}) {
    const prefix = 'deceasedDetails.declaration.';
    return (
        <Grid container justify="flex-start">
            <Grid item xs={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
                <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                <Typography className={classes.stepHeader}>
                    Declaration For Funeral Claim
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <OutlinedSearchSelectField
                    name={`${prefix}certifiedBy`}
                    labelprefixed="true"
                    label="Who certified the death?"
                    options={Certifiers}
                    validate={requiredValidator}
                    disabled={!editable}
                />
                <WhenFieldChanges
                    field={`${prefix}certifiedBy`}
                    set={`${prefix}hospitalName`}
                    to={setHospitalDetails}
                />
                <WhenFieldChanges
                    field={`${prefix}certifiedBy`}
                    set={`${prefix}hospitalContactNumber`}
                    to={setHospitalDetails}
                />
                <WhenFieldChanges
                    field={`${prefix}certifiedBy`}
                    set={`${prefix}hasHospitalDetails`}
                    to={setHospitalDetails}
                />
                <WhenFieldChanges
                    field={`${prefix}certifiedBy`}
                    set={`${prefix}addressDetails`}
                    to={setAddress}
                />
            </Grid>
            <Condition when={`${prefix}certifiedBy`} is="Doctor">
                <True>
                    <Grid item xs={12}>
                        <FixedWidthFileUpload
                            name={`${prefix}noticeOfDeath`}
                            label="BI1663"
                            validate={requiredValidator}
                            labelprefixed="true"
                            readonly={!editable}
                        />
                    </Grid>
                </True>
            </Condition>
            <Condition when={`${prefix}certifiedBy`} is={['TribalChief', 'Induna']}>
                <True>
                    <Grid item xs={12}>
                        <FixedWidthFileUpload
                            name={`${prefix}noticeOfDeath`}
                            label="BI1680"
                            validate={requiredValidator}
                            labelprefixed="true"
                            readonly={!editable}
                        />
                    </Grid>
                </True>
            </Condition>
            <Grid item xs={12}>
                <OutlinedTextField
                    name={`${prefix}initials`}
                    label="Initials"
                    mask={InitialsMask}
                    disabled={!editable}
                />
            </Grid>
            <Grid item xs={12}>
                <OutlinedTextField
                    name={`${prefix}firstName`}
                    validate={requiredValidator}
                    labelprefixed="true"
                    mask={FirstNameMask}
                    disabled={!editable}
                />
            </Grid>
            <Grid item xs={12}>
                <OutlinedTextField
                    name={`${prefix}secondName`}
                    mask={FirstNameMask}
                    disabled={!editable}
                />
            </Grid>
            <Grid item xs={12}>
                <OutlinedTextField
                    name={`${prefix}lastName`}
                    label="Surname"
                    validate={requiredValidator}
                    labelprefixed="true"
                    mask={LastNameMask}
                    disabled={!editable}
                />
            </Grid>
            <Grid item xs={12}>
                <OutlinedPhoneField
                    name={`${prefix}telephoneNumber`}
                    label="Contact Number"
                    validate={requiredValidator}
                    labelprefixed="true"
                    mask={TelephoneNumberMask}
                    disabled={!editable}
                />
            </Grid>
            <Condition when={`${prefix}certifiedBy`} is="Doctor">
                <True>
                    <Grid item xs={12} lg={12}>
                        <FixedWidthButtonGroup
                            name={`${prefix}hasHospitalDetails`}
                            label="Do you have the hospital's details?"
                            options={['Yes', 'No']}
                            disabled={!editable}
                        />
                        <WhenFieldChanges
                            field={`${prefix}hasHospitalDetails`}
                            becomes="No"
                            set={`${prefix}hospitalName`}
                            to={null}
                        />
                        <WhenFieldChanges
                            field={`${prefix}hasHospitalDetails`}
                            becomes="No"
                            set={`${prefix}hospitalContactNumber`}
                            to={null}
                        />
                        <WhenFieldChanges
                            field={`${prefix}hasHospitalDetails`}
                            set={`${prefix}addressDetails`}
                            to={setAddress}
                        />
                    </Grid>

                    <Condition when={`${prefix}hasHospitalDetails`} is="Yes">
                        <True>
                            <Grid item xs={12} style={{ marginTop: '12px', marginBottom: '8px' }}>
                                <Typography className={classes.contentHeader}>
                                    Hospital Details
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <OutlinedTextField
                                    name={`${prefix}hospitalName`}
                                    validate={requiredValidator}
                                    labelprefixed="true"
                                    disabled={!editable}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <OutlinedPhoneField
                                    name={`${prefix}hospitalContactNumber`}
                                    label="Hospital Contact Number"
                                    validate={requiredValidator}
                                    labelprefixed="true"
                                    disabled={!editable}
                                />
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: '12px', marginBottom: '8px' }}>
                                <Typography className={classes.contentHeader}>
                                    Hospital Address Details
                                </Typography>
                                <FuneralAddressDetails
                                    editable={editable}
                                    prefix={`${prefix}addressDetails.`}
                                />
                            </Grid>
                        </True>
                    </Condition>
                </True>
            </Condition>
            <Condition when={`${prefix}certifiedBy`} is="Doctor">
                <False>
                    <Grid item xs={12} style={{ marginTop: '12px', marginBottom: '8px' }}>
                        <Typography className={classes.contentHeader}>Address Details</Typography>
                    </Grid>
                    <FuneralAddressDetails
                        editable={editable}
                        prefix={`${prefix}addressDetails.`}
                    />
                </False>
            </Condition>
            <DeceasedNavigation
                form={form}
                handleBack={handleBack}
                handleBackStepper={handleBackStepper}
                handleNext={handleNext}
                prefix="deceased-funeral-decl"
            />
        </Grid>
    );
};

export function setAddress(value, address) {
    if (value === 'Doctor' || value === 'No') {
        return null;
    }
    return {
        ...address,
        ...getSouthAfricanAddress(),
    };
}

export function setHospitalDetails(value, details) {
    if (value === 'Doctor') {
        return details;
    }
    return null;
}

export const DeclarationForFuneral = withStyles(styles)(declarationForFuneral);
