import * as React from 'react';
import { Grid, Paper, withStyles } from '@material-ui/core';
import styles from '../../../styles/styles';
import { VerticalStepper } from '../../common/vertical-stepper';
import { AddressDetails } from './address-details';
import { DeclarationForFuneral } from './declaration-for-funeral';
import { DetailsOfDeath } from './details-of-death';
import { FuneralParlour } from './funeral-parlour-details';
import { OccupationDetails } from './occupation-details';
import { PersonalDetails } from './personal-details';
import { PolicyDetails } from './policy-details';

const verticalComponents = [
    {
        name: 'Policy Details',
        description: 'Provide all Liberty policy numbers, if available',
        component: PolicyDetails,
    },
    {
        name: 'Personal Details',
        description: 'Personal details of the deceased',
        component: PersonalDetails,
    },
    {
        name: 'Address Details',
        description: 'Provide all address details for the deceased',
        component: AddressDetails,
    },
    {
        name: 'Occupation Details',
        description: `Details of deceased's occupation`,
        component: OccupationDetails,
    },
    {
        name: 'Details Of Death',
        description: 'Details of where & what the cause of death was',
        component: DetailsOfDeath,
    },
    {
        name: 'Declaration For Funeral Claim',
        description: 'Please provide declaration for the funeral claim',
        component: DeclarationForFuneral,
    },
    {
        name: "Funeral Parlour's Details",
        description: "Please provide funeral parlour's details",
        component: FuneralParlour,
    },
];

const deceasedDetails = function ({ classes, form, handleBack, handleSubmit, ...rest }) {
    return (
        <Paper className={[classes.shadedPaper, classes.paperShadowLess].join(' ')}>
            <Grid container justify="flex-start">
                <Grid item xs={12} lg={12}>
                    <VerticalStepper
                        {...rest}
                        verticalComponents={verticalComponents}
                        verticalIndexName={'verticalStepIndex'}
                        form={form}
                        handleBack={handleBack}
                        handleSubmit={handleSubmit}
                    />
                </Grid>
            </Grid>
        </Paper>
    );
};

export const DeceasedDetails = withStyles(styles)(deceasedDetails);
