import * as React from 'react';
import { withStyles } from '@material-ui/core';
import { getDisplayDate, getIdentityType } from '../../shared/helper-functions';
import styles from '../../styles/styles';
import { LabelValue } from './label-value';

const identitySummary = function ({ identityDetails }) {
    return (
        <React.Fragment>
            <LabelValue label="Identity Type" value={getIdentityType(identityDetails.idType)} />
            <LabelValue
                label={identityNumberLabel(identityDetails.idType)}
                value={identityDetails.idNumber}
            />
            {identityDetails.passport && (
                <React.Fragment>
                    <LabelValue label="Country of Issue" value={identityDetails.passport.country} />
                    <LabelValue
                        label="Passport Date of Issue"
                        value={getDisplayDate(identityDetails.passport.issued)}
                    />
                    <LabelValue
                        label="Passport Date of Expiry"
                        value={getDisplayDate(identityDetails.passport.expiry)}
                    />
                </React.Fragment>
            )}
            <LabelValue label="Date of Issue" value={getDisplayDate(identityDetails.issued)} />
            <LabelValue label="Date of Expiry" value={getDisplayDate(identityDetails.expiry)} />
            <LabelValue label="Date of Birth" value={getDisplayDate(identityDetails.dateOfBirth)} />
            <LabelValue
                label="Proof of Identification"
                value={identityDetails.proofOfIdentification}
                isFile
            />
        </React.Fragment>
    );
};

function identityNumberLabel(identityType) {
    switch (identityType) {
        case 'RsaId':
        case 'RsaIdSmartCard':
        case 'RsaIdTemporaryId':
        case 'RsaIdBirthCertificate':
            return 'South African ID Number';
        case 'Passport':
        case 'ForeignPassport':
        case 'RsaPassport':
            return 'Passport Number';
        case 'ForeignIdBook':
            return 'Foreign ID Number';
        default:
            return 'ID Number';
    }
}

export const IdentitySummary = withStyles(styles)(identitySummary);
