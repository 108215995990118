import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from '../../../../styles/styles';
import { getClaimant, getLabel } from '../../../../shared/helper-functions';
import { allRelationships, Titles } from '../../../../shared/lookups';
import { ContactDetailsPreview } from '../../common/contact-details-preview';
import { IdentitySummary } from '../../../common/identity-details-summary';
import { LabelValue } from '../../../common/label-value';
import { AddressDetailsSummary } from '../common/address-details';

const guardianDetails = ({ classes, claimant, claimants }) => {
    if (claimant.individual.relationshipToLifeAssured !== 'MinorChild') {
        return null;
    }
    const guardian = claimant.guardian;
    return (
        <React.Fragment>
            <Grid item xs={12} style={{ marginTop: '24px' }}>
                <Typography variant="h6">Guardian Details</Typography>
                <hr />
            </Grid>
            {claimant.individual.guardianSameAs === 'Other' ? (
                <React.Fragment>
                    <LabelValue label="Title" value={getLabel(guardian.title, Titles)} />
                    <LabelValue label="Name" value={guardian.firstName} />
                    <LabelValue label="Second Name" value={guardian.secondName} />
                    <LabelValue label="Surname" value={guardian.lastName} />
                    <LabelValue label="Initials" value={guardian.initials} />
                    <LabelValue
                        label="Relationship to Life Assured"
                        value={getLabel(guardian.relationshipToLifeAssured, allRelationships())}
                    />
                    <LabelValue
                        label="Description"
                        value={guardian.relationshipToLifeAssuredDescription}
                    />
                    <LabelValue
                        label="Relationship to the minor"
                        value={guardian.relationshipToMinor}
                    />
                    <LabelValue
                        label="Description"
                        value={guardian.relationshipToMinorDescription}
                    />
                    <IdentitySummary identityDetails={guardian} />
                    <LabelValue label="Gender" value={guardian.gender} />
                    <LabelValue label="Is RSA Resident" value={guardian.isRSAResident} />
                    <LabelValue label="Country of Residency" value={guardian.countryOfResidence} />
                    <LabelValue
                        label="Have you officially emigrated?"
                        value={guardian.officiallyEmigrated}
                    />
                    <LabelValue label="Exchange form" value={guardian.exchangeForm} isFile={true} />
                    <LabelValue
                        label="Proof of Guardianship"
                        value={guardian.proofOfGuardianship}
                        isFile={true}
                    />
                    <AddressView
                        classes={classes}
                        address={guardian.residentialAddress}
                        title="Residential Address"
                    />
                    <AddressView
                        classes={classes}
                        address={guardian.postalAddress}
                        title="Postal Address"
                    />
                    <Grid container justify="flex-start">
                        <Typography className={classes.whiteTitle}>Contact Details</Typography>
                        <ContactDetailsPreview contactDetails={guardian.contactDetails} />
                    </Grid>
                </React.Fragment>
            ) : (
                <LabelValue
                    label="Same as"
                    value={getClaimant(claimants, claimant.individual.guardianSameAs)}
                />
            )}
        </React.Fragment>
    );
};

const AddressView = ({ classes, address, title }) => {
    if (!address) {
        return null;
    }
    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Typography className={classes.whiteTitle}>{title}</Typography>
            </Grid>
            <AddressDetailsSummary addressDetails={address} />
        </React.Fragment>
    );
};

export const GuardianDetailsSummary = withStyles(styles)(guardianDetails);
