import React from 'react';
import { withStyles, Grid } from '@material-ui/core';
import { Field } from 'react-final-form';
import { OutlinedPhoneField, OutlinedSearchSelectField, OutlinedTextField } from '../../../forms';
import { emailFormatValidatorRequiredField } from '../../../forms/validations';
import { getCommunicationMethods } from '../../../shared/helper-functions';
import { CommunicationMethods } from '../../../shared/lookups';
import styles from '../../../styles/styles';

const contactDetailsView = function ({ editable, prefix, ...rest }) {
    const { hasCommucationPreference = false, entity = false } = rest;
    return (
        <Grid container spacing={10} justify="flex-start">
            <Grid item xs={12} lg={12}>
                {entity ? (
                    <OutlinedPhoneField name={`${prefix}telephoneNumber`} disabled={!editable} />
                ) : (
                    <React.Fragment>
                        <OutlinedPhoneField name={`${prefix}workNumber`} disabled={!editable} />
                        <OutlinedPhoneField name={`${prefix}homeNumber`} disabled={!editable} />
                    </React.Fragment>
                )}
                <OutlinedPhoneField name={`${prefix}cellphoneNumber`} disabled={!editable} />
                <OutlinedTextField
                    name={`${prefix}emailAddress`}
                    type="email"
                    label="Email Address"
                    labelprefixed="true"
                    validate={emailFormatValidatorRequiredField}
                    disabled={!editable}
                />
                {hasCommucationPreference && (
                    <Field
                        name={`${prefix}`}
                        subscription={{ value: true }}
                        component={({ input: { value: values } }) => {
                            const methods = getCommunicationMethods(values);
                            return (
                                <OutlinedSearchSelectField
                                    name={`${prefix}preferredMethodOfCommunication`}
                                    label="Preferred Method of Communication"
                                    labelprefixed="true"
                                    options={methods.length > 0 ? methods : CommunicationMethods}
                                    disabled={!editable}
                                />
                            );
                        }}
                    />
                )}
            </Grid>
        </Grid>
    );
};

export const ContactDetailsView = withStyles(styles)(contactDetailsView);
