import { connect } from 'react-redux';
import { ExistingClaimSelection as existingClaimSelection } from './existing-claim-selection';
import { showLoading, hideLoading } from '../../../forms/loading-screen/actions';
import { goToCaptureClaim, goToReferencedClaims } from '../claim-selection-actions';

const mapDispatchToProps = {
    showLoading,
    hideLoading,
    goToCaptureClaim,
    goToReferencedClaims,
};

const mapStateToProps = state => {
    const claimSelection = state.claimSelection || {};
    return {
        referencedClaims: claimSelection.referencedClaims,
        isOnlyReferencedClaims: claimSelection.isOnlyReferencedClaims,
    };
};

export const ExistingClaimSelection = connect(
    mapStateToProps,
    mapDispatchToProps
)(existingClaimSelection);
