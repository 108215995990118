import * as React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from '../../../styles/styles';
import {
    Condition,
    FixedWidthRadioGroup,
    FixedWidthRadioOption,
    OutlinedSearchSelectField,
    True,
    WhenFieldChanges,
} from '../../../forms';
import { requiredValidator } from '../../../forms/validations';
import {
    ClaimantTypes,
    Relationships,
    RelationshipsChildren,
    RelationshipsSpouses,
    RelationshipsExSpouses,
    RelationshipsPartner,
    RelationshipsRelatives,
    RelationshipsParents,
    RelationshipsOther,
} from '../../../shared/lookups';
import { getPossibleGuardians } from '../../../shared/helper-functions';
import { NavigationButton } from '../../common/navigation/navigation-button';

const claimantSelection = function ({
    classes,
    form,
    index,
    editable,
    handleNext,
    stepsLength,
    claimantIndex,
}) {
    const prefix = `claimants[${claimantIndex}].`;
    const claimants = form.getState().values.claimants;

    return (
        <Grid container spacing={10} justify="flex-start">
            <Grid item xs={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
                <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                <Typography className={classes.stepHeader}>Claimant Details</Typography>
            </Grid>
            <Grid item xs={12} lg={12}>
                <WhenFieldChanges
                    field={`${prefix}individual.relationshipToLifeAssured`}
                    set={`${prefix}individual.studentScholar`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${prefix}individual.relationshipToLifeAssured`}
                    condition={rel => rel === 'Spouse' || rel === 'EstrangedSpouse'}
                    set={`${prefix}individual.maritalStatus`}
                    to="Married"
                />
                <WhenFieldChanges
                    field={`${prefix}individual.relationshipToLifeAssured`}
                    set={`${prefix}individual.dateOfMarriageToLifeAssured`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${prefix}individual.relationshipToLifeAssured`}
                    set={`${prefix}individual.dateOfDivorceFromLifeAssured`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${prefix}individual.relationshipToLifeAssured`}
                    set={`${prefix}individual.marriageCertificate`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${prefix}individual.relationshipToLifeAssured`}
                    set={`${prefix}individual.dateOfMarriage`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${prefix}individual.relationshipToLifeAssured`}
                    set={`${prefix}individual.yearsEstranged`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${prefix}individual.relationshipToLifeAssured`}
                    becomes="MinorChild"
                    set={`${prefix}individual.maritalStatus`}
                    to="Single"
                />
                <OutlinedSearchSelectField
                    name={`${prefix}claimantType`}
                    id="claimantType"
                    options={ClaimantTypes}
                    label="Claimant type"
                    disabled={!editable}
                />
            </Grid>
            <WhenFieldChanges
                field={`${prefix}individual.relationshipToLifeAssured`}
                becomes="Spouse"
                set={`${prefix}individual.maritalStatus`}
                to="Married"
            />
            <WhenFieldChanges
                field={`${prefix}individual.relationshipToLifeAssured`}
                becomes="MinorChild"
                set={`${prefix}curator`}
                to={null}
            />
            <WhenFieldChanges
                field={`${prefix}individual.relationshipToLifeAssured`}
                becomes="MinorChild"
                set={`${prefix}underCuratorship`}
                to={null}
            />
            <WhenFieldChanges
                field={`${prefix}individual.relationshipToLifeAssured`}
                condition={rel => rel !== 'MinorChild'}
                set={`${prefix}guardian`}
                to={null}
            />
            <Condition when={`${prefix}claimantType`} is="Individual">
                <True>
                    <Grid item xs={12} lg={12}>
                        <OutlinedSearchSelectField
                            name={`${prefix}individual.relationshipToLifeAssured`}
                            options={Relationships}
                            label="Relationship to Life Assured"
                            validate={requiredValidator}
                            labelprefixed="true"
                            disabled={!editable}
                        />
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <Condition when={`${prefix}individual.relationshipToLifeAssured`} hasValue>
                            <True>
                                <Condition
                                    when={`${prefix}individual.relationshipToLifeAssured`}
                                    is="ExSpouse"
                                >
                                    <True>
                                        <OutlinedSearchSelectField
                                            name={`${prefix}individual.relationshipToLifeAssuredDescription`}
                                            label="Description"
                                            options={RelationshipsExSpouses}
                                            validate={requiredValidator}
                                            labelprefixed="true"
                                            disabled={!editable}
                                        />
                                    </True>
                                </Condition>
                                <Condition
                                    when={`${prefix}individual.relationshipToLifeAssured`}
                                    is="EstrangedSpouse"
                                >
                                    <True>
                                        <OutlinedSearchSelectField
                                            name={`${prefix}individual.relationshipToLifeAssuredDescription`}
                                            label="Description"
                                            options={RelationshipsSpouses}
                                            validate={requiredValidator}
                                            labelprefixed="true"
                                            disabled={!editable}
                                        />
                                    </True>
                                </Condition>
                                <Condition
                                    when={`${prefix}individual.relationshipToLifeAssured`}
                                    is="LifePartner"
                                >
                                    <True>
                                        <OutlinedSearchSelectField
                                            name={`${prefix}individual.relationshipToLifeAssuredDescription`}
                                            label="Description"
                                            options={RelationshipsPartner}
                                            validate={requiredValidator}
                                            labelprefixed="true"
                                            disabled={!editable}
                                        />
                                    </True>
                                </Condition>
                                <Condition
                                    when={`${prefix}individual.relationshipToLifeAssured`}
                                    is={['MinorChild', 'MajorChild']}
                                >
                                    <True>
                                        <OutlinedSearchSelectField
                                            name={`${prefix}individual.relationshipToLifeAssuredDescription`}
                                            label="Description"
                                            options={RelationshipsChildren}
                                            validate={requiredValidator}
                                            labelprefixed="true"
                                            disabled={!editable}
                                        />
                                        <Condition
                                            when={`${prefix}individual.relationshipToLifeAssuredDescription`}
                                            hasValue
                                        >
                                            <True>
                                                <OutlinedSearchSelectField
                                                    name={`${prefix}individual.childOf`}
                                                    label="Child of"
                                                    options={getPossibleGuardians(claimants)}
                                                    disabled={!editable}
                                                />
                                            </True>
                                        </Condition>
                                    </True>
                                </Condition>
                                <Condition
                                    when={`${prefix}individual.relationshipToLifeAssured`}
                                    is="Parent"
                                >
                                    <True>
                                        <OutlinedSearchSelectField
                                            name={`${prefix}individual.relationshipToLifeAssuredDescription`}
                                            label="Description"
                                            options={RelationshipsParents}
                                            validate={requiredValidator}
                                            labelprefixed="true"
                                            disabled={!editable}
                                        />
                                    </True>
                                </Condition>
                                <Condition
                                    when={`${prefix}individual.relationshipToLifeAssured`}
                                    is="Relative"
                                >
                                    <True>
                                        <OutlinedSearchSelectField
                                            name={`${prefix}individual.relationshipToLifeAssuredDescription`}
                                            label="Description"
                                            options={RelationshipsRelatives}
                                            validate={requiredValidator}
                                            labelprefixed="true"
                                            disabled={!editable}
                                        />
                                    </True>
                                </Condition>
                                <Condition
                                    when={`${prefix}individual.relationshipToLifeAssured`}
                                    is="Spouse"
                                >
                                    <True>
                                        <OutlinedSearchSelectField
                                            name={`${prefix}individual.relationshipToLifeAssuredDescription`}
                                            label="Description of Relationship to Life Assured"
                                            options={RelationshipsSpouses}
                                            validate={requiredValidator}
                                            labelprefixed="true"
                                            disabled={!editable}
                                        />
                                    </True>
                                </Condition>
                                <Condition
                                    when={`${prefix}individual.relationshipToLifeAssured`}
                                    is="Other"
                                >
                                    <True>
                                        <OutlinedSearchSelectField
                                            name={`${prefix}individual.relationshipToLifeAssuredDescription`}
                                            label="Description of Relationship to Life Assured"
                                            options={RelationshipsOther}
                                            validate={requiredValidator}
                                            labelprefixed="true"
                                            disabled={!editable}
                                        />
                                    </True>
                                </Condition>
                            </True>
                        </Condition>
                    </Grid>
                </True>
            </Condition>

            <Condition when={`${prefix}claimantType`} hasValue>
                <True>
                    <Grid item xs={12} lg={12}>
                        <FixedWidthRadioGroup
                            name={`${prefix}claimCapacity`}
                            labelprefixed="true"
                            label="In what capacity are you claiming?"
                            validate={requiredValidator}
                            disabled={!editable}
                            className={classes.radioStyle}
                        >
                            <FixedWidthRadioOption value="Beneficiary" />
                            <FixedWidthRadioOption value="Dependant" />
                            <FixedWidthRadioOption value="Both" />
                        </FixedWidthRadioGroup>
                    </Grid>
                </True>
            </Condition>
            <Grid item xs={12} className={classes.navigationGroup}>
                <NavigationButton
                    color="primary"
                    className={classes.button}
                    onClick={() => handleNext(form)}
                    id={`claimant-sel-continue-${claimantIndex}`}
                    fullWidth
                    label="Continue"
                />
            </Grid>
        </Grid>
    );
};

export const ClaimantSelection = withStyles(styles)(claimantSelection);
