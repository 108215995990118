import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { Condition, FixedWidthButtonGroup, OutlinedDatePicker, OutlinedSearchSelectField, OutlinedTextField, True } from '../../../../forms';
import { requiredValidator } from '../../../../forms/validations';
import styles from '../../../../styles/styles';
import { MedicalAidSchemes, Titles } from '../../../../shared/lookups';
import classNames from 'classnames';
import { ClaimantNavigation } from '../../../common/navigation/claimant-navigation';
import { NavigationButton } from '../../../common/navigation/navigation-button';

const medicalAidDetails = ({
    classes,
    handleBack,
    index,
    editable,
    handleBackStepper,
    handleNext,
    stepsLength,
    form,
}) => {
    const isEditable = true;

    return (
        <React.Fragment>
            <Grid item xs={12} lg={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
            </Grid>
            <Grid item xs={12} lg={12} container justify="flex-start">
                <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>{' '}
                <Typography className={classes.stepHeader}>Medical Aid Details</Typography>
            </Grid>
            <Grid item xs={12}>
                <FixedWidthButtonGroup
                    label="Does the Life Assured have a medical aid?"
                    name={'lifeAssured.medicalAidDetails.hasMedicalAid'}
                    options={['Yes', 'No']}
                    disabled={!isEditable}
                    validate={requiredValidator}
                    labelprefixed="true"
                />
            </Grid> 
            <Grid item xs={12}>
                <Condition when={'lifeAssured.medicalAidDetails.hasMedicalAid'} is="Yes">
                    <True>
                        <Grid item xs={12} lg={12}>
                            <OutlinedSearchSelectField
                                name={'lifeAssured.medicalAidDetails.lifeAssuredMedicalAidName'}
                                options={MedicalAidSchemes}
                                disabled={!editable}
                                label="Medical Aid Name"
                            />
                            <Condition when={'lifeAssured.medicalAidDetails.lifeAssuredMedicalAidName'} is="Other">
                                <True>
                                    <OutlinedTextField
                                        name='lifeAssured.medicalAidDetails.lifeAssuredMedicalAidDescription'
                                        label="Medical aid name (Other)"
                                        disabled={!editable}
                                    />
                                </True>
                            </Condition>
                            <OutlinedTextField
                                name={'lifeAssured.medicalAidDetails.lifeAssuredMedicalAidNumber'}
                                label="Medical Aid Number"
                                validate={requiredValidator}
                                labelprefixed="true"
                            />
                            <OutlinedDatePicker
                                name={'lifeAssured.medicalAidDetails.lifeAssuredMedicalDateJoined'}
                                label="Date joined"
                                disableFuture
                                validate={requiredValidator}
                                labelprefixed="true" /> 
                            <FixedWidthButtonGroup
                                label="Is the life assured the main member on the medical aid?"
                                name={'lifeAssured.medicalAidDetails.isLifeAssuredMainMember'}
                                options={['Yes', 'No']}
                                validate={requiredValidator}
                                labelprefixed="true"
                            /> 
                        </Grid>    
                    </True>
                </Condition>
                <Condition when={'lifeAssured.medicalAidDetails.isLifeAssuredMainMember'} is="No">
                    <True>
                        <Grid item xs={12} lg={12}>
                            <OutlinedSearchSelectField
                                name={'lifeAssured.medicalAidDetails.lifeAssuredTitle'}
                                options={Titles}
                                validate={requiredValidator}
                                labelprefixed="true" />
                            <OutlinedTextField
                                name={'lifeAssured.medicalAidDetails.lifeAssuredName'}
                                label="Name"
                                validate={requiredValidator}
                                labelprefixed="true"
                            />
                            <OutlinedTextField
                                name={'lifeAssured.medicalAidDetails.lifeAssuredSurname'}
                                label="Surname"
                                validate={requiredValidator}
                                labelprefixed="true"
                            />
                            <OutlinedTextField
                                name={'lifeAssured.medicalAidDetails.lifeAssuredIDNumber'}
                                label="ID Number"
                                validate={requiredValidator}
                                labelprefixed="true"
                            />
                        </Grid>    
                    </True>
                </Condition>
            </Grid>
            <ClaimantNavigation
                form={form}
                handleBackStepper={handleBackStepper}
                handleNext={handleNext}
                prefix={'lifeAssured.medicalAidDetails'}
            />
            <Grid item xs={12} className={classes.navigationGroup}>
                <NavigationButton
                    className={classNames(
                        classes.buttonSecondary,
                        classes.buttonAlignment,
                        classes.fullWidthButton
                    )}
                    onClick={() => handleBack(form)}
                    id="medical-aid-details-back"
                    label="Previous Section"
                />
            </Grid>
        </React.Fragment>     
    );
};

export const MedicalAidDetails = withStyles(styles)(medicalAidDetails);
