import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from '../../../styles/styles';
import {
    FixedWidthButtonGroup,
    FixedWidthRadioGroup,
    FixedWidthRadioOption,
    OutlinedDatePicker,
    OutlinedSearchSelectField,
    OutlinedTextField,
} from '../../../forms';
import { requiredValidator } from '../../../forms/validations';
import { FirstNameMask, InitialsMask, LastNameMask } from '../../../forms/input-masks';
import { IdentityDetails } from '../../common/identity-details';
import { Condition, True, WhenFieldChanges } from '../../../forms';
import { isAdult, isMinor } from '../../../shared/helper-functions';
import { Countries, MaritalStatuses, NaturalRelationships } from '../../../shared/lookups';
import { FixedWidthFileUpload } from '../../../forms/form-components/fixed-width-file-upload';
import { ClaimantNavigation } from '../../common/navigation/claimant-navigation';
import { GuardianDetails } from './guardian-details';

const personalDetails = function ({
    classes,
    form,
    index,
    handleNext,
    handleBackStepper,
    editable,
    stepsLength,
    claimantIndex,
}) {
    const prefix = `claimants[${claimantIndex}]`;

    return (
        <Grid container spacing={10} justify="flex-start">
            <Grid item xs={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
                <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                <Typography className={classes.stepHeader}>Personal Details</Typography>
            </Grid>
            <Grid item xs={12} lg={12}>
                <OutlinedTextField
                    name={`${prefix}.individual.firstName`}
                    label="First Name"
                    mask={FirstNameMask}
                    validate={requiredValidator}
                    labelprefixed="true"
                    disabled={!editable}
                />

                <OutlinedTextField
                    name={`${prefix}.individual.surname`}
                    label="Surname"
                    validate={requiredValidator}
                    labelprefixed="true"
                    mask={LastNameMask}
                    disabled={!editable}
                />
                <OutlinedTextField
                    name={`${prefix}.individual.initials`}
                    label="Initials"
                    validate={requiredValidator}
                    labelprefixed="true"
                    mask={InitialsMask}
                    disabled={!editable}
                />
                <IdentityDetails
                    includeGender
                    includeForeignTypes
                    prefix={`${prefix}.individual.`}
                    editable={editable}
                />
                <WhenFieldChanges
                    field={`${prefix}.individual.dateOfBirth`}
                    is={isMinor}
                    set={`${prefix}.guardian`}
                    to={null}
                />
                <Condition when={`${prefix}.individual.dateOfBirth`} passes={isAdult}>
                    <True>
                        <Condition
                            when={`${prefix}.individual.idType`}
                            is={['RsaIdBirthCertificate', 'ForeignBirthCertificate']}
                        >
                            <True>
                                <FixedWidthFileUpload
                                    name={`${prefix}.individual.birthCertificate`}
                                    label="Upload unabridged birth certificate"
                                    helperText="*Only needed if claimant is a minor"
                                    editable={editable}
                                    validate={requiredValidator}
                                />
                            </True>
                        </Condition>

                        <Condition
                            when={`${prefix}.individual.idType`}
                            is={['RsaId', 'RsaIdSmartCard', 'RsaIdTemporaryId']}
                        >
                            <True>
                                <FixedWidthFileUpload
                                    name={`${prefix}.individual.idDocument`}
                                    label="Upload claimant's ID document"
                                    helperText="*Can also be a copy of the back and front of ID smart card"
                                    validate={requiredValidator}
                                    editable={editable}
                                />
                            </True>
                        </Condition>

                        <Condition
                            when={`${prefix}.individual.idType`}
                            is={['RsaPassport', 'ForeignPassport']}
                        >
                            <True>
                                <FixedWidthFileUpload
                                    name={`${prefix}.individual.passportUpload`}
                                    label="Upload claimant's passport"
                                    validate={requiredValidator}
                                    editable={editable}
                                />
                            </True>
                        </Condition>

                        <Condition when={`${prefix}.individual.idType`} is={['ForeignIdBook']}>
                            <True>
                                <FixedWidthFileUpload
                                    name={`${prefix}.individual.idDocument`}
                                    label="Upload claimant's foreign ID book"
                                    validate={requiredValidator}
                                    editable={editable}
                                />
                            </True>
                        </Condition>

                        <Condition
                            when={`${prefix}.individual.idType`}
                            is={[
                                'ForeignDrivingLicence',
                                'RsaIdDrivingLicence',
                                'AAInternationalDrivingLicence',
                            ]}
                        >
                            <True>
                                <FixedWidthFileUpload
                                    name={`${prefix}.individual.idDocument`}
                                    label="Upload claimant's licence"
                                    validate={requiredValidator}
                                    editable={editable}
                                />
                            </True>
                        </Condition>

                        <Condition when={`${prefix}.individual.idType`} is={['WorkPermit']}>
                            <True>
                                <FixedWidthFileUpload
                                    name={`${prefix}.individual.idDocument`}
                                    label="Upload claimant's work permit"
                                    validate={requiredValidator}
                                    editable={editable}
                                />
                            </True>
                        </Condition>

                        <Condition when={`${prefix}.individual.idType`} is={['Asylum']}>
                            <True>
                                <FixedWidthFileUpload
                                    name={`${prefix}.individual.idDocument`}
                                    label="Upload claimant's asylum document"
                                    validate={requiredValidator}
                                    editable={editable}
                                />
                            </True>
                        </Condition>
                        <OutlinedSearchSelectField
                            name={`${prefix}.individual.maritalStatus`}
                            options={MaritalStatuses}
                            disabled={!editable}
                            label="Marital status"
                        />
                        <WhenFieldChanges
                            field={`${prefix}.individual.maritalStatus`}
                            set={`${prefix}.individual.dateOfMarriage`}
                            to={null}
                        />
                        <WhenFieldChanges
                            field={`${prefix}.individual.maritalStatus`}
                            set={`${prefix}.individual.dateOfDivorce`}
                            to={null}
                        />
                        <Condition when={`${prefix}.individual.maritalStatus`} is="Married">
                            <True>
                                <OutlinedDatePicker
                                    name={`${prefix}.individual.dateOfMarriage`}
                                    label="Date of marriage"
                                    disableFuture
                                    validate={requiredValidator}
                                    disabled={!editable}
                                    labelprefixed="true"
                                />
                            </True>
                        </Condition>
                        <Condition when={`${prefix}.individual.maritalStatus`} is="Divorced">
                            <True>
                                <OutlinedDatePicker
                                    name={`${prefix}.individual.dateOfDivorce`}
                                    label="Date of Divorce"
                                    disableFuture
                                    validate={requiredValidator}
                                    disabled={!editable}
                                    labelprefixed="true"
                                />
                            </True>
                        </Condition>
                        <FixedWidthButtonGroup
                            name={`${prefix}.individual.isRSAResident`}
                            label="Are you a South African resident?"
                            validate={requiredValidator}
                            disabled={!editable}
                            labelprefixed="true"
                            options={['Yes', 'No']}
                        />
                        <WhenFieldChanges
                            field={`${prefix}.individual.isRSAResident`}
                            set={`${prefix}.individual.countryOfResidence`}
                            to={null}
                        />

                        <Condition when={`${prefix}.individual.isRSAResident`} is="No">
                            <True>
                                <OutlinedSearchSelectField
                                    name={`${prefix}.individual.countryOfResidence`}
                                    options={Countries}
                                    disabled={!editable}
                                    label="Country of Residence"
                                    validate={requiredValidator}
                                    labelprefixed="true"
                                />
                            </True>
                        </Condition>

                        <FixedWidthRadioGroup
                            name={`${prefix}.individual.officiallyEmigrated`}
                            label="Have you officially emigrated?"
                            validate={requiredValidator}
                            labelprefixed="true"
                            disabled={!editable}
                        >
                            <FixedWidthRadioOption value="Yes" disabled={!editable} />
                            <FixedWidthRadioOption value="No" disabled={!editable} />
                        </FixedWidthRadioGroup>

                        <WhenFieldChanges
                            field={`${prefix}.individual.officiallyEmigrated`}
                            set={`${prefix}.individual.emigrationExchangeForm`}
                            to={null}
                        />

                        <Condition when={`${prefix}.individual.officiallyEmigrated`} is="Yes">
                            <True>
                                <FixedWidthFileUpload
                                    name={`${prefix}.individual.emigrationExchangeForm`}
                                    label="Upload Exchange Form"
                                    validate={requiredValidator}
                                    editable={editable}
                                    disabled={!editable}
                                />
                            </True>
                        </Condition>
                    </True>
                </Condition>
                <OutlinedSearchSelectField
                    name={`${prefix}.individual.relationshipToDeceased`}
                    label="What was your relationship to the deceased?"
                    validate={requiredValidator}
                    options={NaturalRelationships}
                    disabled={!editable}
                    labelprefixed="true"
                />
                <WhenFieldChanges
                    field={`${prefix}.individual.relationshipToDeceased`}
                    set={`${prefix}.individual.relationshipToDeceasedOther`}
                    to={null}
                />
                <Condition when={`${prefix}.individual.relationshipToDeceased`} is="Other">
                    <True>
                        <OutlinedTextField
                            name={`${prefix}.individual.relationshipToDeceasedOther`}
                            label="Please specify the nature of the relationship"
                            validate={requiredValidator}
                            disabled={!editable}
                            labelprefixed="true"
                        />
                    </True>
                </Condition>
                <Condition when={`${prefix}.individual.dateOfBirth`} passes={isMinor}>
                    <True>
                        <GuardianDetails editable={editable} prefix={prefix} />
                    </True>
                </Condition>
            </Grid>
            <ClaimantNavigation
                form={form}
                handleBackStepper={handleBackStepper}
                handleNext={handleNext}
                prefix={`claimant-${claimantIndex}-person`}
            />
        </Grid>
    );
};

export const PersonalDetails = withStyles(styles)(personalDetails);
