import * as React from 'react';

import { Field } from 'react-final-form';
import { TextField } from '../text-field/index';
import { FormControl } from '../form-control';

export const OutlinedTextField = ({
    name,
    fullWidth = true,
    type = 'text',
    shaded = false,
    ...rest
}) => {
    return (
        <FormControl>
            <Field
                {...rest}
                name={name}
                fullWidth={fullWidth}
                // @ts-ignore
                component={TextField}
                type={type}
                shaded={shaded}
            />
        </FormControl>
    );
};
