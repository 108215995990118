import * as React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from '../../../styles/styles';
import {
    Countries,
} from '../../../shared/lookups';
import { requiredValidator } from '../../../forms/validations';
import {
    OutlinedDatePicker,
    OutlinedSearchSelectField,
    OutlinedTextField,
} from '../../../forms';
import { ClaimantNavigation } from '../../common/navigation/claimant-navigation';

const entityDetails = function ({
    classes,
    index,
    editable,
    stepsLength,
    handleBackStepper,
    handleNext,
    form,
    claimantIndex,
}) {
    const prefix = 'policyholder.individual.companyDetails.';
    return (
        <Grid container spacing={10} justify="flex-start">
            <Grid item xs={12} lg={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
                <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                <Typography className={classes.stepHeader}>Policyholder Details</Typography>
            </Grid>
            <Grid item xs={12} lg={12}>
                <Grid item xs={12} lg={12}>
                    <OutlinedTextField
                        name={`${prefix}entityName`}
                        disabled={!editable}
                        labelprefixed="true"
                        label="Company Trading Name"
                        validate={requiredValidator}
                    />
                </Grid>
                <Grid item xs={12} lg={12}>
                    <OutlinedTextField
                        name={`${prefix}entityRegistrationNumber`}
                        label="Company Registration Number"
                        disabled={!editable}
                        labelprefixed="true"
                        validate={requiredValidator}
                    />
                </Grid>
                <Grid item xs={12} lg={12}>
                    <OutlinedDatePicker
                        name={`${prefix}dateOfIncorporation`}
                        label="Company Registration Date"
                        disableFuture
                        disabled={!editable}
                        labelprefixed="true"
                        validate={requiredValidator}
                    />
                </Grid>
                <Grid item xs={12} lg={12}>
                    <OutlinedSearchSelectField
                        shaded={false}
                        name={`${prefix}countryOfIncorporation`}
                        label="Country of incorporation"
                        options={Countries}
                        disabled={!editable}
                        labelprefixed="true"
                        validate={requiredValidator}
                    />
                </Grid>
            </Grid>
            <ClaimantNavigation
                form={form}
                handleBackStepper={handleBackStepper}
                handleNext={handleNext}
                prefix={`claimant-${claimantIndex}-entity`}
            />
        </Grid>
    );
};

export const EntityDetails = withStyles(styles)(entityDetails);
