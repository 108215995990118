import { withStyles, Typography, Grid } from '@material-ui/core';
import styles from '../../../styles/styles';
import React from 'react';
import { Condition, FixedWidthButtonGroup, OutlinedTextField, True, WhenFieldChanges } from '../../../forms';
import { requiredValidator } from '../../../forms/validations';
import { ClaimantNavigation } from '../../common/navigation/claimant-navigation';
import { FirstNameMask, NumberMask } from '../../../forms/input-masks';

const employmentDetails = function ({
    classes,
    form,
    editable,
    index,
    stepsLength,
    handleBackStepper,
    handleNext,
}) {
    const prefix = 'policyholder.individual.employmentDetails.';
    return (
        <React.Fragment>
            <Grid item xs={12} lg={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
            </Grid>
            <Grid item xs={12} lg={12} container justify="flex-start">
                <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>{' '}
                <Typography className={classes.stepHeader}> Employment Details</Typography>
            </Grid>
            <Grid item xs={12}>
                <FixedWidthButtonGroup
                    label="Is the policyholder employed?"
                    name={`${prefix}isPolicyHolderEmployed`}
                    options={['Yes', 'No']}
                    disabled={!editable}
                    validate={requiredValidator}
                    labelprefixed="true"
                />
            </Grid>

            <Condition when={`${prefix}isPolicyHolderEmployed`} is="Yes">
                <True>
                    <Grid item xs={12}>
                        <OutlinedTextField
                            name={`${prefix}NominatedOccupation`}
                            label="Nominated occupation"
                            disabled={!editable}
                            mask={FirstNameMask}
                            validate={requiredValidator}
                            labelprefixed="true"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <OutlinedTextField
                            name={`${prefix}industryOfOccupation`}
                            label="Industry of occupation"
                            disabled={!editable}
                            validate={requiredValidator}
                            mask={FirstNameMask}
                            labelprefixed="true"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <OutlinedTextField
                            name={`${prefix}employerName`}
                            label="Employer name"
                            disabled={!editable}
                            validate={requiredValidator}
                            labelprefixed="true"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <OutlinedTextField
                            name={`${prefix}saTaxReferenceNumber`}
                            label="SA Income tax reference number"
                            disabled={!editable}
                            validate={requiredValidator}
                            mask={NumberMask}
                            labelprefixed="true"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <OutlinedTextField
                            name={`${prefix}taxOffice`}
                            label="Tax office"
                            disabled={!editable}
                        />
                    </Grid>
                </True>
            </Condition>
            <Condition when={`${prefix}isPolicyHolderEmployed`} is="No">
                <True>
                    <Grid item xs={12}>
                        <FixedWidthButtonGroup
                            label="Do you have an income tax number?"
                            name={`${prefix}hasTaxNumber`}
                            options={['Yes', 'No']}
                            disabled={!editable}
                            validate={requiredValidator}
                            labelprefixed="true"
                        />
                    </Grid>
                    <WhenFieldChanges
                        field={`${prefix}hasTaxNumber`}
                        set={`${prefix}saTaxReferenceNumber`}
                        to={null}
                    />
                    <WhenFieldChanges
                        field={`${prefix}hasTaxNumber`}
                        set={`${prefix}taxOffice`}
                        to={null}
                    />
                    <Condition when={`${prefix}hasTaxNumber`} is="Yes">
                        <True>
                            <Grid item xs={12}>
                                <OutlinedTextField
                                    name={`${prefix}saTaxReferenceNumber`}
                                    label="SA Income tax reference number"
                                    disabled={!editable}
                                    validate={requiredValidator}
                                    mask={NumberMask}
                                    labelprefixed="true"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <OutlinedTextField
                                    name={`${prefix}taxOffice`}
                                    label="Tax office"
                                    disabled={!editable}
                                />
                            </Grid>
                        </True>
                    </Condition>
                </True>
            </Condition>

            <Grid item xs={12} className={classes.navigationGroup}>
                <ClaimantNavigation
                    form={form}
                    handleBackStepper={handleBackStepper}
                    handleNext={handleNext}
                    prefix={'policeholder-employment'}
                />
            </Grid>
        </React.Fragment>
    );
};


export const EmploymentDetails = withStyles(styles)(employmentDetails);

