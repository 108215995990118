import { every } from 'lodash';

export function dateBefore(leftDate, rightDate) {
    if (!leftDate || !rightDate) {
        return false;
    }
    return leftDate < rightDate;
}

export function isEmpty(value) {
    if (!value) {
        return true;
    }

    // eslint-disable-next-line
    for (const key in value) {
        if (value.hasOwnProperty(key)) {
            const propValue = value[key];
            if (propValue === undefined || propValue === null) {
                continue;
            }

            if (Array.isArray(propValue) && propValue.length === 0) {
                continue;
            }

            if (typeof propValue === 'object' && every(Object.values(propValue), isEmpty)) {
                continue;
            }

            return false;
        }
    }
    return true;
}

export function combineValidationErrors(...errors) {
    const _errors = [];
    // eslint-disable-next-line
    for (const error of errors) {
        if (error) {
            _errors.push(error);
        }
    }
    if (_errors.length <= 0) {
        return undefined;
    }
    return _errors.join('\n');
}
