import { connect } from 'react-redux';
import { NewClaimSelection as newClaimSelection } from './new-claim-selection';
import { goToCaptureClaim } from '../claim-selection-actions';

const mapDispatchToProps = {
    goToCaptureClaim,
};

const mapStateToProps = state => {
    const claimSelection = state.claimSelection || {};
    return {
        referencedClaims: claimSelection.referencedClaims,
        isOnlyReferencedClaims: claimSelection.isOnlyReferencedClaims,
    };
};

export const NewClaimSelection = connect(mapStateToProps, mapDispatchToProps)(newClaimSelection);
