import { Grid, Tooltip, Typography, withStyles } from '@material-ui/core';
import { Visibility } from '@material-ui/icons';
import React from 'react';
import { connect } from 'react-redux';
import { viewDocument } from '../../forms';
import styles from '../../styles/styles';

function summaryFileViewer({ classes, viewDocument, value }) {
    if (!value) {
        return null;
    }
    let files = JSON.parse(value);
    if (!Array.isArray(files)) {
        files = [files];
    }

    return (
        <React.Fragment>
            <Grid container justify="flex-start">
                <Grid container item xs={9}>
                    {files.map(file => (
                        <React.Fragment key={file.name}>
                            <Grid item xs={8}>
                                <Typography className={classes.fileViewerName}>

                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography className={classes.fileViewerName}>
                                    {file.name} ({getDocumentSize(file.size)})
                                </Typography>
                            </Grid>
                        </React.Fragment>
                    ))}
                </Grid>
                <Grid item xs={3}>
                    <Tooltip title="View document" style={{ cursor: 'pointer' }}>
                        <Grid
                            container
                            justify="flex-start"
                            id="summary-view-file"
                            onClick={() => viewDocument(files)}
                        >
                            <Visibility className={classes.fileViewerIcon} />
                            <Typography className={classes.fileViewerLabel}>View</Typography>
                        </Grid>
                    </Tooltip>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export function getDocumentSize(size) {
    if (size && !isNaN(size)) {
        if (size <= 1042999) {
            return `${(size / 1024 / 1024).toFixed(2)} MB`;
        }
        return `${(size / 1024 / 1024).toFixed(1)} MB`;
    }
    return '';
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    viewDocument: viewDocument,
};

export const SummaryFileViewer = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(summaryFileViewer));
