import * as React from 'react';

import { Field } from 'react-final-form';
import { Checkbox } from '../checkbox';
import { FormControl } from '../form-control';

export const FixedWidthCheckBoxField = ({ name, ...rest }) => {
    return (
        <FormControl>
            <Field {...rest} name={name} component={Checkbox} type="checkbox" />
        </FormControl>
    );
};
