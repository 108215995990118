import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from '../../../../styles/styles';
import { LabelValue } from '../../../common/label-value';

const taxInformation = ({ individual }) => {
    if (individual.relationshipToLifeAssured === 'MinorChild') {
        return null;
    }
    return (
        <React.Fragment>
            <Grid item xs={12} style={{ marginTop: '24px' }}>
                <Typography variant="h6">Tax Information</Typography>
                <hr />
            </Grid>
            <LabelValue label="Are you currently employed?" value={individual.isEmployed} />
            <LabelValue label="Occupation" value={individual.occupation} />
            <LabelValue label="Company" value={individual.company} />
            <LabelValue
                label="Are you a South African Resident?"
                value={individual.isRSAResident}
            />
            <LabelValue label="Country of Residency" value={individual.countryOfResidence} />
            <LabelValue
                label="Have you officially emigrated?"
                value={individual.officiallyEmigrated}
            />
            <LabelValue
                label="Exchange form"
                value={individual.exchangeForm}
                isFile={true}
            />
            <LabelValue label="Country of Tax Residence" value={individual.countryOfTaxResidence} />
            <LabelValue label="Tax Number" value={individual.taxReferenceNumber} />
        </React.Fragment>
    );
};

export const TaxInformationSummary = withStyles(styles)(taxInformation);
