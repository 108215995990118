import { withStyles, Grid } from '@material-ui/core';
import React from 'react';
import {
    Condition,
    FixedWidthRadioGroup,
    FixedWidthRadioOption,
    OutlinedTextField,
    True,
    WhenFieldChanges,
} from '../../forms';
import { FixedWidthFileUpload } from '../../forms/form-components/fixed-width-file-upload';
import { FullNameMask } from '../../forms/input-masks';
import { requiredValidator } from '../../forms/validations';
import styles from '../../styles/styles';
import { BankAccountDetailsView } from './bank-account-details';

const BankingDetailsViewEdit = function ({
    editable,
    prefix,
    accountHolder = 'bankAccountHoldersName',
    other = false,
    proofOfAccount = false,
}) {
    return (
        <Grid container spacing={10} justify="flex-start">
            <Grid item xs={12} lg={12}>
                <OutlinedTextField
                    name={`${prefix}${accountHolder}`}
                    label="Bank Account Holders Name"
                    disabled={!editable}
                    mask={FullNameMask}
                    validate={requiredValidator}
                    labelprefixed="true"
                />
            </Grid>
            <BankAccountDetailsView {...{ prefix, editable }} />
            <Condition when={`${prefix}claimantType`} is="Individual">
                <True>
                    <Grid item xs={12} lg={12}>
                        <FixedWidthRadioGroup
                            name={`${prefix}accountHolderRelationship`}
                            label="Account Relationship"
                            labelprefixed="true"
                            style={{ flexDirection: 'column' }}
                            disabled={!editable}
                            validate={requiredValidator}
                        >
                            <FixedWidthRadioOption value="Joint" />
                            <FixedWidthRadioOption value="Own" />
                            {other ? <FixedWidthRadioOption value="Other" /> : <></>}
                        </FixedWidthRadioGroup>
                    </Grid>
                    <WhenFieldChanges
                        field={`${prefix}accountHolderRelationship`}
                        set={`${prefix}accountHolderRelationshipOther`}
                        to={null}
                    />
                </True>
            </Condition>
            <Condition when={`${prefix}accountHolderRelationship`} is="Other">
                <True>
                    <Grid item xs={12}>
                        <OutlinedTextField
                            name={`${prefix}accountHolderRelationshipOther`}
                            label="Description"
                            disabled={!editable}
                            labelprefixed="true"
                            validate={requiredValidator}
                        />
                    </Grid>
                </True>
            </Condition>
            {proofOfAccount && (
                <Grid item xs={12}>
                    <FixedWidthFileUpload
                        name={`${prefix}proofOfBankAccount`}
                        validate={requiredValidator}
                        labelprefixed="true"
                        readonly={!editable}
                    />
                </Grid>
            )}
        </Grid>
    );
};

export const BankingDetailsView = withStyles(styles)(BankingDetailsViewEdit);
