import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    Typography,
    withStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as React from 'react';
import { connect } from 'react-redux';
import { getDisplayDate } from '../../../../shared/helper-functions';
import styles from '../../../../styles/styles';
import { LabelValue } from '../../../common/label-value';
import { IndividualAddressSummary } from '../address-details/address-details';
import { AddressDetailsSummary } from './address-details';
import { Relationships } from '../../../../shared/lookups';

const lifeAssuredSummary = function ({ classes, lifeAssured }) {
    if (lifeAssured && lifeAssured.length < 1) {
        return null;
    }

    return (
        <><Accordion
            style={{
                margin: '-24px -16px 24px -16px',
                borderRadius: '4px',
            }}
        >

            <AccordionSummary
                className={[classes.whiteBackground, classes.whiteTitle].join(' ')}
                expandIcon={<ExpandMoreIcon />}
                style={{ borderRadius: '4px' }}
            >
                <Typography className={classes.previewIndex}>3</Typography>
                <Typography className={classes.contentHeader}>Life Assured Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid xs={12} sm={12}>
                    <LifeAssuredDetails
                        key={'lifeAssuredSummary'}
                        lifeAssured={lifeAssured}
                        classes={classes}
                        index={0} />
                </Grid>
            </AccordionDetails>
        </Accordion></>
    );
};

const LifeAssuredDetails = ({ lifeAssured, classes, index }) => {
    const relationshipToPolicyHolder = Relationships.find(rel => (rel.value === lifeAssured.PersonalDetails.relationshipToPolicyHolder) || (rel.value === lifeAssured.PersonalDetails.lifeAssuredRelationshipToPolicyHolder));
    const relationshipToPolicyHolderLabel = relationshipToPolicyHolder && relationshipToPolicyHolder.label;

    return (
        <Accordion>

            <AccordionDetails>
                <Grid container justify="flex-start">
                    <Grid item xs={12} style={{ marginTop: '24px' }}>
                        <Typography variant="h6">Personal Details</Typography>
                        <hr />
                    </Grid>
                    <React.Fragment>
                        <LabelValue label="Is Life Assured the same as policy holder?" value={lifeAssured.PersonalDetails.sameAsPolicyHolder} />
                        <LabelValue label="Relationship to policy holder" value={relationshipToPolicyHolderLabel} />
                        <LabelValue label="Other Relationship" value={lifeAssured.PersonalDetails.otherRelationship} />
                        <LabelValue label="Title" value={lifeAssured.PersonalDetails.lifeAssuredTitle ?? lifeAssured.PersonalDetails.title} />
                        <LabelValue label="First name" value={lifeAssured.PersonalDetails.lifeAssuredFirstName ?? lifeAssured.PersonalDetails.firstName} />
                        <LabelValue label="Surname" value={lifeAssured.PersonalDetails.lifeAssuredSurname ?? lifeAssured.PersonalDetails.surname} />
                        <LabelValue label="Maiden Name" value={lifeAssured.PersonalDetails.maiden} />
                        <LabelValue label="Gender" value={lifeAssured.PersonalDetails.lifeAssuredGender ?? lifeAssured.PersonalDetails.gender} />
                        <LabelValue label="Marital Status" value={lifeAssured.PersonalDetails.lifeAssuredMaritalStatus ?? lifeAssured.PersonalDetails.maritalStatus} />
                        <LabelValue
                            label="Date Of Marriage"
                            value={getDisplayDate(lifeAssured.PersonalDetails.lifeAssuredDateOfMarriage ?? lifeAssured.PersonalDetails.dateOfMarriage)}
                        />
                        <LabelValue
                            label="Date of Divorce"
                            value={getDisplayDate(lifeAssured.PersonalDetails.lifeAssuredDateOfDivorce ?? lifeAssured.PersonalDetails.dateOfDivorce)}
                        />
                        <LabelValue
                            label="South African Resident"
                            value={lifeAssured.PersonalDetails.lifeAssuredIsRSAResident ?? lifeAssured.PersonalDetails.isRSAResident}
                        />
                        {(lifeAssured.PersonalDetails.lifeAssuredIsRSAResident === 'Yes' || lifeAssured.PersonalDetails.isRSAResident === 'Yes') && (
                            <>
                                <LabelValue label="ID Number" value={lifeAssured.PersonalDetails.lifeAssuredIdNumber ?? lifeAssured.PersonalDetails.idNumber} />
                                <LabelValue label="Date of Birth" value={getDisplayDate(lifeAssured.PersonalDetails.lifeAssuredDateOfBirth ?? lifeAssured.PersonalDetails.dateOfBirth)} />
                            </>
                        )}
                        {lifeAssured.PersonalDetails.isRSAResident === 'No' && (
                            <Grid container justify="flex-start">
                                <LabelValue label="Passport Number" value={lifeAssured.PersonalDetails.lifeAssuredIdNumber ?? lifeAssured.PersonalDetails.idNumber} />
                                <LabelValue label="Country of Issue" value={lifeAssured.PersonalDetails.lifeAssuredCountryOfIssue ?? lifeAssured.PersonalDetails.countryOfIssue} />
                                <LabelValue
                                    label="Date of Issue"
                                    value={getDisplayDate(lifeAssured.PersonalDetails.lifeAssuredDateOfIssue ?? lifeAssured.PersonalDetails.dateOfIssue)}
                                />
                                <LabelValue
                                    label="Date of Expiry"
                                    value={getDisplayDate(lifeAssured.PersonalDetails.lifeAssuredDateOfExpiry ?? lifeAssured.PersonalDetails.dateOfExpiry)}
                                />
                            </Grid>
                        )}
                        <LabelValue label="Place of birth" value={lifeAssured.PersonalDetails.lifeAssuredPlaceOfBirth ?? lifeAssured.PersonalDetails.placeOfBirth} />
                        <LabelValue label="Country of Birth" value={lifeAssured.PersonalDetails.lifeAssuredCountryOfBirth ?? lifeAssured.PersonalDetails.countryOfBirth} />
                        <LabelValue label="Country of Residence" value={lifeAssured.PersonalDetails.lifeAssuredCountryOfResidence ?? lifeAssured.PersonalDetails.countryOfResidence} />
                        <LabelValue label="Proof of Identification" value={lifeAssured.PersonalDetails.proofOfIdentification} isFile />
                    </React.Fragment>
                    <React.Fragment>
                        <IndividualAddressSummary policyholder={lifeAssured} displayPostalAddress={true} />
                    </React.Fragment>
                    <React.Fragment>
                        <Grid item xs={12} style={{ marginTop: '24px' }}>
                            <Typography variant="h6">Contact Details</Typography>
                            <hr />
                        </Grid>
                        <LabelValue label="Home Telephone Number" value={lifeAssured.contactDetails.homeTelephoneNumber} />
                        <LabelValue label="Work Telephone Number" value={lifeAssured.contactDetails.workTelephoneNumber} />
                        <LabelValue label="Cellphone Number" value={lifeAssured.contactDetails.cellphoneNumber} />
                        <LabelValue label="Telephone Number" value={lifeAssured.contactDetails.telephoneNumber} />
                        <LabelValue label="Fax Number" value={lifeAssured.contactDetails.faxNumber} />
                        <LabelValue label="Email Address" value={lifeAssured.contactDetails.emailAddress} />
                    </React.Fragment>
                    <React.Fragment>
                        <DoctorsDetails
                            classes={classes}
                            doctorsVisits={lifeAssured.MedicalInformation.doctorVisits}
                            medicalInformation={lifeAssured.MedicalInformation}
                        />
                    </React.Fragment>
                    <React.Fragment>
                        <MedicalAidDetails
                            classes={classes}
                            medicalAidInformation={lifeAssured.medicalAidDetails}
                        />
                    </React.Fragment>
                    <React.Fragment>
                        <OtherDetails
                            classes={classes}
                            OtherInformation={lifeAssured.OtherDetails}
                        />
                    </React.Fragment>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

const DoctorsDetails = ({ classes, doctorsVisits, medicalInformation }) => {
    return (
        <Grid container justify="flex-start">
            <Grid item xs={12} style={{ marginTop: '24px' }}>
                <Typography variant="h6">Medical Information</Typography>
                <hr />
            </Grid>
            <LabelValue label="What is the medical reason for the claim being submitted?" value={medicalInformation.lifeAssuredMedicalReason} />
            <LabelValue label="Please provide date of diagnosis or date that the event took place" value={getDisplayDate(medicalInformation.lifeAssuredDateOfDiagnosis)} />
            <LabelValue label="Are you currently taking any medication or undergoing treatment?" value={medicalInformation.takingMedicationOrTreatment} />
            <LabelValue label="Medical condition was acquired due to?" value={medicalInformation.lifeAssuredMedicalCondition} />
            <LabelValue label="Provide accident details and case number if applicable" value={medicalInformation.lifeAssuredaccidentDetails} />

            {medicalInformation.lifeAssuredMedicalEvidence1 && <LabelValue label="Supporting Medical Evidence 1" value={medicalInformation.lifeAssuredMedicalEvidence1} isFile />}
            {medicalInformation.lifeAssuredMedicalEvidence && medicalInformation.lifeAssuredMedicalEvidence.map((medicalDetails, medicalInformationIndex) => {
                return (
                    <LabelValue
                        key={medicalInformationIndex}
                        label={`Supporting Medical Evidence ${medicalInformationIndex + 2}`}
                        value={medicalDetails}
                        isFile
                    />
                );
            })}
            <LabelValue label="Extract Of Medical History 1" value={medicalInformation.extractOfMedicalHistory1} isFile />
            {medicalInformation.extractOfMedicalHistory && medicalInformation.extractOfMedicalHistory.map((medicalDetails, doctorsIndex) => {
                return (
                    <LabelValue
                        key={doctorsIndex}
                        label={`Extract Of Medical History ${doctorsIndex + 2}`}
                        value={medicalDetails}
                        isFile
                    />
                );
            })}

            {doctorsVisits && (doctorsVisits.map((doctersDetails, doctorsIndex) => {
                return (
                    <Grid container justify="flex-start" key={doctorsIndex}>
                        <Grid item xs={12}>
                            <Typography className={classes.whiteTitle}>
                                {`Doctor ${doctorsIndex + 1}`}
                            </Typography>
                        </Grid>
                        <LabelValue label="Doctor's Name" value={doctersDetails.doctorsName} />
                        <LabelValue label="Practice Number" value={doctersDetails.practiceNumber} />
                        <LabelValue label="Contact Number" value={doctersDetails.doctorsContactDetails} />
                        <LabelValue label="Doctor's email address" value={doctersDetails.emailAddress} />

                        <AddressDetailsSummary addressDetails={doctersDetails.addressDetails} />
                        {doctersDetails.occurences &&
                            doctersDetails.occurences.map((visit, visitIndex) => {
                                return (
                                    <Grid container justify="flex-start" key={visitIndex}>
                                        <Grid item xs={12}>
                                            <Typography className={classes.whiteTitle}>
                                                {`Doctors Consultation ${visitIndex + 1}`}
                                            </Typography>
                                        </Grid>
                                        <LabelValue
                                            label="Date of Attendance"
                                            value={getDisplayDate(visit.dateOfAttendance)}
                                        />
                                        <LabelValue
                                            label="Reason for Consultation"
                                            value={visit.reason}
                                        />
                                    </Grid>
                                );
                            })}
                    </Grid>
                );
            }))}
        </Grid>
    );
};

const MedicalAidDetails = ({ classes, medicalAidInformation }) => {
    if (!medicalAidInformation) {
        return null;
    }

    return (
        <Grid container justify="flex-start">
            <Grid item xs={12} style={{ marginTop: '24px' }}>
                <Typography variant="h6">Medical Aid Details</Typography>
                <hr />
            </Grid>
            <LabelValue label="Does the Life Assured have a medical aid?" value={medicalAidInformation.hasMedicalAid} />
            <LabelValue label="Medical Aid Name" value={medicalAidInformation.lifeAssuredMedicalAidName} />
            {medicalAidInformation.lifeAssuredMedicalAidName === "Other" &&
                <LabelValue label="Medical Aid Name (Other)" value={medicalAidInformation.lifeAssuredMedicalAidDescription} />
            }
            <LabelValue label="Medical Aid Number" value={medicalAidInformation.lifeAssuredMedicalAidNumber} />
            <LabelValue label="Date joined" value={getDisplayDate(medicalAidInformation.lifeAssuredMedicalDateJoined)} />
            <LabelValue label="Is the life assured the main member on the medical aid?" value={medicalAidInformation.isLifeAssuredMainMember} />
            <LabelValue label="Life Assured Title" value={medicalAidInformation.lifeAssuredTitle} />
            <LabelValue label="Name" value={medicalAidInformation.lifeAssuredName} />
            <LabelValue label="Surname" value={medicalAidInformation.lifeAssuredSurname} />
            <LabelValue label="ID Number" value={medicalAidInformation.lifeAssuredIDNumber} />
        </Grid>
    );
};

const OtherDetails = ({ classes, OtherInformation }) => {
    if (!OtherInformation) {
        return null;
    }

    return (
        <Grid container justify="flex-start">
            <Grid item xs={12} style={{ marginTop: '24px' }}>
                <Typography variant="h6">Other Details</Typography>
                <hr />
            </Grid>
            <LabelValue label="What is your highest qualification?" value={OtherInformation.highestQualification} />
            <LabelValue label="Have you ever been declared insolvent or are sequestration hearing proceedings, pending or completed?"
                value={OtherInformation.hasInsolvency} />
            <LabelValue label="Do you consume alcohol?" value={OtherInformation.consumesAlcohol} />
            <LabelValue label="How many spirits and tots per week?" value={OtherInformation.totCount} />
            <LabelValue label="How many wines in a glass per week?" value={OtherInformation.wineGlassCount} />
            <LabelValue label="How many beers/ciders in a can/bottle per week?" value={OtherInformation.beerCanOrCider} />
            <LabelValue label="Have you ever received advice to reduce or discontinue alcohol consumption, or have you ever been charged with drunken driving?"
                value={OtherInformation.receivedAdviceToReduceAlcohol} />
            <LabelValue label="Please provide full details"
                value={OtherInformation.alcoholAdviceDetails} />
            <LabelValue label="Do you currently practice any avocation/s e.g scuba diving, flying, etc" value={OtherInformation.practiceAvocation} />
            <LabelValue label="Please provide full details" value={OtherInformation.practiceAvocationDetails} />
            <LabelValue label="Please provide the average number of hours that you were working per week, for three month prior to the date of the claim event"
                value={OtherInformation.hoursWorkedPerWeek} />
            <LabelValue label="Were you partially or fully retired at the date of the claim event?" value={OtherInformation.wasRetired} />
        </Grid>
    );
};

const mapStateToProps = state => {
    const claimSelection = (state && state.claimSelection) || {};
    return {
        lifeAssured: (claimSelection.claim && claimSelection.claim.lifeAssured) || [],
    };
};

export const LifeAssuredSummary = connect(mapStateToProps, {})(withStyles(styles)(lifeAssuredSummary));
