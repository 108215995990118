import * as React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from '../../../../styles/styles';
import { LabelValue } from '../../../common/label-value';

const residentialAddress = function ({ classes, policyholder }) {
    let isLifeAssured = false;
    let isPolicyHolder = true;

    if (policyholder && policyholder.addressDetails) {
        isLifeAssured = true;
        isPolicyHolder = false;
    } else {
        isPolicyHolder = true;
        isLifeAssured = false;
    }

    if (!isLifeAssured) {
        if (!policyholder || !policyholder.individual || !policyholder.individual.addressDetails) {
            isLifeAssured = true;
            isPolicyHolder = false;
            return null;
        }
    }


    return (
        <Grid container justify="flex-start">
            {isPolicyHolder && policyholder.policyHolderType === 'Individual' &&
                <><Grid item xs={12}>
                    <Typography className={classes.whiteTitle}>Residential Address</Typography>
                </Grid>
                <LabelValue label="Address Type" value="RSA Street address" />
                <LabelValue label="Address Line 1" value={policyholder.individual.addressDetails.AddressLineOne} />
                <LabelValue label="Address Line 2" value={policyholder.individual.addressDetails.AddressLineTwo} />
                <LabelValue label="Town/Suburb" value={policyholder.individual.addressDetails.AddressSuburb} />
                <LabelValue label="City" value={policyholder.individual.addressDetails.AddressTown} />
                <LabelValue label="Postal Code" value={policyholder.individual.addressDetails.PostalCode} />
                <LabelValue label="Province" value={policyholder.individual.addressDetails.Province} /></>
            }
            {isPolicyHolder && policyholder.policyHolderType === 'Company' &&
                <><Grid item xs={12}>
                    <Typography className={classes.whiteTitle}>Business Address</Typography>
                </Grid>
                <LabelValue label="Address Type" value={'RSA Street address'} />
                <LabelValue label="Address Line 1" value={policyholder.individual.companyDetails.entityAddressLine1} />
                <LabelValue label="Address Line 2" value={policyholder.individual.companyDetails.entityAddressLine2} />
                <LabelValue label="Town/Suburb" value={policyholder.individual.companyDetails.entitySuburb} />
                <LabelValue label="City" value={policyholder.individual.companyDetails.entityTown} />
                <LabelValue label="Postal Code" value={policyholder.individual.companyDetails.entityPostalCode} />
                <LabelValue label="Province" value={policyholder.individual.companyDetails.entityProvince} />
                <LabelValue label="Country" value={policyholder.individual.companyDetails.entityCountry} /></>
            }

            {
                isLifeAssured &&
                <>
                    <Grid item xs={12}>
                        <Typography className={classes.whiteTitle}>Residential Address</Typography>
                    </Grid>
                    <LabelValue label="Address Type" value={'RSA Street address'} />
                    <LabelValue label="Address Line 1" value={policyholder.addressDetails.AddressLineOne} />
                    <LabelValue label="Address Line 2" value={policyholder.addressDetails.AddressLineTwo} />
                    <LabelValue label="Town/Suburb" value={policyholder.addressDetails.AddressSuburb} />
                    <LabelValue label="City" value={policyholder.addressDetails.AddressTown} />
                    <LabelValue label="Postal Code" value={policyholder.addressDetails.PostalCode} />
                    <LabelValue label="Province" value={policyholder.addressDetails.Province} />
                    <LabelValue label="Country" value={policyholder.addressDetails.country} />
                </>
            }
        </Grid>
    );
};

export const ResidentialAddress = withStyles(styles)(residentialAddress);
