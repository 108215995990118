import { Grid, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import informationIcon from '../../../styles/icons/information.svg';
import styles from '../../../styles/styles';

function stepDisclaimer({ classes, name }) {
    return (
        <React.Fragment>
            <Grid item xs={12} className={classes.grayTitle}>
                <Typography className={classes.grayTitleText}>
                    {`We may require the following documentation in the ${name} step`}
                </Typography>
            </Grid>
            <Grid item xs={12} className={classes.blueBackground}>
                <div className={classes.infoIcon}>
                    <img src={informationIcon} alt="information" />
                </div>
                <span className={classes.blueTitle}>
                    Please ensure that all documents are clear and legible, else re-submission may
                    be required
                </span>
                <div className={classes.blueSubtitle}>
                    File types allowed: .png, .jpeg, .jpg, .pdf, docx Documents to be uploaded have
                    a maximum file size of 20mb
                </div>
            </Grid>
        </React.Fragment>
    );
}

export const StepDisclaimer = withStyles(styles)(stepDisclaimer);
