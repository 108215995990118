import React from 'react';
import { withStyles, Typography, Grid } from '@material-ui/core';
import styles from '../../../styles/styles';
import { Condition, True, WhenFieldChanges } from '../../../forms';
import {
    FixedWidthRadioGroup,
    FixedWidthRadioOption,
    OutlinedDatePicker,
    OutlinedTextField,
} from '../../../forms';
import { requiredValidator } from '../../../forms/validations';
import { DeceasedNavigation } from '../../common/navigation/deceased-navigation';

const financialDetails = function ({
    classes,
    handleBack,
    handleBackStepper,
    handleNext,
    form,
    index,
    stepsLength,
    editable,
}) {
    return (
        <Grid container spacing={10} justify="flex-start">
            <Grid item xs={12} lg={12}>
                <Typography className={classes.utilSteps}>
                    {' '}
                    Step {index + 1} of {stepsLength}
                </Typography>
                <Grid item xs={12} lg={12} style={{ display: 'flex' }}>
                    <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                    <Typography className={classes.stepHeader}>Financial Details</Typography>
                </Grid>
                <Grid item xs={12} lg={12}>
                    <FixedWidthRadioGroup
                        name="deceasedDetails.financialDetails.insolventStatus"
                        labelprefixed="true"
                        label="Has the deceased member ever been insolvent, or are any sequestration
                        proceedings or contemplated?"
                        validate={requiredValidator}
                        style={{ marginTop: '21px' }}
                        disabled={!editable}
                    >
                        <FixedWidthRadioOption value="Yes" />
                        <FixedWidthRadioOption value="No" />
                        <FixedWidthRadioOption value="I Dont know" label="I Don't Know" />
                    </FixedWidthRadioGroup>
                </Grid>
                <WhenFieldChanges
                    field="deceasedDetails.financialDetails.insolventStatus"
                    set="deceasedDetails.financialDetails.insolventDateOfOccurence"
                    to={null}
                />
                <WhenFieldChanges
                    field="deceasedDetails.financialDetails.insolventStatus"
                    set="deceasedDetails.financialDetails.insolventReason"
                    to={null}
                />
                <Condition when={'deceasedDetails.financialDetails.insolventStatus'} is={'Yes'}>
                    <True>
                        <Grid item xs={12} lg={12}>
                            <OutlinedDatePicker
                                name="deceasedDetails.financialDetails.insolventDateOfOccurence"
                                label="Date of Occurrence"
                                validate={requiredValidator}
                                labelprefixed="true"
                                disabled={!editable}
                                disableFuture
                            />
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <OutlinedTextField
                                name="deceasedDetails.financialDetails.insolventReason"
                                label="Reason"
                                validate={requiredValidator}
                                labelprefixed="true"
                                disabled={!editable}
                            />
                        </Grid>
                    </True>
                </Condition>
                <Grid item xs={12} lg={12}>
                    <FixedWidthRadioGroup
                        name="deceasedDetails.financialDetails.estateInsolventStatus"
                        labelprefixed="true"
                        label="Was the estate of the deceased member insolvent at the time of death?"
                        validate={requiredValidator}
                        disabled={!editable}
                        style={{ marginTop: '21px' }}
                    >
                        <FixedWidthRadioOption value="Yes" />
                        <FixedWidthRadioOption value="No" />
                        <FixedWidthRadioOption value="I Dont Know" label="I Don't Know" />
                    </FixedWidthRadioGroup>
                </Grid>
                <WhenFieldChanges
                    field="deceasedDetails.financialDetails.estateInsolventStatus"
                    set="deceasedDetails.financialDetails.estateInsolventDateOfOccurence"
                    to={null}
                />
                <WhenFieldChanges
                    field="deceasedDetails.financialDetails.estateInsolventStatus"
                    set="deceasedDetails.financialDetails.estateInsolventReason"
                    to={null}
                />
                <Condition
                    when={'deceasedDetails.financialDetails.estateInsolventStatus'}
                    is={'Yes'}
                >
                    <True>
                        <Grid item xs={12} lg={12}>
                            <OutlinedDatePicker
                                name="deceasedDetails.financialDetails.estateInsolventDateOfOccurence"
                                label="Date of Occurrence"
                                validate={requiredValidator}
                                labelprefixed="true"
                                disabled={!editable}
                                disableFuture
                            />
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <OutlinedTextField
                                name="deceasedDetails.financialDetails.estateInsolventReason"
                                label="Reason"
                                validate={requiredValidator}
                                labelprefixed="true"
                                disabled={!editable}
                            />
                        </Grid>
                    </True>
                </Condition>
            </Grid>
            <DeceasedNavigation
                form={form}
                handleBack={handleBack}
                handleBackStepper={handleBackStepper}
                handleNext={handleNext}
                prefix="deceased-financial"
            />
        </Grid>
    );
};

export const FinancialDetails = withStyles(styles)(financialDetails);
