import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from '../../../../styles/styles';
import { ResidentialAddress } from './residential-address';
import { PostalAddress } from './postal-address';

const addressDetails = ({ claimant }) => {
    return (
        <React.Fragment>
            <Grid item xs={12} style={{ marginTop: '24px' }}>
                <Typography variant="h6">Address Details</Typography>
                <hr />
            </Grid>
            <ResidentialAddress claimant={claimant} />
            <PostalAddress claimant={claimant} />
        </React.Fragment>
    );
};

export const IndividualAddressSummary = withStyles(styles)(addressDetails);
