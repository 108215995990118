import { INITIATE_LOGIN, SET_CHECKED_LOGIN, SET_ACCOUNT } from './authentication-types';

export const initialAuthState = {
    redirecting: false,
    checkedIfLoggedIn: false,
};

export function authenticationReducer(state = initialAuthState, action) {
    switch (action.type) {
        case INITIATE_LOGIN:
            return { ...state, redirecting: true };
        case SET_CHECKED_LOGIN:
            return { ...state, checkedIfLoggedIn: true };
        case SET_ACCOUNT:
            return { ...state, account: action.account };
        default:
            return state;
    }
}
