import * as React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { DateTime } from 'luxon';
import iconExclamationBlue from '../../../master-layout/images/icon-exclamation-blue.svg';
import iconConfirmationGreen from '../../../master-layout/images/icon-confirmation-green.svg';
import iconExclamationGrey from '../../../master-layout/images/icon-exclamation-grey.svg';
import iconExclamationRed from '../../../master-layout/images/icon-exclamation-red.svg';
import iconExclamationYellow from '../../../master-layout/images/icon-exclamation-yellow.svg';
import styles from '../../../styles/styles';
import { ClaimOutcomes } from './existing-claim-outcome';
import { OutstandingDocuments } from './outstanding-documents';

const claimStatus = ({ claimInfo, classes, setLoaded, claimType = '' }) => {
    if (!claimInfo) {
        return null;
    }

    return (
        <Grid container className={classes.boxParent}>
            {claimInfo.claimProgress.map((claimProgress, index) => {
                return (
                    <ClaimStatusBox
                        key={`claimstatusbox-${index}`}
                        classes={classes}
                        currentStatus={claimInfo.claimStatus}
                        claimProgress={claimProgress}
                        claimType={claimType}
                    />
                );
            })}
            <OutstandingDocuments claimInfo={claimInfo} setLoaded={setLoaded} />
            <ClaimOutcomes claimOutcomes={claimInfo.outcomes} />
        </Grid>
    );
};

const ClaimStatusBox = ({ classes, currentStatus, claimProgress, claimType }) => {
    let icon = null;
    let colour = null;
    let statusText = '';

    const isLivingLifestyleClaim = (claimType === 'Living Lifestyle Claims');
    if (isLivingLifestyleClaim) {
        if (claimProgress.isInProgress) {
            statusText = 'IN PROGRESS';
        } else {
            statusText = claimProgress.isCompleted === true ? 'COMPLETE' : 'PENDING';
        }
    } else {
        if (claimProgress.claimStatusOverview === currentStatus) {
            statusText = 'CURRENT ACTION';
        } else {
            statusText = claimProgress.isCompleted === true ? 'COMPLETE' : 'PENDING';
        }
    }

    if (
        currentStatus === 'DocumentsOutstanding' &&
        claimProgress.isCompleted === false &&
        claimProgress.dateStarted != null
    ) {
        const hoursSinceStarted = DateTime.fromISO(new Date().toISOString())
            .diff(DateTime.fromISO(claimProgress.dateStarted), 'hours')
            .toObject().hours;
        if (hoursSinceStarted < 10 * 24) {
            icon = iconExclamationYellow;
            colour = '#ffab00';
        } else {
            icon = iconExclamationRed;
            colour = '#e6253a';
        }
    } else if (
        claimProgress.claimStatusOverview === 'Assessment' &&
        currentStatus === 'Assessment' &&
        !isLivingLifestyleClaim
    ) {
        icon = iconExclamationBlue;
        colour = '#4378fd';
    } else if (claimProgress.isCompleted === true) {
        icon = iconConfirmationGreen;
        colour = '#36b37e';
    } else if (claimProgress.isInProgress === true) {
        icon = iconExclamationYellow;
        colour = '#ffab00';
    } else {
        icon = iconExclamationGrey;
        colour = '#424757';
    }

    return (
        <Grid item lg={3}>
            <div className={classes.boxContainer} style={{ border: `solid 1px ${colour}` }}>
                <div className={classes.boxHeader} style={{ backgroundColor: colour }}>
                    <Typography className={classes.boxHeaderContent}>
                        {getHeader(claimProgress.claimStatusOverview)}
                    </Typography>
                </div>
                <div className={classes.boxDetails}>
                    <div className={classes.boxDetailsHeader}>
                        <img alt="upload" src={icon} style={{ verticalAlign: 'middle' }} />
                        <span style={{ paddingLeft: '12px' }}>{statusText}</span>
                    </div>
                    <div className={classes.boxDetailsDate}>
                        {claimProgress.dateCompleted
                            ? (isLivingLifestyleClaim) ? DateTime.fromISO(claimProgress.dateCompleted).toFormat('dd/MM/yyyy') :
                                DateTime.fromISO(claimProgress.dateCompleted).toLocaleString(
                                    DateTime.DATE_SHORT
                                )
                            : claimProgress.isInProgress ? DateTime.fromISO(claimProgress.dateStarted).toFormat('dd/MM/yyyy') :  ''}
                    </div>
                    <div className={classes.boxDetailsSummary}>{claimProgress.description}</div>
                </div>
            </div>
        </Grid>
    );
};

function getHeader(status) {
    switch (status) {
        case 'Initiated':
            return 'CLAIM INITIATION';
        case 'DocumentsOutstanding':
            return 'REQUIRED DOCUMENTS';
        case 'Assessment':
            return 'CLAIM ASSESSMENT';
        case 'Outcome':
            return 'CLAIM OUTCOME';
        default:
            return '';
    }
}

export const ExistingClaimStatus = withStyles(styles)(claimStatus);
