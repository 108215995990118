import { Grid, Tooltip, Typography, withStyles } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import classNames from 'classnames';
import round from 'lodash/round';
import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import axios from '../../../http-request';
import { callAllFunctions, getComponentId, nameToLabel, processMetaForErrors } from '../..';
import { getValidatedLabel } from '../../form-component-utilities/label-validator/label-validator';
import { uploadInProgressThunk } from '../../uploads-in-progess/action';
import './animations.scss';
import CloudIcon from './CloudUploadOutlined.svg';
import { FileViewer } from './file-viewer';
import styles from './styles';

const { env } = require('../../../env/env');
const fileTypes = ['.png', '.jpeg', '.pdf', '.docx'];
const fileTypesExtended = ['.png', '.jpeg', '.pdf', '.docx', '.PNG', '.JPEG', '.PDF', '.DOCX'];
const extensionCheckRegEx = /(?:\.([^.]+))?$/;

function FileUploadComponent({
    id,
    input,
    meta,
    label,
    helperText,
    onChange,
    uploadInProgressThunk,
    classes,
    uploadUrl = `${env.REACT_APP_API_URL}/upload-files`,
    fileLimit = 20 * 1024 * 1024,
    multiple = true,
    readonly,
    ...rest
}) {
    const [files, setFiles] = useState([]);
    const { name, value } = input;
    const parsedValue = value ? JSON.parse(value) : null;
    if (parsedValue) {
        if (files.length !== parsedValue.length) {
            setFiles(parsedValue);
        }
    } else {
        if (files.length) {
            setFiles([]);
        }
    }

    let { errorMessage, showError } = processMetaForErrors(meta);
    const [loading, setLoading] = useState(false);
    const [uploadError, setUploadError] = useState({ errorMessage: '', showError: false });
    errorMessage = errorMessage || uploadError.errorMessage;
    showError = showError || uploadError.showError;
    const showHelperText = (showError && errorMessage) || helperText;
    const _id = `${getComponentId({ id, name })}-upload`;
    const _label = getValidatedLabel(
        rest,
        label ? label : `Upload ${nameToLabel({ label, name })}`
    );

    if (files.length > 0 || loading) {
        const handleDelete = () => callAllFunctions(onChange, input.onChange)(null);
        return <UploadedFiles {...{ loading, files, classes, _label, handleDelete, readonly }} />;
    }

    return (
        <Grid container justify="center" className={classes.uploadContainer}>
            <Grid item xs={12} style={showError ? { color: '#cd003d' } : {}}>
                <Typography className={classes.uploadLabel}>{_label}</Typography>
                <Typography variant="caption" className={classes.captionText}>
                    Max file size {readableSize(fileLimit)}. File types allowed:
                    {` ${fileTypes.join(', ')}`}
                </Typography>
                <div className={classes.dropZoneWrapper}>
                    <Dropzone
                        onDrop={acceptedFiles => fileSelected(acceptedFiles)}
                        disabled={readonly}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <section className={classes.dropZoneSection}>
                                <div {...getRootProps()} className={classes.dropZoneInner}>
                                    <input id={_id} {...getInputProps()} />
                                    <div className={classes.dropZoneDiv}>
                                        <img
                                            src={CloudIcon}
                                            alt="upload"
                                            className={classes.dropZoneCloud}
                                        />
                                        <p className={classes.dropZoneDropHere}>
                                            Drag and drop file here or browse to upload
                                        </p>
                                    </div>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                </div>
                {showHelperText && (
                    <Typography
                        variant="subtitle2"
                        className={classNames(classes.helperText, {
                            [classes.error]: showError,
                        })}
                    >
                        {showError ? errorMessage + '\u00A0'.repeat(18) : helperText}
                    </Typography>
                )}
            </Grid>
        </Grid>
    );

    function fileSelected(uploadFiles) {
        for (let i = 0; i < uploadFiles.length; i++) {
            const file = uploadFiles[i];
            if (fileTypesExtended.indexOf(`.${extensionCheckRegEx.exec(file.name)[1]}`) === -1) {
                setUploadError({
                    errorMessage: `Allowed file extensions are: ${fileTypes.join(', ')}`,
                    showError: true,
                });
                uploadInProgressThunk(-1);
                return;
            }
        }
        setLoading(true);
        setUploadError({ errorMessage: '', showError: false });
        uploadInProgressThunk(1);
        uploadToServerAndGetUrls(uploadUrl, fileLimit, uploadFiles)
            .then(filesFromServer => {
                const value = JSON.stringify(files.concat(filesFromServer));
                callAllFunctions(onChange, input.onChange)(value);
            })
            .catch(err => {
                setUploadError({
                    errorMessage: 'An error occurred while uploading. Please retry.',
                    showError: true,
                });
            })
            .finally(() => {
                setLoading(false);
                uploadInProgressThunk(-1);
            });
    }
}

const UploadedFiles = ({ loading, files, classes, _label, handleDelete, readonly }) => {
    const fileName = loading ? 'uploading file...' : files[0].name;
    return (
        <Grid container justify="center" className={classes.fileContainer}>
            <Grid container item xs={12} className={classes.file}>
                <Grid container justify="flex-start" item xs={12}>
                    <Grid item xs={10}>
                        <Typography className={classes.uploadLabel}>{_label}</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <FileDelete {...{ classes, handleDelete, readonly }} />
                    </Grid>
                    <Grid item xs={1}>
                        <FileViewer files={files} />
                    </Grid>
                </Grid>
                <div className={classNames(classes.fileUploadComplete, { sliding: loading })} />
                <div style={{ width: '100%' }}>
                    <span className={classes.fileName} style={{ float: 'left' }}>
                        {fileName}
                    </span>
                    <span className={classes.fileName} style={{ float: 'right' }}>
                        {loading ? (
                            <div className={classNames({ percentage: loading })} />
                        ) : (
                            '100% Complete'
                        )}
                    </span>
                </div>
            </Grid>
        </Grid>
    );
};

const FileDelete = ({ classes, handleDelete, readonly }) => {
    if (readonly) {
        return null;
    }
    return (
        <Tooltip title="Remove file">
            <div id="remove-file" className={classes.fileAction} onClick={handleDelete}>
                <Delete className={classes.fileIcon} />
            </div>
        </Tooltip>
    );
};

async function uploadToServerAndGetUrls(uploadUrl, fileLimit, files) {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
        let file = files[i];
        if (file.size > fileLimit) {
            throw new Error(
                `File "${file.name}" is too large, the maximum allowed size is ${readableSize(
                    fileLimit
                )}, current size is ${readableSize(file.size)}`
            );
        } else {
            formData.append('files[]', file);
        }
    }
    const config = {
        headers: {
            'content-type': 'multipart/form-data',
        },
    };
    const response = await axios.post(uploadUrl, formData, config);
    return response.data;
}

function readableSize(bytes) {
    return `${round(bytes / 1024 / 1024, 2)} MB`;
}

const mapDispatchToProps = { uploadInProgressThunk: uploadInProgressThunk };

export { uploadToServerAndGetUrls, fileTypes, fileTypesExtended, extensionCheckRegEx };
export const FileUpload = connect(
    () => ({}),
    mapDispatchToProps
)(withStyles(styles)(FileUploadComponent));
