import { Grid, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import { getLabel } from '../../../../shared/helper-functions';
import { allRelationships, Titles } from '../../../../shared/lookups';
import styles from '../../../../styles/styles';
import { IdentitySummary } from '../../../common/identity-details-summary';
import { LabelValue } from '../../../common/label-value';

const guardianDetails = function ({ guardianDetails }) {
    if (!guardianDetails) {
        return null;
    }

    return (
        <Grid container justify="flex-start">
            <Grid item xs={12} style={{ marginTop: '24px' }}>
                <Typography variant="h6">Guardian Details</Typography>
                <hr />
            </Grid>
            <LabelValue
                label="Relationship To Minor"
                value={getLabel(guardianDetails.relationshipToMinor, allRelationships())}
            />
            <LabelValue
                label="Relationship Description"
                value={guardianDetails.relationshipToMinorDescription}
            />
            <LabelValue label="Title" value={getLabel(guardianDetails.title, Titles)} />
            <LabelValue label="Initials" value={guardianDetails.initials} />
            <LabelValue label="First Name" value={guardianDetails.firstName} />
            <LabelValue label="Second Name" value={guardianDetails.secondName || '-'} />
            <LabelValue label="Surname" value={guardianDetails.lastName} />
            <IdentitySummary identityDetails={guardianDetails} />
        </Grid>
    );
};

export const GuardianDetails = withStyles(styles)(guardianDetails);
