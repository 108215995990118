import React from 'react';

import { Route, Switch } from 'react-router';
import { ClaimSelection } from '../pages/dashboard';
import { SelectedExistingClaim } from '../pages/dashboard/existing/connected-selected-existing-claim';
import { LoginPage } from '../pages/postlogin';
import { RetirementAnnuityClaim } from '../pages/ra-death-claim/retirement-annuity-claim';
import { DeathClaim } from '../pages/death-claim/death-claim';
import { FuneralClaim } from '../pages/funeral/funeral-claim';
import authRequired from './auth-required';
import { LivingLifeStyleClaim } from '../pages/living-lifestyle/living-lifestyle-claim';

export const staticRoutes = (
    <Switch>
        <Route exact path="/" component={authRequired(LoginPage)} />
        <Route path="/claim" component={authRequired(SelectedExistingClaim)} />
        <Route path="/dashboard" component={authRequired(ClaimSelection)} />
        <Route path="/death-claim" component={authRequired(DeathClaim)} />
        <Route path="/funeral-claim" component={authRequired(FuneralClaim)} />
        <Route path="/retirement-annuity" component={authRequired(RetirementAnnuityClaim)} />
        <Route path="/living-lifestyle/living-lifestyle-claim" component={authRequired(LivingLifeStyleClaim)} />
    </Switch>
);

export default staticRoutes;
