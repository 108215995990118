import { Grid, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import { OutlinedPhoneField, OutlinedTextField } from '../../../forms';
import { FixedWidthFileUpload } from '../../../forms/form-components/fixed-width-file-upload';
import { FullNameMask, NumberMask } from '../../../forms/input-masks';
import { composeValidators, requiredValidator } from '../../../forms/validations';
import styles from '../../../styles/styles';
import { BankAccountDetailsView } from '../../common/bank-account-details';
import { validateRSAIdNumber } from '../../common/identity-details';

const PaymentDetailsView = ({ classes, prefix = '', ...rest }) => {
    const { index, stepsLength, editable } = rest;
    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
                <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                <Typography className={classes.stepHeader}>Payment Details</Typography>
            </Grid>
            <Grid item xs={12}>
                <OutlinedTextField
                    name={`${prefix}name`}
                    label="Account Holders Name"
                    disabled={!editable}
                    mask={FullNameMask}
                    validate={requiredValidator}
                    labelprefixed="true"
                />
            </Grid>
            <Grid item xs={12}>
                <OutlinedTextField
                    name={`${prefix}idNumber`}
                    label="Account Holder's ID Number"
                    validate={composeValidators(requiredValidator, validateRSAIdNumber)}
                    mask={NumberMask}
                    disabled={!editable}
                    labelprefixed="true"
                />
            </Grid>
            <Grid item xs={12}>
                <OutlinedPhoneField
                    name={`${prefix}telephoneNumber`}
                    label="Account Holder's Telephone Number"
                    disabled={!editable}
                />
            </Grid>
            <Grid item xs={12}>
                <OutlinedPhoneField
                    name={`${prefix}cellphoneNumber`}
                    label="Account Holder's Cellphone Number"
                    disabled={!editable}
                />
            </Grid>
            <BankAccountDetailsView {...{ prefix, editable }} />
            <Grid item xs={12}>
                <FixedWidthFileUpload
                    name={`${prefix}proofOfBankAccount`}
                    validate={requiredValidator}
                    labelprefixed="true"
                    readonly={!editable}
                />
            </Grid>
        </React.Fragment>
    );
};

export const PaymentDetails = withStyles(styles)(PaymentDetailsView);
