import { withStyles, Typography, Grid } from '@material-ui/core';
import React from 'react';
import {
    Condition,
    OutlinedSearchSelectField,
    OutlinedTextField,
    True,
    WhenFieldChanges,
} from '../../../forms';
import { requiredValidator } from '../../../forms/validations';
import { NaturalRelationships } from '../../../shared/lookups';
import styles from '../../../styles/styles';
import { ClaimantNavigation } from '../../common/navigation/claimant-navigation';
import { PersonDetails } from '../common/person-details';

const guardianDetails = function ({ classes, ...rest }) {
    const { index, stepsLength, editable, claimantIndex } = rest;
    const prefix = `claimants[${claimantIndex}].guardianDetails.`;
    return (
        <Grid container spacing={10} justify="flex-start">
            <Grid item xs={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
                <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                <Typography className={classes.stepHeader}>Guardian Details</Typography>
            </Grid>
            <Grid item xs={12}>
                <OutlinedSearchSelectField
                    name={`${prefix}relationshipToMinor`}
                    options={NaturalRelationships}
                    label="Relationship to Deceased"
                    validate={requiredValidator}
                    labelprefixed="true"
                    disabled={!editable}
                />
            </Grid>
            <WhenFieldChanges
                field={`${prefix}relationshipToMinor`}
                set={`${prefix}relationshipToMinorDescription`}
                to={null}
            />
            <Condition when={`${prefix}relationshipToMinor`} is="Other">
                <True>
                    <Grid item xs={12}>
                        <OutlinedTextField
                            name={`${prefix}relationshipToMinorDescription`}
                            label="Description"
                            validate={requiredValidator}
                            labelprefixed="true"
                            disabled={!editable}
                        />
                    </Grid>
                </True>
            </Condition>
            <PersonDetails editable={editable} prefix={prefix} />
            <ClaimantNavigation {...rest} prefix={`claimant-${claimantIndex}-guardian-details`} />
        </Grid>
    );
};

export const GuardianDetails = withStyles(styles)(guardianDetails);
