import { withStyles, Typography, Grid } from '@material-ui/core';
import React from 'react';
import {
    Condition,
    False,
    OutlinedPhoneField,
    OutlinedSearchSelectField,
    OutlinedTextField,
    True,
    WhenFieldChanges,
} from '../../../forms';
import { requiredValidator } from '../../../forms/validations';
import { getSouthAfricanAddress } from '../../../shared/helper-functions';
import styles from '../../../styles/styles';
import { DeceasedNavigation } from '../../common/navigation/deceased-navigation';
import { FuneralAddressDetails } from '../common/funeral-address-details';

const OccupationStatuses = [
    { value: 'Employed', label: 'Employed' },
    { value: 'Scholar', label: 'Scholar' },
    { value: 'Student', label: 'Student' },
    { value: 'Unemployed', label: 'Unemployed' },
];
const occupationDetails = function ({
    classes,
    handleBack,
    handleBackStepper,
    handleNext,
    form,
    index,
    stepsLength,
    editable,
}) {
    const prefix = 'deceasedDetails.occupationDetails.';
    return (
        <Grid container justify="flex-start">
            <Grid item xs={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
                <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                <Typography className={classes.stepHeader}>Occupation Details</Typography>
            </Grid>
            <Grid item xs={12}>
                <OutlinedSearchSelectField
                    name={`${prefix}status`}
                    label="What was the deceased occupation status?"
                    placeholder="occupation status"
                    labelprefixed="true"
                    options={OccupationStatuses}
                    validate={requiredValidator}
                    disabled={!editable}
                />
                <WhenFieldChanges
                    field={`${prefix}status`}
                    set={`${prefix}addressDetails`}
                    to={setAddress}
                />
                <WhenFieldChanges
                    field={`${prefix}status`}
                    condition={isNotEmployed}
                    set={`${prefix}occupation`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${prefix}status`}
                    becomes="Unemployed"
                    set={`${prefix}institution`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${prefix}status`}
                    becomes="Unemployed"
                    set={`${prefix}contactPerson`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${prefix}status`}
                    becomes="Student"
                    set={`${prefix}contactPerson`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${prefix}status`}
                    becomes="Unemployed"
                    set={`${prefix}telephoneNumber`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${prefix}status`}
                    condition={isNotStudent}
                    set={`${prefix}studentNumber`}
                    to={null}
                />
            </Grid>
            <Condition when={`${prefix}status`} is="Employed">
                <True>
                    <Grid item xs={12}>
                        <OutlinedTextField
                            name={`${prefix}occupation`}
                            label="Occupation at date of death"
                            validate={requiredValidator}
                            labelprefixed="true"
                            disabled={!editable}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <OutlinedTextField
                            name={`${prefix}institution`}
                            label="Employer Name"
                            validate={requiredValidator}
                            labelprefixed="true"
                            disabled={!editable}
                        />
                    </Grid>
                    <SubHeading classes={classes} heading="Contact Details" />
                    <Grid item xs={12}>
                        <OutlinedTextField
                            name={`${prefix}contactPerson`}
                            label="Manager's Name"
                            disabled={!editable}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <OutlinedPhoneField
                            name={`${prefix}telephoneNumber`}
                            label="Work Number"
                            disabled={!editable}
                        />
                    </Grid>
                    <SubHeading classes={classes} heading="Work Address" />
                </True>
            </Condition>
            <Condition when={`${prefix}status`} is="Student">
                <True>
                    <Grid item xs={12}>
                        <OutlinedTextField
                            name={`${prefix}studentNumber`}
                            label="Student Number"
                            disabled={!editable}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <OutlinedTextField
                            name={`${prefix}institution`}
                            label="Institution Name"
                            validate={requiredValidator}
                            labelprefixed="true"
                            disabled={!editable}
                        />
                    </Grid>
                    <SubHeading classes={classes} heading="Contact Details" />
                    <Grid item xs={12}>
                        <OutlinedTextField
                            name={`${prefix}contactPerson`}
                            label="Contact Person's Name"
                            disabled={!editable}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <OutlinedPhoneField
                            name={`${prefix}telephoneNumber`}
                            label="Institution's Number"
                            disabled={!editable}
                        />
                    </Grid>
                    <SubHeading classes={classes} heading="Institution Address" />
                </True>
            </Condition>
            <Condition when={`${prefix}status`} is="Scholar">
                <True>
                    <Grid item xs={12}>
                        <OutlinedTextField
                            name={`${prefix}studentNumber`}
                            label="Scholar Number"
                            disabled={!editable}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <OutlinedTextField
                            name={`${prefix}institution`}
                            label="School Name"
                            validate={requiredValidator}
                            labelprefixed="true"
                            disabled={!editable}
                        />
                    </Grid>
                    <SubHeading classes={classes} heading="Contact Details" />
                    <Grid item xs={12}>
                        <OutlinedTextField
                            name={`${prefix}contactPerson`}
                            label="Principal's Name"
                            disabled={!editable}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <OutlinedPhoneField
                            name={`${prefix}telephoneNumber`}
                            label="School's Number"
                            disabled={!editable}
                        />
                    </Grid>
                    <SubHeading classes={classes} heading="School Address" />
                </True>
            </Condition>
            <Condition when={`${prefix}status`} is="Unemployed">
                <False>
                    <FuneralAddressDetails
                        editable={editable}
                        prefix={`${prefix}addressDetails.`}
                    />
                </False>
            </Condition>
            <DeceasedNavigation
                form={form}
                handleBack={handleBack}
                handleBackStepper={handleBackStepper}
                handleNext={handleNext}
                prefix="deceased-occupation"
            />
        </Grid>
    );
};

const SubHeading = ({ classes, heading }) => {
    return (
        <Grid item xs={12}>
            <Typography className={classes.subHeading2} style={{ marginTop: '20px' }}>
                {heading}
            </Typography>
        </Grid>
    );
};

export function setAddress(status, address) {
    if (status === 'Unemployed') {
        return null;
    }
    return {
        ...address,
        ...getSouthAfricanAddress(),
    };
}

export function isNotStudent(status) {
    return status !== 'Student' && status !== 'Scholar';
}

export function isNotEmployed(status) {
    return status !== 'Employed';
}

export const OccupationDetails = withStyles(styles)(occupationDetails);
