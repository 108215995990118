import React from 'react';
import { withStyles, Grid, Typography } from '@material-ui/core';
import styles from '../../../styles/styles';
import { LabelValue } from '../../common/label-value';
import { CalculateAge } from '../../../shared/helper-functions';

const contactDetailsSummary = function ({ claimant }) {
    const individual = claimant.individual;
    const guardian = claimant.guardian;

    return (
        <Grid container justify="flex-start">
            <Grid item xs={12} style={{ marginTop: '24px' }}>
                <Typography variant="h6">Contact Details</Typography>
                <hr />
            </Grid>
            {individual && CalculateAge(individual.dateOfBirth) > 17 && (
                <React.Fragment>
                    <Grid xs={12} sm={12}>
                        <LabelValue label="Cellphone Number" value={individual.cellphoneNumber} />
                        <LabelValue label="Work Number" value={individual.workPhone} />
                        <LabelValue label="Home Number" value={individual.homeNumber} />
                        <LabelValue label="Telephone Number" value={individual.telephoneNumber} />
                        <LabelValue label="Fax Number" value={individual.faxNumber} />
                        <LabelValue label="Email Address" value={individual.emailAddress} />
                        <LabelValue
                            label="Preferred Method of Communication"
                            value={individual.preferredMethodOfCommunication}
                        />
                    </Grid>
                </React.Fragment>
            )}
            {guardian && (
                <React.Fragment>
                    <Grid xs={12} sm={12}>
                        <LabelValue label="Cellphone Number" value={guardian.cellphoneNumber} />
                        <LabelValue label="Work Number" value={guardian.workPhone} />
                        <LabelValue label="Home Number" value={guardian.homeNumber} />
                        <LabelValue label="Telephone Number" value={guardian.telephoneNumber} />
                        <LabelValue label="Fax Number" value={guardian.faxNumber} />
                        <LabelValue label="Email Address" value={guardian.emailAddress} />
                        <LabelValue
                            label="Preferred Method of Communication"
                            value={guardian.preferredMethodOfCommunication}
                        />
                    </Grid>
                </React.Fragment>
            )}
        </Grid>
    );
};

export const ContactDetailsSummary = withStyles(styles)(contactDetailsSummary);
