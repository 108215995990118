import * as React from 'react';
import { Grid, Paper, Typography, withStyles } from '@material-ui/core';
import styles from '../../styles/styles';
import { DownloadFile } from '../common/introduction/download-file';
import { IntroductionHeader } from '../common/introduction/introduction-header';
import { StepDisclaimer } from '../common/introduction/step-disclaimer';
import { StepsInformation } from '../common/introduction/steps-information';
import { IntroNavigation } from '../common/navigation/intro-navigation';
import { deathComponents } from './death-claim';

const { env } = require('../../env/env');

const introduction = function ({ classes, editable, form, handleSubmit }) {
    return (
        <Paper className={[classes.shadedPaper, classes.paperShadowLess].join(' ')}>
            <Grid container spacing={0} justify="flex-start">
                <IntroductionHeader claim="Non-Retirement Annuity Death" policy="Liberty" />
                <DownloadFile
                    url={env.NonRA_Checklist_URL}
                    filename="Claim requirements checklist"
                />
                <StepsInformation components={deathComponents} />
                <StepDisclaimer name="Deceased Details" />
                <Grid item xs={12} className={classes.emptyBlock}>
                    <div className={classes.headingNoBg}>1. Death Certificate</div>
                    <div className={classes.bodyNoBg}>Issued by the Department of Home Affairs</div>
                    <hr />
                </Grid>
                <Grid item xs={12} className={classes.emptyBlock}>
                    <div className={classes.headingNoBg}>2. Notice of Death </div>
                    <div className={classes.bodyNoBg}>
                        Issued by the doctor who certified the Death or by the undertaker
                    </div>
                    <hr />
                </Grid>
                <Grid item xs={12} className={classes.emptyBlock}>
                    <div className={classes.headingNoBg}>3. Police Statement</div>
                    <div className={classes.bodyNoBg}>
                        Only needed in the event of unnatural Death, completed by investigating
                        officer
                        <DownloadFile
                            url={env.REACT_APP_DOWNLOAD_URL}
                            filename="Statement by Police form"
                        />
                    </div>
                    <hr />
                </Grid>
                <Grid item xs={12} className={classes.emptyBlock}>
                    <div className={classes.headingNoBg}>4. Extract of Medical History</div>
                    <div className={classes.bodyNoBg}>
                        Personal medical attendant's report, if a policy is less than 3 years
                    </div>
                    <hr />
                </Grid>
                <Grid item xs={12} className={classes.emptyBlock}>
                    <div className={classes.headingNoBg}>5. Accident Report</div>
                    <div className={classes.bodyNoBg}>
                        Only needed in the event of unnatural Death
                    </div>
                    <hr />
                </Grid>
                <StepDisclaimer name="Claimant Details" />
                <Grid item xs={12} className={classes.emptyBlock}>
                    <div className={classes.headingNoBg}>
                        1. Identity document, ID card, passport or unabridged birth certificate
                    </div>
                    <div className={classes.bodyNoBg}>
                        A certified copy to be provided by all Claimants - if it is an ID card, a
                        copy of the back and the front of the ID smart card must be provided or a
                        copy of the <b>unabridged birth certificate</b> if claimant is a minor
                    </div>
                    <hr />
                </Grid>

                <Grid item xs={12} className={classes.emptyBlock}>
                    <div className={classes.headingNoBg}>2. Proof of Guardianship</div>
                    <div className={classes.bodyNoBg}>
                        If the claimant is a minor we require <b>proof of guardianship </b>
                        or a <b>letter from a registered social worker</b>
                    </div>
                    <hr />
                </Grid>
                <Grid item xs={12} className={classes.emptyBlock}>
                    <div className={classes.headingNoBg}>3. Entity Documentation</div>
                    <div className={classes.bodyNoBg}>
                        If the claimant is an entity, we require the following, but is not limited
                        to :
                    </div>
                    <div>
                        <ul>
                            <li className={classes.listItem}>Closed Corporation</li>
                            <ul>
                                <li className={classes.subListItem}>CK1 Document</li>
                            </ul>
                        </ul>
                        <ul>
                            <li className={classes.listItem}>Foreign Company</li>
                            <ul>
                                <li className={classes.subListItem}>Company Resolution</li>
                                <li className={classes.subListItem}>
                                    ID Documents of the Members of the Company
                                </li>
                            </ul>
                        </ul>
                        <ul>
                            <li className={classes.listItem}>Foreign Trust</li>
                            <ul>
                                <li className={classes.subListItem}>
                                    Trust Resolution or Trust Deed
                                </li>
                                <li className={classes.subListItem}>
                                    Letter of Authority reflecting all trustee names
                                </li>
                            </ul>
                        </ul>
                        <ul>
                            <li className={classes.listItem}>Non Profit Organisation</li>
                            <ul>
                                <li className={classes.subListItem}>CK1 Document</li>
                            </ul>
                        </ul>
                        <ul>
                            <li className={classes.listItem}>Personal Liability Company</li>
                            <ul>
                                <li className={classes.subListItem}>Company Resolution</li>
                                <li className={classes.subListItem}>
                                    ID Documents of the Members of the Company
                                </li>
                            </ul>
                        </ul>
                        <ul>
                            <li className={classes.listItem}>Private Company</li>
                            <ul>
                                <li className={classes.subListItem}>Company Resolution</li>
                                <li className={classes.subListItem}>
                                    ID Documents of the Members of the Company
                                </li>
                            </ul>
                        </ul>
                        <ul>
                            <li className={classes.listItem}>Public Company</li>
                            <ul>
                                <li className={classes.subListItem}>Company Resolution</li>
                                <li className={classes.subListItem}>
                                    ID Documents of the Members of the Company
                                </li>
                            </ul>
                        </ul>
                        <ul>
                            <li className={classes.listItem}> State Owned Company</li>

                            <ul>
                                <li className={classes.subListItem}>Company Resolution</li>
                                <li className={classes.subListItem}>
                                    ID Documents of the Members of the Company
                                </li>
                            </ul>
                        </ul>
                        <ul>
                            <li className={classes.listItem}>Trust</li>
                            <ul>
                                <li className={classes.subListItem}>
                                    Trust Resolution or Trust Deed
                                </li>
                                <li className={classes.subListItem}>
                                    Letter of Authority reflecting all trustee names
                                </li>
                            </ul>
                        </ul>
                    </div>
                    <hr />
                </Grid>
                <StepDisclaimer name="Executor Details" />
                <Grid item xs={12} className={classes.emptyBlock}>
                    <div className={classes.headingNoBg}>1. Letter of Executorship</div>
                    <div className={classes.bodyNoBg}>
                        This is obtrained from the Office of the Master of High Court
                    </div>
                    <hr />
                </Grid>
                <Grid item xs={12} className={classes.grayTitle}>
                    <Typography className={classes.grayTitleText}>
                        Track the progress of your claim
                    </Typography>
                </Grid>
                <div className={classes.bodyNoBg} style={{ marginTop: '15px' }}>
                    Once you’ve submitted the claim, you’ll get an email from Liberty to confirm we
                    have received it. We will keep you updated on the progress of the claim by email
                    and you can log in here again to see the progress of your claim or upload any
                    outstanding documentation.
                </div>
                <DownloadFile
                    url={env.NonRA_Checklist_URL}
                    filename="Claim requirements checklist"
                />
                <IntroNavigation editable={editable} form={form} handleSubmit={handleSubmit} />
            </Grid>
        </Paper>
    );
};

export const Introduction = withStyles(styles)(introduction);
