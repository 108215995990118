import React from 'react';
import { withStyles, Typography, Grid } from '@material-ui/core';
import styles from '../../../styles/styles';
import { Condition, True, WhenFieldChanges } from '../../../forms';
import { NaturalCausesOfDeath, UnnaturalCausesOfDeath } from '../../../shared/lookups';
import { requiredValidator } from '../../../forms/validations';
import {
    OutlinedDatePicker,
    OutlinedSearchSelectField,
    OutlinedTextField,
    FixedWidthButtonGroup,
} from '../../../forms';
import { FixedWidthFileUpload } from '../../../forms/form-components/fixed-width-file-upload';
import { DeceasedNavigation } from '../../common/navigation/deceased-navigation';

const detailsOfDeath = function ({
    classes,
    form,
    handleBack,
    handleBackStepper,
    handleNext,
    index,
    stepsLength,
    editable,
}) {
    const prefix = 'deceasedDetails.detailsOfDeath.';
    return (
        <Grid container spacing={10} justify="flex-start">
            <Grid item xs={12} lg={12}>
                <Typography className={classes.utilSteps}>
                    {' '}
                    Step {index + 1} of {stepsLength}
                </Typography>
                <Grid item xs={12} lg={12} style={{ display: 'flex' }}>
                    <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                    <Typography className={classes.stepHeader}>Details Of Death</Typography>
                </Grid>
                <Grid item xs={12} lg={12}>
                    <OutlinedDatePicker
                        name={`${prefix}dateOfDeath`}
                        label="Date of Death"
                        validate={requiredValidator}
                        labelprefixed="true"
                        disabled={!editable}
                        disableFuture
                    />
                </Grid>
                <Grid item xs={12} lg={12}>
                    <OutlinedTextField
                        name={`${prefix}placeOfDeath`}
                        label="Place of Death"
                        validate={requiredValidator}
                        labelprefixed="true"
                        disabled={!editable}
                    />
                </Grid>
                <FixedWidthButtonGroup
                    label="Is there a last will and testament?"
                    name={`${prefix}hasLastWillAndTestament`}
                    options={['Yes', 'No']}
                    validate={requiredValidator}
                    labelprefixed="true"
                    disabled={!editable}
                />
                <WhenFieldChanges
                    field={`${prefix}hasLastWillAndTestament`}
                    set={`${prefix}dateOfWill`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${prefix}hasLastWillAndTestament`}
                    set={`${prefix}willAndTestamentAffidavit`}
                    to={null}
                />
                <Condition when={`${prefix}hasLastWillAndTestament`} is="Yes">
                    <True>
                        <Grid item xs={12} lg={12}>
                            <OutlinedDatePicker
                                name={`${prefix}dateOfWill`}
                                label="Date of Will"
                                disabled={!editable}
                                validate={requiredValidator}
                                labelprefixed="true"
                                disableFuture
                            />
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <FixedWidthFileUpload
                                name={`${prefix}willAndTestamentAffidavit`}
                                label="Upload Will"
                                validate={requiredValidator}
                                labelprefixed="true"
                                readonly={!editable}
                            />
                        </Grid>
                    </True>
                </Condition>
                <Condition when={`${prefix}hasLastWillAndTestament`} is="No">
                    <True>
                        <Grid item xs={12} lg={12}>
                            <FixedWidthFileUpload
                                name={`${prefix}willAndTestamentAffidavit`}
                                label="Upload affidavit if no Will"
                                labelprefixed="true"
                                validate={requiredValidator}
                                readonly={!editable}
                            />
                        </Grid>
                    </True>
                </Condition>

                <FixedWidthButtonGroup
                    label="Type of Death"
                    name={`${prefix}conditionType`}
                    options={['Natural', 'Unnatural']}
                    validate={requiredValidator}
                    labelprefixed="true"
                    disabled={!editable}
                />
                <WhenFieldChanges
                    field={`${prefix}conditionType`}
                    set={`${prefix}condition`}
                    to={null}
                />

                <Condition when={`${prefix}conditionType`} is="Natural">
                    <True>
                        <Grid item xs={12} lg={12}>
                            <OutlinedSearchSelectField
                                name={`${prefix}condition`}
                                options={NaturalCausesOfDeath}
                                validate={requiredValidator}
                                labelprefixed="true"
                                disabled={!editable}
                            />
                        </Grid>
                    </True>
                </Condition>
                <Condition when={`${prefix}conditionType`} is="Unnatural">
                    <True>
                        <Grid item xs={12} lg={12}>
                            <OutlinedSearchSelectField
                                name={`${prefix}condition`}
                                options={UnnaturalCausesOfDeath}
                                validate={requiredValidator}
                                labelprefixed="true"
                                disabled={!editable}
                            />
                        </Grid>
                    </True>
                </Condition>
                <WhenFieldChanges
                    field={`${prefix}condition`}
                    set={`${prefix}description`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${prefix}conditionType`}
                    becomes="Unnatural"
                    set={`${prefix}reportedToPolice`}
                    to="No"
                />
                <Condition when={`${prefix}condition`} is={['798', '799']}>
                    <True>
                        <Grid item xs={12} lg={12}>
                            <OutlinedTextField
                                name={`${prefix}description`}
                                label="Description"
                                disabled={!editable}
                            />
                        </Grid>
                    </True>
                </Condition>
                <Condition when={`${prefix}conditionType`} is="Unnatural">
                    <True>
                        <FixedWidthButtonGroup
                            label="Was the death reported to the police?"
                            name={`${prefix}reportedToPolice`}
                            options={['Yes', 'No']}
                            validate={requiredValidator}
                            labelprefixed="true"
                            disabled={!editable}
                        />
                        <Condition when={`${prefix}reportedToPolice`} is="Yes">
                            <True>
                                <Grid item xs={12} lg={12}>
                                    <OutlinedTextField
                                        name={`${prefix}caseNumber`}
                                        label="Enter Case Number"
                                        validate={requiredValidator}
                                        labelprefixed="true"
                                        disabled={!editable}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <FixedWidthFileUpload
                                        name={`${prefix}policeReport`}
                                        label="Upload Police Report"
                                        validate={requiredValidator}
                                        labelprefixed="true"
                                        readonly={!editable}
                                    />
                                </Grid>
                            </True>
                        </Condition>
                    </True>
                </Condition>

                <WhenFieldChanges
                    field={`${prefix}reportedToPolice`}
                    set={`${prefix}caseNumber`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${prefix}reportedToPolice`}
                    set={`${prefix}policeReport`}
                    to={null}
                />
            </Grid>
            <Grid item xs={12} lg={12}>
                <FixedWidthFileUpload
                    name={`${prefix}deathCertificate`}
                    label="Upload Death Certificate"
                    validate={requiredValidator}
                    labelprefixed="true"
                    readonly={!editable}
                />
                <FixedWidthFileUpload
                    name={`${prefix}noticeOfDeath`}
                    label="Notice of Death (BI1663/DHA1663)"
                    validate={requiredValidator}
                    labelprefixed="true"
                    readonly={!editable}
                />
            </Grid>
            <DeceasedNavigation
                form={form}
                handleBack={handleBack}
                handleBackStepper={handleBackStepper}
                handleNext={handleNext}
                prefix="deceased-death"
            />
        </Grid>
    );
};
export const DetailsOfDeath = withStyles(styles)(detailsOfDeath);
