import * as React from 'react';
import { Grid, withStyles } from '@material-ui/core';
import styles from '../../../styles/styles';
import { PersonalDetailsSummary } from './personal-details';
import { GuardianDetailsSummary } from './guardian-details';
import { ContactDetailsSummary } from './contact-details';
import { IndividualAddressSummary } from './address-details/address-details';
import { FinancialDetailsSummary } from './financial-details';
import { ClaimantTypeSummary } from './claimant-type';

const individualDetailsView = function ({ claimant }) {
    if (!claimant) {
        return null;
    }

    return (
        <Grid container justify="flex-start">
            <Grid sx={12} sm={12}>
                <ClaimantTypeSummary claimant={claimant} />
                <PersonalDetailsSummary claimant={claimant} />
                <GuardianDetailsSummary claimant={claimant} />
                <ContactDetailsSummary claimant={claimant} />
                <IndividualAddressSummary claimant={claimant} />
                <FinancialDetailsSummary claimant={claimant} />
            </Grid>
        </Grid>
    );
};

export const IndividualDetailsSummary = withStyles(styles)(individualDetailsView);
