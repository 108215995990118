import { LOAD_POSTAL_CODES } from './postal-codes';
import { LOAD_UNIVERSAL_BRANCHES, LOAD_BANK_NAMES } from './bank-branch-codes';

export const initialLookups = {
    postalCodesBox: [],
    postalCodesStreet: [],
    universalBranches: [],
    bankNames: [],
};

export function lookupReducer(state = initialLookups, action) {
    switch (action.type) {
        case LOAD_POSTAL_CODES:
            return {
                ...state,
                ...action.lookups,
            };
        case LOAD_BANK_NAMES:
            return {
                ...state,
                ...action.lookups
            };
        case LOAD_UNIVERSAL_BRANCHES:
            return {
                ...state,
                ...action.lookups
            };
        default:
            return state;
    }
}

export function getLookups(state) {
    return state.lookups || initialLookups;
}
