import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from '../../../../styles/styles';
import classNames from 'classnames';
import { ClaimantNavigation } from '../../../common/navigation/claimant-navigation';
import { NavigationButton } from '../../../common/navigation/navigation-button';
import { FirstNameMask } from '../../../../forms/input-masks';
import { requiredValidator } from '../../../../forms/validations';
import { Condition, FixedWidthButtonGroup, OutlinedTextField, True } from '../../../../forms';
import { NumberCounterText } from '../../../../forms/form-components/number-input';

const otherDetails = ({
    classes,
    handleBack,
    index,
    editable,
    handleBackStepper,
    handleNext,
    stepsLength,
    form,
}) => {
    const prefix = 'lifeAssured.OtherDetails.';
    const onValueChange = (e) => {
        return e;
    };

    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
            </Grid>
            <Grid item xs={12} container justify="flex-start">
                <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                <Typography className={classes.stepHeader}>Other Details</Typography>
            </Grid>
            <Grid item xs={12}>
                <OutlinedTextField
                    name={`${prefix}highestQualification`}
                    label="What is your highest qualification?"
                    mask={FirstNameMask}
                    validate={requiredValidator}
                    labelprefixed="true"
                    disabled={!editable}
                    placeholder=''/>
                <FixedWidthButtonGroup
                    name={`${prefix}hasInsolvency`}
                    label="Have you ever been declared insolvent or are sequestration hearing proceedings, pending or completed?"
                    options={['Yes', 'No']}
                    disabled={!editable}
                    validate={requiredValidator}
                    labelprefixed="true"
                />
                <FixedWidthButtonGroup
                    name={`${prefix}consumesAlcohol`}
                    label="Do you consume alcohol?"
                    options={['Yes', 'No']}
                    disabled={!editable}
                    validate={requiredValidator}
                    labelprefixed="true"
                    helperText={
                        <>
                            <span style={{ color: '#002B99' }}>&#9432;</span> Note: 1 bottle of spirits = 21 tots, 1 bottle of wine = 6 wine glasses
                        </>
                    }
                />
                <Condition when={`${prefix}consumesAlcohol`} is="Yes">
                    <True>
                        <NumberCounterText
                            name={`${prefix}totCount`}
                            label='How many spirits and tots per week?'
                            validate={requiredValidator}
                            labelprefixed="true"
                            onChange = {onValueChange}
                        />
                        <NumberCounterText
                            name={`${prefix}wineGlassCount`}
                            label='How many wines in a glass per week?'
                            validate={requiredValidator}
                            labelprefixed="true"
                            onChange = {onValueChange}
                        />
                        <NumberCounterText
                            name={`${prefix}beerCanOrCider`}
                            label='How many beers/ciders in a can/bottle per week?'
                            validate={requiredValidator}
                            labelprefixed="true"
                            onChange = {onValueChange}
                        />
                    </True>
                </Condition>
                <FixedWidthButtonGroup
                    name={`${prefix}receivedAdviceToReduceAlcohol`}
                    label="Have you ever received advice to reduce or discontinue alcohol consumption,
                           or have you ever been charged with drunken driving?"
                    options={['Yes', 'No']}
                    disabled={!editable}
                    validate={requiredValidator}
                    labelprefixed="true"
                />
                <Condition when={`${prefix}receivedAdviceToReduceAlcohol`} is="Yes">
                    <True>
                        <OutlinedTextField
                            name={`${prefix}alcoholAdviceDetails`}
                            label="Please provide full details"
                            validate={requiredValidator}
                            labelprefixed="true"
                            disabled={!editable}
                            multiline
                            placeholder='' />
                    </True>
                </Condition>
                <FixedWidthButtonGroup
                    name={`${prefix}practiceAvocation`}
                    label="Do you currently practice any avocation/s e.g scuba diving, flying, etc"
                    options={['Yes', 'No']}
                    disabled={!editable}
                    validate={requiredValidator}
                    labelprefixed="true"
                />
                <Condition when={`${prefix}practiceAvocation`} is="Yes">
                    <True>
                        <OutlinedTextField
                            name={`${prefix}practiceAvocationDetails`}
                            label="Please provide full details"
                            validate={requiredValidator}
                            labelprefixed="true"
                            disabled={!editable}
                            multiline
                            placeholder='' />
                    </True>
                </Condition>
                <OutlinedTextField
                    name={`${prefix}hoursWorkedPerWeek`}
                    label="Please provide the average number of hours that you were working per week,
                           for three month prior to the date of the claim event"
                    validate={requiredValidator}
                    labelprefixed="true"
                    disabled={!editable}
                    placeholder=''/>
                <FixedWidthButtonGroup
                    name={`${prefix}wasRetired`}
                    label="Were you partially or fully retired at the date of the claim event?"
                    options={['Yes', 'No']}
                    disabled={!editable}
                    validate={requiredValidator}
                    labelprefixed="true"
                />
                <Condition when={`${prefix}wasRetired`} is="Yes">
                    <True>
                        <OutlinedTextField
                            name={`${prefix}retirementFullDetails`}
                            label="Please provide full details"
                            validate={requiredValidator}
                            labelprefixed="true"
                            disabled={!editable}
                            placeholder='' />
                    </True>
                </Condition>
            </Grid>
            <ClaimantNavigation
                form={form}
                handleBackStepper={handleBackStepper}
                handleNext={handleNext}
                prefix={'lifeAssured.otherDetails'}
            />
            <Grid item xs={12} className={classes.navigationGroup}>
                <NavigationButton
                    className={classNames(
                        classes.buttonSecondary,
                        classes.buttonAlignment,
                        classes.fullWidthButton
                    )}
                    onClick={() => handleBack(form)}
                    id="other-details-back"
                    label="Previous Section"
                />
            </Grid>
        </React.Fragment>
    );
};

export const OtherDetails = withStyles(styles)(otherDetails);
