import { createStyles } from '@material-ui/core/styles';

export function styles(theme) {
    return createStyles({
        clearOverlay: clearOverlay(),
        greyOverlay: greyOverlay(),
    });
}

function clearOverlay() {
    return {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        zIndex: 2000,
    };
}

function greyOverlay() {
    return {
        ...clearOverlay(),
        backgroundColor: 'black',
        opacity: 0.4,
    };
}
