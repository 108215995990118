import { Grid, Paper, withStyles } from '@material-ui/core';
import React from 'react';
import { Condition, False, True } from '../../../forms';
import styles from '../../../styles/styles';
import { DeceasedNavigation } from '../../common/navigation/deceased-navigation';
import { VerticalStepper } from '../../common/vertical-stepper';
import { AddressDetails } from '../common/address-details';
import { ContactDetails } from '../common/contact-details';
import { PaymentDetails } from '../common/payment-details';
import { PersonalDetails } from './personal-details';

export const OwnerAddressDetails = withStyles(styles)(function ({ ...props }) {
    const prefix = 'policyOwner.addressDetails.';
    return (
        <Grid container justify="flex-start">
            <Condition when="policyOwner.personalDetails.employed" is="Yes">
                <True>
                    <AddressDetails {...props} prefix={prefix} isWorkAddress />
                </True>
                <False>
                    <AddressDetails {...props} prefix={prefix} />
                </False>
            </Condition>
            <DeceasedNavigation {...props} prefix="dec-contact-details" />
        </Grid>
    );
});

export const OwnerContactDetails = withStyles(styles)(function ({ ...props }) {
    return (
        <Grid container justify="flex-start">
            <ContactDetails {...props} prefix="policyOwner." />
            <DeceasedNavigation {...props} prefix="dec-address-details" />
        </Grid>
    );
});

export const OwnerPaymentDetails = withStyles(styles)(function ({ ...props }) {
    return (
        <Grid container justify="flex-start">
            <PaymentDetails {...props} prefix="policyOwner.bankingDetails." />
            <DeceasedNavigation {...props} prefix="dec-payment-details" />
        </Grid>
    );
});

const verticalComponents = [
    {
        name: 'Personal Details',
        description: 'Personal details of the policy owner',
        component: PersonalDetails,
    },
    {
        name: 'Contact Details',
        description: 'Provide required contact details of the policy owner',
        component: OwnerContactDetails,
    },
    {
        name: 'Address Details',
        description: 'Provide all address details of the policy owner',
        component: OwnerAddressDetails,
    },
    {
        name: 'Payment Details',
        description: 'Provide banking details of the policy owner',
        component: OwnerPaymentDetails,
    },
];

const policyOwner = function ({ classes, form, handleBack, handleSubmit, ...rest }) {
    return (
        <Paper className={[classes.shadedPaper, classes.paperShadowLess].join(' ')}>
            <Grid container spacing={10} justify="flex-start">
                <Grid item xs={12}>
                    <VerticalStepper
                        {...rest}
                        verticalComponents={verticalComponents}
                        verticalIndexName={'verticalStepIndex'}
                        form={form}
                        handleBack={handleBack}
                        handleSubmit={handleSubmit}
                    />
                </Grid>
            </Grid>
        </Paper>
    );
};

export const PolicyOwner = withStyles(styles)(policyOwner);
