import * as React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import {
    getDisplayDate,
    getLabel,
    getClaimant,
} from '../../../shared/helper-functions';
import { Titles, allRelationships } from '../../../shared/lookups';
import styles from '../../../styles/styles';
import { IdentitySummary } from '../../common/identity-details-summary';
import { IndividualAddressSummary } from './individual/address-details';
import { ContactDetailsSummary } from './individual/contact-details';
import { CuratorDetailsSummary } from './individual/curator-details';
import { FinancialDetailsSummary } from './individual/financial-details';
import { GuardianDetailsSummary } from './individual/guardian-details';
import { StudentScholarSummary } from './individual/student-details';
import { TaxInformationSummary } from './individual/tax-information';
import { OtherInformationSummary } from './common/other-information';
import { LabelValue } from '../../common/label-value';

const individualDetailsView = function ({ claimants, index }) {
    if (!claimants || claimants.length === 0 || index >= claimants.length) {
        return null;
    }

    const claimant = claimants[index];
    if (!claimant.individual) {
        return null;
    }
    const individual = claimant.individual;
    return (
        <Grid xs={12} sm={12}>
            {/* Claimant Details Starts Here */}
            <Grid item xs={12} style={{ marginTop: '24px' }}>
                <Typography variant="h6">Claimant Type</Typography>
                <hr />
            </Grid>
            <LabelValue label="Claimant Type" value={claimant.claimantType} />
            <LabelValue
                label="Relationship to Life Assured"
                value={getLabel(individual.relationshipToLifeAssured, allRelationships())}
            />
            <LabelValue
                label="Relationship to Life Assured Description"
                value={getLabel(
                    individual.relationshipToLifeAssuredDescription,
                    allRelationships()
                )}
            />
            <LabelValue label="In what capacity are you claiming?" value={claimant.claimCapacity} />
            <LabelValue label="Child Of" value={getClaimant(claimants, individual.childOf)} />
            {/* Personal Details Starts Here */}
            <Grid item xs={12} style={{ marginTop: '24px' }}>
                <Typography variant="h6">Personal Details</Typography>
                <hr />
            </Grid>
            <Grid xs={12} sm={12}>
                <LabelValue label="Title" value={getLabel(individual.title, Titles)} />
                <LabelValue label="First Name" value={individual.firstName} />
                <LabelValue label="Second Name" value={individual.secondName || '-'} />
                <LabelValue label="Surname" value={individual.lastName} />
                <LabelValue label="Initials" value={individual.initials} />
                <LabelValue label="Nationality" value={individual.nationality} />
                <IdentitySummary identityDetails={individual} />
                <LabelValue label="Gender" value={individual.gender} />
                <LabelValue label="Marital Status" value={individual.maritalStatus} />
                <LabelValue
                    label="Date of Marriage"
                    value={getDisplayDate(individual.dateOfMarriage)}
                />
                <LabelValue
                    label="Marriage Certificate"
                    value={individual.marriageCertificate}
                    isFile={true}
                />
                <LabelValue label="Date of divorce" value={getDisplayDate(individual.dateOfDivorce)} />
                <LabelValue label="Divorce decree" value={individual.divorceDecree} isFile={true} />
                <LabelValue
                    label="Date of Marriage to Life Assured"
                    value={getDisplayDate(individual.dateOfMarriageToLifeAssured)}
                />
                <LabelValue label="No. of Years Estranged" value={individual.yearsEstranged} />
                <LabelValue
                    label="Date of Divorce from Life Assured"
                    value={getDisplayDate(individual.dateOfDivorceFromLifeAssured)}
                />
                <LabelValue
                    label="Was there a settlement in the divorce?"
                    value={individual.divorceSettlement}
                />
                <LabelValue label="Has the claimant remarried?" value={individual.remarried} />
                <LabelValue
                    label="No. of Years in Relationship"
                    value={individual.yearsInRelationship}
                />
                <LabelValue label="Do you have a disability?" value={individual.hasDisability} />
                <LabelValue label="Disability Description" value={individual.disabilityDescription} />
            </Grid>

            <Grid xs={12} sm={12}>
                <StudentScholarSummary individual={individual} />
                <TaxInformationSummary individual={individual} />
                <CuratorDetailsSummary claimant={claimant} claimants={claimants} />
                <GuardianDetailsSummary claimant={claimant} claimants={claimants} />
                <ContactDetailsSummary claimant={claimant} claimants={claimants} />
                <IndividualAddressSummary claimant={claimant} claimants={claimants} />
                <FinancialDetailsSummary claimant={claimant} />
                <OtherInformationSummary claimant={claimant} />
            </Grid>
        </Grid>
    );
};

export const IndividualDetailsSummary = withStyles(styles)(individualDetailsView);
