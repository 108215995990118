import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    Typography,
    withStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as React from 'react';
import { connect } from 'react-redux';
import { getDisplayDate } from '../../../../shared/helper-functions';
import styles from '../../../../styles/styles';
import { LabelValue } from '../../../common/label-value';
import { IndividualAddressSummary } from '../address-details/address-details';

const policyHolderSummary = function ({ classes, policyholder }) {
    if (!policyholder || policyholder.length < 1) {
        return null;
    }
    return (
        <><Accordion
            style={{
                margin: '-24px -16px 24px -16px',
                borderRadius: '4px',
            }}
        >

            <AccordionSummary
                className={[classes.whiteBackground, classes.whiteTitle].join(' ')}
                expandIcon={<ExpandMoreIcon />}
                style={{ borderRadius: '4px' }}
            >
                <Typography className={classes.previewIndex}>2</Typography>
                <Typography className={classes.contentHeader}>Policyholder Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid xs={12} sm={12}>
                    <PolicyholderDetails
                        key={'policyHolderSummary'}
                        policyholder={policyholder}
                        classes={classes}
                        index={0} />
                </Grid>
            </AccordionDetails>
        </Accordion></>
    );
};

const PolicyholderDetails = ({ policyholder, classes, index }) => {
    return (
        <Accordion>

            <AccordionDetails>
                <Grid container justify="flex-start">
                    <Grid item xs={12} style={{ marginTop: '24px' }}>
                        <Typography variant="h6">Policy Details</Typography>
                        <hr />
                    </Grid>
                    {policyholder.policyHolderType === 'Individual' && <React.Fragment>
                        <LabelValue label="Policyholder Type" value={policyholder.policyHolderType} />
                        <LabelValue label="Title" value={policyholder.individual.personalDetails.title} />
                        <LabelValue label="First name" value={policyholder.individual.personalDetails.firstName} />
                        <LabelValue label="Surname" value={policyholder.individual.personalDetails.surname} />
                        <LabelValue label="Maiden Name" value={policyholder.individual.personalDetails.maiden} />
                        <LabelValue label="Gender" value={policyholder.individual.personalDetails.gender} />
                        <LabelValue label="Marital Status" value={policyholder.individual.personalDetails.maritalStatus} />
                        <LabelValue
                            label="Date Of Marriage"
                            value={getDisplayDate(policyholder.individual.personalDetails.dateOfMarriage)}
                        />
                        <LabelValue
                            label="Date of Divorce"
                            value={getDisplayDate(policyholder.individual.personalDetails.dateOfDivorce)}
                        />
                        <LabelValue
                            label="South African Resident"
                            value={policyholder.individual.personalDetails.isRSAResident}
                        />
                        {policyholder.individual.personalDetails.isRSAResident === 'Yes' && (
                            <>
                                <LabelValue label="ID Number" value={policyholder.individual.personalDetails.idNumber} />
                                <LabelValue label="Date of Birth" value={getDisplayDate(policyholder.individual.personalDetails.dateOfBirth)} />
                            </>
                        )}
                        {policyholder.individual.personalDetails.isRSAResident === 'No' && (
                            <Grid container justify="flex-start">
                                <LabelValue label="Passport Number" value={policyholder.individual.personalDetails.idNumber} />
                                <LabelValue label="Country of Issue" value={policyholder.individual.personalDetails.countryOfIssue} />
                                <LabelValue
                                    label="Date of Issue"
                                    value={getDisplayDate(policyholder.individual.personalDetails.dateOfIssue)}
                                />
                                <LabelValue
                                    label="Date of Expiry"
                                    value={getDisplayDate(policyholder.individual.personalDetails.dateOfExpiry)}
                                />
                            </Grid>
                        )}
                        <LabelValue label="Place of birth" value={policyholder.individual.personalDetails.placeOfBirth} />
                        <LabelValue label="Country of Birth" value={policyholder.individual.personalDetails.countryOfBirth} />
                        <LabelValue label="Country of Residence" value={policyholder.individual.personalDetails.countryOfResidence} />
                        <LabelValue label="Proof of Identification" value={policyholder.individual.personalDetails.proofOfIdentification} isFile/>
                    </React.Fragment>}
                    {policyholder.policyHolderType === 'Company' && <React.Fragment>
                        <LabelValue label="Policyholder Type" value={policyholder.policyHolderType} />
                        <LabelValue label="Company Trading Name" value={policyholder.individual.companyDetails.entityName} />
                        <LabelValue label="Company Registration Number" value={policyholder.individual.companyDetails.entityRegistrationNumber} />
                        <LabelValue label="Company Registration Date" value={getDisplayDate(policyholder.individual.companyDetails.dateOfIncorporation)} />
                        <LabelValue label="Country of incorporation" value={policyholder.individual.companyDetails.countryOfIncorporation} />
                    </React.Fragment>}
                    <React.Fragment>
                        <IndividualAddressSummary policyholder={policyholder} displayPostalAddress={false}/>
                    </React.Fragment>
                    <React.Fragment>
                        <Grid item xs={12} style={{ marginTop: '24px' }}>
                            <Typography variant="h6">Contact Details</Typography>
                            <hr />
                        </Grid>
                        <LabelValue label="Home Telephone Number" value={policyholder.individual.contactDetails.homeTelephoneNumber} />
                        <LabelValue label="Work Telephone Number" value={policyholder.individual.contactDetails.workTelephoneNumber} />
                        <LabelValue label="Cellphone Number" value={policyholder.individual.contactDetails.cellphoneNumber} />
                        <LabelValue label="Telephone Number" value={policyholder.individual.contactDetails.telephoneNumber} />
                        <LabelValue label="Fax Number" value={policyholder.individual.contactDetails.faxNumber} />
                        <LabelValue label="Email Address" value={policyholder.individual.contactDetails.emailAddress} />
                    </React.Fragment>
                    {policyholder.policyHolderType === 'Individual' && <React.Fragment>
                        <Grid item xs={12} style={{ marginTop: '24px' }}>
                            <Typography variant="h6">Employment Details</Typography>
                            <hr />
                        </Grid>
                        <LabelValue label="Is the policyholder employed?" value={policyholder.individual.employmentDetails.isPolicyHolderEmployed} />
                        <LabelValue label="Nominated occupation" value={policyholder.individual.employmentDetails.NominatedOccupation} />
                        <LabelValue label="Industry of occupation" value={policyholder.individual.employmentDetails.industryOfOccupation} />
                        <LabelValue label="Employer name" value={policyholder.individual.employmentDetails.employerName} />
                        <LabelValue label="SA Income tax reference number" value={policyholder.individual.employmentDetails.saTaxReferenceNumber} />
                        <LabelValue label="Tax office" value={policyholder.individual.employmentDetails.taxOffice} />
                        <LabelValue label="Do you have an income tax number?" value={policyholder.individual.employmentDetails.hasTaxNumber} />
                    </React.Fragment>}
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

const mapStateToProps = state => {
    const claimSelection = (state && state.claimSelection) || {};
    return {
        policyholder: (claimSelection.claim && claimSelection.claim.policyholder) || [],
    };
};

export const PolicyHolderSummary = connect(mapStateToProps, {})(withStyles(styles)(policyHolderSummary));
