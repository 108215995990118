import React, { useState, useEffect } from 'react';
import { Button, Grid, IconButton, Tooltip, Typography, withStyles } from '@material-ui/core';
import { AddCircle, Delete } from '@material-ui/icons';
import classNames from 'classnames';
import { FieldArray } from 'react-final-form-arrays';
import { Condition, ExpansionPanel, False, FixedWidthButtonGroup, OutlinedDatePicker, OutlinedPhoneField, OutlinedTextField, True } from '../../../../forms';
import { FixedWidthFileUpload } from '../../../../forms/form-components/fixed-width-file-upload';
import { emailFormatValidatorRequiredField, requiredValidator } from '../../../../forms/validations';
import {
    EmailMask,
    FirstNameMask,
    NumberMask,
    TelephoneNumberMask,
} from '../../../../forms/input-masks';
import styles from '../../../../styles/styles';
import { DeceasedNavigation } from '../../../common/navigation/deceased-navigation';
import { DoctorsAddress } from './doctors-address';
import { getSouthAfricanAddress } from '../../../../shared/helper-functions';

const MedicalInformation = ({
    classes,
    handleBack,
    index,
    editable,
    handleBackStepper,
    handleNext,
    stepsLength,
    form,
}) => {
    const { push } = form.mutators;
    const prefix = 'lifeAssured.MedicalInformation.doctorVisits';
    const [isDoctorsDetailsCaptured, isDoctorsDetailsAllCaptured] = useState(true);
    const [isAllConsultationDetailsCaptured, setAllDoctorsConsultationDetails] = useState([]);
    const updatedDetails = [...isAllConsultationDetailsCaptured];

    useEffect(() => {
        const isDoctorDetailsComplete = (doctorIndex) => {
            const formData = form.getState().values;
            const doctorFields = formData && formData.lifeAssured.MedicalInformation && formData.lifeAssured.MedicalInformation.doctorVisits[doctorIndex];
            return (
                doctorFields &&
                doctorFields.doctorsName &&
                doctorFields.practiceNumber &&
                doctorFields.doctorsContactDetails &&
                doctorFields.emailAddress &&
                doctorFields.addressDetails.town &&
                doctorFields.addressDetails.addressLineOne &&
                doctorFields.addressDetails.addressPostalCode &&
                doctorFields.addressDetails.province &&
                doctorFields.addressDetails.suburb
            );
        };

        const isOccurrenceDetailsComplete = (doctorIndex, occurrenceIndex) => {
            const formData = form.getState().values;
            const occurrences = formData && formData.lifeAssured.MedicalInformation && formData.lifeAssured.MedicalInformation.doctorVisits[doctorIndex].occurences;
            const occurrence = occurrences && occurrences[occurrenceIndex];
            return (
                occurrence &&
                occurrence.dateOfAttendance &&
                occurrence.reason
            );
        };

        const checkAllDoctorsComplete = () => {
            const formData = form.getState().values;
            if (Object.keys(formData).length > 0) {
                const visits = formData && formData.lifeAssured.MedicalInformation && formData.lifeAssured.MedicalInformation.doctorVisits;
                const allCaptured = visits && visits.every((visit, doctorIndex) => {
                    const isDoctorInformationCaptured = isDoctorDetailsComplete(doctorIndex);
                    const doctorConsultations = visit.occurences;
                    const allDoctorsConsultationsCaptured = doctorConsultations && doctorConsultations.every((occurrence, occurrenceIndex) => {
                        return isOccurrenceDetailsComplete(doctorIndex, occurrenceIndex);
                    });

                    if (updatedDetails.length <= doctorIndex) {
                        updatedDetails.push(allDoctorsConsultationsCaptured);
                    } else {
                        updatedDetails.splice(doctorIndex, 1, allDoctorsConsultationsCaptured);
                    }

                    setAllDoctorsConsultationDetails(updatedDetails);

                    return isDoctorInformationCaptured;
                });

                if (visits) {
                    isDoctorsDetailsAllCaptured(allCaptured);
                } else {
                    isDoctorsDetailsAllCaptured(true);
                }
            } else {
                return false;
            }
        };

        const unsubscribe = form.subscribe(
            () => {
                checkAllDoctorsComplete();
            },
            { values: true }
        );
        checkAllDoctorsComplete();

        return () => {
            unsubscribe();
        };
    }, [form, prefix]);

    const handleAddDoctor = () => {
        push(`${prefix}`, { occurences: [{}], addressDetails: getSouthAfricanAddress() });
    };

    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
            </Grid>
            <Grid item xs={12} container justify="flex-start">
                <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                <Typography className={classes.stepHeader}>Medical Information</Typography>
            </Grid>
            <Grid item xs={12}>
                <OutlinedTextField
                    name={'lifeAssured.MedicalInformation.lifeAssuredMedicalReason'}
                    label="What is the medical reason for the claim being submitted?"
                    mask={FirstNameMask}
                    validate={requiredValidator}
                    labelprefixed="true"
                    disabled={!editable}
                    placeholder=''/>
            </Grid>
            <Grid item xs={12}>
                <OutlinedDatePicker
                    name={'lifeAssured.MedicalInformation.lifeAssuredDateOfDiagnosis'}
                    label="Please provide date of diagnosis or date that the event took place"
                    disabled={!editable}
                    disableFuture
                    labelprefixed="true"
                    validate={requiredValidator}
                    placeholder='' />
            </Grid>
            <Grid item xs={12}>
                <FixedWidthButtonGroup
                    label="Are you currently taking any medication or undergoing treatment?"
                    name={'lifeAssured.MedicalInformation.takingMedicationOrTreatment'}
                    options={['Yes', 'No']}
                    disabled={!editable}
                    validate={requiredValidator}
                />
                <Condition when={'lifeAssured.MedicalInformation.takingMedicationOrTreatment'} is="Yes">
                    <True>
                        <OutlinedTextField
                            name={'lifeAssured.MedicalInformation.lifeAssuredlistMedicationTreatment'}
                            label="Please list details about medication and/or treatment below"
                            validate={requiredValidator}
                            labelprefixed="true"
                            disabled={!editable}
                            multiline
                            placeholder='' />
                    </True>
                    <False><div></div></False>
                </Condition>
            </Grid>
            <Grid item xs={12}>
                <FixedWidthButtonGroup
                    label="Medical condition was acquired due to?"
                    name={'lifeAssured.MedicalInformation.lifeAssuredMedicalCondition'}
                    options={['Disease', 'Accident']}
                    disabled={!editable}
                    validate={requiredValidator}
                />
                <Condition when={'lifeAssured.MedicalInformation.lifeAssuredMedicalCondition'} is="Disease">
                    <True>
                        <FixedWidthFileUpload
                            name={`lifeAssured.MedicalInformation.lifeAssuredMedicalEvidence${1}`}
                            label="Upload Supporting medical evidence (e.g. x-ray reports) 1"
                            validate={requiredValidator}
                            disabled={!editable} />
                        <Grid item xs={12}>
                            <FieldArray name={'lifeAssured.MedicalInformation.lifeAssuredMedicalEvidence'}>
                                {({ fields }) => (
                                    <>
                                        {fields.map((field, newIndex) => {
                                            const adjustedIndex = newIndex + 2;
                                            const fieldName = `${field}`;
                                            const fieldValue = fields.value[newIndex];

                                            return (
                                                <Grid container key={fieldName} alignItems="center" style={{ position: 'relative' }}>
                                                    <Grid item xs={12}>
                                                        <FixedWidthFileUpload
                                                            name={fieldName}
                                                            validate={requiredValidator}
                                                            labelprefixed="true"
                                                            index={newIndex}
                                                            label={`Upload Supporting medical evidence (e.g. x-ray reports) ${adjustedIndex}`}
                                                        />
                                                    </Grid>
                                                    {editable && !fieldValue && (
                                                        <Tooltip title="Remove file uploader">
                                                            <IconButton
                                                                style={{
                                                                    color: '#cd003d',
                                                                    position: 'absolute',
                                                                    top: '170px',
                                                                    transform: 'translateY(-200%) translateX(740%)'
                                                                }}
                                                                onClick={() => fields.remove(newIndex)}
                                                            >
                                                                <Delete />
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}
                                                </Grid>
                                            );
                                        })}
                                        {editable && (
                                            <Grid item>
                                                <Button
                                                    variant="contained"
                                                    id="add-visit"
                                                    className={classNames(classes.customButton, classes.fullWidthButton)}
                                                    onClick={() => push('lifeAssured.MedicalInformation.lifeAssuredMedicalEvidence', undefined)}
                                                    startIcon={<AddCircle />}
                                                >
                        Add More Supporting Medical Evidence
                                                </Button>
                                            </Grid>
                                        )}
                                    </>
                                )}
                            </FieldArray>
                        </Grid>
                    </True>
                </Condition>

                <Condition when={'lifeAssured.MedicalInformation.lifeAssuredMedicalCondition'} is="Accident">
                    <True>
                        <OutlinedTextField
                            name={'lifeAssured.MedicalInformation.lifeAssuredaccidentDetails'}
                            label="Provide accident details and case number if applicable"
                            validate={requiredValidator}
                            labelprefixed="true"
                            disabled={!editable}
                            multiline
                            placeholder='' />
                    </True>
                </Condition>
            </Grid>
            <Grid item xs={12}>
                <Typography className={classes.doctorsVisitTitle}>
                    Doctor’s and Practice Details
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography className={classes.description}>
                    Please provide the names, addresses and phone
                    numbers of all doctors that you have consulted during
                    the last 5 years(compulsory).
                </Typography>
            </Grid>
            <Grid item xs={12} style={{ marginLeft: '16px' }}>
                <FieldArray name={`${prefix}`}>
                    {({ fields }) =>
                        fields.map((doctor, doctorIndex) => {
                            return doctorsVisitRow(
                                editable,
                                prefix,
                                fields,
                                classes,
                                form,
                                doctorIndex,
                                isAllConsultationDetailsCaptured
                            );
                        })
                    }
                </FieldArray>
            </Grid>
            {editable && (
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        id="add-visit"
                        className={classNames(classes.customButton, classes.fullWidthButton)}
                        onClick={handleAddDoctor}
                        disabled={!isDoctorsDetailsCaptured}
                    >
                        <AddCircle style={{ marginRight: '8px' }} />
    Add Doctor
                    </Button>

                </Grid>
            )}
            <Grid item xs={12}>
                <FixedWidthFileUpload
                    name={`lifeAssured.MedicalInformation.extractOfMedicalHistory${1}`}
                    helperText="Personal medical attendant's report, if a policy is less than 3 years"
                    validate={requiredValidator}
                    labelprefixed="true"
                />
            </Grid>
            <Grid item xs={12}>
                <FieldArray name={'lifeAssured.MedicalInformation.extractOfMedicalHistory'}>
                    {({ fields }) => (
                        <>
                            {fields.map((field, newIndex) => {
                                const adjustedIndex = newIndex + 2;
                                const fieldName = `${field}`;
                                const fieldValue = fields.value[newIndex];

                                return (
                                    <Grid container key={fieldName} alignItems="center"  style={{ position: 'relative' }}>
                                        <Grid item xs={12}>
                                            <FixedWidthFileUpload
                                                name={fieldName}
                                                helperText="Personal medical attendant's report, if a policy is less than 3 years"
                                                validate={requiredValidator}
                                                labelprefixed="true"
                                                index={newIndex}
                                                label={` Upload Extract Of Medical History ${adjustedIndex}`}
                                            />
                                        </Grid>
                                        {editable && !fieldValue && (
                                            <Tooltip title={'Remove file uploader'}>
                                                <IconButton style={{ color: '#cd003d', position: 'relative', right: '-402px',transform: 'translateY(-200%) translateX(-100%)', }} onClick={() => fields.remove(newIndex)}>
                                                    <Delete />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </Grid>
                                );
                            })}
                            {editable && (
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        id="add-visit"
                                        className={classNames(classes.customButton, classes.fullWidthButton)}
                                        onClick={() => push('lifeAssured.MedicalInformation.extractOfMedicalHistory', undefined)}
                                        startIcon={<AddCircle />}
                                    >
                        Add More Medical History Files
                                    </Button>
                                </Grid>
                            )}
                        </>
                    )}
                </FieldArray>


            </Grid>

            <Grid item xs={12} className={classes.navigationGroup}>
                <DeceasedNavigation
                    form={form}
                    handleBack={handleBack}
                    handleBackStepper={handleBackStepper}
                    handleNext={handleNext}
                    prefix="deceased-doctors"
                />
            </Grid>
        </React.Fragment>
    );
};

function doctorsVisitRow(editable, prefix, fields, classes, form, doctorIndex, isAllConsultationDetailsCaptured) {
    const { push } = form.mutators;
    if (fields.length === 0) {
        fields.push(`${prefix}`, undefined);
    }

    return (
        <ExpansionPanel
            key={`${prefix}[${doctorIndex + 1}]`}
            name={`doctor${doctorIndex + 1}`}
            id={`doctor${doctorIndex + 1}`}
            className={classes.expensionPanel}
            style={{ marginLeft: '-16px', marginTop: '16px', width: '120%' }}
            defaultExpanded={true}
            remove={() => fields.remove(doctorIndex)}
            deletable={editable}
        >
            <Grid container>
                <Grid item xs={12}>
                    <Typography style={{ marginTop: '30px' }} className={classes.expansionSubTile}>
                        Doctor's Details
                    </Typography>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <OutlinedTextField
                            label="Doctor's Name"
                            name={`${prefix}[${doctorIndex}].doctorsName`}
                            mask={FirstNameMask}
                            placeholder="Enter name"
                            disabled={!editable}
                            className={classes.expensionPanelComponents}
                            validate={requiredValidator}
                            labelprefixed="true"
                        />

                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <OutlinedTextField
                            label="Practice Number"
                            name={`${prefix}[${doctorIndex}].practiceNumber`}
                            mask={NumberMask}
                            placeholder="Enter practice number"
                            disabled={!editable}
                            className={classes.expensionPanelComponents}
                            validate={requiredValidator}
                            labelprefixed="true"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <OutlinedPhoneField
                            label="Contact Number"
                            name={`${prefix}[${doctorIndex}].doctorsContactDetails`}
                            placeholder="Enter contact number"
                            mask={TelephoneNumberMask}
                            disabled={!editable}
                            className={classes.expensionPanelComponents}
                            validate={requiredValidator}
                            labelprefixed="true"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <OutlinedTextField
                            label="Doctor's email address"
                            name={`${prefix}[${doctorIndex}].emailAddress`}
                            mask={EmailMask}
                            validate={emailFormatValidatorRequiredField}
                            placeholder="Enter email address"
                            labelprefixed={'true'}
                            disabled={!editable}
                            className={classes.expensionPanelComponents}
                        />
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Typography style={{ marginTop: '24px' }} className={classes.expansionSubTile}>
                        Practice Details
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <DoctorsAddress
                        prefix={`${prefix}[${doctorIndex}].addressDetails.`}
                        editable={editable}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FieldArray
                        name={`${prefix}[${doctorIndex}].occurences`}
                        className={classes.expensionPanelComponents}
                    >
                        {({ fields }) =>
                            fields.map((visit, visitIndex) => {
                                return visitOccurenceRow(
                                    editable,
                                    prefix,
                                    fields,
                                    doctorIndex,
                                    visitIndex,
                                    classes
                                );
                            })
                        }
                    </FieldArray>
                </Grid>
                {editable && (
                    <Button
                        variant="contained"
                        className={classes.customButton}
                        onClick={() => push(`${prefix}[${doctorIndex}].occurences`, undefined)}
                        id={`add-occurence${doctorIndex}`}
                        style={{ marginLeft: '1px', width: '333px' }}
                        disabled={!isAllConsultationDetailsCaptured[doctorIndex]}
                    >
                        <AddCircle style={{ marginRight: '8px' }} />
                        Add Consultation
                    </Button>
                )}
            </Grid>
        </ExpansionPanel>
    );
}

function visitOccurenceRow(editable, prefix, fields, doctorIndex, visitIndex, classes) {
    if (fields.length === 0) {
        fields.push(`${prefix}[${doctorIndex}].occurences`, [{}]);
    }
    return (
        <ExpansionPanel
            key={`doctorsVisits${doctorIndex}occurence${visitIndex + 1}`}
            name={`visit${visitIndex + 1}`}
            id={`visit${visitIndex + 1}`}
            label={`Reason for consultation ${visitIndex + 1}`}
            className={classes.expensionPanel}
            style={{ marginLeft: '-16px', marginTop: '16px', width: '104.5%' }}
            remove={() => fields.remove(visitIndex)}
            deletable={editable}
        >
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <OutlinedDatePicker
                        className={classes.expensionPanelComponents}
                        name={`${prefix}[${doctorIndex}].occurences[${visitIndex}].dateOfAttendance`}
                        disabled={!editable}
                        validate={requiredValidator}
                        labelprefixed="true"
                        disableFuture
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <OutlinedTextField
                        className={classes.expensionPanelComponents}
                        name={`${prefix}[${doctorIndex}].occurences[${visitIndex}].reason`}
                        label="Reason"
                        placeholder="Enter reason"
                        disabled={!editable}
                        validate={requiredValidator}
                        labelprefixed="true"
                        multiline
                    />
                </Grid>
            </Grid>
        </ExpansionPanel>
    );
}

export default withStyles(styles)(MedicalInformation);
