import { Grid, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import { getLabel } from '../../../../shared/helper-functions';
import { Certifiers } from '../../../../shared/lookups';
import styles from '../../../../styles/styles';
import { LabelValue } from '../../../common/label-value';
import { AddressDetails } from '../common/address-details';

const declarationSummary = function ({ declaration }) {
    if (!declaration) {
        return null;
    }
    return (
        <Grid container justify="flex-start">
            <Grid item xs={12} style={{ marginTop: '24px' }}>
                <Typography variant="h6">Declaration For Funeral Claim</Typography>
                <hr />
            </Grid>
            <LabelValue
                label="Who certified the death?"
                value={getLabel(declaration.certifiedBy, Certifiers)}
            />
            <LabelValue
                label={declaration.certifiedBy === 'Doctor' ? 'BI1663' : 'BI1680'}
                value={declaration.noticeOfDeath}
                isFile
            />
            <LabelValue label="Initials" value={declaration.initials} />
            <LabelValue label="First Name" value={declaration.firstName} />
            <LabelValue label="Second Name" value={declaration.secondName} />
            <LabelValue label="Surname" value={declaration.lastName} />
            <LabelValue label="Contact Number" value={declaration.telephoneNumber} />
            {declaration.certifiedBy === 'Doctor' && (
                <React.Fragment>
                    <LabelValue
                        label="Do you have the hospital's details?"
                        value={declaration.hasHospitalDetails}
                    />
                </React.Fragment>
            )}
            {declaration.hasHospitalDetails === 'Yes' && (
                <React.Fragment>
                    <Grid item xs={12} style={{ marginTop: '24px' }}>
                        <Typography variant="h6">Hospital Details</Typography>
                        <hr />
                    </Grid>
                    <LabelValue label="Hospital Name" value={declaration.hospitalName} />
                    <LabelValue
                        label="Hospital Contact Number"
                        value={declaration.hospitalContactNumber}
                    />
                </React.Fragment>
            )}
            <AddressDetails addressDetails={declaration.addressDetails} />
        </Grid>
    );
};

export const DeclarationSummary = withStyles(styles)(declarationSummary);
