import * as React from 'react';
import { Grid, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import { NavigationButton } from './navigation-button';
import styles from '../../../styles/styles';

const deceasedNavigation = ({ classes, ...rest }) => {
    const { form, handleBack, handleBackStepper, handleNext, prefix } = rest;
    return (
        <React.Fragment>
            <Grid item xs={12} className={classes.navigationGroup}>
                <NavigationButton
                    className={classNames(classes.buttonSecondary, classes.buttonAlignment)}
                    onClick={() => handleBackStepper(form)}
                    id={`${prefix}-back-button`}
                    label="Back"
                />
                <NavigationButton
                    color="primary"
                    className={classNames(classes.button)}
                    onClick={() => handleNext(form)}
                    id={`${prefix}-continue-button`}
                    label="Continue"
                />
            </Grid>
            <Grid item xs={12} className={classes.navigationGroup}>
                <NavigationButton
                    className={classNames(classes.buttonSecondary, classes.fullWidthButton)}
                    onClick={() => handleBack(form)}
                    id={`${prefix}-previous-button`}
                    label="Previous Section"
                />
            </Grid>
        </React.Fragment>
    );
};

export const DeceasedNavigation = withStyles(styles)(deceasedNavigation);
