import * as React from 'react';

import { FormControlLabel, Radio as MuiRadio } from '@material-ui/core';
import { callAllFunctions, getComponentId, nameToLabel } from '../..';

export const Radio = ({ input, label, id, onChange, className, ...rest }) => {
    const { checked, name, ...restInput } = input;
    const marginTop = rest.margintop;

    return (
        <FormControlLabel
            className={className}
            control={
                <MuiRadio
                    {...rest}
                    id={getComponentId({ id, name })}
                    name={name}
                    inputProps={restInput}
                    checked={!!checked}
                    onChange={callAllFunctions(onChange, input.onChange)}
                />
            }
            label= {(name==='claimType') ? label : nameToLabel({ label, name })}
            style={{ height: 70, marginTop: marginTop ? marginTop: 0, marginLeft: name==='claimType' ? 20: '' }}
        />
    );
};
