import { Grid, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import { getBankName } from '../../../../shared/banks';
import styles from '../../../../styles/styles';
import { LabelValue } from '../../../common/label-value';

const paymentDetails = function ({ bankingDetails }) {
    if (!bankingDetails) {
        return null;
    }

    return (
        <Grid container justify="flex-start">
            <Grid item xs={12} style={{ marginTop: '24px' }}>
                <Typography variant="h6">Payment Details</Typography>
                <hr />
            </Grid>
            <LabelValue label="Account Holder Name" value={bankingDetails.name} />
            <LabelValue label="ID Number" value={bankingDetails.idNumber} />
            <LabelValue label="Telephone Number" value={bankingDetails.telephoneNumber} />
            <LabelValue label="Cellphone Number" value={bankingDetails.cellphoneNumber} />
            <LabelValue label="Bank" value={getBankName(bankingDetails.bankName)} />
            <LabelValue label="Branch Name" value={bankingDetails.branchName} />
            <LabelValue label="Branch Code" value={bankingDetails.branchCode} />
            <LabelValue label="Account Number" value={bankingDetails.accountNumber} />
            <LabelValue label="Account Type" value={bankingDetails.accountType} />
            <LabelValue
                label="Proof of Bank Account"
                value={bankingDetails.proofOfBankAccount}
                isFile={true}
            />
        </Grid>
    );
};

export const PaymentDetails = withStyles(styles)(paymentDetails);
