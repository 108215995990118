import * as React from 'react';
import { Button, Grid, Paper, withStyles } from '@material-ui/core';
import { Print } from '@material-ui/icons';
import styles from '../../../styles/styles';
import { NavigationButton } from '../../common/navigation/navigation-button';
import { PolicyOwnerSummary } from './policy-owner/policy-owner';
import { ClaimantSummary } from './claimant-details/claimant-summary';
import { DeceasedDeatailSummary } from './deceased-details/deceased-details';

const summary = function ({ classes, form, handleBack, handleSubmit }) {
    return (
        <Paper className={[classes.shadedPaper, classes.paperShadowLess].join(' ')}>
            <DeceasedDeatailSummary />
            <br/>
            <PolicyOwnerSummary />
            <br/>
            <ClaimantSummary />
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    className={classes.printButton}
                    id="add-insurance-company"
                    style={{display: 'none'}}
                >
                    <Print /> Print
                </Button>
            </Grid>
            <Grid container justify="flex-end" spacing={1} className={classes.continueAndPreviouseButton} style={{marginTop: '7%'}}>
                <Grid item xs={5.5} >
                    <NavigationButton
                        className={classes.buttonSecondary}
                        onClick={() => handleBack(form)}
                        id="summary-back"
                        fullWidth
                        style={{
                            height: '48px',
                            width: '176px',
                        }}
                        label="Previous Section"
                    />
                </Grid>
                <Grid item xs={5.5}>
                    <NavigationButton
                        color="primary"
                        className={classes.button}
                        onClick={() => {
                            sessionStorage.setItem('canGoToNextPage', 'true');
                            handleSubmit(form);
                        }}
                        id="summary-continue"
                        fullWidth
                        style={{
                            height: '48px',
                            width: '176px',
                            marginRight: '-4px',
                        }}
                        label="Continue"
                    />
                </Grid>
            </Grid>
        </Paper>
    );
};

export const Summary = withStyles(styles)(summary);
