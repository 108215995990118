import { HIDE_LOADING, SHOW_LOADING } from '../loading-screen-types';

export function showLoading() {
    return async function (dispatch) {
        dispatch(showLoadingAction());
    };
}
export function hideLoading() {
    return async function (dispatch) {
        dispatch(hideLoadingAction());
    };
}

export function hideLoadingAction() {
    return {
        type: HIDE_LOADING,
    };
}

export function showLoadingAction() {
    return {
        type: SHOW_LOADING,
    };
}
