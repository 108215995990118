import * as React from 'react';
import { Grid, Paper, withStyles, Typography } from '@material-ui/core';
import styles from '../../styles/styles';

const applicationProcess = function ({ classes, sectionIndex }) {
    return (
        <Paper
            className={[classes.shadedPaper, classes.paperShadowLess].join(' ')}
            style={{ paddingBottom: '0px' }}
        >
            <Grid container justify="flex-start">
                <Grid item xs={12} lg={12}>
                    <Typography className={classes.componentHeader}>
                        Submit a Retirement Annuity Death Claim
                    </Typography>
                    <br />
                    <Typography className={classes.componentBody}>
                        In order for your claim to be processed, you will need to submit the
                        relevant requirements needed to support your claim. In addition to the
                        standard requirements, a request may be made for additional requirements at
                        a later point in the process. Please ensure that you are able to upload the
                        following:
                    </Typography>
                    <SectionInformation classes={classes} sectionIndex={sectionIndex} />
                    <Typography className={classes.componentHeader}>Application Process</Typography>
                    <Typography style={{ fontSize: '16px', lineHeight: '27px' }}>
                        Complete the following steps to complete your submission
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    );
};

const SectionInformation = ({ sectionIndex, classes }) => {
    switch (sectionIndex) {
        case 1:
            return (
                <ol className={classes.body2}>
                    <li>
                        Copy of the deceased’s ID document or passport, if it is an ID smart card -
                        a copy of the back and the front of ID card is needed
                    </li>
                    <li>If the deceased was married, a marriage certificate</li>
                    <li>
                        If the deceased was divorced, a divorce certificate for all previous
                        marriages
                    </li>
                </ol>
            );
        default:
            return (
                <ol className={classes.body2}>
                    <li>
                        Copy of the claimant’s ID document or passport, if it is an ID smart card -
                        a copy of the back and the front of ID card is needed
                    </li>
                    <li>If the claimant is the spouse, a marriage certificate</li>
                    <li>If the claimant is an ex-spouse, a divorce certificate</li>
                </ol>
            );
    }
};

export const ApplicationProcess = withStyles(styles)(applicationProcess);
