import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from '../../styles/styles';
import { SummaryFileViewer } from './summary-file-viewer';
import { SummaryValue } from './summary-value';

const labelValue = ({ classes, label, value, isFile = false }) => {
    if (!value) {
        return null;
    }
    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={5} style={{ marginBottom: '20px' }}>
                    <Typography variant="subtitle2">
                        {label}
                    </Typography>
                </Grid>
                <Grid item xs={7}>
                    {isFile ? (
                        <SummaryFileViewer value={value} />
                    ) : (
                        <SummaryValue value={value} />
                    )}
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export const LabelValue = withStyles(styles)(labelValue);
