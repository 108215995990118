import * as React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from '../../../styles/styles';
import { AddressDetails } from '../common/address-details';
import { ClaimantNavigation } from '../../common/navigation/claimant-navigation';

const entityAddressDetails = function ({
    classes,
    editable,
    handleBackStepper,
    handleNext,
    index,
    stepsLength,
    form,
    claimantIndex,
}) {
    return (
        <Grid container spacing={10} justify="flex-start">
            <Grid item xs={12} lg={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
                <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                <Typography className={classes.stepHeader}>Business Address</Typography>
            </Grid>
            <Grid item xs={12}>
                <AddressDetails
                    isResidential
                    editable={editable}
                    prefix={`claimants[${claimantIndex}].entity.address.`}
                    proofOfAddress={true}
                />
            </Grid>
            <ClaimantNavigation
                form={form}
                handleBackStepper={handleBackStepper}
                handleNext={handleNext}
                prefix={`claimant-${claimantIndex}-address`}
            />
        </Grid>
    );
};

export const EntityAddressDetails = withStyles(styles)(entityAddressDetails);
