const digitRegex = /\d/;

export const NumberMask = value => {
    const numberMask = [digitRegex];
    return numberMask.concat(
        value
            .split('')
            .slice(1)
            .filter(c => digitRegex.test(c))
            .map(c => digitRegex)
    );
};
