import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from '../../../styles/styles';
import { LabelValue } from '../../common/label-value';

const claimantType = ({ claimant }) => {
    if (!claimant || !claimant.individual) {
        return null;
    }
    const individual = claimant.individual;
    return (
        <React.Fragment>
            <Grid item xs={12} style={{ marginTop: '24px' }}>
                <Typography variant="h6">Claimant Type</Typography>
                <hr />
            </Grid>
            <Grid xs={12} sm={12}>
                <LabelValue label="Claimant Type" value={claimant.claimantType} />
                <LabelValue
                    label="Relationship to Deceased"
                    value={individual.relationshipToDeceased}
                />
                <LabelValue
                    label="Relationship to Deceased"
                    value={individual.relationshipToDeceasedOther}
                />
                <LabelValue label="Relationship to Owner" value={individual.relationshipToOwner} />
                <LabelValue label="Relationship to Owner" value={individual.relationshipToOwnerOther} />
            </Grid>
        </React.Fragment>
    );
};

export const ClaimantTypeSummary = withStyles(styles)(claimantType);
