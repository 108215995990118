import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from '../../../../styles/styles';
import { currencyFormat, getLabel } from '../../../../shared/helper-functions';
import { MaintenanceType } from '../../../../shared/lookups';
import { ContactDetailsPreview } from '../../common/contact-details-preview';
import { LabelValue } from '../../../common/label-value';

const financialDetails = ({ classes, claimant }) => {
    const individualDetails = claimant.individual;
    return (
        <React.Fragment>
            <Grid item xs={12} style={{ marginTop: '24px' }}>
                <Typography variant="h6">Financial Details</Typography>
                <hr />
            </Grid>
            <LabelValue
                label="Were you financially dependant on the deceased ?"
                value={individualDetails.isDependent}
            />
            {individualDetails.isDependent === 'Yes' && (
                <React.Fragment>
                    <LabelValue
                        label="Proof of Dependency"
                        value={individualDetails.proofOfDependency}
                        isFile={true}
                    />
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" className={classes.customLabel}>
                            Please provide us with a basic income & expenditure breakdown.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} container justify="flex-start">
                        <Grid item xs={3}>
                            <Typography variant="subtitle2" className={classes.customHeading}>
                                Description
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Grid item xs={12} container justify="flex-end">
                                <Typography className={classes.customHeading}>Income</Typography>
                            </Grid>
                            <Grid item xs={12} container justify="flex-end">
                                <Typography className={classes.customHeading}>
                                    (in Rands per Month)
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={3}>
                            <Grid item xs={12} container justify="flex-end">
                                <Typography className={classes.customHeading}>
                                    Expenditure
                                </Typography>
                            </Grid>
                            <Grid item xs={12} container justify="flex-end">
                                <Typography className={classes.customHeading}>
                                    (in Rands per Month)
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={2}>
                            <Grid item xs={12} container justify="flex-end">
                                <Typography className={classes.customHeading}>
                                    Deceased's
                                </Typography>
                            </Grid>
                            <Grid item xs={12} container justify="flex-end">
                                <Typography className={classes.customHeading}>
                                    contribution
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={2}>
                            <Grid item xs={12} container justify="flex-end">
                                <Typography className={classes.customHeading}>
                                    Frequency of
                                </Typography>
                            </Grid>
                            <Grid item xs={12} container justify="flex-end">
                                <Typography className={classes.customHeading}>
                                    Deceased's
                                </Typography>
                            </Grid>
                            <Grid item xs={12} container justify="flex-end">
                                <Typography className={classes.customHeading}>
                                    contribution
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '21px' }}>
                        {individualDetails.expenses &&
                            individualDetails.expenses.map((expense, index) => {
                                return (
                                    <Grid container justify="flex-start" item xs={12} key={index}>
                                        <Grid item xs={3}>
                                            <Typography variant="subtitle2">
                                                {getLabel(expense.description, MaintenanceType)}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2} container justify="flex-end">
                                            <Typography>
                                                {expense.type === 'Income' &&
                                                    currencyFormat(expense.incomeExpenditure)}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3} container justify="flex-end">
                                            <Typography>
                                                {expense.type === 'Expense' &&
                                                    currencyFormat(expense.incomeExpenditure)}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2} container justify="flex-end">
                                            <Typography>
                                                {currencyFormat(expense.contribution)}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2} container justify="flex-end">
                                            <Typography>{expense.frequency}</Typography>
                                        </Grid>
                                        <hr style={{ width: '100%' }} />
                                    </Grid>
                                );
                            })}
                    </Grid>
                    <Grid item xs={12} container justify="flex-start">
                        <Grid item xs={3}>
                            <Typography variant="subtitle2" className={classes.customHeading}>
                                Totals
                            </Typography>
                        </Grid>
                        <Grid item xs={2} container justify="flex-end">
                            <Typography className={classes.customHeading}>
                                {getIncomeExpenseTotal(individualDetails.expenses, 'Income')}
                            </Typography>
                        </Grid>
                        <Grid item xs={3} container justify="flex-end">
                            <Typography className={classes.customHeading}>
                                {getIncomeExpenseTotal(individualDetails.expenses, 'Expense')}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} container justify="flex-end">
                            <Typography className={classes.customHeading}>
                                {getContributionTotal(individualDetails.expenses)}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} container justify="flex-end">
                            <Typography className={classes.customHeading}>Annually</Typography>
                        </Grid>
                    </Grid>
                </React.Fragment>
            )}
            {individualDetails.relationshipToLifeAssured !== 'MinorChild' && (
                <React.Fragment>
                    <LabelValue
                        label="Do you have a financial adviser who can explain your investment options to you?"
                        value={claimant.hasFinancialAdvisor}
                    />
                    <LabelValue
                        label="Would you like us to ask a liberty financial adviser to contact you about your investment options?"
                        value={claimant.consentToLibertyAdvisor}
                    />
                    {claimant.financialAdvisor && (
                        <React.Fragment>
                            <Grid item xs={12}>
                                <Typography className={classes.whiteTitle}>
                                    Financial Adviser
                                </Typography>
                            </Grid>
                            <LabelValue
                                label="First Name"
                                value={claimant.financialAdvisor.firstName}
                            />
                            <LabelValue
                                label="Surname"
                                value={claimant.financialAdvisor.lastName}
                            />
                            <LabelValue
                                label="Initials"
                                value={claimant.financialAdvisor.initials}
                            />
                            <ContactDetailsPreview
                                contactDetails={claimant.financialAdvisor.contactDetails}
                            />
                        </React.Fragment>
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

const getIncomeExpenseTotal = (expenses, type) => {
    let totalAmount = 0;
    if (expenses) {
        expenses.forEach(expense => {
            if (expense.incomeExpenditure !== undefined && expense.incomeExpenditure !== '') {
                if (expense && expense.type === type) {
                    totalAmount += expense.incomeExpenditure;
                }
            }
        });
    }
    return currencyFormat(totalAmount * 12);
};

const getContributionTotal = expenses => {
    let totalAmount = 0;
    if (expenses) {
        expenses.forEach(expense => {
            if (expense.contribution !== undefined && expense.contribution !== '') {
                totalAmount += expense.contribution * frequency(expense.frequency);
            }
        });
    }
    return currencyFormat(totalAmount);
};

function frequency(value) {
    switch (value.toLowerCase()) {
        case 'annually':
            return 1;
        case 'bi-annually':
            return 2;
        case 'quarterly':
            return 4;
        case 'monthly':
            return 12;
        default:
            return 0;
    }
}

export const FinancialDetailsSummary = withStyles(styles)(financialDetails);
