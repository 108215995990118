import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    Typography,
    withStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as React from 'react';
import { connect } from 'react-redux';
import { getLabel } from '../../../../shared/helper-functions';
import styles from '../../../../styles/styles';
import { LabelValue } from '../../../common/label-value';
import { Titles } from '../../../../shared/lookups';
import { IdentitySummary } from './identity-details-summary';

const claimantSummary = function ({ classes, claimants }) {
    if (!claimants || claimants.length < 1) {
        return null;
    }
    return (
        <Accordion
            style={{
                margin: '-24px -16px 24px -16px',
                borderRadius: '4px',
            }}
        >
            <AccordionSummary
                className={[classes.whiteBackground, classes.whiteTitle].join(' ')}
                expandIcon={<ExpandMoreIcon />}
                style={{ borderRadius: '4px' }}
            >
                <Typography className={classes.previewIndex}>1</Typography>
                <Typography className={classes.contentHeader}>Claimant Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid xs={12} sm={12}>
                    <ClaimantDetails
                        key={'claimantSummary'}
                        claimants={claimants}
                        classes={classes}
                        index={0}
                    />
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

const ClaimantDetails = ({ claimants, classes, index }) => {
    return (
        <Accordion>

            <AccordionDetails>
                <Grid container justify="flex-start">
                    <Grid item xs={12} style={{ marginTop: '24px' }}>
                        <Typography variant="h6">Policy Details</Typography>
                        <hr />
                    </Grid>
                    <React.Fragment>
                        <LabelValue label="Policy number" value={claimants.policyDetails.policyNumber} />
                        <LabelValue label="Are you the policy owner" value={claimants.policyDetails.isOwner} />
                        <LabelValue label="In what capacity are you claiming as?" value={claimants.policyDetails.claimingCapacity} />
                    </React.Fragment>
                    <React.Fragment>
                        <Grid item xs={12} style={{ marginTop: '24px' }}>
                            <Typography variant="h6">Personal Details</Typography>
                            <hr />
                        </Grid>
                        <LabelValue label="Title" value={getLabel(claimants.personalDetails.title, Titles)} />
                        <LabelValue label="First Name" value={claimants.personalDetails.firstName} />
                        <LabelValue label="Surname" value={claimants.personalDetails.surname} />
                        <LabelValue label="Company Name" value={claimants.personalDetails.companyName} />
                        <LabelValue label="Consultant Code" value={claimants.personalDetails.consultantCode} />
                        <LabelValue label="Initials" value={claimants.personalDetails.initials} />
                        <IdentitySummary identityDetails={claimants.personalDetails} />
                        <LabelValue label="Gender" value={claimants.personalDetails.gender} />
                    </React.Fragment>
                    <React.Fragment>
                        <Grid item xs={12} style={{ marginTop: '24px' }}>
                            <Typography variant="h6">Contact Details</Typography>
                            <hr />
                        </Grid>
                        <LabelValue label="Telephone Number" value={claimants.contactDetails.telephoneNumber} />
                        <LabelValue label="Cellphone Number" value={claimants.contactDetails.cellphoneNumber} />
                        <LabelValue label="Fax Number" value={claimants.contactDetails.faxNumber} />
                        <LabelValue label="Email Address" value={claimants.contactDetails.emailAddress} />
                        <LabelValue label="Preferred Method Of Communication" value={claimants.contactDetails.preferredMethodOfCommunication} />
                    </React.Fragment>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

const mapStateToProps = state => {
    const claimSelection = (state && state.claimSelection) || {};
    return {
        claimants: (claimSelection.claim && claimSelection.claim.claimant) || [],
    };
};

export const ClaimantSummary = connect(mapStateToProps, {})(withStyles(styles)(claimantSummary));
