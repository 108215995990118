import * as React from 'react';
import {
    Accordion,
    Grid,
    Typography,
    withStyles,
    AccordionSummary,
    AccordionDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styles from '../../../styles/styles';
import { EntityDetailsSummary } from './entity-details';
import { IndividualDetailsSummary } from './individual-details';
import { TypeSuffixes } from '../../../shared/lookups';
import { getLabel } from '../../../shared/helper-functions';

const claimantDetailsView = function ({ classes, formData }) {
    const claimants = formData && formData.claimants;
    if (!claimants) {
        return null;
    }
    return (
        <Accordion
            defaultExpanded={false}
            style={{
                margin: '24px -16px 24px -16px',
                borderRadius: '4px',
            }}
        >
            <AccordionSummary
                className={[classes.whiteBackground, classes.whiteTitle].join(' ')}
                expandIcon={<ExpandMoreIcon />}
                style={{ borderRadius: '4px' }}
            >
                <Typography className={classes.previewIndex}>2</Typography>
                <Typography className={classes.contentHeader}>Claimant Details</Typography>
            </AccordionSummary>

            <AccordionDetails>
                <Grid item xs={12} lg={12}>
                    {claimants.map((claimant, claimantIndex) => {
                        return (
                            <Accordion defaultExpanded={false} key={claimantIndex}>
                                <AccordionSummary
                                    className={[
                                        classes.claimantBackground,
                                        classes.whiteTitle,
                                    ].join(' ')}
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    {`Claimant ${claimantIndex + 1} | ${truncateText(claimantName(
                                        claimant
                                    ), 100)}`}
                                </AccordionSummary>
                                <AccordionDetails>
                                    {claimant.claimantType === 'Entity' && (
                                        <Grid container justify="flex-start">
                                            <Grid xs={12} sm={12}>
                                                <EntityDetailsSummary claimant={claimant} />
                                            </Grid>
                                        </Grid>
                                    )}
                                    {claimant.claimantType === 'Individual' && (
                                        <Grid xs={12} sm={12}>
                                            <IndividualDetailsSummary
                                                claimants={claimants}
                                                index={claimantIndex}
                                            />
                                        </Grid>
                                    )}
                                </AccordionDetails>
                            </Accordion>
                        );
                    })}
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

function claimantName(claimant) {
    return claimant.claimantType === 'Entity'
        ? claimant.entity &&
              `${claimant.entity.entityName} ${getLabel(
                  claimant.entity.entitySuffix,
                  TypeSuffixes
              )}`
        : claimant.individual && `${claimant.individual.firstName} ${claimant.individual.lastName}`;
}
function truncateText(text, limit) {
    if (text.length > limit) {
        return text.substring(0, limit) + '...';
    }
    return text;
}

export const ClaimantDetailsSummary = withStyles(styles)(claimantDetailsView);
