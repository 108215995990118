import { Button, Grid, Paper, Typography, withStyles } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { CustomExpansionPanel } from '../../../forms';
import { getSouthAfricanAddress, isMinor } from '../../../shared/helper-functions';
import styles from '../../../styles/styles';
import { ClaimantNavigation } from '../../common/navigation/claimant-navigation';
import { NavigationButton } from '../../common/navigation/navigation-button';
import { VerticalStepper } from '../../common/vertical-stepper';
import { AddressDetails } from '../common/address-details';
import { ContactDetails } from '../common/contact-details';
import { PaymentDetails } from '../common/payment-details';
import { GuardianDetails } from './guardian-details';
import { PersonalDetails } from './personal-details';

export const ClaimantAddressDetails = withStyles(styles)(function ({ ...props }) {
    const { claimantIndex } = props;
    return (
        <Grid container justify="flex-start">
            <AddressDetails {...props} prefix={`claimants[${claimantIndex}].addressDetails.`} />
            <ClaimantNavigation {...props} prefix={`claimant-${claimantIndex}-add-details`} />
        </Grid>
    );
});

export const ClaimantContactDetails = withStyles(styles)(function ({ ...props }) {
    const { claimantIndex } = props;
    return (
        <Grid container justify="flex-start">
            <ContactDetails {...props} prefix={`claimants[${claimantIndex}].`} />
            <ClaimantNavigation {...props} prefix={`claimant-${claimantIndex}-contact-details`} />
        </Grid>
    );
});

export const ClaimantPaymentDetails = withStyles(styles)(function ({ ...props }) {
    const { claimantIndex } = props;
    return (
        <Grid container justify="flex-start">
            <PaymentDetails {...props} prefix={`claimants[${claimantIndex}].bankingDetails.`} />
            <ClaimantNavigation {...props} prefix={`claimant-${claimantIndex}-pay-details`} />
        </Grid>
    );
});

export const defaultComponents = [
    {
        name: 'Personal Details',
        description: 'Personal details of the claimant',
        component: PersonalDetails,
    },
    {
        name: 'Contact Details',
        description: 'Provide required contact details of the claimant',
        component: ClaimantContactDetails,
    },
    {
        name: 'Address Details',
        description: 'Provide the residential address details of the claimant',
        component: ClaimantAddressDetails,
    },
    {
        name: 'Payment Details',
        description: 'Provide banking details of the claimant',
        component: ClaimantPaymentDetails,
    },
];

function getVerticalSteps(claimant) {
    if (claimant && claimant.personalDetails && claimant.personalDetails.dateOfBirth) {
        if (isMinor(claimant.personalDetails.dateOfBirth)) {
            return [
                ...defaultComponents.slice(0, 1),
                {
                    name: 'Guardian Details',
                    description: `Provide the guardian's personal details.`,
                    component: GuardianDetails,
                },
                ...defaultComponents.slice(1),
            ];
        }
    }
    return defaultComponents;
}

const claimantDetails = function ({ classes, editable, form, handleBack, ...rest }) {
    const { push } = form.mutators;
    return (
        <Paper className={[classes.shadedPaper, classes.paperShadowLess].join(' ')}>
            <Grid container spacing={10} justify="flex-start">
                <Grid item xs={12}>
                    <Typography className={classes.claimantTitle}>Claimant Details</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography className={classes.claimantSubTitle}>
                        Please complete the claimant details for each person claiming.
                    </Typography>
                </Grid>
            </Grid>
            <FieldArray name={`claimants`}>
                {({ fields }) =>
                    fields.map((field, index) => {
                        return (
                            <ClaimantSection
                                {...rest}
                                key={`claimant-section-${index}`}
                                classes={classes}
                                claimantIndex={index}
                                editable={editable}
                                fields={fields}
                                form={form}
                                handleBack={handleBack}
                            />
                        );
                    })
                }
            </FieldArray>
            <Grid container justify="flex-start">
                <Grid item xs={6} className={classes.leftAlign}>
                    {editable && (
                        <Button
                            variant="contained"
                            className={classes.customButton}
                            style={{
                                marginLeft: '-8px',
                                marginTop: '8px',
                                width: '206px',
                            }}
                            id="add-claimant"
                            onClick={() =>
                                push(`claimants`, { addressDetails: getSouthAfricanAddress() })
                            }
                        >
                            <AddCircle style={{ marginRight: '8px' }} />
                            Add Claimant
                        </Button>
                    )}
                </Grid>
                <Grid item xs={6} className={classes.rightAlign}>
                    <NavigationButton
                        className={classes.buttonSecondary}
                        style={{ marginRight: '-8px' }}
                        onClick={() => handleBack(form)}
                        id="claimant-back"
                        fullWidth
                        label="Previous Section"
                    />
                </Grid>
            </Grid>
        </Paper>
    );
};

function ClaimantSection({ classes, editable, fields, form, claimantIndex, ...rest }) {
    const claimants = form.getState().values.claimants;
    return (
        <CustomExpansionPanel
            defaultExpanded={true}
            style={{ margin: '20px -8px 20px -8px', borderRadius: '4px' }}
            name={`claimant${claimantIndex + 1}`}
            id={`claimant${claimantIndex + 1}`}
            label={`Claimant ${claimantIndex + 1}`}
            remove={() => remove(fields, claimantIndex)}
            deletable={editable && fields.length > 1}
        >
            <Paper className={classes.paperShadowLess} style={{ marginTop: '32px' }}>
                <VerticalStepper
                    {...rest}
                    editable={editable}
                    verticalComponents={getVerticalSteps(claimants[claimantIndex])}
                    form={form}
                    claimantIndex={claimantIndex}
                    verticalIndexName={`claimantStep${claimantIndex}`}
                />
            </Paper>
        </CustomExpansionPanel>
    );
}

const remove = (fields, index) => {
    if (fields.length > 1) {
        for (let i = index; i < fields.length; i++) {
            const nextValue = sessionStorage.getItem(`claimantStep${i + 1}`);
            sessionStorage.setItem(`claimantStep${i}`, nextValue);
        }
        sessionStorage.setItem(`claimantStep${fields.length - 1}`, '0');
        fields.remove(index);
    }
};

export const ClaimantDetails = withStyles(styles)(claimantDetails);
