import React, { Component } from 'react';
import MasterLayout from './master-layout/connected-master-layout';
import LuxonUtils from '@date-io/luxon';
import { MuiThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import theme from './styles/theme';
import { AppContainer } from 'react-hot-loader';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import store from './redux/store';
import staticRoutes from './routing/static-routes';
import history from './routing/history';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-73729928-8');

history.listen(location => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
});

class App extends Component {
    render() {
        return (
            <AppContainer>
                <Provider store={store}>
                    <MuiThemeProvider theme={theme}>
                        <MuiPickersUtilsProvider utils={LuxonUtils}>
                            <ConnectedRouter history={history}>
                                <MasterLayout>{staticRoutes}</MasterLayout>
                            </ConnectedRouter>
                        </MuiPickersUtilsProvider>
                    </MuiThemeProvider>
                </Provider>
            </AppContainer>
        );
    }
}

export default App;
