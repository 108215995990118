import * as React from 'react';
import { Grid, Paper, withStyles, Typography } from '@material-ui/core';
import styles from '../../styles/styles';
import { NewClaimSelection } from './new/connected-new-claim-selection';
import { ExistingClaimSelection } from './existing/connected-existing-claim-selection';
import { connect } from 'react-redux';

function claimSelection({ classes, isOnlyReferencedClaims }) {
    return (
        <Paper className={[classes.shadedPaper, classes.paperShadowLess].join(' ')}>
            <Grid container spacing={10} alignContent="center" alignItems="center" justify="center">
                <ClaimsDashboard classes={classes} isAgent={isOnlyReferencedClaims} />
            </Grid>
        </Paper>
    );
}

const ClaimsDashboard = ({ classes, isAgent }) => {
    if (isAgent) {
        return (
            <Grid item xs={12}>
                <Typography className={classes.pageHeading}>
                    Liberty Claims Agent Dashboard
                </Typography>
                <ExistingClaimSelection />
            </Grid>
        );
    }

    return (
        <Grid item xs={12}>
            <Typography className={classes.pageHeading}>Claims Dashboard</Typography>
            <ExistingClaimSelection />
            <NewClaimSelection />
        </Grid>
    );
};

const mapStateToProps = state => {
    const claimSelection = state.claimSelection || {};
    return {
        isOnlyReferencedClaims: claimSelection.isOnlyReferencedClaims,
    };
};

export const ClaimSelection = connect(mapStateToProps, null)(withStyles(styles)(claimSelection));
