import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from '../../../styles/styles';
import {
    OutlinedDatePicker,
    OutlinedSearchSelectField,
    OutlinedTextField,
    WhenFieldChanges,
} from '../../../forms';
import { composeValidators, requiredValidator } from '../../../forms/validations';
import { FirstNameMask, LastNameMask, NumberMask } from '../../../forms/input-masks';
import { Condition, True } from '../../../forms';
import { Countries, MaritalStatuses, Titles, Gender} from '../../../shared/lookups';
import { ClaimantNavigation } from '../../common/navigation/claimant-navigation';
import { getDateOfBirthFromRsaId, validateRSAIdNumber } from '../../common/identity-details';
import { FixedWidthFileUpload } from '../../../forms/form-components/fixed-width-file-upload';
import { NavigationButton } from '../../common/navigation/navigation-button';
import classNames from 'classnames';

const personalDetails = function ({
    classes,
    form,
    index,
    handleNext,
    handleBackStepper,
    editable,
    stepsLength,
    handleBack
}) {
    const prefix = 'policyholder.individual.personalDetails.';

    return (
        <Grid container spacing={10} justify="flex-start">
            <Grid item xs={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
                <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                <Typography className={classes.stepHeader}>Policyholder Details</Typography>
            </Grid>
            <Grid item xs={12} lg={12}>
                <OutlinedSearchSelectField
                    name={`${prefix}title`}
                    options={Titles}
                    label="Title"
                    disabled={!editable}
                    validate={requiredValidator}
                    labelprefixed="true"
                />
                <OutlinedTextField
                    name={`${prefix}firstName`}
                    label="First Name"
                    mask={FirstNameMask}
                    validate={requiredValidator}
                    labelprefixed="true"
                    disabled={!editable}
                />

                <OutlinedTextField
                    name={`${prefix}surname`}
                    label="Surname"
                    validate={requiredValidator}
                    labelprefixed="true"
                    mask={LastNameMask}
                    disabled={!editable}
                />
                <OutlinedTextField
                    name={`${prefix}maiden`}
                    label="Maiden"
                    mask={LastNameMask}
                />
                <OutlinedSearchSelectField
                    name={`${prefix}gender`}
                    options={Gender}
                    disabled={!editable}
                    validate={requiredValidator}
                    labelprefixed="true"
                />
                <OutlinedSearchSelectField
                    name={`${prefix}maritalStatus`}
                    options={MaritalStatuses}
                    disabled={!editable}
                    label="Marital status"
                    validate={requiredValidator}
                    labelprefixed="true"
                />
                <Condition when={`${prefix}maritalStatus`} is="Married">
                    <True>
                        <OutlinedDatePicker
                            name={`${prefix}dateOfMarriage`}
                            label="Date of marriage"
                            disableFuture
                            validate={requiredValidator}
                            disabled={!editable}
                            labelprefixed="true"
                        />
                    </True>
                </Condition>
                <Condition when={`${prefix}maritalStatus`} is="Divorced">
                    <True>
                        <OutlinedDatePicker
                            name={`${prefix}dateOfDivorce`}
                            label="Date of Divorce"
                            disableFuture
                            validate={requiredValidator}
                            disabled={!editable}
                            labelprefixed="true"
                        />
                    </True>
                </Condition>
                <OutlinedSearchSelectField
                    name={`${prefix}isRSAResident`}
                    options={[
                        {
                            value: 'Yes',
                            label: 'Yes',
                        },
                        {
                            value: 'No',
                            label: 'No',
                        },
                    ]}
                    disabled={!editable}
                    label="Are you a South African resident?"
                    validate={requiredValidator}
                    labelprefixed="true"
                    placeholder="Option"
                />

                <WhenFieldChanges
                    field={`${prefix}isRSAResident`}
                    set={`${prefix}idNumber`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${prefix}isRSAResident`}
                    set={`${prefix}dateOfBirth`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${prefix}isRSAResident`}
                    set={`${prefix}placeOfBirth`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${prefix}isRSAResident`}
                    set={`${prefix}countryOfBirth`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${prefix}isRSAResident`}
                    set={`${prefix}countryOfResidence`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${prefix}isRSAResident`}
                    set={`${prefix}proofOfIdentification`}
                    to={null}
                />

                <WhenFieldChanges
                    field={`${prefix}isRSAResident`}
                    set={`${prefix}passportNumber`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${prefix}isRSAResident`}
                    set={`${prefix}countryOfIssue`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${prefix}isRSAResident`}
                    set={`${prefix}dateOfIssue`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${prefix}isRSAResident`}
                    set={`${prefix}dateOfExpiry`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${prefix}isRSAResident`}
                    set={`${prefix}placeOfBirth`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${prefix}isRSAResident`}
                    set={`${prefix}proofOfIdentification`}
                    to={null}
                />
                <Condition when={`${prefix}isRSAResident`} is="Yes">
                    <True>
                        <OutlinedTextField
                            name={`${prefix}idNumber`}
                            label="ID Number"
                            validate={composeValidators(requiredValidator, validateRSAIdNumber)}
                            mask={NumberMask}
                            disabled={!editable}
                            labelprefixed="true"
                        />
                        <WhenFieldChanges
                            field={`${prefix}idNumber`}
                            set={'dateOfBirth'}
                            to={getDateOfBirthFromRsaId}
                        />
                        <WhenFieldChanges
                            field={`${prefix}idNumber`}
                            set={`${prefix}dateOfBirth`}
                            to={getDateOfBirthFromRsaId}
                        />
                        <OutlinedDatePicker
                            name={`${prefix}dateOfBirth`}
                            validate={requiredValidator}
                            labelprefixed="true"
                            disabled={!editable}
                            disableFuture
                        />
                        <OutlinedTextField
                            name={`${prefix}placeOfBirth`}
                            label="Place of Birth"
                            validate={requiredValidator}
                            labelprefixed="true"
                            disabled={!editable}
                            mask={FirstNameMask}
                        />
                        <OutlinedSearchSelectField
                            name={`${prefix}countryOfBirth`}
                            options={Countries}
                            disabled={!editable}
                            label="Country of Birth"
                            validate={requiredValidator}
                            labelprefixed="true"
                        />
                        <OutlinedSearchSelectField
                            name={`${prefix}countryOfResidence`}
                            options={Countries}
                            disabled={!editable}
                            label="Country of Residence"
                            validate={requiredValidator}
                            labelprefixed="true"
                        />
                        <FixedWidthFileUpload
                            name={`${prefix}proofOfIdentification`}
                            label="* ID Document"
                            validate={requiredValidator}
                            readonly={!editable}
                        />
                    </True>
                </Condition>
                <Condition when={`${prefix}isRSAResident`} is="No">

                    <True>
                        <OutlinedTextField
                            name={`${prefix}passportNumber`}
                            label="Passport Number"
                            validate={requiredValidator}
                            disabled={!editable}
                            labelprefixed="true"
                        />
                        <OutlinedSearchSelectField
                            name={`${prefix}countryOfIssue`}
                            options={Countries}
                            disabled={!editable}
                            label="Country of Issue"
                            validate={requiredValidator}
                            labelprefixed="true"
                        />
                        <OutlinedDatePicker
                            name={`${prefix}dateOfIssue`}
                            validate={requiredValidator}
                            labelprefixed="true"
                            disabled={!editable}
                            disableFuture
                        />
                        <OutlinedDatePicker
                            name={`${prefix}dateOfExpiry`}
                            validate={requiredValidator}
                            labelprefixed="true"
                            disabled={!editable}
                        />
                        <OutlinedTextField
                            name={`${prefix}placeOfBirth`}
                            label="Place of Birth"
                            disabled={!editable}
                            validate={requiredValidator}
                            mask={FirstNameMask}
                            labelprefixed="true"
                        />
                        <OutlinedSearchSelectField
                            name={`${prefix}countryOfBirth`}
                            options={Countries}
                            disabled={!editable}
                            label="Country of Birth"
                            validate={requiredValidator}
                            labelprefixed="true"
                        />
                        <OutlinedSearchSelectField
                            name={`${prefix}countryOfResidence`}
                            options={Countries}
                            disabled={!editable}
                            label="Country of Residence"
                            validate={requiredValidator}
                            labelprefixed="true"
                        />
                        <FixedWidthFileUpload
                            name={`${prefix}proofOfIdentification`}
                            label="* Passport"
                            validate={requiredValidator}
                            readonly={!editable}
                        />
                    </True>
                </Condition>
                <ClaimantNavigation
                    form={form}
                    handleBackStepper={handleBackStepper}
                    handleNext={handleNext}
                    prefix={'policyholder-personal'}
                />
                <Grid item xs={12} className={classes.navigationGroup}>
                    <NavigationButton
                        className={classNames(
                            classes.buttonSecondary,
                            classes.buttonAlignment,
                            classes.fullWidthButton
                        )}
                        onClick={() => handleBack(form)}
                        id="policy-details-back"
                        label="Previous Section"
                    />
                </Grid>
            </Grid>

        </Grid>
    );
};

export const PersonalDetails = withStyles(styles)(personalDetails);
