import * as React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from '../../../styles/styles';
import { getBankName } from '../../../shared/banks';

function claimOutcomes({ claimOutcomes, classes }) {
    if (!claimOutcomes || claimOutcomes.length === 0) {
        return null;
    }
    return (
        <React.Fragment>
            <Grid container style={{ paddingTop: '15px', paddingBottom: '15px' }}>
                <Typography className={classes.cardSubHeadingSubtle}>Claim outcome</Typography>
                <Typography className={classes.cardBody}>
                    We are pleased to inform you that the claim submitted has been accepted and is
                    now finalised. The claim has been paid and you can view the payment details
                    below:
                </Typography>
            </Grid>
            <Grid container justify="space-between" style={{ marginRight: '12px' }}>
                <Grid item lg={2} md={2} style={{ overflowX: 'auto' }}>
                    <Typography className={classes.cardLabel}>Policy Number</Typography>
                </Grid>
                <Grid item lg={2} md={2} style={{ overflowX: 'auto' }}>
                    <Typography className={classes.cardLabel}>Bank</Typography>
                </Grid>
                <Grid item lg={2} md={2} style={{ overflowX: 'auto' }}>
                    <Typography className={classes.cardLabel}>Account Number</Typography>
                </Grid>
                <Grid item lg={2} md={2} style={{ overflowX: 'auto' }}>
                    <Typography className={classes.cardLabel}>Account Holder</Typography>
                </Grid>
                <Grid item lg={2} md={2} style={{ overflowX: 'auto', textAlign: 'right' }}>
                    <Typography className={classes.cardLabel}>Amount paid</Typography>
                </Grid>
            </Grid>
            {claimOutcomes.map(outcome => (
                // eslint-disable-next-line react/jsx-key
                <Grid container justify="space-between" style={{ paddingBottom: '10px', marginRight: '12px' }}>
                    <Grid item lg={2} md={2} style={{ overflowX: 'auto' }}>
                        <Typography className={classes.cardValue}>
                            {outcome.policyNumber}
                        </Typography>
                    </Grid>
                    <Grid item lg={2} md={2} style={{ overflowX: 'auto' }}>
                        <Typography className={classes.cardValue}>
                            {getBankName(outcome.bank)}
                        </Typography>
                    </Grid>
                    <Grid item lg={2} md={2} style={{ overflowX: 'auto' }}>
                        <Typography className={classes.cardValue}>
                            {outcome.accountNumber}
                        </Typography>
                    </Grid>
                    <Grid item lg={2} md={2} style={{ overflowX: 'auto' }}>
                        <Typography className={classes.cardValue}>
                            {outcome.accountHolder}
                        </Typography>
                    </Grid>
                    <Grid item lg={2} md={2} style={{ overflowX: 'auto', textAlign: 'right' }}>
                        <Typography className={classes.cardValue}>
                            {`R ${outcome.amountPaid.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}`}
                        </Typography>
                    </Grid>
                </Grid>
            ))}
        </React.Fragment>
    );
}

export const ClaimOutcomes = withStyles(styles)(claimOutcomes);
