import { env } from '../../env/env';
import axios from '../../http-request';
import { LOAD_POSTAL_CODES } from './types';

export function loadPostalCodesAction() {
    return async (dispatch, getState) => {
        const { lookups } = getState();
        if (lookups.postalCodesBox.length === 0 || lookups.postalCodesStreet.length === 0) {
            axios.get(`${env.REACT_APP_API_URL}/lookups/postalCodes`).then(response => {
                dispatch({ type: LOAD_POSTAL_CODES, lookups: response.data });
            });
        }
        return;
    };
}

export * from './types';
