import * as React from 'react';

import { Field } from 'react-final-form';
import { FormControl } from '..';
import { ToggleButtonGroup } from '../toggle-button-group';

export const FixedWidthButtonGroup = ({ name, options, ...rest }) => {
    return (
        <FormControl>
            <Field {...rest} name={name} component={ToggleButtonGroup} options={options} />
        </FormControl>
    );
};
