import { UPLOADS_IN_PROGRESS } from './types';

export const initialState = {
    uploadsCount: 0,
};

export function uploadsInProgressReducer(state = initialState, action) {
    switch (action.type) {
        case UPLOADS_IN_PROGRESS:
            return { uploadsCount: Math.max(0, state.uploadsCount + action.payload) };
        default:
            return state;
    }
}
