import { withStyles } from '@material-ui/core';
import { FormHelperText, Typography } from '@material-ui/core';
import * as React from 'react';
import { Field } from 'react-final-form';
import Select from 'react-virtualized-select';
import {
    callAllFunctions,
    FormControl,
    getComponentId,
    nameToLabel,
    processMetaForErrors,
} from '../..';
import { raspberry } from '../../../styles/colours';
import { getValidatedLabel } from '../../form-component-utilities/label-validator/label-validator';
import styles from '../styles';
import './override.css';

const NoOptionsMessage = props => {
    return (
        <Typography color="textSecondary" className={props.classes.noOptionsMessage}>
            No options
        </Typography>
    );
};

const SearchSelectField = withStyles(styles)(props => {
    const {
        classes,
        input,
        meta,
        id,
        label,
        helperText,
        options,
        onChange,
        disabled,
        hideLabel,
        shaded,
        placeholder,
        matchPos,
        ...rest
    } = props;
    const { name, value } = input;
    const { errorMessage, showError } = processMetaForErrors(meta);
    const color = showError ? raspberry : 'rgba(0, 0, 0, 0.54)';

    return (
        <div className={classes.textField}>
            {!hideLabel && (
                <Typography className={classes.cssLabel} style={showError ? { color } : {}}>
                    {!label
                        ? getValidatedLabel(rest, nameToLabel({ label, name }))
                        : getValidatedLabel(rest, label)}
                </Typography>
            )}
            <Select
                {...rest}
                style={showError ? { borderColor: color } : {}}
                className={shaded ? classes.selectControlShaded : classes.selectControl}
                noResultsText={<NoOptionsMessage {...{ classes: classes }} />}
                id={getComponentId({ id, name })}
                name={name}
                matchPos={matchPos}
                options={options}
                value={transformFromValue(options, value)}
                onChange={op => callAllFunctions(onChange, input.onChange)(transformToValue(op))}
                placeholder={`Select ${placeholder || nameToLabel({ label, name }).toLowerCase()}`}
                disabled={disabled}
            />
            {(showError || !!helperText) && (
                // @ts-ignore
                <FormHelperText error={showError} component="pre">
                    {showError ? errorMessage : helperText}
                </FormHelperText>
            )}
        </div>
    );
});

function transformFromValue(options, value) {
    if (options) {
        return options.filter(op => op.value === value)[0];
    }
    return undefined;
}

function transformToValue(option) {
    return (option || { value: undefined }).value;
}

export const VirtualizedSearchSelectField = ({ name, ...rest }) => {
    return (
        <FormControl>
            <Field
                {...rest}
                name={name}
                // @ts-ignore
                component={SearchSelectField}
            />
        </FormControl>
    );
};
