import { Grid } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import { connect } from 'react-redux';
import {
    OutlinedSearchSelectField,
    OutlinedTextField,
    VirtualizedSearchSelectField,
    WhenFieldChanges,
} from '../../../../forms';
import { NumberMask } from '../../../../forms/input-masks';
import { requiredValidator } from '../../../../forms/validations';
import { getLookups, loadBankBranchCodesAction } from '../../../../lookups';
import { AccountHolderRelationships } from '../../../../shared/lookups';

const BankAccount = ({
    bankNames,
    editable,
    loadBankBranchCodesAction,
    prefix,
    universalBranches,
    payerType = '',
}) => {
    const [branchCodes, setBranchCodes] = useState([]);
    const [loadBranches, setLoadBranches] = useState(false);
    const [loadingBranches, setLoadingBranches] = useState(false);
    const [branchName, setBranchName] = useState('');

    const getBranches = useCallback((bankName) => {
        if (loadBranches && !loadingBranches) {
            setLoadingBranches(true);
            setBranchCodes([]);
            loadBankBranchCodesAction(bankName)
                .then(result => {
                    setBranchCodes(result.bankBranchCodes);
                    localStorage.setItem('branchCodes', JSON.stringify(result.bankBranchCodes));
                })
                .finally(() => {
                    setLoadBranches(false);
                    setLoadingBranches(false);
                });
        }
    }, [loadBranches, loadingBranches, loadBankBranchCodesAction]);

    useEffect(() => {
        if (bankNames) {
            const storedBranchCodes = localStorage.getItem('branchCodes');
            if (storedBranchCodes) {
                setBranchCodes(JSON.parse(storedBranchCodes));
            } else {
                getBranches(bankNames);
            }
        }
    }, [bankNames, getBranches]);

    return (
        <React.Fragment>
            <Grid item xs={12}>
                <VirtualizedSearchSelectField
                    shaded={false}
                    name={`${prefix}bankName`}
                    label="Bank Name"
                    labelprefixed="true"
                    options={bankNames}
                    disabled={!editable}
                    validate={requiredValidator}
                    matchPos="start"
                    matchProp="label"
                />
            </Grid>
            <Grid item xs={12}>
                <OutlinedTextField
                    name={`${prefix}accountNumber`}
                    label="Account Number"
                    disabled={!editable}
                    mask={NumberMask}
                    labelprefixed="true"
                    validate={requiredValidator}
                />
            </Grid>
            <Grid item xs={12}>
                <OutlinedSearchSelectField
                    name={`${prefix}accountType`}
                    id={`${prefix}accountType`}
                    options={[
                        { value: 'Current', label: 'Current' },
                        { value: 'Savings', label: 'Savings' },
                        { value: 'Transmission', label: 'Transmission' }
                    ]}
                    placeholder="type"

                    validate={requiredValidator}
                    disabled={!editable}
                    labelprefixed="true"
                />
            </Grid>
            <Grid item xs={12}>
                {
                    (payerType === 'Premium Payer') &&
                    <OutlinedSearchSelectField
                        name={`${prefix}relationshipToAccountHolder`}
                        options={AccountHolderRelationships}
                        label="Account holder relationship"
                        validate={requiredValidator}
                        labelprefixed="true" />
                }
            </Grid>

            <Field
                name={`${prefix}bankName`}
                subscription={{ value: true }}
                component={({ input: { value: bankName } }) => {
                    const universalBranch = universalBranches.find(uc => uc.bankName === bankName);
                    if (universalBranch) {
                        const currentBranch =
                            branchCodes && branchCodes.find(cb => cb.bankName === bankName);
                        if (!currentBranch) {
                            setBranchName(universalBranch.label);
                            setBranchCodes([universalBranch]);
                        }
                    } else {
                        getBranches(bankName);
                    }

                    return (
                        <Grid item xs={12} lg={12}>
                            <VirtualizedSearchSelectField
                                name={`${prefix}branchCode`}
                                options={branchCodes}
                                label="Branch Name"
                                disabled={universalBranch && branchCodes.length === 1}
                                matchPos="start"
                                matchProp="label"
                                onChange={(selectedBranchCode) => {
                                    const selectedBranch = branchCodes.find(branch => branch.value === selectedBranchCode);
                                    const selectedBranchName = selectedBranch ? selectedBranch.label : '';
                                    setBranchName(selectedBranchName);
                                }}
                            />
                        </Grid>
                    );
                }}
            />
            <WhenFieldChanges
                field={`${prefix}bankName`}
                set={`${prefix}branchCode`}
                to={bankName => {
                    const universalBranch = universalBranches.find(uc => uc.bankName === bankName);
                    if (universalBranch) {
                        return universalBranch.value;
                    }
                    setLoadBranches(true);
                    return null;
                }}
            />
            <Grid item xs={12}>
                <OutlinedTextField
                    name={`${prefix}branchCode`}
                    label="Branch Code"
                    mask={NumberMask}
                    disabled
                />
                <WhenFieldChanges
                    field={`${prefix}branchCode`}
                    set={`${prefix}branchName`}
                    to={branchName}
                />
            </Grid>
            <Grid item xs={12} style={{display: 'none'}}>
                <OutlinedTextField
                    name={`${prefix}branchName`}
                    disabled
                    value={branchName}
                />
            </Grid>
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    const { bankNames, universalBranches } = getLookups(state);
    return {
        bankNames,
        universalBranches,
    };
};

const mapDispatchToProps = { loadBankBranchCodesAction };

export const BankAccountDetailsView = connect(mapStateToProps, mapDispatchToProps)(BankAccount);
