import { SET_CLAIM, SET_REFERENCED_CLAIMS } from './claim-selection-types';

export const initialState = {
    claim: null,
    referencedClaims: [],
    isOnlyReferencedClaims: false,
};

export function claimSelectionReducer(state = initialState, action) {
    switch (action.type) {
        case SET_CLAIM:
            return { ...state, claim: action.claim };
        case SET_REFERENCED_CLAIMS:
            return {
                ...state,
                referencedClaims: action.referencedClaims,
                isOnlyReferencedClaims: action.isOnlyReferencedClaims,
            };
        default:
            return state;
    }
}
