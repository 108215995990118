import { Typography, withStyles } from '@material-ui/core';
import * as React from 'react';
import styles from '../../../styles/styles';

/**
 * @typedef {object} Props
 * @property {string} text
 * @property {React.ReactNode} [children]
 * @param {Props & import('../../../styles/styles').StyledComponent} props
 */
const formHeading = ({ classes, text, children }) => {
    return (
        <Typography variant="h5" className={[classes.spacedTypography, classes.pageHeader].join(' ')}>
            {text || children}
        </Typography>
    );
};

export const FormHeading = withStyles(styles)(formHeading);
