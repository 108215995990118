import { withStyles, Grid } from '@material-ui/core';
import React from 'react';
import { AddressDetails as CommonAddress } from '../common/address-details';
import styles from '../../../styles/styles';
import { DeceasedNavigation } from '../../common/navigation/deceased-navigation';

const addressDetails = function ({
    classes,
    handleBack,
    handleBackStepper,
    handleNext,
    form,
    ...rest
}) {
    return (
        <Grid container justify="flex-start">
            <CommonAddress {...rest} prefix="deceasedDetails.addressDetails." />
            <DeceasedNavigation
                form={form}
                handleBack={handleBack}
                handleBackStepper={handleBackStepper}
                handleNext={handleNext}
                prefix="deceased-address"
            />
        </Grid>
    );
};

export const AddressDetails = withStyles(styles)(addressDetails);
