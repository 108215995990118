import * as React from 'react';

import { Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';

export const WhenFieldChanges = ({ field, set, to, ...rest }) => {
    const { becomes, condition } = rest;
    return (
        // No subscription. We only use Field to get to the change function
        <Field name={set} subscription={{ value: true }}>
            {({ input: { onChange, value: setValue } }) => (
                <OnChange name={field}>
                    {fieldValue => {
                        if (
                            (becomes == null ||
                                fieldValue === evaluate(fieldValue, becomes, setValue)) &&
                            (condition == null || evaluate(fieldValue, condition, setValue))
                        ) {
                            setImmediate(() => {
                                const newValue = evaluate(fieldValue, to, setValue);
                                onChange(newValue);
                            });

                        }
                    }}
                </OnChange>
            )}
        </Field>
    );
}

function evaluate(value, evaluee, setValue) {
    if (typeof evaluee === 'function') {
        return evaluee(value, setValue);
    }

    return evaluee;
}
