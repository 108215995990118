const appName = 'liberty-death-claims';

export const noFeatureNameError = 'Feature name cannot be blank';

export function createFeatureActionNamer(featureName = '') {
    const trimmedFeatureName = featureName.toString().trim();
    if (!trimmedFeatureName) {
        throw new Error(noFeatureNameError);
    }
    return function _createActionName(actionName = '') {
        const trimmedActionName = actionName.toString().trim();
        if (!trimmedActionName) {
            throw new Error(noActionNameError(trimmedFeatureName));
        }
        return appName + '/' + trimmedFeatureName + '/' + trimmedActionName;
    };
}

export function noActionNameError(featureName) {
    return `Action name for "${featureName}" cannot be blank`;
}
