import * as React from 'react';

import { CircularProgress, Grid, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { styles } from './styles';

class loadingScreenView extends React.Component {
    //private  focusRef;
    constructor({ props }) {
        super(props);
        this.focusRef = React.createRef();
    }

    componentDidUpdate() {
        if (this.focusRef.current) {
            this.focusRef.current.focus();
        }
    }

    render() {
        const { isLoading, classes } = this.props;
        if (!isLoading) {
            return null;
        }

        setTimeout(() => {
            document.body.scrollTop = document.documentElement.scrollTop = 0;
        }, 200);

        return (
            <React.Fragment>
                <div className={classes.greyOverlay}>
                    <input ref={this.focusRef} style={{ opacity: 0 }} />
                </div>
                <Grid
                    container
                    justify="center"
                    alignItems="center"
                    className={classes.clearOverlay}
                >
                    <Grid item>
                        <CircularProgress />
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        isLoading: state.loadingScreenState.loadingCount > 0,
    };
};

export const LoadingScreenView = connect(
    mapStateToProps,
    {}
)(withStyles(styles)(loadingScreenView));
