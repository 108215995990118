import * as React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { Field } from 'react-final-form';
import { OutlinedPhoneField, OutlinedSearchSelectField, OutlinedTextField } from '../../../forms';
import { EmailMask, TelephoneNumberMask } from '../../../forms/input-masks';
import { conditionalRequired } from '../../../forms/validations';
import { getCommunicationMethods } from '../../../shared/helper-functions';
import { CommunicationMethods } from '../../../shared/lookups';
import styles from '../../../styles/styles';
import { ClaimantNavigation } from '../../common/navigation/claimant-navigation';

const entityContactDetails = function ({
    classes,
    editable,
    handleBackStepper,
    handleNext,
    form,
    index,
    stepsLength,
    claimantIndex,
}) {
    return (
        <Grid container spacing={10} justify="flex-start">
            <Grid item xs={12} lg={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
                <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                <Typography className={classes.stepHeader}>Contact Details</Typography>
            </Grid>
            <Grid item xs={12} lg={12}>
                <OutlinedPhoneField
                    name={`claimants[${claimantIndex}].entity.telephoneNumber`}
                    label="Telephone Number"
                    mask={TelephoneNumberMask}
                    disabled={!editable}
                    validate={conditionalRequired(values => {
                        if (
                            values &&
                            values['claimants'] &&
                            values[`claimants`][claimantIndex] &&
                            values[`claimants`][claimantIndex]['entity'] &&
                            values[`claimants`][claimantIndex]['entity'][
                                'preferredMethodOfCommunication'
                            ]
                        ) {
                            if (
                                values[`claimants`][claimantIndex]['entity'][
                                    'preferredMethodOfCommunication'
                                ] === 'Telephone'
                            )
                                return true;
                        }

                        return false;
                    })}
                />
            </Grid>
            <Grid item xs={12} lg={12}>
                <OutlinedPhoneField
                    name={`claimants[${claimantIndex}].entity.cellphoneNumber`}
                    label="Cellphone Number"
                    mask={TelephoneNumberMask}
                    disabled={!editable}
                    validate={conditionalRequired(values => {
                        if (
                            values &&
                            values['claimants'] &&
                            values[`claimants`][claimantIndex] &&
                            values[`claimants`][claimantIndex]['entity'] &&
                            values[`claimants`][claimantIndex]['entity'][
                                'preferredMethodOfCommunication'
                            ]
                        ) {
                            if (
                                values[`claimants`][claimantIndex]['entity'][
                                    'preferredMethodOfCommunication'
                                ] === 'Cellphone'
                            )
                                return true;
                        }

                        return false;
                    })}
                />
            </Grid>
            <Grid item xs={12} lg={12}>
                <OutlinedPhoneField
                    name={`claimants[${claimantIndex}].entity.faxNumber`}
                    label="Fax Number"
                    mask={EmailMask}
                    disabled={!editable}
                />
            </Grid>
            <Grid item xs={12} lg={12}>
                <OutlinedTextField
                    name={`claimants[${claimantIndex}].entity.emailAddress`}
                    label="Email Address"
                    mask={EmailMask}
                    disabled={!editable}
                    validate={conditionalRequired(values => {
                        if (
                            values &&
                            values['claimants'] &&
                            values[`claimants`][claimantIndex] &&
                            values[`claimants`][claimantIndex]['entity'] &&
                            values[`claimants`][claimantIndex]['entity'][
                                'preferredMethodOfCommunication'
                            ]
                        ) {
                            if (
                                values[`claimants`][claimantIndex]['entity'][
                                    'preferredMethodOfCommunication'
                                ] === 'Email'
                            )
                                return true;
                        }

                        return false;
                    })}
                />
            </Grid>
            <Field
                name={`claimants[${claimantIndex}].entity`}
                subscription={{ value: true }}
                component={({ input: { value: values } }) => {
                    const methods = getCommunicationMethods(values);
                    return (
                        <Grid item xs={12} lg={12}>
                            <OutlinedSearchSelectField
                                label="Preferred Method Of Communication"
                                name={`claimants[${claimantIndex}].entity.preferredMethodOfCommunication`}
                                options={methods.length > 0 ? methods : CommunicationMethods}
                                labelprefixed="true"
                                disabled={!editable}
                                shaded={false}
                            />
                        </Grid>
                    );
                }}
            />
            <ClaimantNavigation
                form={form}
                handleBackStepper={handleBackStepper}
                handleNext={handleNext}
                prefix={`claimant-${claimantIndex}-contact`}
            />
        </Grid>
    );
};

export const EntityContactDetails = withStyles(styles)(entityContactDetails);
