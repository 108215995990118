import { Grid, Paper, Typography, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import styles from '../../../styles/styles';
import { StepImage } from '../step-image';

function stepsInformation({ classes, components }) {
    let stepInfoWidth = components.length > 5 ? 185 : 195;

    if (components.length > 6) {
        stepInfoWidth = 160;
    }

    return (
        <React.Fragment>
            <Grid item xs={12} className={classes.grayTitle}>
                <Typography className={classes.grayTitleText}>
                    Steps in the claim form that you will need to complete
                </Typography>
            </Grid>
            <Paper
                style={{
                    border: '1px solid #AFB3C2',
                    borderRadius: '4px',
                    width: '1140px',
                    marginTop: '24px',
                    backgroundColor: '#FAFAFF',
                    padding: '18px 0px 18px 14px',
                }}
            >
                <Grid item xs={12} container justify="flex-start" spacing={1}>
                    {components.map((step, index) => {
                        return (
                            <StepInformation
                                key={`introduction-step-${index}`}
                                classes={classes}
                                index={index}
                                information={step.information}
                                name={step.name}
                                width={stepInfoWidth}
                                lengthOfComponents={components.length}
                            />
                        );
                    })}
                </Grid>
            </Paper>
        </React.Fragment>
    );
}

const StepInformation = ({ classes, index, information, name, width, lengthOfComponents }) => {
    return (
        <Grid
            container
            justify="flex-start"
            className={[classes.stepperItem, classes.step].join(' ')}
            item
            style={{ maxWidth: `${width}px` }}
        >
            <Grid item xs={12} container justify="flex-start">
                <StepImage currentIndex={index} activeIndex={-1} />
                <Typography style={{ marginLeft: lengthOfComponents > 6 ? '2px' : '7px', fontWeight: 600, fontSize: '16px' }}>
                    {name}
                </Typography>
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'left', marginTop: '8px' }}>
                <Typography
                    style={{
                        color: '#141A2B',
                        fontSize: '14px',
                        lineHeight: '20px',
                    }}
                >
                    {information}
                </Typography>
            </Grid>
        </Grid>
    );
};

export const StepsInformation = withStyles(styles)(stepsInformation);
