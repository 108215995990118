import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Typography,
    withStyles,
} from '@material-ui/core';
import { Error } from '@material-ui/icons';
import * as React from 'react';
import { connect } from 'react-redux';
import styles from '../../styles/styles';
import { closeDialogAction } from './actions';

function confirmationDialog({ dialogDetails, closeDialog }) {
    const executeDialogAction = async dialogAction => {
        if (dialogAction) {
            await dialogAction.action();
        }
        closeDialog();
    };
    const cancelAction = () => {
        executeDialogAction();
    };
    const noAction = () => {
        executeDialogAction(dialogDetails.noAction);
    };
    const yesAction = () => {
        executeDialogAction(dialogDetails.yesAction);
    };

    const dialogContent = (dialogDetails && dialogDetails.content) || {};
    return (
        <Dialog open={dialogDetails && dialogDetails.dialogOpen} fullWidth>
            <DialogTitle>{getTitle(dialogContent)}</DialogTitle>
            <DialogContent>{dialogContent.body}</DialogContent>
            <DialogActions>
                {getDialogActions(dialogDetails, cancelAction, noAction, yesAction)}
            </DialogActions>
        </Dialog>
    );
}

const getTitle = dialogContent => {
    if (dialogContent.error) {
        return <ErrorTitle />;
    }

    return dialogContent.title;
};

const ErrorTitle = () => (
    <Grid container spacing={0} justify="flex-start">
        <Grid item xs={1}>
            <Error color="error" fontSize="large" />
        </Grid>
        <Grid item xs={11}>
            <Typography variant="h5">...Oops</Typography>
        </Grid>
    </Grid>
);

const getDialogActions = (dialogDetails, cancelAction, noAction, yesAction) => {
    if (!dialogDetails.yesAction && !dialogDetails.noAction) {
        return (
            <Button id="close-button" onClick={cancelAction}>
                Close
            </Button>
        );
    }

    const noLabel = (dialogDetails.noAction && dialogDetails.noAction.label) || 'No';
    const yesLabel = (dialogDetails.yesAction && dialogDetails.yesAction.label) || 'Yes';
    return (
        <React.Fragment>
            {dialogDetails.canCancel && (
                <Button id="cancel-button" onClick={cancelAction}>
                    {dialogDetails.cancelLabel || 'Cancel'}
                </Button>
            )}
            <Button id="no-button" onClick={noAction}>
                {noLabel}
            </Button>
            <Button id="yes-button" onClick={yesAction} color="secondary">
                {yesLabel}
            </Button>
        </React.Fragment>
    );
};

const mapStateToProps = state => ({
    dialogDetails: state.confirmationDialog,
});

const mapDispatchToProps = {
    closeDialog: closeDialogAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(confirmationDialog));
