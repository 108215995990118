import { Grid, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import {
    Condition,
    False,
    OutlinedTextField,
    OutlinedSearchSelectField,
    True,
    WhenFieldChanges,
} from '../../../../forms';
import { conditionalRequired, requiredValidator } from '../../../../forms/validations';
import { defaultCountry } from '../../../../shared/helper-functions';
import { addressTypes, Countries, Provinces } from '../../../../shared/lookups';
import styles from '../../../../styles/styles';
import { NumberMask } from '../../../../forms/input-masks';

const entityAddress = function ({ classes, editable, prefix }) {
    prefix = prefix + '.individual.companyDetails.';
    const addressType = 'policyholder.individual.addressDetails.addressType';
    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Typography className={classes.contentHeader}>Address details</Typography>
            </Grid>
            <Grid item xs={12}>
                <OutlinedSearchSelectField
                    name={addressType}
                    label="Address Type"
                    options={addressTypes()}
                    validate={requiredValidator}
                    disabled={!editable}
                />
            </Grid>
            <WhenFieldChanges
                field={addressType}
                set={`${prefix}entityAddressLine1`}
                to={null}
            />
            <WhenFieldChanges
                field={addressType}
                set={`${prefix}.entityAddressLine2`}
                to={null}
            />
            <WhenFieldChanges
                field={addressType}
                set={`${prefix}entityPostalCode`}
                to={null}
            />
            <WhenFieldChanges
                field={addressType}
                set={`${prefix}entitySuburb`}
                to={null}
            />
            <WhenFieldChanges
                field={addressType}
                set={`${prefix}entityTown`}
                to={null}
            />
            <WhenFieldChanges
                field={addressType}
                set={`${prefix}entityPostalCode`}
                to={null}
            />
            <WhenFieldChanges
                field={addressType}
                set={`${prefix}.entityProvince`}
                to={null}
            />
            <WhenFieldChanges
                field={addressType}
                set={`${prefix}entityCountry`}
                to={defaultCountry}
            />
            <Condition when={addressType} hasValue>
                <True>
                    <Condition when={addressType} is={['Street']}>
                        <True>
                            <Grid item xs={12}>
                                <OutlinedTextField
                                    name={`${prefix}entityAddressLine1`}
                                    label="Address line 1"
                                    validate={requiredValidator}
                                    disabled={!editable}
                                    labelprefixed="true"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <OutlinedTextField
                                    name={`${prefix}entityAddressLine2`}
                                    label="Address line 2"
                                    disabled={!editable}
                                    validate={conditionalRequired(
                                        values =>
                                            values &&
                                            values.claimants &&
                                            values.claimants.entity &&
                                            values.claimants.entity.addressLine3
                                    )}
                                />
                            </Grid>
                        </True>
                    </Condition>
                    <Grid item xs={12}>
                        <OutlinedTextField
                            name={`${prefix}entitySuburb`}
                            label="Town/Suburb"
                            disabled={!editable}
                            validate={requiredValidator}
                            labelprefixed="true"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <OutlinedTextField
                            name={`${prefix}entityTown`}
                            label="City"
                            disabled={!editable}
                            validate={requiredValidator}
                            labelprefixed="true"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <OutlinedTextField
                            name={`${prefix}entityPostalCode`}
                            labelprefixed="true"
                            label="Postal Code"
                            validate={requiredValidator}
                            disabled={!editable}
                            mask={NumberMask}
                        />
                    </Grid>
                    <Condition
                        when={addressType}
                        is={['Street']}
                    >
                        <True>
                            <Grid item xs={12}>
                                <OutlinedSearchSelectField
                                    name={`${prefix}entityProvince`}
                                    label="Province"
                                    options={Provinces}
                                    validate={requiredValidator}
                                    labelprefixed="true"
                                    disabled={!editable}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <OutlinedSearchSelectField
                                    name={`${prefix}entityCountry`}
                                    label="Country"
                                    options={Countries}
                                    validate={requiredValidator}
                                    labelprefixed="true"

                                />
                            </Grid>
                        </True>
                        <False>
                            <Grid item xs={12}>
                                <OutlinedSearchSelectField
                                    name={`${prefix}entityCountry`}
                                    label="Country"
                                    options={Countries}
                                    validate={requiredValidator}
                                    labelprefixed="true"
                                    disabled={!editable}
                                />
                            </Grid>
                        </False>
                    </Condition>
                </True>
            </Condition>
        </React.Fragment>
    );
};

export const EntityAddress = withStyles(styles)(entityAddress);
