import { push } from 'connected-react-router';
import { ClaimTypes } from '../../shared/lookups';
import { resetStepperSessionKeys } from '../claim/claim-stepper';
import { SET_CLAIM, SET_REFERENCED_CLAIMS } from './claim-selection-types';

export function goToCaptureClaim(claim, claimType) {
    const productPath = getProductPath((claim && claim.claimType) || claimType);
    resetStepperSessionKeys();
    return async function (dispatch) {
        dispatch(setClaim(claim));
        dispatch(push(`/${productPath}`));
    };
}

function getProductPath(claimType) {
    switch (claimType) {
        case 'Death':
        case ClaimTypes.DeathClaim:
            return 'death-claim';
        case 'RADeath':
        case ClaimTypes.RADeathClaim:
            return 'retirement-annuity';
        case 'Funeral':
        case ClaimTypes.FuneralClaim:
            return 'funeral-claim';
        case ClaimTypes.LivingLifestyleClaim:
            return 'living-lifestyle/living-lifestyle-claim';
        default:
            return '';
    }
}

export function setClaim(claim) {
    if (claim !== null && claim !== undefined) {
        sessionStorage.setItem('claimId', claim._id);
    }
    return {
        type: SET_CLAIM,
        claim,
    };
}

export function updateClaim(claim) {
    return async function (dispatch) {
        dispatch(updateClaimAction(claim));
    };
}

export function updateClaimAction(claim) {
    return {
        type: SET_CLAIM,
        claim,
    };
}

export function goToReferencedClaims(claims) {
    return function (dispatch) {
        dispatch(setReferencedClaims(claims, true));
        dispatch(push('/dashboard'));
    };
}

export function goToHome() {
    return function (dispatch) {
        resetStepperSessionKeys();
        dispatch(push('/dashboard'));
    };
}

export function setReferencedClaims(claims, isOnlyReferencedClaims) {
    return {
        type: SET_REFERENCED_CLAIMS,
        referencedClaims: claims,
        isOnlyReferencedClaims,
    };
}
