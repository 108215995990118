import * as React from 'react';
import { Paper, withStyles } from '@material-ui/core';
import styles from '../../../styles/styles';
import { VerticalStepper } from '../../common/vertical-stepper';
import { getComponents } from './claimant-components';

const claimantDetails = function ({ classes, editable, form, handleBack, ...rest }) {
    const vertComponents = getComponents();

    return (
        <Paper className={[classes.shadedPaper, classes.paperShadowLess].join(' ')}>
            <VerticalStepper
                {...rest}
                editable={editable}
                form={form}
                verticalComponents={vertComponents}
                verticalIndexName={`claimantStep`}
                handleBack={handleBack}
            />
        </Paper>
    );
};

export const ClaimantDetails = withStyles(styles)(claimantDetails);
