import * as React from 'react';

import { Field } from 'react-final-form';

import { FormControl } from '../form-control';
import { FileUpload } from '../file-upload/file-upload';

export const FixedWidthFileUpload = ({ name, fullWidth = true, ...rest }) => {
    return (
        <FormControl>
            <Field {...rest} name={name} fullWidth={fullWidth} component={FileUpload} />
        </FormControl>
    );
};
