import { Grid } from '@material-ui/core';
import React from 'react';
import {
    OutlinedDatePicker,
    OutlinedSearchSelectField,
    OutlinedTextField,
    WhenFieldChanges,
} from '../../../forms';
import { FixedWidthFileUpload } from '../../../forms/form-components/fixed-width-file-upload';
import { FirstNameMask, InitialsMask, LastNameMask, NumberMask } from '../../../forms/input-masks';
import { composeValidators, requiredValidator } from '../../../forms/validations';
import { Titles } from '../../../shared/lookups';
import { getDateOfBirthFromRsaId, validateRSAIdNumber } from '../../common/identity-details';

export const PersonDetails = ({ editable, prefix }) => {
    return (
        <React.Fragment>
            <Grid item xs={12}>
                <OutlinedSearchSelectField
                    name={`${prefix}title`}
                    options={Titles}
                    disabled={!editable}
                />
            </Grid>
            <Grid item xs={12}>
                <OutlinedTextField
                    name={`${prefix}initials`}
                    mask={InitialsMask}
                    validate={requiredValidator}
                    labelprefixed="true"
                    disabled={!editable}
                />
            </Grid>
            <Grid item xs={12}>
                <OutlinedTextField
                    name={`${prefix}firstName`}
                    mask={FirstNameMask}
                    validate={requiredValidator}
                    labelprefixed="true"
                    disabled={!editable}
                />
            </Grid>
            <Grid item xs={12}>
                <OutlinedTextField
                    name={`${prefix}secondName`}
                    mask={FirstNameMask}
                    disabled={!editable}
                />
            </Grid>
            <Grid item xs={12}>
                <OutlinedTextField
                    name={`${prefix}lastName`}
                    label="Surname"
                    mask={LastNameMask}
                    validate={requiredValidator}
                    labelprefixed="true"
                    disabled={!editable}
                />
            </Grid>
            <Grid item xs={12}>
                <OutlinedTextField
                    name={`${prefix}idNumber`}
                    label="RSA ID Number"
                    validate={composeValidators(requiredValidator, validateRSAIdNumber)}
                    mask={NumberMask}
                    disabled={!editable}
                    labelprefixed="true"
                />
            </Grid>
            <WhenFieldChanges
                field={`${prefix}idNumber`}
                set={`${prefix}dateOfBirth`}
                to={getDateOfBirthFromRsaId}
            />
            <Grid item xs={12}>
                <OutlinedDatePicker
                    name={`${prefix}dateOfBirth`}
                    validate={requiredValidator}
                    labelprefixed="true"
                    disabled={!editable}
                    disableFuture
                />
            </Grid>
            <Grid item xs={12}>
                <FixedWidthFileUpload
                    name={`${prefix}proofOfIdentification`}
                    label="* Upload proof of Identification"
                    validate={requiredValidator}
                    readonly={!editable}
                />
            </Grid>
        </React.Fragment>
    );
};
