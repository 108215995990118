import { Grid, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import styles from '../../../../styles/styles';
import { getDisplayDate } from '../../../../shared/helper-functions';
import { LabelValue } from '../../../common/label-value';
import { AddressDetails } from '../common/address-details';

const funeralParlourSummary = function ({ funeralParlour }) {
    if (!funeralParlour) {
        return null;
    }
    return (
        <Grid container justify="flex-start">
            <Grid item xs={12} style={{ marginTop: '24px' }}>
                <Typography variant="h6">Funeral Parlour Details</Typography>
                <hr />
            </Grid>
            <LabelValue
                label="Date of Funeral"
                value={getDisplayDate(funeralParlour.dateOfFuneral)}
            />
            <LabelValue label="Funeral Parlour Name" value={funeralParlour.name} />
            <LabelValue label="Contact Number" value={funeralParlour.telephoneNumber} />
            <AddressDetails addressDetails={funeralParlour.addressDetails} />
        </Grid>
    );
};

export const FuneralParlourSummary = withStyles(styles)(funeralParlourSummary);
