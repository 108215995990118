import { Tooltip, withStyles } from '@material-ui/core';
import { Visibility } from '@material-ui/icons';
import React from 'react';
import { connect } from 'react-redux';
import { viewDocument } from './actions/file-viewer';
import styles from './styles';

function fileViewer({ classes, files, viewDocument }) {
    if (!files || files.length < 1) {
        return null;
    }

    return (
        <Tooltip title="View document">
            <div id="view-file" className={classes.viewAction} onClick={() => viewDocument(files)}>
                <Visibility className={classes.viewIcon} />
            </div>
        </Tooltip>
    );
}

const mapStateToProps = () => ({});
const mapDispatchToProps = { viewDocument: viewDocument };

export const FileViewer = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(fileViewer));
