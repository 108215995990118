import * as React from 'react';

import { CheckCircle } from '@material-ui/icons';
import { withStyles } from '@material-ui/core';
import styles from '../../styles/styles';

const stepImage = ({ classes, activeIndex, currentIndex, submitted = false }) => {
    return (
        <div style={{ display: 'inline-block' }}>
            <Image
                classes={classes}
                activeIndex={activeIndex}
                currentIndex={currentIndex}
                submitted={submitted}
            />
        </div>
    );
};

const Image = ({ classes, activeIndex, currentIndex, submitted }) => {
    if (currentIndex < activeIndex || (submitted && currentIndex !== activeIndex)) {
        return <CheckCircle className={classes.completedCirle} />;
    }

    const circleClass =
        currentIndex === activeIndex ? classes.activeCircle : classes.inactiveCircle;
    return <div className={circleClass}>{currentIndex + 1}</div>;
};

export const StepImage = withStyles(styles)(stepImage);
