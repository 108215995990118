import * as React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from '../../../styles/styles';
import {
    EntityTypes,
    TypeSuffixes,
    Countries,
    TaxStatuses,
    EntityAssigneeRelationships,
} from '../../../shared/lookups';
import {
    Condition,
    OutlinedDatePicker,
    OutlinedSearchSelectField,
    OutlinedTextField,
    True,
    WhenFieldChanges,
} from '../../../forms';
import { FixedWidthFileUpload } from '../../../forms/form-components/fixed-width-file-upload';
import { NumberMask } from '../../../forms/input-masks';
import { requiredValidator } from '../../../forms/validations';
import { ClaimantNavigation } from '../../common/navigation/claimant-navigation';

const entityDetails = function ({
    classes,
    index,
    editable,
    stepsLength,
    handleBackStepper,
    handleNext,
    form,
    claimantIndex,
}) {
    return (
        <Grid container spacing={10} justify="flex-start">
            <Grid item xs={12} lg={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
            </Grid>

            <Grid item xs={12} style={{ display: 'flex' }}>
                <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                <Typography className={classes.stepHeader}>Entity Details</Typography>
            </Grid>
            <Grid item xs={12} lg={12}>
                <OutlinedSearchSelectField
                    shaded={false}
                    name={`claimants[${claimantIndex}].entity.entityType`}
                    options={EntityTypes}
                    disabled={!editable}
                    labelprefixed="true"
                    validate={requiredValidator}
                />
            </Grid>
            <WhenFieldChanges
                field={`claimants[${claimantIndex}].entity.entityType`}
                set={`claimants[${claimantIndex}].entity.trustDocumentType`}
                to={null}
            />
            <WhenFieldChanges
                field={`claimants[${claimantIndex}].entity.entityType`}
                set={`claimants[${claimantIndex}].entity.trustResolution`}
                to={null}
            />
            <WhenFieldChanges
                field={`claimants[${claimantIndex}].entity.entityType`}
                set={`claimants[${claimantIndex}].entity.trustDeed`}
                to={null}
            />
            <WhenFieldChanges
                field={`claimants[${claimantIndex}].entity.entityType`}
                set={`claimants[${claimantIndex}].entity.trustLetterOfAuthority`}
                to={null}
            />
            <WhenFieldChanges
                field={`claimants[${claimantIndex}].entity.entityType`}
                set={`claimants[${claimantIndex}].entity.ck1Document`}
                to={null}
            />
            <WhenFieldChanges
                field={`claimants[${claimantIndex}].entity.entityType`}
                set={`claimants[${claimantIndex}].entity.npoLegalDocument`}
                to={null}
            />
            <Condition
                when={`claimants[${claimantIndex}].entity.entityType`}
                is={['Trust', 'ForeignTrust']}
            >
                <True>
                    <Grid item xs={12} lg={12}>
                        <OutlinedSearchSelectField
                            shaded={false}
                            name={`claimants[${claimantIndex}].entity.entitySuffix`}
                            options={TypeSuffixes}
                            disabled={!editable}
                            labelprefixed="true"
                            validate={requiredValidator}
                        />
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <OutlinedTextField
                            name={`claimants[${claimantIndex}].entity.entityName`}
                            disabled={!editable}
                            labelprefixed="true"
                            validate={requiredValidator}
                        />
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <OutlinedTextField
                            name={`claimants[${claimantIndex}].entity.entityRegistrationNumber`}
                            label="Entity registration number"
                            disabled={!editable}
                            labelprefixed="true"
                            validate={requiredValidator}
                        />
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <OutlinedDatePicker
                            name={`claimants[${claimantIndex}].entity.dateOfIncorporation`}
                            disableFuture
                            disabled={!editable}
                        />
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <OutlinedSearchSelectField
                            shaded={false}
                            name={`claimants[${claimantIndex}].entity.countryOfIncorporation`}
                            options={Countries}
                            disabled={!editable}
                        />
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <OutlinedSearchSelectField
                            shaded={false}
                            name={`claimants[${claimantIndex}].entity.taxStatus`}
                            options={TaxStatuses}
                            disabled={!editable}
                            label="Tax status"
                            labelprefixed="true"
                            validate={requiredValidator}
                        />
                        <WhenFieldChanges
                            field={`claimants[${claimantIndex}].entity.taxStatus`}
                            set={`claimants[${claimantIndex}].entity.entityTaxNumber`}
                            to={null}
                        />
                    </Grid>
                    <Condition
                        when={`claimants[${claimantIndex}].entity.taxStatus`}
                        is="Tax Paying"
                    >
                        <True>
                            <Grid item xs={12} lg={12}>
                                <OutlinedTextField
                                    name={`claimants[${claimantIndex}].entity.entityTaxNumber`}
                                    disabled={!editable}
                                    labelprefixed="true"
                                    mask={NumberMask}
                                    validate={requiredValidator}
                                />
                            </Grid>

                            <Grid item xs={12} lg={12}>
                                <OutlinedSearchSelectField
                                    shaded={false}
                                    name={`claimants[${claimantIndex}].entity.countryOfTaxResidence`}
                                    options={Countries}
                                    disabled={!editable}
                                    labelprefixed="true"
                                    validate={requiredValidator}
                                />
                            </Grid>
                        </True>
                    </Condition>
                    <Grid item xs={12} lg={12}>
                        <OutlinedSearchSelectField
                            shaded={false}
                            name={`claimants[${claimantIndex}].entity.relationshipToLifeAssured`}
                            options={EntityAssigneeRelationships}
                            disabled={!editable}
                            label="Relationship to Life Assured"
                            labelprefixed="true"
                            validate={requiredValidator}
                        />
                        <WhenFieldChanges
                            field={`claimants[${claimantIndex}].entity.relationshipToLifeAssured`}
                            set={`claimants[${claimantIndex}].entity.relationshipToLifeAssuredDescription`}
                            to={null}
                        />
                    </Grid>
                    <Condition
                        when={`claimants[${claimantIndex}].entity.relationshipToLifeAssured`}
                        is="Other"
                    >
                        <True>
                            <Grid item xs={12} lg={12}>
                                <OutlinedTextField
                                    name={`claimants[${claimantIndex}].entity.relationshipToLifeAssuredDescription`}
                                    label="Please specify the nature of the relationship"
                                    disabled={!editable}
                                    labelprefixed="true"
                                    validate={requiredValidator}
                                />
                            </Grid>
                        </True>
                    </Condition>
                    <Grid item xs={12} lg={12}>
                        <OutlinedSearchSelectField
                            shaded={false}
                            name={`claimants[${claimantIndex}].entity.trustDocumentType`}
                            options={[
                                {
                                    value: 'TrustResolution',
                                    label: 'Trust Resolution',
                                },
                                {
                                    value: 'TrustDeed',
                                    label: 'Trust Deed',
                                },
                            ]}
                            disabled={!editable}
                            label="Document Type"
                            labelprefixed="true"
                            validate={requiredValidator}
                        />
                        <WhenFieldChanges
                            field={`claimants[${claimantIndex}].entity.entityType`}
                            set={`claimants[${claimantIndex}].entity.trustDocumentType`}
                            to={null}
                        />
                        <WhenFieldChanges
                            field={`claimants[${claimantIndex}].entity.trustDocumentType`}
                            set={`claimants[${claimantIndex}].entity.trustResolution`}
                            to={null}
                        />
                        <WhenFieldChanges
                            field={`claimants[${claimantIndex}].entity.trustDocumentType`}
                            set={`claimants[${claimantIndex}].entity.trustDeed`}
                            to={null}
                        />
                    </Grid>
                    <Condition
                        when={`claimants[${claimantIndex}].entity.trustDocumentType`}
                        is="TrustResolution"
                    >
                        <True>
                            <Grid item xs={12} md={12} xl={12}>
                                <FixedWidthFileUpload
                                    name={`claimants[${claimantIndex}].entity.trustResolution`}
                                    label="Upload Trust Resolution"
                                    validate={requiredValidator}
                                    labelprefixed="true"
                                    readonly={!editable}
                                />
                            </Grid>
                        </True>
                    </Condition>
                    <Grid item xs={12} md={12} xl={12}>
                        <FixedWidthFileUpload
                            name={`claimants[${claimantIndex}].entity.trustLetterOfAuthority`}
                            label="Upload Letter of Authority reflecting trustee names"
                            validate={requiredValidator}
                            labelprefixed="true"
                            readonly={!editable}
                        />
                    </Grid>
                    <Condition
                        when={`claimants[${claimantIndex}].entity.trustDocumentType`}
                        is="TrustDeed"
                    >
                        <True>
                            <Grid item xs={12} md={12} xl={12}>
                                <FixedWidthFileUpload
                                    name={`claimants[${claimantIndex}].entity.trustDeed`}
                                    label="Upload Trust Deed"
                                    validate={requiredValidator}
                                    labelprefixed="true"
                                    readonly={!editable}
                                />
                            </Grid>
                        </True>
                    </Condition>
                </True>
            </Condition>
            <Condition
                when={`claimants[${claimantIndex}].entity.entityType`}
                is={[
                    'PrivateCompany',
                    'PublicCompany',
                    'StateOwnedCompany',
                    'PersonalLiabilityCompany',
                    'ForeignCompany',
                    'CloseCorporation',
                    'NonProfitOrganisation',
                ]}
            >
                <True>
                    <Grid item xs={12} lg={12}>
                        <OutlinedSearchSelectField
                            shaded={false}
                            name={`claimants[${claimantIndex}].entity.entitySuffix`}
                            options={TypeSuffixes}
                            disabled={!editable}
                            labelprefixed="true"
                            validate={requiredValidator}
                        />
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <OutlinedTextField
                            name={`claimants[${claimantIndex}].entity.entityName`}
                            disabled={!editable}
                            labelprefixed="true"
                            validate={requiredValidator}
                        />
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <OutlinedTextField
                            name={`claimants[${claimantIndex}].entity.entityRegistrationNumber`}
                            label="Entity registration number"
                            disabled={!editable}
                            labelprefixed="true"
                            validate={requiredValidator}
                        />
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <OutlinedDatePicker
                            name={`claimants[${claimantIndex}].entity.dateOfIncorporation`}
                            label="Date of incorporation"
                            disableFuture
                            disabled={!editable}
                        />
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <OutlinedSearchSelectField
                            shaded={false}
                            name={`claimants[${claimantIndex}].entity.countryOfIncorporation`}
                            options={Countries}
                            disabled={!editable}
                            label="Country of incorporation"
                        />
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <OutlinedSearchSelectField
                            shaded={false}
                            name={`claimants[${claimantIndex}].entity.taxStatus`}
                            options={TaxStatuses}
                            disabled={!editable}
                            label="Tax status"
                            labelprefixed="true"
                            validate={requiredValidator}
                        />
                        <WhenFieldChanges
                            field={`claimants[${claimantIndex}].entity.taxStatus`}
                            set={`claimants[${claimantIndex}].entity.entityTaxNumber`}
                            to={null}
                        />
                    </Grid>
                    <Condition
                        when={`claimants[${claimantIndex}].entity.taxStatus`}
                        is="Tax Paying"
                    >
                        <True>
                            <Grid item xs={12} lg={12}>
                                <OutlinedTextField
                                    name={`claimants[${claimantIndex}].entity.entityTaxNumber`}
                                    disabled={!editable}
                                    labelprefixed="true"
                                    mask={NumberMask}
                                    validate={requiredValidator}
                                />
                            </Grid>

                            <Grid item xs={12} lg={12}>
                                <OutlinedSearchSelectField
                                    shaded={false}
                                    name={`claimants[${claimantIndex}].entity.countryOfTaxResidence`}
                                    options={Countries}
                                    disabled={!editable}
                                    labelprefixed="true"
                                    validate={requiredValidator}
                                />
                            </Grid>
                        </True>
                    </Condition>
                    <Grid item xs={12} lg={12}>
                        <OutlinedSearchSelectField
                            shaded={false}
                            name={`claimants[${claimantIndex}].entity.relationshipToLifeAssured`}
                            options={EntityAssigneeRelationships}
                            disabled={!editable}
                            label="Relationship to Deceased"
                            labelprefixed="true"
                            validate={requiredValidator}
                        />
                        <WhenFieldChanges
                            field={`claimants[${claimantIndex}].entity.relationshipToLifeAssured`}
                            set={`claimants[${claimantIndex}].entity.relationshipToLifeAssuredDescription`}
                            to={null}
                        />
                        <WhenFieldChanges
                            field={`claimants[${claimantIndex}].entity.relationshipToLifeAssured`}
                            set={`claimants[${claimantIndex}].entity.letterOfExecutorship`}
                            to={null}
                        />
                    </Grid>
                    <Condition
                        when={`claimants[${claimantIndex}].entity.relationshipToLifeAssured`}
                        is="Executor"
                    >
                        <True>
                            <Grid item xs={12} md={12} xl={12}>
                                <FixedWidthFileUpload
                                    name={`claimants[${claimantIndex}].entity.letterOfExecutorship`}
                                    label="Letter Of Executorship"
                                    validate={requiredValidator}
                                    labelprefixed="true"
                                    readonly={!editable}
                                />
                            </Grid>
                        </True>
                    </Condition>
                    <Condition
                        when={`claimants[${claimantIndex}].entity.relationshipToLifeAssured`}
                        is="Other"
                    >
                        <True>
                            <Grid item xs={12} lg={12}>
                                <OutlinedTextField
                                    name={`claimants[${claimantIndex}].entity.relationshipToLifeAssuredDescription`}
                                    label="Please specify the nature of the relationship"
                                    disabled={!editable}
                                    labelprefixed="true"
                                    validate={requiredValidator}
                                />
                            </Grid>
                        </True>
                    </Condition>
                </True>
            </Condition>
            <Condition
                when={`claimants[${claimantIndex}].entity.entityType`}
                is={[
                    'PrivateCompany',
                    'PublicCompany',
                    'StateOwnedCompany',
                    'PersonalLiabilityCompany',
                    'ForeignCompany',
                ]}
            >
                <True>
                    <Grid item xs={12} md={12} xl={12}>
                        <FixedWidthFileUpload
                            name={`claimants[${claimantIndex}].entity.companyResolution`}
                            label="Upload Company Resolution"
                            validate={requiredValidator}
                            labelprefixed="true"
                            readonly={!editable}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} xl={12}>
                        <FixedWidthFileUpload
                            name={`claimants[${claimantIndex}].entity.membersIdDocument`}
                            label="Upload ID Documents of the Members of the Company"
                            validate={requiredValidator}
                            labelprefixed="true"
                            readonly={!editable}
                        />
                    </Grid>
                </True>
            </Condition>
            <Condition when={`claimants[${claimantIndex}].entity.entityType`} is="CloseCorporation">
                <True>
                    <Grid item xs={12} md={12} xl={12}>
                        <FixedWidthFileUpload
                            name={`claimants[${claimantIndex}].entity.ck1Document`}
                            label="Upload CK1 Document"
                            validate={requiredValidator}
                            labelprefixed="true"
                            readonly={!editable}
                        />
                    </Grid>
                </True>
            </Condition>
            <Condition
                when={`claimants[${claimantIndex}].entity.entityType`}
                is="NonProfitOrganisation"
            >
                <True>
                    <Grid item xs={12} md={12} xl={12}>
                        <FixedWidthFileUpload
                            name={`claimants[${claimantIndex}].entity.npoLegalDocument`}
                            label="Upload NPO Legal document"
                            validate={requiredValidator}
                            labelprefixed="true"
                            readonly={!editable}
                        />
                    </Grid>
                </True>
            </Condition>
            <ClaimantNavigation
                form={form}
                handleBackStepper={handleBackStepper}
                handleNext={handleNext}
                prefix={`claimant-${claimantIndex}-entity`}
            />
        </Grid>
    );
};

export const EntityDetails = withStyles(styles)(entityDetails);
