import React from 'react';
import { Paper, withStyles } from '@material-ui/core';
import styles from '../../styles/styles';
import { LoadingScreenView } from '../../forms/loading-screen';

function loginPage({ classes, initiateLogin, goToClaimSelection, authentication }) {
    if (authentication.account) {
        setImmediate(() => {
            goToClaimSelection();
        });
    }
    return (
        <Paper className={classes.paper}>
            <LoadingScreenView />
        </Paper>
    );
}

export const LoginPage = withStyles(styles)(loginPage);
