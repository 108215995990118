import { Grid, Typography, withStyles } from '@material-ui/core';
import * as React from 'react';
import { getAddressLine, getDisplayDate, getLabel } from '../../../shared/helper-functions';
import { addressTypes, allRelationships, EntityTypes, TypeSuffixes } from '../../../shared/lookups';
import styles from '../../../styles/styles';
import { LabelValue } from '../../common/label-value';

const entityDetailsSummary = function ({ claimant }) {
    if (!claimant) {
        return null;
    }
    return (
        <Grid xs={12} sm={12}>
            <ClaimantType claimant={claimant} />
            <EntityDetails entity={claimant.entity} />
            <ContactDetails entity={claimant.entity} />
            <AddressDetails entity={claimant.entity} />
            <FinancialDetails claimant={claimant} />
        </Grid>
    );
};

const ClaimantType = function ({ claimant }) {
    return (
        <Grid container justify="flex-start">
            <Grid item xs={12} lg={12}>
                <Typography variant="h6">Claimant Type</Typography>
                <hr />
            </Grid>
            <LabelValue label="Claimant Type" value={claimant.claimantType} />
            <LabelValue
                label="Relationship to LifeAssured"
                value={getLabel(claimant.entity.relationshipToLifeAssured, allRelationships())}
            />
            <LabelValue
                label="Description of Relationship to Deceased"
                value={claimant.entity.relationshipToLifeAssuredDescription}
            />
            <LabelValue
                label="Relationship to Owner"
                value={getLabel(claimant.entity.relationshipToOwner, allRelationships())}
            />
            <LabelValue
                label="Description of Relationship to Owner"
                value={claimant.entity.relationshipToOwnerDescription}
            />
        </Grid>
    );
};

const EntityDetails = function ({ entity }) {
    return (
        <Grid container justify="flex-start">
            <Grid item xs={12} lg={12}>
                <Typography variant="h6">Entity Details</Typography>
                <hr />
            </Grid>
            <LabelValue label="Entity Type" value={getLabel(entity.entityType, EntityTypes)} />
            <LabelValue label="Entity Suffix" value={getLabel(entity.entitySuffix, TypeSuffixes)} />
            <LabelValue label="Entity Name" value={entity.entityName} />
            <LabelValue
                label="Entity Registration Number"
                value={entity.entityRegistrationNumber}
            />
            <LabelValue
                label="Date of Incorporation"
                value={getDisplayDate(entity.dateOfIncorporation)}
            />
            <LabelValue label="Country of Incorporation" value={entity.countryOfIncorporation} />
            <LabelValue label="Tax Status" value={entity.taxStatus} />
            <LabelValue label="Entity Tax Number" value={entity.entityTaxNumber} />
            <LabelValue label="Country of Tax Residence" value={entity.countryOfTaxResidence} />
            <LabelValue label="CK1 Document" value={entity.ck1Document} isFile={true} />
            <LabelValue label="Company Resolution" value={entity.companyResolution} isFile={true} />
            <LabelValue
                label="Authorized Persons Identity Document"
                value={entity.membersIdDocument}
                isFile={true}
            />
            <LabelValue
                label="Document Type"
                value={getLabel(entity.trustDocumentType, allRelationships())}
            />
            <LabelValue label="Trust Resolution" value={entity.trustResolution} isFile={true} />
            <LabelValue
                label="Letter of Authority"
                value={entity.trustLetterOfAuthority}
                isFile={true}
            />
            <LabelValue label="Trust Deed" value={entity.trustDeed} isFile={true} />
            <LabelValue label="NPO Legal document" value={entity.npoLegalDocument} isFile={true} />
        </Grid>
    );
};

const ContactDetails = function ({ entity }) {
    return (
        <Grid container justify="flex-start">
            <Grid item xs={12} lg={12}>
                <Typography variant="h6">Contact Details</Typography>
                <hr />
            </Grid>
            <LabelValue label="Cellphone Number" value={entity.cellphoneNumber} />
            <LabelValue label="Email Address" value={entity.emailAddress} />
        </Grid>
    );
};

const AddressDetails = function ({ entity }) {
    return (
        <Grid container justify="flex-start">
            <Grid item xs={12} lg={12}>
                <Typography variant="h6">Address Details</Typography>
                <hr />
            </Grid>
            <LabelValue label="Address Type" value={getLabel(entity.addressType, addressTypes())} />
            <LabelValue label={getAddressLine(entity.addressType)} value={entity.addressLine1} />
            <LabelValue label="Address Line 2" value={entity.addressLine2} />
            <LabelValue label="Address Line 3" value={entity.addressLine3} />
            <LabelValue label="Town/Suburb" value={entity.suburb} />
            <LabelValue label="City" value={entity.town} />
            <LabelValue label="Postal Code" value={entity.postalCode} />
            <LabelValue label="Province" value={entity.province} />
            <LabelValue label="Country" value={entity.country} />
        </Grid>
    );
};

const FinancialDetails = function ({ claimant }) {
    return (
        <Grid container justify="flex-start">
            <Grid item xs={12} lg={12}>
                <Typography variant="h6">Financial Details</Typography>
                <hr />
            </Grid>
            <LabelValue label="Bank Account Holders Name" value={claimant.accountHolder} />
            <LabelValue label="Bank Name" value={claimant.bankName} />
            <LabelValue label="Account Number" value={claimant.accountNumber} />
            <LabelValue label="Branch Name" value={claimant.branchName} />
            <LabelValue label="Branch Code" value={claimant.branchCode} />
            <LabelValue label="Account Type" value={claimant.accountType} />
            <LabelValue
                label="Proof of Bank Account"
                value={claimant.proofOfBankAccount}
                isFile={true}
            />
        </Grid>
    );
};

export const EntityDetailsSummary = withStyles(styles)(entityDetailsSummary);
