import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from '../../../styles/styles';
import { getDisplayDate } from '../../../shared/helper-functions';
import { LabelValue } from '../../common/label-value';
import { IdentitySummary } from '../../common/identity-details-summary';

const guardianDetails = ({ claimant }) => {
    if (!claimant.guardian) {
        return null;
    }
    const guardian = claimant.guardian;
    return (
        <React.Fragment>
            <Grid item xs={12} style={{ marginTop: '24px' }}>
                <Typography variant="h6">Guardian Details</Typography>
                <hr />
            </Grid>
            <Grid sx={12} sm={12}>
                <LabelValue label="Name" value={guardian.firstName} />
                <LabelValue label="Surname" value={guardian.surname} />
                <LabelValue label="Initials" value={guardian.initials} />

                <LabelValue label="Relationship to Deceased" value={guardian.relationshipToDeceased} />
                <LabelValue
                    label="Relationship to Deceased"
                    value={guardian.relationshipToDeceasedOther}
                />

                <LabelValue label="Relationship to Owner" value={guardian.relationshipToPolicyOwner} />
                <LabelValue
                    label="Relationship to Owner"
                    value={guardian.relationshipToPolicyOwnerOther}
                />

                <IdentitySummary identityDetails={guardian} />
                <LabelValue label="Gender" value={guardian.gender} />
                <LabelValue label="Marital Status" value={guardian.maritalStatus} />
                <LabelValue label="Date of Marriage" value={getDisplayDate(guardian.dateOfMarriage)} />
                <LabelValue label="Date of Marriage" value={getDisplayDate(guardian.dateOfDivorce)} />
                <LabelValue label="Is RSA Resident" value={guardian.isRSAResident} />
                <LabelValue label="Country of Residency" value={guardian.countryOfResidence} />
                <LabelValue
                    label="Have you officially emigrated?"
                    value={guardian.officiallyEmigrated}
                />
                <LabelValue
                    label="Exchange form"
                    value={guardian.emigrationExchangeForm}
                    isFile={true}
                />
                <LabelValue
                    label="Proof of Guardianship"
                    value={guardian.proofOfGuardianship}
                    isFile={true}
                />
                <LabelValue label="Tax Reference Number" value={guardian.taxReferenceNumber} />
            </Grid>
        </React.Fragment>
    );
};

export const GuardianDetailsSummary = withStyles(styles)(guardianDetails);
