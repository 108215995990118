import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from '../../../styles/styles';
import {
    Condition,
    FixedWidthButtonGroup,
    FixedWidthCheckBoxField,
    FixedWidthRadioGroup,
    FixedWidthRadioOption,
    OutlinedSearchSelectField,
    OutlinedTextField,
    True,
    WhenFieldChanges,
} from '../../../forms';
import { FixedWidthFileUpload } from '../../../forms/form-components/fixed-width-file-upload';
import { FirstNameMask, InitialsMask, LastNameMask } from '../../../forms/input-masks';
import { requiredValidator } from '../../../forms/validations';
import { MaritalStatuses, Countries, Titles, NaturalRelationships } from '../../../shared/lookups';
import { IdentityDetails } from '../../common/identity-details';
import { AddressDetails } from '../common/address-details';
import { ContactDetailsView } from '../common/contact-details-view';
import { getMajorClaimants } from '../../../shared/helper-functions';
import { ClaimantNavigation } from '../../common/navigation/claimant-navigation';

const curatorDetails = function ({
    classes,
    form,
    index,
    handleBackStepper,
    handleNext,
    editable,
    stepsLength,
    claimantIndex,
}) {
    const prefix = `claimants[${claimantIndex}].curator.`;
    const claimants = form.getState().values.claimants;
    const thisClaimant = claimants && claimants[claimantIndex];

    return (
        <Grid container spacing={10} justify="flex-start">
            <Grid item xs={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
                <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                <Typography className={classes.stepHeader}>Curator Details</Typography>
            </Grid>
            <WhenFieldChanges
                field={`claimants[${claimantIndex}].underCuratorship`}
                becomes="No"
                set={`${prefix}`}
                to={null}
            />
            <Grid item xs={12} lg={12}>
                <WhenFieldChanges
                    field={`claimants[${claimantIndex}].underCuratorship`}
                    set={`claimants[${claimantIndex}].individual.contactsSameAs`}
                    to={null}
                />
                <FixedWidthButtonGroup
                    label="Are you currently under curatorship?"
                    name={`claimants[${claimantIndex}].underCuratorship`}
                    options={['Yes', 'No']}
                    disabled={!editable}
                />
                <WhenFieldChanges
                    field={`claimants[${claimantIndex}].underCuratorship`}
                    set={`claimants[${claimantIndex}].addressSameAs`}
                    to={null}
                />
                <Condition when={`claimants[${claimantIndex}].underCuratorship`} is="Yes">
                    <True>
                        <OutlinedSearchSelectField
                            name={`${prefix}curatorSameAs`}
                            label="Curator Same As"
                            options={getMajorClaimants(claimants, thisClaimant)}
                            validate={requiredValidator}
                            labelprefixed="true"
                            disabled={!editable}
                        />

                        <WhenFieldChanges
                            field={`${prefix}curatorSameAs`}
                            set={`${prefix}title`}
                            to={null}
                        />

                        <WhenFieldChanges
                            field={`${prefix}curatorSameAs`}
                            set={`${prefix}firstName`}
                            to={null}
                        />

                        <WhenFieldChanges
                            field={`${prefix}curatorSameAs`}
                            set={`${prefix}lastName`}
                            to={null}
                        />
                        <WhenFieldChanges
                            field={`${prefix}curatorSameAs`}
                            set={`${prefix}initials`}
                            to={null}
                        />
                        <WhenFieldChanges
                            field={`${prefix}curatorSameAs`}
                            set={`${prefix}relationshipToLifeAssured`}
                            to={null}
                        />

                        <WhenFieldChanges
                            field={`${prefix}curatorSameAs`}
                            set={`${prefix}nationality`}
                            to={null}
                        />

                        <WhenFieldChanges
                            field={`${prefix}curatorSameAs`}
                            set={`${prefix}maritalStatus`}
                            to={null}
                        />

                        <WhenFieldChanges
                            field={`${prefix}curatorSameAs`}
                            set={`${prefix}letterOfCuratorship`}
                            to={null}
                        />

                        <Condition when={`${prefix}curatorSameAs`} is="Other">
                            <True>
                                <OutlinedSearchSelectField
                                    name={`${prefix}title`}
                                    options={Titles}
                                    label="Title"
                                    disabled={!editable}
                                    validate={requiredValidator}
                                    labelprefixed="true"
                                />
                                <Grid item xs={12} lg={12}>
                                    <OutlinedTextField
                                        name={`${prefix}firstName`}
                                        label="Name"
                                        validate={requiredValidator}
                                        labelprefixed="true"
                                        mask={FirstNameMask}
                                        disabled={!editable}
                                    />

                                    <OutlinedTextField
                                        name={`${prefix}lastName`}
                                        label="Surname"
                                        validate={requiredValidator}
                                        labelprefixed="true"
                                        mask={LastNameMask}
                                        disabled={!editable}
                                    />
                                    <OutlinedTextField
                                        name={`${prefix}initials`}
                                        label="Initials"
                                        disabled={!editable}
                                        mask={InitialsMask}
                                    />
                                    <OutlinedSearchSelectField
                                        name={`${prefix}relationshipToLifeAssured`}
                                        options={NaturalRelationships}
                                        label="Relationship to Life Assured"
                                        validate={requiredValidator}
                                        labelprefixed="true"
                                        disabled={!editable}
                                    />
                                    <OutlinedSearchSelectField
                                        name={`${prefix}relationshipToDependant`}
                                        options={NaturalRelationships}
                                        label="Relationship to Dependant"
                                        validate={requiredValidator}
                                        labelprefixed="true"
                                        disabled={!editable}
                                    />

                                    <IdentityDetails
                                        prefix={prefix}
                                        includeForeignTypes={true}
                                        editable={editable}
                                        idDocumentRequired={true}
                                    />
                                    <OutlinedSearchSelectField
                                        name={`${prefix}nationality`}
                                        label="Nationality"
                                        options={Countries}
                                        validate={requiredValidator}
                                        labelprefixed="true"
                                        disabled={!editable}
                                    />
                                    <OutlinedSearchSelectField
                                        name={`${prefix}maritalStatus`}
                                        options={MaritalStatuses}
                                        label="Marital Status"
                                        validate={requiredValidator}
                                        labelprefixed="true"
                                        disabled={!editable}
                                    />

                                    <FixedWidthFileUpload
                                        name={`${prefix}letterOfCuratorship`}
                                        label="Upload Letter of Curatorship"
                                        validate={requiredValidator}
                                        labelprefixed="true"
                                        readonly={!editable}
                                    />
                                </Grid>

                                <Typography className={classes.contentHeader}>
                                    Tax Information
                                </Typography>
                                <FixedWidthRadioGroup
                                    name={`${prefix}taxStatus`}
                                    label="What is your tax status?"
                                    disabled={!editable}
                                    validate={requiredValidator}
                                    labelprefixed="true"
                                >
                                    <FixedWidthRadioOption value="Tax Paying" />
                                    <FixedWidthRadioOption value="Non Tax Paying" />
                                </FixedWidthRadioGroup>
                                <Condition when={`${prefix}taxStatus`} is="Tax Paying">
                                    <True>
                                        <OutlinedSearchSelectField
                                            name={`${prefix}countryOfTaxResidence`}
                                            options={Countries}
                                            label="Country of Tax Residence"
                                            disabled={!editable}
                                        />
                                        <OutlinedTextField
                                            name={`${prefix}taxReferenceNumber`}
                                            disabled={!editable}
                                        />
                                    </True>
                                </Condition>

                                <Typography className={classes.contentHeader}>
                                    Residential Address Details
                                </Typography>
                                <AddressDetails
                                    editable={editable}
                                    prefix={`${prefix}residentialAddress.`}
                                    isResidential
                                    proofOfAddress
                                />
                                <WhenFieldChanges
                                    field={`${prefix}postalAddressDifferent`}
                                    set={`${prefix}postalAddress`}
                                    to={null}
                                />
                                <Typography className={classes.contentHeader}>
                                    Postal Address Details
                                </Typography>
                                <FixedWidthCheckBoxField
                                    name={`${prefix}postalAddressDifferent`}
                                    label="Different to Residential Address"
                                    disabled={!editable}
                                />
                                <Condition when={`${prefix}postalAddressDifferent`} is={true}>
                                    <True>
                                        <Grid item xs={12}>
                                            <AddressDetails
                                                editable={editable}
                                                prefix={`${prefix}postalAddress.`}
                                                isResidential={false}
                                            />
                                        </Grid>
                                    </True>
                                </Condition>
                                <Grid item xs={12}>
                                    <Typography className={classes.contentHeader}>
                                        Contact Details
                                    </Typography>
                                    <ContactDetailsView
                                        prefix={`${prefix}contactDetails.`}
                                        hasCommucationPreference={true}
                                        editable={editable}
                                    />
                                </Grid>
                            </True>
                        </Condition>
                    </True>
                </Condition>
            </Grid>
            <ClaimantNavigation
                form={form}
                handleBackStepper={handleBackStepper}
                handleNext={handleNext}
                prefix={`claimant-${claimantIndex}-curator`}
            />
        </Grid>
    );
};

export const CuratorDetails = withStyles(styles)(curatorDetails);
