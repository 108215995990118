import { hideLoading, showLoading } from '../forms/loading-screen/actions';
import { INITIATE_LOGIN, SET_CHECKED_LOGIN, SET_ACCOUNT } from './authentication-types';
import { redirectToLogin, tryGetTokenSilent, getAccount, logout } from './msal';

export function initiateLogin() {
    return function (dispatch) {
        dispatch(showLoading());
        redirectToLogin();
        return dispatch({ type: INITIATE_LOGIN });
    };
}

export function checkIfLoggedIn() {
    return async function (dispatch, getState) {
        const { authentication } = getState();
        if (authentication.checkedIfLoggedIn) {
            return;
        }
        dispatch(showLoading());
        dispatch({ type: SET_CHECKED_LOGIN });
        if (tryGetTokenSilent()) {
            const account = getAccount();
            if (account) {
                dispatch(setAccount(account));
                dispatch(hideLoading());
                return;
            }
        }
        dispatch(hideLoading());
    };
}

export function checkIfLoggedInRedirectIfNot() {
    return async function (dispatch, getState) {
        const { authentication } = getState();
        if (authentication.checkedIfLoggedIn) {
            return;
        }
        dispatch(showLoading());
        dispatch({ type: SET_CHECKED_LOGIN });
        if (tryGetTokenSilent()) {
            const account = getAccount();
            if (account) {
                dispatch(setAccount(account));
                dispatch(hideLoading());
                return;
            } else {
                redirectToLogin();
            }
        } else {
            redirectToLogin();
        }
        dispatch(hideLoading());
    };
}

export { logout };

/** @param {import('msal').Account} msalAccount*/
export function setAccount(msalAccount) {
    return {
        type: SET_ACCOUNT,
        account: {
            adId: msalAccount.accountIdentifier,
            // @ts-ignore
            email: getFirstEmail(msalAccount.idToken),
            // @ts-ignore
            emails: getEmails(msalAccount.idToken),
            // @ts-ignore
            lastName: msalAccount.idToken.family_name,
            // @ts-ignore
            firstName: msalAccount.idToken.given_name,
        },
    };
}

function getFirstEmail(idToken) {
    const emails = getEmails(idToken);
    if (emails && emails.length > 0) {
        return emails[0];
    }
    return undefined;
}

function getEmails(idToken) {
    if (idToken && idToken.emails) {
        return idToken.emails;
    }
}
