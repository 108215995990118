import * as React from 'react';

import { Paper, withStyles, Tooltip } from '@material-ui/core';
import { useState, useEffect } from 'react';
import stepperPositiveArrowDim from '../../master-layout/images/stepper-positive-arrow-dim.svg';
import styles from '../../styles/styles';
import { StepImage } from './step-image';

function Stepper({ classes, components, activeIndex, stepThrough, updateIndex }) {
    const windowWidth = useWindowWidth();
    return (
        <Paper className={[classes.stepper, classes.stepperOverLayWrapper].join(' ')}>
            <div className={classes.stepperDiv}>
                {components.map((stepComponent, stepIndex) => {
                    const isCurrentStep = stepIndex === activeIndex;
                    const classNames = [classes.stepperItem];

                    if (isCurrentStep && !stepThrough) {
                        classNames.push(classes.currentStep);
                    } else {
                        classNames.push(classes.step);
                    }

                    if (stepThrough || stepIndex < activeIndex) {
                        classNames.push(classes.pointerCursor);
                    }

                    let hideLabel = false;
                    if (!isCurrentStep) {
                        hideLabel = shouldOffset(
                            windowWidth,
                            stepIndex,
                            activeIndex,
                            components.length
                        );
                    }

                    return (
                        <div
                            key={`stepper-${stepIndex}`}
                            className={classNames.join(' ')}
                            onClick={() => updateIndex(stepIndex)}
                        >
                            {hideLabel ? (
                                <Tooltip title={stepComponent.name}>
                                    <span>
                                        <StepImage
                                            currentIndex={stepIndex}
                                            activeIndex={activeIndex}
                                            submitted={stepThrough}
                                        />
                                    </span>
                                </Tooltip>
                            ) : (
                                <React.Fragment>
                                    <StepImage
                                        currentIndex={stepIndex}
                                        activeIndex={activeIndex}
                                        submitted={stepThrough}
                                    />
                                    <span style={{ marginLeft: '2px' }}>{stepComponent.name}</span>
                                </React.Fragment>
                            )}
                            {stepIndex < components.length - 1 && (
                                <div className={classes.stepperArrow}>
                                    <img
                                        alt="Bottom"
                                        style={{ verticalAlign: 'bottom' }}
                                        src={stepperPositiveArrowDim}
                                    />
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        </Paper>
    );
}

function shouldOffset(windowWidth, stepIndex, activeIndex, numberOfComponents) {
    if (windowWidth > 915) {
        if (windowWidth > 920) {
            if (windowWidth > 1100) {
                if (windowWidth > 1160) {
                    if (windowWidth < 1200) {
                        if (activeIndex === 0 || activeIndex === 1) {
                            if (stepIndex === numberOfComponents - 1) {
                                return true;
                            }
                        } else if (stepIndex === 0) {
                            return true;
                        }
                    }
                } else {
                    if (activeIndex === numberOfComponents - 1) {
                        if (stepIndex === 0 || stepIndex === 1) {
                            return true;
                        }
                    } else {
                        if (stepIndex < activeIndex - 2 || stepIndex > activeIndex + 2) {
                            return true;
                        } else if (
                            stepIndex === numberOfComponents - 1 &&
                            activeIndex === numberOfComponents / 2
                        ) {
                            return true;
                        }
                    }
                }
            } else {
                if (activeIndex === 0 || activeIndex === 1) {
                    if (windowWidth < 1015) {
                        if (stepIndex > activeIndex + 1) {
                            return true;
                        }
                    } else if (stepIndex > activeIndex + 2) {
                        return true;
                    }
                } else if (
                    activeIndex === numberOfComponents - 1 ||
                    activeIndex === numberOfComponents - 2
                ) {
                    if (windowWidth < 1015) {
                        if (stepIndex < activeIndex - 1) {
                            return true;
                        }
                    } else if (stepIndex < activeIndex - 2) {
                        return true;
                    }
                } else if (stepIndex < activeIndex - 1 || stepIndex > activeIndex + 1) {
                    return true;
                }
            }
        } else {
            if (stepIndex !== activeIndex - 1 && stepIndex !== activeIndex + 1) {
                return true;
            }
        }
    } else {
        return true;
    }

    return false;
}

function useWindowWidth() {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    });

    return width;
}

export const HorizontalStepper = withStyles(styles)(Stepper);
