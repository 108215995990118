import * as React from 'react';
import { Grid, Paper, withStyles } from '@material-ui/core';
import styles from '../../../styles/styles';
import { DetailsOfDeath } from '../deceased-details/details-of-death';
import { PersonalDetails } from './personal-details';
import { PolicyDetails } from './policy-details';
import { VerticalStepper } from '../../common/vertical-stepper';
import { DoctorVisits } from './doctors-visits/doctors-visits';

const verticalComponents = [
    {
        name: 'Policy Details',
        description: 'Provide all Liberty policy numbers, if available',
        component: PolicyDetails,
    },
    {
        name: 'Personal Details',
        description: 'Personal details of the deceased',
        component: PersonalDetails,
    },
    {
        name: 'Death Details',
        description: 'Details of where & what the cause of death was',
        component: DetailsOfDeath,
    },
    {
        name: 'Doctors Visit',
        description: 'Provide Details of doctors’ who treated the deceased ',
        component: DoctorVisits,
    },
];

const deceasedDetails = function ({ classes, form, handleBack, handleSubmit, ...rest }) {
    return (
        <Paper className={[classes.shadedPaper, classes.paperShadowLess].join(' ')}>
            <Grid container spacing={10} justify="flex-start">
                <Grid item xs={12} lg={12}>
                    <VerticalStepper
                        {...rest}
                        verticalComponents={verticalComponents}
                        verticalIndexName={'verticalStepIndex'}
                        form={form}
                        handleBack={handleBack}
                        handleSubmit={handleSubmit}
                    />
                </Grid>
            </Grid>
        </Paper>
    );
};

export const DeceasedDetails = withStyles(styles)(deceasedDetails);
