import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    Typography,
    withStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as React from 'react';
import { connect } from 'react-redux';
import { getFullName } from '../../../../shared/helper-functions';
import styles from '../../../../styles/styles';
import { AddressDetails } from '../common/address-details';
import { ContactDetails } from '../common/contact-details';
import { GuardianDetails } from './guardian-details';
import { PaymentDetails } from '../common/payment-details';
import { PersonalDetails } from '../common/personal-details';

const claimantSummary = function ({ classes, claimants }) {
    if (!claimants || claimants.length < 1) {
        return null;
    }
    return (
        <Accordion
            style={{
                margin: '-24px -16px 24px -16px',
                borderRadius: '4px',
            }}
        >
            <AccordionSummary
                className={[classes.whiteBackground, classes.whiteTitle].join(' ')}
                expandIcon={<ExpandMoreIcon />}
                style={{ borderRadius: '4px' }}
            >
                <Typography className={classes.previewIndex}>2</Typography>
                <Typography className={classes.contentHeader}>Claimant Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid xs={12} sm={12}>
                    {claimants.map((claimant, index) => {
                        return (
                            <ClaimantDetails
                                key={`claimantSummary${index}`}
                                claimant={claimant}
                                classes={classes}
                                index={index}
                            />
                        );
                    })}
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

const ClaimantDetails = ({ claimant, classes, index }) => {
    return (
        <Accordion>
            <AccordionSummary
                className={[classes.claimantBackground, classes.whiteTitle].join(' ')}
                expandIcon={<ExpandMoreIcon />}
            >
                {`Claimant ${index + 1} | ${getFullName(claimant.personalDetails)}`}
            </AccordionSummary>
            <AccordionDetails>
                <Grid sm={12} xs={12}>
                    <PersonalDetails personalDetails={claimant.personalDetails} />
                    <GuardianDetails guardianDetails={claimant.guardianDetails} />
                    <ContactDetails contactDetails={claimant.contactDetails} />
                    <AddressDetails addressDetails={claimant.addressDetails} />
                    <PaymentDetails bankingDetails={claimant.bankingDetails} />
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

const mapStateToProps = state => {
    const claimSelection = (state && state.claimSelection) || {};
    return {
        claimants: (claimSelection.claim && claimSelection.claim.claimants) || [],
    };
};

export const ClaimantSummary = connect(mapStateToProps, {})(withStyles(styles)(claimantSummary));
