import * as React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from '../../../../styles/styles';
import { LabelValue } from '../../../common/label-value';
import { getBankName } from '../../../../shared/banks';

const bankingDetailsView = function ({ bankingDetails }) {
    if (!bankingDetails) {
        return null;
    }
    return (
        <React.Fragment>
            <Grid item xs={12} style={{ marginTop: '24px' }}>
                <Typography variant="h6">Banking Details</Typography>
                <hr />
            </Grid>
            <LabelValue
                label="Bank Account Holders Name"
                value={bankingDetails.bankAccountHoldersName}
            />
            <LabelValue label="Bank Name" value={getBankName(bankingDetails.bankName)} />
            <LabelValue label="Branch Name" value={bankingDetails.branchName} />
            <LabelValue label="Account Number" value={bankingDetails.accountNumber} />
            <LabelValue label="Branch Code" value={bankingDetails.branchCode} />
            <LabelValue label="Account Type" value={bankingDetails.accountType} />
            <LabelValue
                label="Account Holder Relationship"
                value={bankingDetails.accountHolderRelationship}
            />
            <LabelValue label="Proof of Account" value={bankingDetails.proofOfBankAccount} isFile />
        </React.Fragment>
    );
};

export const BankingDetailsSummary = withStyles(styles)(bankingDetailsView);
