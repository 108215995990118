import React from 'react';
import { withStyles } from '@material-ui/core';
import { Condition, False, True, When, WhenFieldChanges } from '../../../forms';
import { OutlinedSearchSelectField, OutlinedTextField } from '../../../forms';
import { NumberMask } from '../../../forms/input-masks';
import { requiredValidator } from '../../../forms/validations';
import { addressTypes, Provinces, Countries } from '../../../shared/lookups';
import styles from '../../../styles/styles';
import { defaultCountry, isBoxOrBag } from '../../../shared/helper-functions';

const addressDetails = function ({ editable, prefix, isResidential, shaded = false, ...rest }) {
    return (
        <React.Fragment>
            <OutlinedSearchSelectField
                name={`${prefix}addressType`}
                label="Address Type"
                labelprefixed="true"
                options={addressTypes(isResidential)}
                validate={requiredValidator}
                disabled={!editable}
                shaded={shaded}
            />
            <WhenFieldChanges
                field={`${prefix}addressType`}
                set={`${prefix}postalCode`}
                to={null}
            />
            <WhenFieldChanges
                field={`${prefix}addressType`}
                set={`${prefix}country`}
                to={defaultCountry}
            />
            <WhenFieldChanges
                field={`${prefix}addressType`}
                becomes="Foreign"
                set={`${prefix}province`}
                to={null}
            />
            <WhenFieldChanges
                field={`${prefix}addressType`}
                condition={isBoxOrBag}
                set={`${prefix}addressLineOne`}
                to={null}
            />
            <WhenFieldChanges
                field={`${prefix}addressType`}
                condition={isBoxOrBag}
                set={`${prefix}addressLineTwo`}
                to={null}
            />
            <WhenFieldChanges
                field={`${prefix}addressType`}
                condition={isBoxOrBag}
                set={`${prefix}addressLineThree`}
                to={null}
            />
            <Condition when={`${prefix}addressType`} hasValue>
                <True>
                    <Condition when={`${prefix}addressType`} matchesChild>
                        <When is="PrivateBag">
                            <OutlinedTextField
                                name={`${prefix}addressLineOne`}
                                label="Private Bag"
                                labelprefixed="true"
                                validate={requiredValidator}
                                disabled={!editable}
                                shaded={shaded}
                            />
                        </When>
                        <When is="PostBox">
                            <OutlinedTextField
                                name={`${prefix}addressLineOne`}
                                label="PO Box Number"
                                labelprefixed="true"
                                validate={requiredValidator}
                                disabled={!editable}
                                shaded={shaded}
                                mask={NumberMask}
                            />
                        </When>
                    </Condition>
                    <Condition when={`${prefix}addressType`} is={['Street', 'Foreign']}>
                        <True>
                            <OutlinedTextField
                                name={`${prefix}addressLineOne`}
                                label="Address Line 1"
                                labelprefixed="true"
                                validate={requiredValidator}
                                disabled={!editable}
                                shaded={shaded}
                            />
                            <OutlinedTextField
                                name={`${prefix}addressLineTwo`}
                                label="Address Line 2"
                                disabled={!editable}
                                shaded={shaded}
                            />
                            <OutlinedTextField
                                name={`${prefix}addressLineThree`}
                                label="Address Line 3"
                                disabled={!editable}
                                shaded={shaded}
                            />
                        </True>
                    </Condition>
                    <OutlinedTextField
                        name={`${prefix}townSuburb`}
                        label="Town/Suburb"
                        labelprefixed="true"
                        validate={requiredValidator}
                        disabled={!editable}
                        shaded={shaded}
                    />
                    <OutlinedTextField
                        name={`${prefix}city`}
                        label="City"
                        labelprefixed="true"
                        validate={requiredValidator}
                        disabled={!editable}
                        shaded={shaded}
                    />
                    <Condition when={`${prefix}addressType`} is={'Foreign'}>
                        <True>
                            <OutlinedTextField
                                name={`${prefix}postalCode`}
                                label="Postal Code"
                                disabled={!editable}
                                shaded={shaded}
                            />
                            <OutlinedSearchSelectField
                                name={`${prefix}country`}
                                label="Country"
                                labelprefixed="true"
                                options={Countries}
                                validate={requiredValidator}
                                disabled={!editable}
                                shaded={shaded}
                            />
                        </True>
                        <False>
                            <OutlinedTextField
                                name={`${prefix}postalCode`}
                                label="Postal Code"
                                labelprefixed="true"
                                validate={requiredValidator}
                                shaded={shaded}
                                mask={NumberMask}
                                disabled={!editable}
                            />
                            <OutlinedSearchSelectField
                                name={`${prefix}province`}
                                label="Province"
                                labelprefixed="true"
                                options={Provinces}
                                validate={requiredValidator}
                                disabled={!editable}
                                shaded={shaded}
                            />
                            <OutlinedSearchSelectField
                                name={`${prefix}country`}
                                label="Country"
                                labelprefixed="true"
                                options={Countries}
                                disabled={true}
                                shaded={shaded}
                            />
                        </False>
                    </Condition>
                </True>
            </Condition>
        </React.Fragment>
    );
};

export const AddressDetails = withStyles(styles)(addressDetails);
