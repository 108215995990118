import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import {
    Condition,
    True,
    False,
    WhenFieldChanges,
    OutlinedSearchSelectField,
    OutlinedTextField,
    OutlinedDatePicker,
    FixedWidthButtonGroup,
    FixedWidthRadioGroup,
    FixedWidthRadioOption,
} from '../../../forms';
import { FixedWidthFileUpload } from '../../../forms/form-components/fixed-width-file-upload';
import { requiredValidator } from '../../../forms/validations';
import { Titles, MaritalStatuses, Countries } from '../../../shared/lookups';
import styles from '../../../styles/styles';
import { IdentityDetails } from '../../common/identity-details';
import { FirstNameMask, InitialsMask, LastNameMask, NumberMask } from '../../../forms/input-masks';
import { ClaimantNavigation } from '../../common/navigation/claimant-navigation';

const personalDetails = function ({
    classes,
    form,
    index,
    handleBackStepper,
    handleNext,
    editable,
    stepsLength,
    claimantIndex,
}) {
    const prefix = `claimants[${claimantIndex}].individual.`;
    return (
        <React.Fragment>
            <Grid container spacing={10} justify="flex-start">
                <Grid item xs={12} lg={12}>
                    <Grid item xs={12}>
                        <Typography className={classes.utilSteps}>
                            Step {index + 1} of {stepsLength}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex' }}>
                        <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                        <Typography className={classes.stepHeader}>Personal Details</Typography>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <OutlinedSearchSelectField
                            name={`${prefix}title`}
                            options={Titles}
                            label="Title"
                            disabled={!editable}
                            validate={requiredValidator}
                            labelprefixed="true"
                        />
                        <OutlinedTextField
                            name={`${prefix}firstName`}
                            validate={requiredValidator}
                            labelprefixed="true"
                            mask={FirstNameMask}
                            disabled={!editable}
                        />
                        <OutlinedTextField
                            name={`${prefix}secondName`}
                            mask={FirstNameMask}
                            disabled={!editable}
                        />
                        <OutlinedTextField
                            name={`${prefix}lastName`}
                            label="Surname"
                            validate={requiredValidator}
                            labelprefixed="true"
                            mask={LastNameMask}
                            disabled={!editable}
                        />
                        <OutlinedTextField
                            name={`${prefix}initials`}
                            disabled={!editable}
                            mask={InitialsMask}
                        />
                        <OutlinedSearchSelectField
                            name={`${prefix}nationality`}
                            options={Countries}
                            label="Nationality"
                            validate={requiredValidator}
                            labelprefixed="true"
                            disabled={!editable}
                        />
                        <IdentityDetails
                            prefix={prefix}
                            includeGender={true}
                            includeForeignTypes={true}
                            editable={editable}
                            idDocumentRequired={true}
                        />
                        <Condition when={`${prefix}relationshipToLifeAssured`} is="ExSpouse">
                            <True>
                                <OutlinedDatePicker
                                    name={`${prefix}dateOfMarriageToLifeAssured`}
                                    label="Date of Marriage To Life Assured"
                                    disabled={!editable}
                                    validate={requiredValidator}
                                    labelprefixed="true"
                                    disableFuture
                                />
                                <OutlinedDatePicker
                                    name={`${prefix}dateOfDivorceFromLifeAssured`}
                                    label="Date of Divorce From Life Assured"
                                    disabled={!editable}
                                    validate={requiredValidator}
                                    labelprefixed="true"
                                    disableFuture
                                />
                                <FixedWidthButtonGroup
                                    label="Was there a settlement in the divorce?"
                                    name={`${prefix}divorceSettlement`}
                                    options={['Yes', 'No']}
                                    validate={requiredValidator}
                                    labelprefixed="true"
                                    disabled={!editable}
                                />
                                <FixedWidthButtonGroup
                                    label="Has the claimant remarried?"
                                    name={`${prefix}remarried`}
                                    options={['Yes', 'No']}
                                    validate={requiredValidator}
                                    labelprefixed="true"
                                    disabled={!editable}
                                />
                                <WhenFieldChanges
                                    field={`${prefix}remarried`}
                                    becomes="No"
                                    set={`${prefix}maritalStatus`}
                                    to="Divorced"
                                />
                                <WhenFieldChanges
                                    field={`${prefix}remarried`}
                                    becomes="No"
                                    set={`${prefix}dateOfMarriage`}
                                    to={null}
                                />
                            </True>
                        </Condition>
                        <Condition
                            when={`${prefix}relationshipToLifeAssured`}
                            is={['Spouse', 'EstrangedSpouse']}
                        >
                            <True>
                                <OutlinedSearchSelectField
                                    name={`${prefix}maritalStatus`}
                                    options={MaritalStatuses}
                                    label="Marital Status"
                                    validate={requiredValidator}
                                    labelprefixed="true"
                                    disabled={true}
                                />
                            </True>
                            <False>
                                <Condition
                                    when={`${prefix}relationshipToLifeAssured`}
                                    is="ExSpouse"
                                >
                                    <True>
                                        <Condition when={`${prefix}remarried`} is="Yes">
                                            <True>
                                                <OutlinedSearchSelectField
                                                    name={`${prefix}maritalStatus`}
                                                    options={MaritalStatuses}
                                                    label="Current Marital Status"
                                                    validate={requiredValidator}
                                                    labelprefixed="true"
                                                    disabled={!editable}
                                                />
                                                <Condition
                                                    when={`${prefix}maritalStatus`}
                                                    is="Married"
                                                >
                                                    <True>
                                                        <OutlinedDatePicker
                                                            name={`${prefix}dateOfMarriage`}
                                                            label="Date of Current Marriage"
                                                            validate={requiredValidator}
                                                            labelprefixed="true"
                                                            disableFuture
                                                            disabled={!editable}
                                                        />
                                                    </True>
                                                </Condition>
                                            </True>
                                            <False>
                                                <OutlinedSearchSelectField
                                                    name={`${prefix}maritalStatus`}
                                                    options={MaritalStatuses}
                                                    label="Current Marital Status"
                                                    validate={requiredValidator}
                                                    labelprefixed="true"
                                                    disabled={true}
                                                />
                                            </False>
                                        </Condition>
                                    </True>
                                    <False>
                                        <Condition
                                            when={`${prefix}relationshipToLifeAssured`}
                                            is="MinorChild"
                                        >
                                            <True>
                                                <OutlinedSearchSelectField
                                                    name={`${prefix}maritalStatus`}
                                                    options={MaritalStatuses}
                                                    label="Marital Status"
                                                    labelprefixed="true"
                                                    disabled={true}
                                                />
                                            </True>
                                            <False>
                                                <OutlinedSearchSelectField
                                                    name={`${prefix}maritalStatus`}
                                                    options={MaritalStatuses}
                                                    label="Marital Status"
                                                    validate={requiredValidator}
                                                    labelprefixed="true"
                                                    disabled={!editable}
                                                />
                                            </False>
                                        </Condition>
                                    </False>
                                </Condition>
                            </False>
                        </Condition>
                        <Condition when={`${prefix}relationshipToLifeAssured`} is="ExSpouse">
                            <False>
                                <Condition
                                    when={`${prefix}maritalStatus`}
                                    is={['Married', 'Widowed']}
                                >
                                    <True>
                                        <Grid item xs={12}>
                                            <OutlinedDatePicker
                                                name={`${prefix}dateOfMarriage`}
                                                label="Date of Marriage"
                                                validate={requiredValidator}
                                                labelprefixed="true"
                                                disableFuture
                                                disabled={!editable}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FixedWidthFileUpload
                                                name={`${prefix}marriageCertificate`}
                                                label="Upload Marriage Certificate"
                                                labelprefixed="true"
                                                validate={requiredValidator}
                                                readonly={!editable}
                                            />
                                        </Grid>
                                    </True>
                                </Condition>
                                <Condition when={`${prefix}maritalStatus`} is={'Divorced'}>
                                    <True>
                                        <Grid item xs={12}>
                                            <OutlinedDatePicker
                                                name={`${prefix}dateOfDivorce`}
                                                label="Date of Divorce"
                                                validate={requiredValidator}
                                                labelprefixed="true"
                                                disabled={!editable}
                                                disableFuture
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FixedWidthFileUpload
                                                name={`${prefix}divorceDecree`}
                                                label="Upload Divorce Decree"
                                                labelprefixed="true"
                                                validate={requiredValidator}
                                                readonly={!editable}
                                            />
                                        </Grid>
                                    </True>
                                </Condition>
                            </False>
                        </Condition>
                        <Condition when={`${prefix}relationshipToLifeAssured`} is="EstrangedSpouse">
                            <True>
                                <OutlinedTextField
                                    name={`${prefix}yearsEstranged`}
                                    label="No. of Years Estranged"
                                    mask={NumberMask}
                                    disabled={!editable}
                                />
                            </True>
                        </Condition>
                        <Condition when={`${prefix}relationshipToLifeAssured`} is="LifePartner">
                            <True>
                                <OutlinedTextField
                                    name={`${prefix}yearsInRelationship`}
                                    label="No. of Years in Relationship"
                                    mask={NumberMask}
                                    disabled={!editable}
                                />
                            </True>
                        </Condition>
                        <WhenFieldChanges
                            field={`${prefix}hasDisability`}
                            becomes="No"
                            set={`${prefix}disabilityDescription`}
                            to={null}
                        />
                        <FixedWidthButtonGroup
                            label="Do you have a disability?"
                            name={`${prefix}hasDisability`}
                            options={['Yes', 'No']}
                            disabled={!editable}
                        />

                        <Condition when={`${prefix}hasDisability`} is="Yes">
                            <True>
                                <OutlinedTextField
                                    name={`${prefix}disabilityDescription`}
                                    validate={requiredValidator}
                                    labelprefixed="true"
                                    disabled={!editable}
                                />
                            </True>
                        </Condition>

                        <Condition
                            when={`${prefix}relationshipToLifeAssured`}
                            is={['MajorChild', 'MinorChild']}
                        >
                            <True>
                                <FixedWidthRadioGroup
                                    name={`${prefix}studentScholar`}
                                    labelprefixed="true"
                                    label="Are you a scholar or a student?"
                                    validate={requiredValidator}
                                    disabled={!editable}
                                    className={classes.radioStyle}
                                >
                                    <FixedWidthRadioOption value="Student" />
                                    <FixedWidthRadioOption value="Scholar" />
                                    <FixedWidthRadioOption value="None" />
                                </FixedWidthRadioGroup>

                                <WhenFieldChanges
                                    field={`${prefix}studentScholar`}
                                    set={`${prefix}currentYearGrade`}
                                    to={null}
                                />
                                <WhenFieldChanges
                                    field={`${prefix}studentScholar`}
                                    set={`${prefix}schoolYearsLeft`}
                                    to={null}
                                />
                                <WhenFieldChanges
                                    field={`${prefix}studentScholar`}
                                    set={`${prefix}institution`}
                                    to={null}
                                />
                                <WhenFieldChanges
                                    field={`${prefix}studentScholar`}
                                    set={`${prefix}proofOfStudies`}
                                    to={null}
                                />
                                <WhenFieldChanges
                                    field={`${prefix}studentScholar`}
                                    set={`${prefix}currentYearGrade`}
                                    to={null}
                                />
                                <WhenFieldChanges
                                    field={`${prefix}studentScholar`}
                                    set={`${prefix}schoolYearsLeft`}
                                    to={null}
                                />
                                <WhenFieldChanges
                                    field={`${prefix}studentScholar`}
                                    set={`${prefix}institution`}
                                    to={null}
                                />
                                <WhenFieldChanges
                                    field={`${prefix}studentScholar`}
                                    set={`${prefix}courseName`}
                                    to={null}
                                />
                                <WhenFieldChanges
                                    field={`${prefix}studentScholar`}
                                    set={`${prefix}courseType`}
                                    to={null}
                                />
                            </True>
                        </Condition>
                        <Condition when={`${prefix}studentScholar`} is="Scholar">
                            <True>
                                <OutlinedTextField
                                    name={`${prefix}currentYearGrade`}
                                    label="Current grade"
                                    validate={requiredValidator}
                                    labelprefixed="true"
                                    disabled={!editable}
                                />
                                <OutlinedTextField
                                    name={`${prefix}schoolYearsLeft`}
                                    label="Number of years left at school"
                                    validate={requiredValidator}
                                    labelprefixed="true"
                                    disabled={!editable}
                                />
                                <OutlinedTextField
                                    name={`${prefix}institution`}
                                    label="Name of school"
                                    validate={requiredValidator}
                                    labelprefixed="true"
                                    disabled={!editable}
                                />
                                <FixedWidthFileUpload
                                    name={`${prefix}proofOfStudies`}
                                    label="Upload Proof of Studies"
                                    labelprefixed="true"
                                    validate={requiredValidator}
                                    readonly={!editable}
                                />
                            </True>
                        </Condition>
                        <Condition when={`${prefix}studentScholar`} is="Student">
                            <True>
                                <OutlinedTextField
                                    name={`${prefix}currentYearGrade`}
                                    label="Current year of study"
                                    validate={requiredValidator}
                                    labelprefixed="true"
                                    disabled={!editable}
                                />
                                <OutlinedTextField
                                    name={`${prefix}schoolYearsLeft`}
                                    label="Number of years left of study"
                                    validate={requiredValidator}
                                    labelprefixed="true"
                                    disabled={!editable}
                                />
                                <OutlinedTextField
                                    name={`${prefix}institution`}
                                    validate={requiredValidator}
                                    labelprefixed="true"
                                    disabled={!editable}
                                />
                                <OutlinedTextField
                                    name={`${prefix}courseType`}
                                    label="Course type"
                                    validate={requiredValidator}
                                    labelprefixed="true"
                                    disabled={!editable}
                                />
                                <OutlinedTextField
                                    name={`${prefix}courseName`}
                                    label="Course Name"
                                    validate={requiredValidator}
                                    labelprefixed="true"
                                    disabled={!editable}
                                />
                                <FixedWidthFileUpload
                                    name={`${prefix}proofOfStudies`}
                                    label="Upload Proof of Studies"
                                    labelprefixed="true"
                                    validate={requiredValidator}
                                    readonly={!editable}
                                />
                            </True>
                        </Condition>
                    </Grid>
                    <ClaimantNavigation
                        form={form}
                        handleBackStepper={handleBackStepper}
                        handleNext={handleNext}
                        prefix={`claimant-${claimantIndex}-person`}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export const PersonalDetails = withStyles(styles)(personalDetails);
