import * as React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import {
    Condition,
    FixedWidthButtonGroup,
    OutlinedTextField,
    True,
    WhenFieldChanges,
} from '../../../forms';
import {
    FirstNameMask,
    InitialsMask,
    LastNameMask,
    TelephoneNumberMask,
} from '../../../forms/input-masks';
import { emailFormatValidatorRequiredField, requiredValidator } from '../../../forms/validations';
import styles from '../../../styles/styles';
import { BankingDetailsView } from '../../common/banking-details';
import { ClaimantNavigation } from '../../common/navigation/claimant-navigation';

const financialDetails = function ({
    classes,
    form,
    index,
    handleBackStepper,
    handleNext,
    editable,
    stepsLength,
    claimantIndex,
}) {
    return (
        <Grid container justify="flex-start">
            <Grid item xs={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
                <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                <Typography className={classes.stepHeader}>Financial Details</Typography>
            </Grid>
            <Condition when={`claimants[${claimantIndex}].claimantType`} is="Individual">
                <True>
                    <Grid item xs={12}>
                        <OutlinedTextField
                            name={`claimants[${claimantIndex}].individual.taxReferenceNumber`}
                            disabled={!editable}
                            validate={requiredValidator}
                            labelprefixed="true"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.contentHeader}>Banking Details</Typography>
                    </Grid>
                </True>
            </Condition>
            <BankingDetailsView
                editable={editable}
                prefix={`claimants[${claimantIndex}].`}
                accountHolder="accountHolder"
                other
                proofOfAccount
            />
            <Condition when={`claimants[${claimantIndex}].claimantType`} is="Individual">
                <True>
                    <Grid item xs={12}>
                        <Typography className={classes.contentHeader}>
                            Financial Adviser's Details
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <FixedWidthButtonGroup
                            name={`claimants[${claimantIndex}].doYouHaveAnFa`}
                            label="Do you have a Financial Adviser who can explain your investment options to you?"
                            options={['Yes', 'No']}
                            disabled={!editable}
                            labelprefixed="true"
                        />
                        <WhenFieldChanges
                            field={`claimants[${claimantIndex}].doYouHaveAnFa`}
                            becomes="No"
                            set={`claimants[${claimantIndex}].financialAdviser`}
                            to={null}
                        />
                        <WhenFieldChanges
                            field={`claimants[${claimantIndex}].doYouHaveAnFa`}
                            set={`claimants[${claimantIndex}].wouldYouAnFaToContactYou`}
                            to={null}
                        />
                    </Grid>
                    <Condition when={`claimants[${claimantIndex}].doYouHaveAnFa`} is="Yes">
                        <True>
                            <Grid item xs={12}>
                                <OutlinedTextField
                                    name={`claimants[${claimantIndex}].financialAdviser.firstName`}
                                    mask={FirstNameMask}
                                    disabled={!editable}
                                    validate={requiredValidator}
                                    labelprefixed="true"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <OutlinedTextField
                                    name={`claimants[${claimantIndex}].financialAdviser.lastName`}
                                    label="Surname"
                                    mask={LastNameMask}
                                    disabled={!editable}
                                    validate={requiredValidator}
                                    labelprefixed="true"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <OutlinedTextField
                                    name={`claimants[${claimantIndex}].financialAdviser.initials`}
                                    disabled={!editable}
                                    mask={InitialsMask}
                                    validate={requiredValidator}
                                    labelprefixed="true"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <OutlinedTextField
                                    name={`claimants[${claimantIndex}].financialAdviser.workPhone`}
                                    label="Work Phone Number"
                                    mask={TelephoneNumberMask}
                                    disabled={!editable}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <OutlinedTextField
                                    name={`claimants[${claimantIndex}].financialAdviser.cellPhone`}
                                    label="Cellphone Number"
                                    mask={TelephoneNumberMask}
                                    validate={requiredValidator}
                                    disabled={!editable}
                                    labelprefixed="true"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <OutlinedTextField
                                    name={`claimants[${claimantIndex}].financialAdviser.email`}
                                    label="Email Address"
                                    type="email"
                                    validate={emailFormatValidatorRequiredField}
                                    disabled={!editable}
                                    labelprefixed="true"
                                />
                            </Grid>
                        </True>
                    </Condition>
                    <Condition when={`claimants[${claimantIndex}].doYouHaveAnFa`} is="No">
                        <True>
                            <Grid item xs={12}>
                                <FixedWidthButtonGroup
                                    label="Would you like us to ask a Liberty Financial Adviser to contact you about your investment options?"
                                    name={`claimants[${claimantIndex}].wouldYouAnFaToContactYou`}
                                    options={['Yes', 'No']}
                                    validate={requiredValidator}
                                    labelprefixed="true"
                                    disabled={!editable}
                                />
                            </Grid>
                        </True>
                    </Condition>
                </True>
            </Condition>
            <ClaimantNavigation
                form={form}
                handleBackStepper={handleBackStepper}
                handleNext={handleNext}
                prefix={`claimant-${claimantIndex}-financial`}
            />
        </Grid>
    );
};

export const FinancialDetails = withStyles(styles)(financialDetails);
