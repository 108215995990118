import { withStyles, Typography, Grid } from '@material-ui/core';
import React from 'react';
import {
    Condition,
    FixedWidthButtonGroup,
    OutlinedDatePicker,
    OutlinedSearchSelectField,
    OutlinedTextField,
    True,
    WhenFieldChanges,
} from '../../../forms';
import { FixedWidthFileUpload } from '../../../forms/form-components/fixed-width-file-upload';
import { requiredValidator } from '../../../forms/validations';
import { NaturalCausesOfDeath, UnnaturalCausesOfDeath } from '../../../shared/lookups';
import styles from '../../../styles/styles';
import { DeceasedNavigation } from '../../common/navigation/deceased-navigation';

const detailsOfDeath = function ({
    classes,
    handleBack,
    handleBackStepper,
    handleNext,
    form,
    index,
    stepsLength,
    editable,
}) {
    const prefix = 'deceasedDetails.detailsOfDeath.';
    return (
        <Grid container justify="flex-start">
            <Grid item xs={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
                <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                <Typography className={classes.stepHeader}>Details Of Death</Typography>
            </Grid>
            <Grid item xs={12}>
                <OutlinedDatePicker
                    name={`${prefix}dateOfDeath`}
                    label="Date of Death"
                    validate={requiredValidator}
                    labelprefixed="true"
                    disabled={!editable}
                    disableFuture
                />
            </Grid>
            <Grid item xs={12}>
                <OutlinedTextField
                    name={`${prefix}placeOfDeath`}
                    label="Place of Death"
                    validate={requiredValidator}
                    labelprefixed="true"
                    disabled={!editable}
                />
            </Grid>
            <Grid item xs={12}>
                <FixedWidthButtonGroup
                    label="Cause of Death"
                    name={`${prefix}conditionType`}
                    options={['Natural', 'Unnatural']}
                    validate={requiredValidator}
                    labelprefixed="true"
                    disabled={!editable}
                />
            </Grid>
            <WhenFieldChanges
                field={`${prefix}conditionType`}
                set={`${prefix}condition`}
                to={null}
            />
            <WhenFieldChanges
                field={`${prefix}conditionType`}
                becomes="Natural"
                set={`${prefix}reportedToPolice`}
                to={null}
            />
            <Condition when={`${prefix}conditionType`} is="Natural">
                <True>
                    <Grid item xs={12}>
                        <OutlinedSearchSelectField
                            name={`${prefix}condition`}
                            options={NaturalCausesOfDeath}
                            disabled={!editable}
                        />
                    </Grid>
                </True>
            </Condition>
            <Condition when={`${prefix}conditionType`} is="Unnatural">
                <True>
                    <Grid item xs={12}>
                        <OutlinedSearchSelectField
                            name={`${prefix}condition`}
                            options={UnnaturalCausesOfDeath}
                            disabled={!editable}
                        />
                    </Grid>
                </True>
            </Condition>
            <WhenFieldChanges field={`${prefix}condition`} set={`${prefix}description`} to={null} />
            <WhenFieldChanges
                field={`${prefix}conditionType`}
                becomes="Unnatural"
                set={`${prefix}reportedToPolice`}
                to="No"
            />
            <Condition when={`${prefix}condition`} is={['798', '799']}>
                <True>
                    <Grid item xs={12}>
                        <OutlinedTextField
                            name={`${prefix}description`}
                            label="Description"
                            disabled={!editable}
                        />
                    </Grid>
                </True>
            </Condition>
            <Condition when={`${prefix}conditionType`} is="Unnatural">
                <True>
                    <Grid item xs={12}>
                        <FixedWidthButtonGroup
                            label="Was the death reported to the police?"
                            name={`${prefix}reportedToPolice`}
                            options={['Yes', 'No']}
                            validate={requiredValidator}
                            labelprefixed="true"
                            disabled={!editable}
                        />
                        <WhenFieldChanges
                            field={`${prefix}reportedToPolice`}
                            becomes="No"
                            set={`${prefix}policeStation`}
                            to={null}
                        />
                        <WhenFieldChanges
                            field={`${prefix}reportedToPolice`}
                            becomes="No"
                            set={`${prefix}policeCaseNumber`}
                            to={null}
                        />
                        <WhenFieldChanges
                            field={`${prefix}reportedToPolice`}
                            becomes="No"
                            set="policeStatement"
                            to={null}
                        />
                    </Grid>
                    <Condition when={`${prefix}reportedToPolice`} is="Yes">
                        <True>
                            <Grid item xs={12}>
                                <OutlinedTextField
                                    name={`${prefix}policeStation`}
                                    validate={requiredValidator}
                                    labelprefixed="true"
                                    disabled={!editable}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <OutlinedTextField
                                    name={`${prefix}policeCaseNumber`}
                                    label="Case Number"
                                    validate={requiredValidator}
                                    labelprefixed="true"
                                    disabled={!editable}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FixedWidthFileUpload
                                    name={`${prefix}policeStatement`}
                                    label="Upload Police Report"
                                    validate={requiredValidator}
                                    labelprefixed="true"
                                    readonly={!editable}
                                />
                            </Grid>
                        </True>
                    </Condition>
                </True>
            </Condition>
            <Grid item xs={12}>
                <FixedWidthFileUpload
                    name={`${prefix}deathCertificate`}
                    label="Upload Death Certificate"
                    validate={requiredValidator}
                    labelprefixed="true"
                    readonly={!editable}
                />
            </Grid>
            <DeceasedNavigation
                form={form}
                handleBack={handleBack}
                handleBackStepper={handleBackStepper}
                handleNext={handleNext}
                prefix="deceased-death-details"
            />
        </Grid>
    );
};

export const DetailsOfDeath = withStyles(styles)(detailsOfDeath);
