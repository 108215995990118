import * as React from 'react';
import { Paper, withStyles } from '@material-ui/core';
import styles from '../../../styles/styles';
import { VerticalStepper } from '../../common/vertical-stepper';
import { getComponents } from './policyholder-components';

const policyOwnerDetails = function ({ classes, editable, form, handleBack, ...rest }) {
    const policyHolder = form.getState().values.policyholder || [];

    return (
        <Paper className={[classes.shadedPaper, classes.paperShadowLess].join(' ')}>
            <VerticalStepper
                {...rest}
                editable={editable}
                form={form}
                verticalComponents={getComponents(policyHolder)}
                handleBack={handleBack}
                verticalIndexName={`verticalStepIndex`}
            />
        </Paper>
    );
};

export const PolicyOwnerDetails = withStyles(styles)(policyOwnerDetails);
