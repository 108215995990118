import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { OutlinedPhoneField, OutlinedSearchSelectField, OutlinedTextField } from '../../../forms';
import { EmailMask, TelephoneNumberMask } from '../../../forms/input-masks';
import {
    conditionalRequired, phoneNumberRequiredValidator,
} from '../../../forms/validations';
import styles from '../../../styles/styles';
import { ClaimantNavigation } from '../../common/navigation/claimant-navigation';
import { Field } from 'react-final-form';
import { getCommunicationMethods } from '../../../shared/helper-functions';
import { CommunicationMethods } from '../../../shared/lookups';
import { NavigationButton } from '../../common/navigation/navigation-button';
import classNames from 'classnames';

const contactDetails = function ({
    classes,
    form,
    index,
    handleNext,
    handleBackStepper,
    editable,
    stepsLength,
    claimantIndex,
    handleBack
}) {
    const prefix = 'claimant.contactDetails.';
    return (
        <Grid container spacing={10} justify="flex-start">
            <Grid item xs={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
                <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                <Typography className={classes.stepHeader}>Contact Details</Typography>
            </Grid>
            <Grid item xs={12} lg={12}>
                <Grid item xs={12} lg={12}>
                    <OutlinedPhoneField
                        name={`${prefix}telephoneNumber`}
                        label="Telephone Number"
                        mask={TelephoneNumberMask}
                        disabled={!editable}
                        validate={conditionalRequired(values => {
                            if (
                                values &&
                                values['claimant'] &&
                                values['claimant']['contactDetails'] &&
                                values['claimant']['contactDetails'][
                                    'preferredMethodOfCommunication'
                                ]
                            ) {
                                if (
                                    values['claimant']['contactDetails'][
                                        'preferredMethodOfCommunication'
                                    ] === 'Telephone'
                                )
                                    return true;
                            }

                            return false;
                        })}
                    />
                </Grid>
                <Grid item xs={12} lg={12}>
                    <OutlinedPhoneField
                        name={`${prefix}cellphoneNumber`}
                        label="Cellphone Number"
                        mask={TelephoneNumberMask}
                        labelprefixed={true}
                        disabled={!editable}
                        validate={phoneNumberRequiredValidator}
                    />
                </Grid>
                <Grid item xs={12} lg={12}>
                    <OutlinedPhoneField
                        name={`${prefix}faxNumber`}
                        label="Fax Number"
                        mask={EmailMask}
                        disabled={!editable}
                    />
                </Grid>
                <Grid item xs={12} lg={12}>
                    <OutlinedTextField
                        name={`${prefix}emailAddress`}
                        label="Email Address"
                        mask={EmailMask}
                        disabled={!editable}
                        validate={conditionalRequired(values => {
                            if (
                                values &&
                                values['claimant'] &&
                                values['claimant']['contactDetails'] &&
                                values['claimant']['contactDetails'][
                                    'preferredMethodOfCommunication'
                                ]
                            ) {
                                if (
                                    values['claimant']['contactDetails'][
                                        'preferredMethodOfCommunication'
                                    ] === 'Email'
                                )
                                    return true;
                            }


                            return false;
                        })}
                    />
                </Grid>
                <Field
                    name={'claimant.contactDetails.preferredMethodOfCommunication'}
                    subscription={{ value: true, error: true, touched: true }}
                    validate={value => (value ? undefined : 'This is a required field')}
                    component={({ input: { value: values, onChange }, meta }) => {
                        const methods = getCommunicationMethods(values);
                        return (
                            <Grid item xs={12} lg={12}>
                                <OutlinedSearchSelectField
                                    label="Preferred Method Of Communication"
                                    name={'claimant.contactDetails.preferredMethodOfCommunication'}
                                    options={methods.length > 0 ? methods : CommunicationMethods}
                                    labelprefixed="true"
                                    disabled={!editable}
                                    shaded={false}
                                    onChange={onChange}
                                    error={meta.touched && meta.error}
                                    helperText={meta.touched && meta.error}
                                />
                            </Grid>
                        );
                    }}
                />

            </Grid>
            <ClaimantNavigation
                form={form}
                handleBackStepper={handleBackStepper}
                handleNext={handleNext}
                prefix={`claimant-${claimantIndex}-contact`}
            />
            <Grid item xs={12} className={classes.navigationGroup}>
                <NavigationButton
                    className={classNames(classes.buttonSecondary, classes.fullWidthButton)}
                    onClick={() => handleBack(form)}
                    id={`${prefix}-previous-button`}
                    label="Previous Section"
                />
            </Grid>
        </Grid>
    );
};

export const ContactDetails = withStyles(styles)(contactDetails);
