const charRegex = /[A-Za-z]/;
const dotRegex = /\./;

export const InitialsMask = value => {
    if (value.length < 2 || value[1] !== '.') {
        return withoutDotMask(value);
    }
    return withDotMask(value);
};

const withoutDotMask = value => {
    return value
        .split('')
        .filter(c => charRegex.test(c))
        .map(c => charRegex);
};
const withDotMask = value => {
    return value
        .split('')
        .map((c, i) => {
            if (charRegex.test(c) && i % 2 === 0) {
                return charRegex;
            }

            if (dotRegex.test(c) && i % 2 === 1) {
                return dotRegex;
            }

            return '';
        })
        .filter(r => !!r);
};
