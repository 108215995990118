import { connect } from 'react-redux';
import {
    checkIfLoggedIn,
    checkIfLoggedInRedirectIfNot,
    masterPageProps,
    logout,
} from '../authentication';
import MasterLayout from './master-layout';
import { goToHome } from '../pages/dashboard/claim-selection-actions.js';
import { loadPostalCodesAction } from '../lookups';
import { loadBankNamesAction, loadUniversalBranchesAction } from '../lookups';

function mapStateToProps(state) {
    return masterPageProps(state);
}

const mapDispatchToProps = {
    checkIfLoggedIn,
    checkIfLoggedInRedirectIfNot,
    loadPostalCodesAction,
    loadBankNamesAction,
    loadUniversalBranchesAction,
    logout,
    goToHome,
};

export default connect(mapStateToProps, mapDispatchToProps)(MasterLayout);
