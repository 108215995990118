import { Grid, withStyles } from '@material-ui/core';
import { push } from 'connected-react-router';
import React from 'react';
import { connect } from 'react-redux';
import styles from '../../../styles/styles';
import { NavigationButton } from './navigation-button';

function introNavigation({ classes, editable, form, goToDashboard, handleSubmit }) {
    return (
        <Grid item xs={12} container justify="center" style={{ marginTop: '24px' }}>
            <Grid item xs={4}>
                <NavigationButton
                    className={classes.buttonSecondary}
                    onClick={goToDashboard}
                    id="complete-later"
                    label={editable ? 'Complete Later' : 'Dashboard'}
                />
                <NavigationButton
                    color="primary"
                    className={classes.button}
                    onClick={() => handleSubmit(form)}
                    id="complete-now"
                    label={editable ? 'Complete Now' : 'View Claim'}
                />
            </Grid>
        </Grid>
    );
}

const mapDispatchToProps = {
    goToDashboard: () => push('/'),
};

export const IntroNavigation = connect(
    null,
    mapDispatchToProps
)(withStyles(styles)(introNavigation));
