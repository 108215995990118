import { CLOSE_DIALOG, OPEN_DIALOG } from './types';

export function openDialogAction(options) {
    return {
        type: OPEN_DIALOG,
        payload: {
            ...options,
            dialogOpen: true,
        },
    };
}

export function closeDialogAction() {
    return {
        type: CLOSE_DIALOG,
        payload: {
            dialogOpen: false,
        },
    };
}
