import { withStyles } from '@material-ui/core';
import React from 'react';
import {
    Condition,
    OutlinedSearchSelectField,
    OutlinedTextField,
    True,
    WhenFieldChanges,
} from '../../../../forms';

import { NumberMask } from '../../../../forms/input-masks';
import { conditionalRequired, requiredValidator } from '../../../../forms/validations';
import { defaultCountry } from '../../../../shared/helper-functions';
import { addressTypes, Provinces } from '../../../../shared/lookups';
import styles from '../../../../styles/styles';

const commonAddress = ({ editable, prefix, isResidential, shaded = false, ...rest }) => {
    const postalCodeProperty = isResidential ? `${prefix}PostalCode` : `${prefix}Code`;
    return (
        <React.Fragment>
            <OutlinedSearchSelectField
                name={`policyholder.individual.addressDetails.addressType`}
                label="Address Type"
                options={addressTypes()}
                validate={requiredValidator}
                disabled
                shaded={shaded}
            />
            <WhenFieldChanges
                field={`policyholder.individual.addressDetails.addressType`}
                set={`${prefix}AddressSuburb`}
                to={'Street'}
            />
            <WhenFieldChanges
                field={`policyholder.individual.addressDetails.addressType`}
                set={`${prefix}AddressTown`}
                to={null}
            />
            <WhenFieldChanges field={`policyholder.individual.addressDetails.addressType`} set={postalCodeProperty} to={null} />
            <WhenFieldChanges
                field={`${prefix}addressType`}
                set={`${prefix}AddressCountry`}
                to={defaultCountry}
            />
            <Condition when={`policyholder.individual.addressDetails.addressType`} hasValue>
                <True>
                    <Condition when={`policyholder.individual.addressDetails.addressType`} is={['Street']}>
                        <True>
                            <OutlinedTextField
                                name={`${prefix}AddressLineOne`}
                                label="Address Line 1"
                                labelprefixed={true}
                                validate={requiredValidator}
                                disabled={!editable}
                                shaded={shaded}
                            />
                            <OutlinedTextField
                                name={`${prefix}AddressLineTwo`}
                                label="Address Line 2"
                                disabled={!editable}
                                shaded={shaded}
                                validate={conditionalRequired(
                                    values =>
                                        values &&
                                        values.claimants &&
                                        values.claimants.individual
                                )}
                            />
                        </True>
                    </Condition>
                    <OutlinedTextField
                        name={`${prefix}AddressSuburb`}
                        label="Town/Suburb"
                        labelprefixed={true}
                        validate={requiredValidator}
                        disabled={!editable}
                        shaded={shaded}
                    />
                    <OutlinedTextField
                        name={`${prefix}AddressTown`}
                        label="City"
                        labelprefixed={true}
                        validate={requiredValidator}
                        disabled={!editable}
                        shaded={shaded}
                    />
                <OutlinedTextField
                    name={`${prefix}PostalCode`}
                    labelprefixed="true"
                    label="Postal Code"
                    validate={requiredValidator}
                    disabled={!editable}
                    mask={NumberMask}
                />
                <OutlinedSearchSelectField
                    name={`${prefix}Province`}
                    labelprefixed="true"
                    label="Province"
                    options={Provinces}
                    validate={requiredValidator}
                    disabled={!editable}
                />
                </True>
            </Condition>
        </React.Fragment>
    );
};

export const CommonAddress = withStyles(styles)(commonAddress);
