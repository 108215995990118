import * as React from 'react';
import { Grid, withStyles } from '@material-ui/core';
import * as rsaIdParser from 'south-african-id-parser';
import { Condition, False, WhenFieldChanges, True, validations } from '../../forms';
import {
    FixedWidthRadioGroup,
    FixedWidthRadioOption,
    OutlinedDatePicker,
    OutlinedSearchSelectField,
    OutlinedTextField,
} from '../../forms';
import { FixedWidthFileUpload } from '../../forms/form-components/fixed-width-file-upload';
import styles from '../../styles/styles';
import { Countries, idOptions, idOptionsWithForeign } from '../../shared/lookups';

const { requiredValidator, composeValidators } = validations;

function identityDetails({ prefix, editable, ...rest }) {
    const { includeGender, includeForeignTypes, idDocumentRequired } = rest;
    return (
        <React.Fragment>
            <Grid item xs={12} lg={12}>
                <OutlinedSearchSelectField
                    name={`${prefix}idType`}
                    options={includeForeignTypes ? idOptionsWithForeign : idOptions}
                    validate={requiredValidator}
                    disabled={!editable}
                    labelprefixed="true"
                    label="Identification Type"
                />
                <WhenFieldChanges
                    field={`${prefix}idType`}
                    becomes="RsaPassport"
                    set={`${prefix}passport.country`}
                    to="South Africa"
                />
                <WhenFieldChanges
                    field={`${prefix}idType`}
                    condition={isNotRsaPassport}
                    set={`${prefix}passport.country`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${prefix}idType`}
                    condition={isNotPassport}
                    set={`${prefix}passport`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${prefix}idType`}
                    condition={isTemporaryDocument}
                    set={`${prefix}country`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${prefix}idType`}
                    condition={isNotOtherIdTypes}
                    set={`${prefix}country`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${prefix}idType`}
                    condition={isNotOtherIdTypes}
                    set={`${prefix}issued`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${prefix}idType`}
                    condition={isNotOtherIdTypes}
                    set={`${prefix}expiry`}
                    to={null}
                />
            </Grid>
            <Condition
                when={`${prefix}idType`}
                is={[
                    'RsaId',
                    'RsaIdSmartCard',
                    'RsaIdTemporaryId',
                    'RsaIdBirthCertificate',
                    'RsaIdDrivingLicence',
                ]}
            >
                <True>
                    <Grid item xs={12} lg={12}>
                        <OutlinedTextField
                            name={`${prefix}idNumber`}
                            label="RSA ID Number"
                            validate={composeValidators(requiredValidator, validateRSAIdNumber)}
                            disabled={!editable}
                            labelprefixed="true"
                        />
                    </Grid>
                </True>
            </Condition>
            <Condition
                when={`${prefix}idType`}
                is={[
                    'ForeignIdBook',
                    'ForeignDrivingLicence',
                    'ForeignBirthCertificate',
                    'AAInternationalDrivingLicence',
                    'WorkPermit',
                    'Asylum',
                ]}
            >
                <True>
                    <Grid item xs={12} lg={12}>
                        <OutlinedTextField
                            name={`${prefix}idNumber`}
                            label="ID number"
                            disabled={!editable}
                            validate={requiredValidator}
                            labelprefixed="true"
                        />
                    </Grid>
                </True>
            </Condition>
            <Condition
                when={`${prefix}idType`}
                is={['Asylum', 'WorkPermit', 'ForeignIdBook', 'RsaIdTemporaryId']}
            >
                <True>
                    <Condition when={`${prefix}idType`} is={['WorkPermit', 'RsaIdTemporaryId']}>
                        <False>
                            <Grid item xs={12} lg={12}>
                                <OutlinedSearchSelectField
                                    shaded={false}
                                    name={`${prefix}country`}
                                    options={Countries}
                                    validate={requiredValidator}
                                    disabled={!editable}
                                    labelprefixed="true"
                                    label="Country of Issue"
                                />
                            </Grid>
                        </False>
                    </Condition>
                    <Grid item xs={12} lg={12}>
                        <OutlinedDatePicker
                            name={`${prefix}issued`}
                            label="Date of Issue"
                            disableFuture
                            validate={requiredValidator}
                            disabled={!editable}
                            labelprefixed="true"
                        />
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <OutlinedDatePicker
                            name={`${prefix}expiry`}
                            label="Date of Expiry"
                            validate={requiredValidator}
                            disabled={!editable}
                            labelprefixed="true"
                        />
                    </Grid>
                </True>
            </Condition>
            <Condition when={`${prefix}idType`} is={['Passport', 'ForeignPassport', 'RsaPassport']}>
                <True>
                    <Grid item xs={12} lg={12}>
                        <OutlinedTextField
                            name={`${prefix}idNumber`}
                            label="Passport Number"
                            validate={requiredValidator}
                            disabled={!editable}
                            labelprefixed="true"
                        />
                    </Grid>
                    <Condition when={`${prefix}idType`} is={['Passport', 'ForeignPassport']}>
                        <True>
                            <Grid item xs={12} lg={12}>
                                <OutlinedSearchSelectField
                                    shaded={false}
                                    name={`${prefix}passport.country`}
                                    options={Countries}
                                    validate={requiredValidator}
                                    disabled={!editable}
                                    labelprefixed="true"
                                    label="Country of Issue"
                                />
                            </Grid>
                        </True>
                        <False>
                            <Grid item xs={12} lg={12}>
                                <OutlinedSearchSelectField
                                    shaded={false}
                                    name={`${prefix}passport.country`}
                                    label="Country of Issue"
                                    labelprefixed="true"
                                    options={Countries}
                                    disabled
                                />
                            </Grid>
                        </False>
                    </Condition>
                    <Grid item xs={12} lg={12}>
                        <OutlinedDatePicker
                            name={`${prefix}passport.issued`}
                            label="Passport Date of Issue"
                            disableFuture
                            validate={requiredValidator}
                            disabled={!editable}
                            labelprefixed="true"
                        />
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <OutlinedDatePicker
                            name={`${prefix}passport.expiry`}
                            label="Passport Date of Expiry"
                            validate={requiredValidator}
                            disabled={!editable}
                            labelprefixed="true"
                        />
                    </Grid>
                </True>
            </Condition>
            {idDocumentRequired && (
                <Condition when={`${prefix}idType`} hasValue>
                    <True>
                        <Grid item xs={12}>
                            <FixedWidthFileUpload
                                name={`${prefix}proofOfIdentification`}
                                label="* Upload proof of Identification "
                                validate={requiredValidator}
                                readonly={!editable}
                            />
                        </Grid>
                    </True>
                </Condition>
            )}
            <WhenFieldChanges
                field={`${prefix}idNumber`}
                set={`${prefix}dateOfBirth`}
                to={getDateOfBirthFromRsaId}
            />
            <Grid item xs={12} lg={12}>
                <OutlinedDatePicker
                    name={`${prefix}dateOfBirth`}
                    label="Date of Birth"
                    validate={requiredValidator}
                    disabled={!editable}
                    labelprefixed="true"
                    disableFuture
                />
            </Grid>
            <WhenFieldChanges
                field={`${prefix}idNumber`}
                set={`${prefix}gender`}
                to={getGenderFromId}
            />
            {includeGender && (
                <Grid item xs={12}>
                    <FixedWidthRadioGroup
                        name={`${prefix}gender`}
                        label="Gender"
                        disabled={!editable}
                        validate={requiredValidator}
                    >
                        <FixedWidthRadioOption value="Female" />
                        <FixedWidthRadioOption value="Male" />
                    </FixedWidthRadioGroup>
                </Grid>
            )}
        </React.Fragment>
    );
}

export function validateRSAIdNumber(value) {
    const isValid = rsaIdParser.validate(value);
    return isValid ? undefined : 'Invalid RSA Id number';
}

export function getDateOfBirthFromRsaId(id, currentDateOfBirth) {
    if (!id || !rsaIdParser.validate(id)) {
        return currentDateOfBirth;
    }
    const date = rsaIdParser.parseDateOfBirth(id);
    return date;
}

export function getGenderFromId(id, gender) {
    if (id && rsaIdParser.validate(id)) {
        if (rsaIdParser.parseIsMale(id)) {
            return 'Male';
        }
        if (rsaIdParser.parseIsFemale) {
            return 'Female';
        }
    }
    return gender;
}

export function isPassport(idType) {
    const passports = ['Passport', 'ForeignPassport', 'RsaPassport'];
    return passports.includes(idType);
}

export function isNotPassport(idType) {
    return isPassport(idType) === false;
}

export function isNotOtherIdTypes(idType) {
    return ['Asylum', 'WorkPermit', 'ForeignIdBook', 'RsaIdTemporaryId'].includes(idType) === false;
}

export function isTemporaryDocument(idType) {
    return ['WorkPermit', 'RsaIdTemporaryId'].includes(idType);
}

export function isNotRsaPassport(idType) {
    return idType !== 'RsaPassport';
}

export const IdentityDetails = withStyles(styles)(identityDetails);
