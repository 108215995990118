import { Grid, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import { OutlinedPhoneField, OutlinedTextField } from '../../../forms';
import { emailFormatValidator, requiredValidator } from '../../../forms/validations';
import styles from '../../../styles/styles';

const personalDetails = function ({ classes, prefix = '', ...rest }) {
    const { index, stepsLength, editable } = rest;
    const fullPrefix = `${prefix}contactDetails.`;
    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
                <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                <Typography className={classes.stepHeader}>Contact Details</Typography>
            </Grid>
            <Grid item xs={12}>
                <OutlinedPhoneField name={`${fullPrefix}homeNumber`} disabled={!editable} />
            </Grid>
            <Grid item xs={12}>
                <OutlinedPhoneField name={`${fullPrefix}workNumber`} disabled={!editable} />
            </Grid>
            <Grid item xs={12}>
                <OutlinedPhoneField
                    name={`${fullPrefix}cellphoneNumber`}
                    disabled={!editable}
                    validate={requiredValidator}
                />
            </Grid>
            <Grid item xs={12}>
                <OutlinedTextField
                    name={`${fullPrefix}emailAddress`}
                    type="email"
                    label="Email Address"
                    validate={emailFormatValidator}
                    disabled={!editable}
                />
            </Grid>
        </React.Fragment>
    );
};

export const ContactDetails = withStyles(styles)(personalDetails);
