import * as React from 'react';
import { Grid, Paper, withStyles } from '@material-ui/core';
import styles from '../../styles/styles';
import { DownloadFile } from '../common/introduction/download-file';
import { StepsInformation } from '../common/introduction/steps-information';
import { IntroNavigation } from '../common/navigation/intro-navigation';
import { extendedComponents } from './funeral-claim';
import { IntroductionHeader } from '../common/introduction/introduction-header';

const { env } = require('../../env/env');

const introduction = function ({ classes, editable, form, handleSubmit }) {
    return (
        <Paper className={[classes.shadedPaper, classes.paperShadowLess].join(' ')}>
            <Grid container spacing={0} justify="flex-start">
                <IntroductionHeader claim="Funeral" policy="Liberty" />
                <DownloadFile
                    url={env.Funeral_Checklist_URL}
                    filename="Claim requirements checklist"
                />
                <StepsInformation components={extendedComponents} />
                <IntroNavigation editable={editable} form={form} handleSubmit={handleSubmit} />
            </Grid>
        </Paper>
    );
};

export const Introduction = withStyles(styles)(introduction);
