import React from 'react';
import { requiredValidator } from '../../../forms/validations';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from '../../../styles/styles';
import { FixedWidthFileUpload } from '../../../forms/form-components/fixed-width-file-upload';
import {
    Condition,
    FixedWidthCheckBoxField,
    FixedWidthRadioGroup,
    FixedWidthRadioOption,
    OutlinedSearchSelectField,
    OutlinedTextField,
    True,
    WhenFieldChanges,
} from '../../../forms';
import { Countries, Titles, NaturalRelationships } from '../../../shared/lookups';
import { IdentityDetails } from '../../common/identity-details';
import { FirstNameMask, InitialsMask, LastNameMask } from '../../../forms/input-masks';
import { AddressDetails } from '../common/address-details';
import { ContactDetailsView } from '../common/contact-details-view';
import { getPossibleGuardians } from '../../../shared/helper-functions';
import { ClaimantNavigation } from '../../common/navigation/claimant-navigation';

const guardianDetails = function ({
    classes,
    form,
    index,
    handleBackStepper,
    handleNext,
    editable,
    stepsLength,
    claimantIndex,
}) {
    const prefix = `claimants[${claimantIndex}].guardian.`;
    const claimants = form.getState().values.claimants;
    return (
        <Grid container spacing={10} justify="flex-start">
            <Grid item xs={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
                <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                <Typography className={classes.stepHeader}>Guardian Details</Typography>
            </Grid>

            <Grid item xs={12} lg={12}>
                <WhenFieldChanges
                    field={`claimants[${claimantIndex}].individual.guardianSameAs`}
                    set={`claimants[${claimantIndex}].guardian.`}
                    to={null}
                />
                <OutlinedSearchSelectField
                    name={`claimants[${claimantIndex}].individual.guardianSameAs`}
                    options={getGuardians(claimants)}
                    label="Guardian Same As"
                    disabled={!editable}
                    validate={requiredValidator}
                    labelprefixed="true"
                />
                <Condition
                    when={`claimants[${claimantIndex}].individual.guardianSameAs`}
                    is="Other"
                >
                    <True>
                        <OutlinedSearchSelectField
                            name={`${prefix}title`}
                            options={Titles}
                            label="Title"
                            disabled={!editable}
                            validate={requiredValidator}
                            labelprefixed="true"
                            focused={requiredValidator}
                        />
                        <OutlinedTextField
                            name={`${prefix}firstName`}
                            label="First Name"
                            validate={requiredValidator}
                            labelprefixed="true"
                            mask={FirstNameMask}
                            disabled={!editable}
                        />
                        <OutlinedTextField
                            name={`${prefix}secondName`}
                            label="Second Name"
                            mask={FirstNameMask}
                            disabled={!editable}
                        />
                        <OutlinedTextField
                            name={`${prefix}lastName`}
                            label="Surname"
                            validate={requiredValidator}
                            labelprefixed="true"
                            mask={LastNameMask}
                            disabled={!editable}
                        />
                        <OutlinedTextField
                            name={`${prefix}initials`}
                            disabled={!editable}
                            mask={InitialsMask}
                        />

                        <IdentityDetails
                            prefix={prefix}
                            includeGender={true}
                            includeForeignTypes={true}
                            editable={editable}
                            idDocumentRequired={true}
                        />
                        <OutlinedSearchSelectField
                            name={`${prefix}nationality`}
                            options={Countries}
                            validate={requiredValidator}
                            labelprefixed="true"
                            disabled={!editable}
                        />
                        <OutlinedSearchSelectField
                            name={`${prefix}relationshipToLifeAssured`}
                            options={NaturalRelationships}
                            label="Relationship to Life Assured"
                            validate={requiredValidator}
                            labelprefixed="true"
                            disabled={!editable}
                        />
                        <Condition when={`${prefix}relationshipToLifeAssured`} is="Other">
                            <True>
                                <OutlinedTextField
                                    name={`${prefix}relationshipToLifeAssuredDescription`}
                                    disabled={!editable}
                                />
                            </True>
                        </Condition>

                        <OutlinedSearchSelectField
                            name={`${prefix}relationshipToMinor`}
                            options={NaturalRelationships}
                            label="Relationship to the minor?"
                            validate={requiredValidator}
                            labelprefixed="true"
                            disabled={!editable}
                        />
                        <Condition when={`${prefix}relationshipToMinor`} is="Other">
                            <True>
                                <OutlinedTextField
                                    name={`${prefix}relationshipToMinorDescription`}
                                    disabled={!editable}
                                />
                            </True>
                        </Condition>

                        <Condition when={`${prefix}relationshipToMinor`} hasValue>
                            <True>
                                <Grid item xs={12} lg={12}>
                                    <FixedWidthFileUpload
                                        name={`${prefix}proofOfGuardianship`}
                                        label="Upload Proof of Guardianship"
                                        validate={requiredValidator}
                                        labelprefixed="true"
                                        readonly={!editable}
                                    />
                                </Grid>
                            </True>
                        </Condition>
                        <FixedWidthRadioGroup
                            name={`${prefix}isRSAResident`}
                            label="Are you a South African resident?"
                            className={classes.radioGroupStyle}
                            disabled={!editable}
                            validate={requiredValidator}
                            labelprefixed="true"
                        >
                            <FixedWidthRadioOption value="Yes" />
                            <FixedWidthRadioOption value="No" />
                        </FixedWidthRadioGroup>
                        <Condition when={`${prefix}isRSAResident`} is="No">
                            <True>
                                <OutlinedSearchSelectField
                                    name={`${prefix}countryOfResidence`}
                                    options={Countries}
                                    disabled={!editable}
                                />
                                <FixedWidthRadioGroup
                                    name={`${prefix}officiallyEmigrated`}
                                    label="Have you officially emigrated?"
                                    disabled={!editable}
                                >
                                    <FixedWidthRadioOption value="Yes" />
                                    <FixedWidthRadioOption value="No" />
                                </FixedWidthRadioGroup>
                            </True>
                        </Condition>

                        <WhenFieldChanges
                            field={`${prefix}officiallyEmigrated`}
                            becomes="No"
                            set={`${prefix}exchangeForm`}
                            to={null}
                        />
                        <Condition when={`${prefix}officiallyEmigrated`} is="Yes">
                            <True>
                                <FixedWidthFileUpload
                                    name={`${prefix}exchangeForm`}
                                    label="Upload Exchange Form"
                                    validate={requiredValidator}
                                    labelprefixed="true"
                                    readonly={!editable}
                                />
                            </True>
                        </Condition>
                        <FixedWidthRadioGroup
                            name={`${prefix}taxStatus`}
                            label="What is your tax status?"
                            disabled={!editable}
                        >
                            <FixedWidthRadioOption value="Tax Paying" />
                            <FixedWidthRadioOption value="Non Tax Paying" />
                        </FixedWidthRadioGroup>
                        <Condition when={`${prefix}taxStatus`} is="Tax Paying">
                            <True>
                                <OutlinedSearchSelectField
                                    name={`${prefix}countryOfTaxResidence`}
                                    label="Country of Tax Residence"
                                    options={Countries}
                                    validate={requiredValidator}
                                    labelprefixed="true"
                                    disabled={!editable}
                                />
                            </True>
                        </Condition>
                        <Typography className={classes.contentHeader}>
                            Guardian's Residential Address Details
                        </Typography>
                        <AddressDetails
                            editable={editable}
                            prefix={`${prefix}residentialAddress.`}
                            isResidential
                            proofOfAddress
                        />
                        <WhenFieldChanges
                            field={`${prefix}postalAddressDifferent`}
                            set={`${prefix}postalAddress`}
                            to={null}
                        />
                        <Typography className={classes.contentHeader}>
                            Guardian's Postal Address Details
                        </Typography>
                        <FixedWidthCheckBoxField
                            name={`${prefix}postalAddressDifferent`}
                            label="Different to Residential Address"
                            disabled={!editable}
                        />
                        <Condition when={`${prefix}postalAddressDifferent`} is={true}>
                            <True>
                                <Grid item xs={12}>
                                    <AddressDetails
                                        editable={editable}
                                        prefix={`${prefix}postalAddress.`}
                                        isResidential={false}
                                    />
                                </Grid>
                            </True>
                        </Condition>
                        <Grid item xs={12}>
                            <Typography className={classes.contentHeader}>
                                Guardian's Contact Details
                            </Typography>
                            <ContactDetailsView
                                prefix={`${prefix}contactDetails.`}
                                hasCommucationPreference={true}
                                editable={editable}
                            />
                        </Grid>
                    </True>
                </Condition>
            </Grid>
            <ClaimantNavigation
                form={form}
                handleBackStepper={handleBackStepper}
                handleNext={handleNext}
                prefix={`claimant-${claimantIndex}-guardian`}
            />
        </Grid>
    );
};

function getGuardians(claimants) {
    const options = getPossibleGuardians(claimants);
    options.push({
        label: 'Other',
        value: 'Other',
    });
    return options;
}

export const GuardianDetails = withStyles(styles)(guardianDetails);
