import * as React from 'react';
import { Grid, Paper, withStyles, Typography } from '@material-ui/core';
import styles from '../../styles/styles';

const applicationProcess = function ({ classes, sectionIndex }) {
    return (
        <Paper
            className={[classes.shadedPaper, classes.paperShadowLess].join(' ')}
            style={{ paddingBottom: '0px' }}
        >
            <Grid container justify="flex-start">
                <Grid item xs={12}>
                    <Typography className={classes.componentHeader}>
                        Submit a Living Lifestyle Claim
                    </Typography>
                    <br />
                    <Typography className={classes.componentBody}>
                    In order for your claim to be processed, you will need to submit the relevant requirements needed to support your claim.
                    All requirements must be uploaded before your online claim form can be submitted. In addition to the standard requirements,
                    a request may be made for additional requirements at a later point in the process. Please ensure that you are able to upload the following:
                    </Typography>
                    <SectionInformation classes={classes} sectionIndex={sectionIndex} />
                    <Typography className={classes.componentHeader}>Application Process</Typography>
                    <Typography style={{ fontSize: '16px', lineHeight: '27px' }}>
                        Complete the following steps to complete your submission
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    );
};

const SectionInformation = ({ sectionIndex, classes }) => {
    switch (sectionIndex) {
        case 1:
            return (
                <ol className={classes.body2}>
                    <li>Supporting medical evidence(e.g. x-ray reports)</li>
                    <li>Liberty Medical Aid Claim Form(completed by a specialist and not a general practitioner(GP))</li>
                    <li>Copy of Life Assured ‘s ID document or copy of the back and front of ID smart card</li>
                    <li>Personal Medical Attendance(PMA) report will be requested by Liberty from the doctor</li>
                </ol>

            );
        default:
            return (
                <ol className={classes.body2}>
                    <li>Supporting medical evidence(e.g. x-ray reports)</li>
                    <li>Liberty Medical Aid Claim Form(completed by a specialist and not a general practitioner(GP))</li>
                    <li>Copy of Life Assured ‘s ID document or copy of the back and front of ID smart card</li>
                    <li>Personal Medical Attendance(PMA) report will be requested by Liberty from the doctor</li>
                </ol>
            );
    }
};

export const ApplicationProcess = withStyles(styles)(applicationProcess);
