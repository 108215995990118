// @ts-nocheck
import { createStyles } from '@material-ui/core/styles';
import { slateGray } from './colours';

/**
 * @typedef {import('@material-ui/core/styles/withStyles').WithStyles<typeof styles>} StyledComponent;
 * @param {import('@material-ui/core').Theme} theme
 */
export default function styles(theme) {
    return createStyles({
        gridContainer: {
            paddingTop: '48px',
            maxWidth: '368px',
            [theme.breakpoints.down('sm')]: {
                padding: '48px 10x',
            },
        },
        fileViewerName: {
            marginTop: '5px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        },
        fileViewerIcon: {
            color: '#4378FD',
            marginTop: '3px',
        },
        fileViewerLabel: {
            color: '#4378FD',
            marginLeft: '6px',
            marginTop: '2px',
            lineHeight: '27px',
            fontWeight: 500,
        },
        validationToastr: {
            height: '80px',
            width: '1140px',
            borderRadius: '4px',
            backgroundColor: '#FFEBEE',
            boxShadow: '0 1px 8px 0 rgba(0,0,0,0.2)',
        },
        continueAndPreviouseButton: {
            flexWrap: 'unset',
            width: '36%',
            position: 'relative',
            bottom: '80px',
            left: '65%',
        },
        customPrintButton: {
            position: 'relative',
            left: '15px',
            bottom: '18px',
            height: '48px',
            width: '132px',
        },
        contentWrapper: {
            maxWidth: '368px',
            padding: '20px 0px 0px',
            marginLeft: 'auto',
            marginRight: 'auto',
            [theme.breakpoints.down('xs')]: {
                maxWidth: '368px',
                padding: '0 20px 20px',
            },
        },
        steps: {
            height: '282px',
            width: '1120px',
            border: '1px',
        },
        utilSteps: {
            height: '20px',
            width: '70px',
            color: '#0D1325',
            fontSize: '14px',
            letterSpacing: '0',
            lineHeight: '20px',
        },
        claimantBackground: {
            boxSizing: 'border-box',
            height: '60px',
            border: '2px solid #002B99',
            borderRadius: '4px 4px 0 0',
            backgroundColor: '#FFFFFF',
        },
        activeStepperCircle: {
            height: '24px',
            width: '24px',
            borderRadius: '50%',
            backgroundColor: '#002B99',
            color: '#FFFFFF',
            margin: '12px',
            marginLeft: '0px',
            textAlign: 'center',
        },
        previewIndex: {
            borderRadius: '50%',
            height: '24px',
            width: '24px',
            border: '1px solid #002B99',
            color: '#002B99',
            textAlign: 'center',
            margin: '12px',
            marginLeft: '0px',
        },
        addressLabel: {
            height: '34px',
            color: '#0D1325',
            fontSize: '20px',
            letterSpacing: '0.14px',
            lineHeight: '34px',
        },
        stepHeader: {
            fontSize: '24px',
            lineHeight: '30px',
            lineSpacing: '0.17px',
            fontWeight: '700',
            marginTop: '8px',
        },
        contentHeader: {
            fontSize: '20px',
            lineHeight: '30px',
            lineSpacing: '0.14px',
            fontWeight: '700',
            marginTop: '8px',
        },
        deleteIcon: {
            position: 'absolute',
            left: -5,
            top: 28,
            color: '#F46363',
            zIndex: 1,
            cursor: 'pointer',
        },
        deleteBeneficiaryIcon: {
            position: 'absolute',
            left: -20,
            top: 28,
            color: '#F46363',
            zIndex: 1,
            cursor: 'pointer',
        },
        policyDetailsDelete: {
            color: '#F46363',
            cursor: 'pointer',
        },
        description: {
            height: '60px',
            width: '368px',
            color: '#0D1325',
            fontSize: '14px',
            letterSpacing: '0',
            lineHeight: '20px',
        },
        dropContainerPos: {
            width: '100%',
            height: '100%',
            marginTop: '-10px',
            '& div:nth-child(3) ul li span:nth-child(3)': {
                marginLeft: '10px',
                color: slateGray,
            },
            maxWidth: 'none',
        },
        blueTitle: {
            fontSize: '18px',
            lineHeight: '20px',
            paddingLeft: '48px',
        },
        blueBackground: {
            marginTop: '24px',
            height: '98px',
            borderRadius: '4px',
            backgroundColor: '#DEECFF',
        },
        whiteTitle: {
            color: '#0D1325',
            fontSize: '16px',
            fontWeight: 600,
            letterSpacing: 0,
            lineHeight: '27px',
        },
        whiteBackground: {
            boxSizing: 'border-box',
            height: '60px',
            border: '2px solid #002B99',
            borderRadius: '4px',
            backgroundColor: '#FFFFFF',
        },
        blueSubtitle: {
            width: '928.2px',
            color: '#424757',
            fontSize: '12px',
            letterSpacing: 0,
            lineHeight: '18px',
            paddingLeft: '48px',
        },
        grayTitle: {
            padding: '15px',
            paddingLeft: '24px',
            marginTop: '24px',
            borderRadius: '4px',
            backgroundColor: '#C5C7D2',
        },
        grayTitleText: {
            color: '#0D1325',
            fontSize: '18px',
            fontWeight: 500,
            letterSpacing: 0,
            lineHeight: '24px',
        },
        headingNoBg: {
            height: '31px',
            width: '1033px',
            color: '#0D1325',
            fontSize: '18px',
            fontWeight: 500,
            letterSpacing: 0,
            lineHeight: '31px',
        },
        paragraph: {
            color: '#424757',
            fontSize: '16px',
            letterSpacing: 0,
            lineHeight: '22px',
            marginTop: '24px',
        },
        bodyNoBg: {
            color: '#424757',
            fontSize: '16px',
            letterSpacing: 0,
            lineHeight: '22px',
            paddingLeft: '20px',
            paddingTop: '10px',
            paddingBottom: '24px',
        },
        expansionSubTile: {
            width: '148px',
            fontSize: '18px',
            fontWeight: 600,
            lineHeight: '31px',
        },
        infoIcon: {
            paddingLeft: '19px',
            paddingTop: '30px',
            height: '18px',
            width: '18px',
        },
        downloadText: {
            height: '20px',
            width: '347px',
            color: '#00164E',
            fontSize: '16px',
            fontWeight: 500,
            letterSpacing: '1.23px',
            lineHeight: '20px',
            paddingLeft: '10px',
        },
        pointerCursor: {
            cursor: 'pointer',
        },
        downloadIcon: {
            height: '24px',
            width: '24px',
        },
        emptyBlock: {
            paddingTop: '24px',
        },
        errorContainer: {
            display: 'flex',
        },
        errorDetails: {
            margin: '24px 0px 20px 16px',
            width: '90%',
            fontSize: '18px',
            lineHeight: '20px',
            backgroundColor: '#FFEBEE',
        },
        errorHeading: {
            fontSize: '18px',
            lineHeight: '20px',
            color: '#0d1325',
        },
        errorIcon: {
            color: '#F46363',
            marginLeft: '18px',
            marginTop: '31px',
        },
        errorImage: {
            width: '32px',
            height: '32px',
            cursor: 'pointer',
            marginTop: '16px',
        },
        errorLine: {
            height: '18px',
            color: '#424757',
            fontSize: '12px',
            lineHeight: '18px',
        },
        errorWrapper: {
            width: '100%',
            height: '100%',
            backgroundColor: '#FFEBEE',
            borderRadius: '4px',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            padding: '0px',
            position: 'sticky',
            marginTop: '-5px',
            top: '64px',
            zIndex: 11 * 1001,
        },
        hrSpacing: {
            paddingTop: '10px',
        },
        stepperIcon: {
            color: '#36B37E',
            height: '24px',
            width: '24px',
            marginTop: '20px',
            marginBottom: '20px',
        },
        stepperContent: {
            height: '120px',
            width: '173px',
            color: '#141A2B',
            fontSize: '14px',
            letterSpacing: 0,
            lineHeight: '20px',
        },
        buttonGroupDescription: {
            color: '#0D1325',
            height: '20px',
            fontSize: '12px',
            lineHeight: '20px',
            marginBottom: '-14px',
            letterSpacing: '0',
        },
        expensionPanel: {
            width: '368px',
            marginTop: '16px',
            borderRadius: '4px',
            backgroundColor: '#FFFFFF',
            boxShadow: '0 1px 0 rgba(139,146,167,0.5)',
        },
        expensionPanelComponents: {
            width: '336px',
        },
        stepperAlignment: {
            display: 'flex',
            marginLeft: '-15px',
        },
        buttonDivider: {
            maxWidth: '1px',
            border: '2px solid #AFB3C2',
        },
        overWriteLabel: {
            width: '0%',
        },
        overWriteSelected: {
            backgroundColor: '#214EBF',
            color: '#FFFFFF',
        },
        buttonGroup: {
            boxSizing: 'border-box',
            height: '48px',
            width: '184px',
            borderRadius: '4px 4px 4px 4px',
            textTransform: 'none',
            border: '2px solid #214EBF',
            backgroundColor: '#FFFFFF',
            '&:hover': {
                background: '#214EBF',
                borderColor: '#214EBF',
                color: '#FFFFFF',
            },
        },
        submittedAlert: {
            width: '416px',
            height: '416px',
            borderRadius: '5px',
            border: 'solid 1px #FFFFFF',
            justifyContent: 'space-evenly',
        },
        submittedButton: {
            height: '48px',
            width: '368px',
            borderRadius: '4px',
            backgroundColor: '#002B99',
            marginLeft: '8px',
        },
        submittedContainer: {
            marginTop: '-24px',
            textAlign: 'center',
        },
        submittedMessage: {
            fontSize: '14px',
            lineHeight: '20px',
            marginTop: '24px',
        },
        submittedReference: {
            height: '27px',
            fontSize: '16px',
            lineHeight: '27px',
        },
        submittedTitle: {
            height: '37px',
            fontSize: '20px',
            fontWeight: 600,
            letterSpacing: '0.14px',
            lineHeight: '34px',
        },
        listItem: {
            fontWeight: 600,
            fontSize: '16px',
        },
        claimantTitle: {
            color: '#0D1325',
            fontSize: '24px',
            fontWeight: 500,
            letterSpacing: '0.17px',
            lineHeight: '41px',
            marginLeft: '-8px',
        },
        claimantSubTitle: {
            width: '500px',
            color: '#0D1325',
            fontSize: '16px',
            letterSpacing: 0,
            lineHeight: '27px',
            marginLeft: '-8px',
        },
        subHeadingContainer: {
            height: '89px',
            width: '368px',
            float: 'left',
        },
        rightContainer: {
            height: '507px',
            width: '306px',
            float: 'left',
            paddingLeft: '32px',
        },
        requiredInformation: {
            backgroundColor: '#DEECFF',
            borderRadius: '4px',
            display: 'flex',
            height: '40px',
            margin: '24px auto 32px auto',
            padding: '11px 11px 11px 19px',
            width: '100%',
        },
        requiredInformationText: {
            marginLeft: '11px',
            fontSize: '12px',
        },
        stepsContainer: {
            borderRight: '1px solid #8B92A7',
            marginRight: 41,
            width: '28%',
        },
        stepsContents: {
            borderLeft: '1px solid #8B92A7',
            paddingLeft: '30px',
        },
        stepDetails: {
            marginRight: 33,
            marginTop: '-16px',
            textAlign: 'right',
        },
        stepDescription: {
            color: '#8B92A7',
            fontSize: '12px',
            lineHeight: '20px',
            textAlign: 'right',
        },
        stepInfoContainer: {
            display: 'fixed',
            width: '100%',
            marginBottom: '18px',
        },
        stepInfo: {
            display: 'flex',
            float: 'right',
        },
        stepName: stepName(),
        stepNameActive: {
            ...stepName(),
            fontWeight: 600,
        },
        stepIcon: stepIcon(),
        stepIconCompleted: {
            ...stepIcon(),
            cursor: 'pointer',
        },
        subListItem: {
            fontSize: '16px',
        },
        leftAlign: {
            float: 'left',
        },
        subSectionTitle: {
            height: '53px',
            width: '368px',
        },
        radioStyle: {
            height: '18px',
            width: '536px',
            color: '#0D1325',
            fontSize: '16px',
            fontWeight: 500,
            letterSpacing: 0,
            lineHeight: '18px',
        },
        yesNoPadding: {
            paddingLeft: '62px',
            float: 'left',
        },
        radioGroupStyle: {
            height: '20px',
            width: '368px',
            color: '#0D1325',
            fontSize: '14px',
            letterSpacing: 0,
            lineHeight: '20px',
        },
        customLabel: {
            lineHeight: '2.5',
            paddingRight: '4px',
        },
        customLabelTwoLine: {
            lineHeight: '1.5',
            paddingRight: '4px',
            paddingBottom: '8px',
        },
        customHeading: {
            color: '#0D1325',
            fontFamily: 'Noto Sans',
            fontSize: '14px',
            fontWeight: '600',
            letterSpacing: '0',
            lineHeight: '27px',
        },
        addButtonStyle: {
            height: '48px',
            width: '206px',
            borderRadius: '4px',
            backgroundColor: '#DEECFF',
        },
        previousButton: {
            boxSizing: 'border-box',
            height: '48px',
            width: '368px',
            border: '2px solid #002B99',
            borderRadius: '4px',
            backgroundColor: '#FFFFFF',
        },
        buttonFormControl: {
            paddingRight: '50px',
        },
        buttonText: {
            color: '#00164E',
            fontSize: '16px',
            fontWeight: 500,
            letterSpacing: '1.23px',
            lineHeight: '19px',
            textAlign: 'center',
            textTransform: 'none',
            paddingLeft: '11px',
        },
        addStyleIcon: {
            height: '18px',
            width: '18px',
        },
        navigationGroup: {
            marginTop: '32px',
        },
        paper: paper(theme),
        shadedPaper: shadedPaper(theme),
        helperText: helperText(theme),
        pageHeader: pageHeader(theme),
        paperShadowLess: paperShadowLess(theme),
        button: buttonPrimary(theme),
        buttonSecondary: buttonSecondary(theme),
        buttonAlignment: buttonAlignment(theme),
        disableButton: disableButton(theme),
        customButton: customButton(theme),
        fullWidthButton: fullWidthButton(theme),
        spacedTypography: spacedTypography(theme),
        link: link(theme),
        drawerDivider: drawerDivider(theme),
        marginTop: marginTop(theme),
        marginBottom: marginBottom(theme),
        marginRight: marginRight(theme),
        marginLeft: marginLeft(theme),
        padding: padding(theme),
        paddingTop: paddingTop(theme),
        paddingRight: paddingRight(theme),
        paddingBottom: paddingBottom(theme),
        paddingLeft: paddingLeft(theme),
        stepper: stepper(theme),
        searchDivider: searchDivider(theme),
        boxContainer: boxContainer(),
        boxHeader: boxHeader(),
        boxDetails: boxDetails(),
        boxHeaderContent: boxHeaderContent(),
        boxDetailsHeader: boxDetailsHeader(),
        boxDetailsDate: boxDetailsDate(),
        boxUploaderBox: boxUploaderBox(),
        boxUploaderLink: boxUploaderLink(),
        boxParent: boxParent(),
        boxDetailsSummary: boxDetailsSummary(),
        stepperItem: stepperItem(),
        stepperBehind: stepperBehind(theme),
        stepperCurrent: stepperCurrent(theme),
        stepperAhead: stepperAhead(theme),
        stepperArrow: stepperArrow(theme),
        stepperButtonActive: stepperButtonActive(theme),
        stepperButtonInactive: stepperButtonInactive(theme),
        stepperInner: stepperInner(theme),
        stepperOverLayWrapper: stepperOverLayWrapper(),
        rightAlign: rightAlign(theme),
        marginTop3: { marginTop: theme.spacing(3) },
        marginTop4: { marginTop: theme.spacing(4.5) },
        error: {
            color: theme.palette.error.main,
        },
        body1: theme.typography.body1,
        body2: body2(theme),
        subHeading: subHeading(theme),
        subHeading2: subHeading2(theme),
        pageHeading: pageHeading(theme),
        bodyImportant: bodyImportant(theme),
        bodyHeading: bodyHeading(theme),
        bodyHeadingCard: bodyHeadingCard(theme),
        bodySubHeading: bodySubHeading(theme),
        bodySubHeading2: bodySubHeading2(theme),
        bodySubHeadingSubTitle: bodySubHeadingSubTitle(theme),
        bodyListHeading: bodyListHeading(theme),
        bodyList: bodyList(theme),
        bodyParagraph: bodyParagraph(theme),
        card: card(theme),
        cardButton: { textAlign: 'right', marginBottom: '20px', paddingRight: '16px' },
        cardExistingItem: cardExistingItem(theme),
        cardBody: cardBody(theme),
        cardValue: cardValue(theme),
        cardLabel: cardLabel(theme),
        cardActions: cardActions(theme),
        cardSubHeading: cardSubHeading(theme),
        cardSubHeadingSubtle: cardSubHeadingSubtle(theme),
        cardTitle: cardTitle(theme),
        overLayWrapper: overLayWrapper(),
        cardHeading: cardHeading(theme),
        heading: heading(theme),
        componentHeader: {
            height: '41px',
            fontSize: '24px',
            fontWeight: 500,
            lineHeight: '41px',
        },
        componentIntro: {
            marginTop: '22px',
            marginLeft: '8px',
            marginBottom: '25.15px',
            height: '30px',
            fontSize: '22px',
            fontWeight: 500,
            lineHeight: '30px',
        },
        doctorsVisitTitle: {
            marginTop: '22px',
            height: '30px',
            fontSize: '22px',
            lineHeight: '34px',
        },
        componentBody: {
            marginLeft: '8px',
            fontSize: '18px',
            lineHeight: '25px',
        },
        ToggleButtonYes: {
            boxSizing: 'border-box',
            height: '48px',
            width: '185px',
            border: '2px solid #AFB3C2',
            borderRadius: '4px 0 0 4px',
            backgroundColor: '#FFFFFF',
        },
        currentStep: currentStep(),
        step: step(),
        stepperDiv: stepperDiv(),
        activeCircle: activeCircle(),
        completedCirle: completedCirle(),
        inactiveCircle: inactiveCircle(),
        printButton: {
            ...customButton(theme),
            ...fullWidthButton(),
            position: 'relative',
            left: '15px',
            bottom: '18px',
            height: '48px',
            width: '132px',
            marginLeft: '-32px',
        },
    });
}

function stepName() {
    return {
        color: '#0D1325',
        fontSize: '14px',
        lineHeight: '20px',
        textAlign: 'right',
    };
}

function stepIcon() {
    return {
        ...stepperItem(),
        backgroundColor: 'white',
        borderRadius: '50%',
        height: '23px',
        width: '23px',
        marginRight: '-12px',
        border: '0px',
    };
}

function stepperDiv() {
    return {
        border: '1px solid #AFB3C2',
        borderRadius: '4px',
        width: '100%',
        backgroundColor: '#FAFAFF',
        paddingTop: '18px',
        paddingBottom: '19px',
        paddingLeft: '24px',
        alignItems: 'center',
        flexDirection: 'row',
        height: '64px',
    };
}

function completedCirle() {
    return {
        color: '#36B37E',
        height: '27px',
        width: '27px',
        marginTop: '-8px',
        marginBottom: '-10px',
        marginLeft: '-1.5px',
        textAlign: 'center',
    };
}

function activeCircle() {
    return {
        borderRadius: '50%',
        height: '24px',
        width: '24px',
        border: '0.5px solid',
        backgroundColor: '#002B99',
        color: '#ffffff',
        textAlign: 'center',
    };
}

function inactiveCircle() {
    return {
        borderRadius: '50%',
        height: '24px',
        width: '24px',
        border: '1px solid #002B99',
        color: '#002B99',
        textAlign: 'center',
    };
}

function boxParent() {
    return {
        width: '100%',
        paddingTop: '24px',
        paddingBottom: '24px',
        paddingLeft: '20px',
    };
}

function boxContainer() {
    return {
        maxWidth: '228px',
        height: '255px',
    };
}

function boxHeader() {
    return {
        height: '94px',
        display: 'flex',
    };
}

function boxHeaderContent() {
    return {
        margin: 'auto',
        color: '#ffffff',
        fontSize: '18px',
        textAlign: 'center',
        fontWeight: 'bold',
        width: 'min-intrinsic',
        // eslint-disable-next-line
        width: '-webkit-min-content',
        // eslint-disable-next-line
        width: '-moz-min-content',
        // eslint-disable-next-line
        width: 'min-content',
        display: 'table-caption -ms-grid',
        '-ms-grid-columns': 'min-content',
        textTransform: 'uppercase',
    };
}

function boxDetails() {
    return {
        height: '161px',
    };
}

function boxDetailsHeader() {
    return {
        paddingTop: '25px',
        textAlign: 'center',
        color: '#000000',
        fontSize: '14px',
        textTransform: 'uppercase',
    };
}

function boxDetailsDate() {
    return {
        color: '#424757',
        fontSize: '14px',
        fontWeight: 'bold',
        paddingTop: '30px',
        paddingLeft: '10px',
        height: '50px',
    };
}

function boxUploaderBox() {
    return {
        paddingRight: '40px',
        paddingBottom: '5px',
    };
}

function boxUploaderLink() {
    return {
        color: '#4378fd',
        fontSize: '16px',
        cursor: 'pointer',
    };
}

function boxDetailsSummary() {
    return {
        color: '#424757',
        paddingLeft: '10px',
        paddingRight: '10px',
        fontSize: '14px',
    };
}

function searchDivider() {
    return {
        boxShadow: '0 3px 10px 0 rgba(0, 0, 0, 0.09)',
        backgroundColor: '#ffffff',
        height: '1px',
        width: '100%',
    };
}

function body2(theme) {
    return {
        ...theme.typography.body2,
        fontSize: '16px',
        color: '#0d1325',
    };
}

function marginTop(theme) {
    return {
        marginTop: theme.spacing(2),
    };
}

function marginRight(theme) {
    return {
        marginRight: theme.spacing(2),
    };
}

function marginLeft(theme) {
    return {
        marginLeft: theme.spacing(2),
    };
}

function marginBottom(theme) {
    return {
        marginBottom: theme.spacing(2),
    };
}

function padding(theme) {
    return {
        padding: theme.spacing(2),
    };
}

function paddingLeft(theme) {
    return {
        paddingLeft: theme.spacing(2),
    };
}

function paddingRight(theme) {
    return {
        paddingRight: theme.spacing(2),
    };
}

function paddingTop(theme) {
    return {
        paddingTop: theme.spacing(2),
    };
}

function paddingBottom(theme) {
    return {
        paddingBottom: theme.spacing(2),
    };
}

export function paper(theme) {
    return {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
        height: '100%',
    };
}

function shadedPaper(theme) {
    return {
        ...paper(theme),
        backgroundColor: '#F0F1F4',
    };
}

function paperShadowLess() {
    return {
        boxShadow: ['none'],
    };
}

function stepper() {
    return {
        position: 'sticky',
        top: '64px',
        zIndex: 10 * 1000,
        boxShadow: ['none'],
    };
}

function stepperItem() {
    return {
        display: 'inline-block',
        textAlign: 'center',
    };
}

function stepperBehind() {
    return {
        color: '#4378fd',
    };
}

function stepperCurrent() {
    return {
        color: '#002b99',
    };
}

function stepperAhead() {
    return {
        color: '#8b92a7',
    };
}

function currentStep() {
    return {
        color: '#0D1325',
        fontWeight: 600,
    };
}

function step() {
    return {
        color: '#0D1325',
    };
}

function stepperArrow() {
    return {
        paddingLeft: '10px',
        paddingRight: '10px',
        display: 'inline-block',
        textAlign: 'right',
        float: 'right',
    };
}

function stepperButtonActive() {
    return {
        color: '#5581ef',
    };
}

function stepperButtonInactive() {
    return {
        color: '#0d3393',
    };
}

function stepperInner() {
    return {
        paddingTop: '18px',
        paddingBottom: '18px',
        paddingRight: '24px',
        paddingLeft: '24px',
        backgroundColor: 'rgb(248,248,250)',
    };
}

function buttonPrimary(theme) {
    return {
        margin: theme.spacing(1),
        marginRight: '0px',
        backgroundColor: '#002B99',
        minWidth: '176px',
        maxWidth: '368px',
        boxShadow: ['none'],
        textTransform: 'none',
        height: '48px',
        borderRadius: '5px',
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: 1.69,
        '&:hover': {
            background: '#214EBF',
            borderColor: '#214EBF',
            color: '#FFFFFF',
        },
    };
}

function customButton(theme) {
    return {
        ...buttonSecondary(theme),
        height: '48px',
        borderRadius: '4px',
        marginTop: '15px',
        backgroundColor: '#DEECFF',
        width: '336px',
        border: 'none',
    };
}

function buttonAlignment() {
    return {
        marginLeft: '1px',
    };
}

function disableButton() {
    return {
        pointerEvents: 'none',
        opacity: 0.5
    };
}

function fullWidthButton(theme) {
    return {
        width: '368px',
        marginLeft: '1px',
    };
}

function buttonSecondary(theme) {
    return {
        margin: theme.spacing(1),
        backgroundColor: 'white',
        border: '2px solid #002B99',
        color: '#002B99',
        minWidth: '176px',
        maxWidth: '368px',
        boxShadow: ['none'],
        textTransform: 'none',
        height: '48px',
        borderRadius: '5px',
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '1px',
        '&:hover': {
            background: '#214EBF',
            borderColor: '#214EBF',
            color: '#FFFFFF',
        },
    };
}

function bodyHeading(theme) {
    return {
        fontWeight: '500',
        fontSize: '24px',
        paddingBottom: '15px',
        color: '#0d1325',
    };
}

function pageHeading(theme) {
    return {
        fontWeight: 'bold',
        fontSize: '24px',
        paddingBottom: '15px',
        color: '#0d1325',
    };
}

function bodyHeadingCard(theme) {
    return {
        fontWeight: '500',
        fontSize: '24px',
        color: '#0d1325',
    };
}

function bodySubHeading(theme) {
    return {
        fontWeight: '500',
        paddingTop: '25px',
        paddingBottom: '25px',
        fontSize: '18px',
    };
}

function subHeading(theme) {
    return {
        fontWeight: '500',
        fontSize: '18px',
        color: '#0d1325',
        lineHeight: 1.72,
    };
}

function subHeading2(theme) {
    return {
        fontSize: '20px',
        color: '#0d1325',
    };
}

function bodyImportant(theme) {
    return {
        ...theme.typography.body1,
        fontWeight: '500',
        fontSize: '16px',
        color: '#0d1325',
    };
}

function bodySubHeading2(theme) {
    return {
        paddingTop: '20px',
        paddingBottom: '20px',
        fontSize: '20px',
        color: '#0d1325',
    };
}

function helperText(theme) {
    return {
        fontSize: '16px',
        lineHeight: '1.69',
        color: '#959cb3',
        paddingTop: '20px',
        paddingBottom: '20px',
    };
}

function cardSubHeading(theme) {
    return {
        fontWeight: '500',
        paddingTop: '10px',
        fontSize: '18px',
        color: 'black',
    };
}

function cardSubHeadingSubtle(theme) {
    return {
        fontSize: '16px',
        opacity: 0.8,
        color: '#8b92a7',
    };
}

function cardBody(theme) {
    return {
        fontSize: '16px',
        paddingTop: '10px',
        color: '#0d1325',
    };
}

function cardValue(theme) {
    return {
        fontWeight: '500',
        fontSize: '16px',
        color: '#0d1325',
        paddingTop: '0px',
        marginBottom: '2px',
    };
}

function cardLabel(theme) {
    return {
        fontSize: '16px',
        color: '#8b92a7',
        paddingTop: '0px',
        marginBottom: '2px',
    };
}

function bodyParagraph(theme) {
    return {
        paddingBottom: '20px',
    };
}

function bodySubHeadingSubTitle(theme) {
    return {
        fontStyle: 'italic',
        fontSize: '16px',
        paddingBottom: '25px',
    };
}

function bodyListHeading(theme) {
    return {
        paddingBottom: '15px',
    };
}

function bodyList(theme) {
    return {
        fontSize: '16px',
        lineHeight: 1.75,
    };
}

function pageHeader(theme) {
    return {
        fontSize: '24px',
        fontWeight: '500',
        lineHeight: 1.71,
        color: '#0d1325',
        paddingTop: '5px',
    };
}

function spacedTypography(theme) {
    return {
        marginBottom: theme.spacing(2),
    };
}

function link(theme) {
    return {
        color: theme.palette.common.white,
    };
}

function drawerDivider(theme) {
    return {
        borderColor: theme.palette.common.white,
        backgroundColor: theme.palette.common.white,
    };
}

function rightAlign(theme) {
    return {
        textAlign: 'right',
    };
}

function cardTitle(theme) {
    return {
        title: {
            color: 'red',
            fontSize: 25,
        },
    };
}

function card(theme) {
    return {
        height: '213px',
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
        position: 'relative',
    };
}

function cardExistingItem(theme) {
    return {
        height: '186px',
    };
}

function cardActions(theme) {
    return {
        bottom: 0,
        position: 'absolute',
        right: 0,
    };
}

function overLayWrapper() {
    return {
        position: 'inherit',
    };
}

function stepperOverLayWrapper() {
    return {
        zIndex: '1',
    };
}

function heading(theme) {
    return {
        height: '41px',
        fontSize: '24px',
        fontWeight: 500,
        marginTop: theme.spacing(2),
    };
}

function cardHeading(theme) {
    return {
        fontWeight: 'bold',
        fontSize: '20px',
        color: '#0d1325',
    };
}
