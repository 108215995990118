import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from '../../../styles/styles';
import {
    Condition,
    FixedWidthButtonGroup,
    OutlinedTextField,
    True,
    WhenFieldChanges,
} from '../../../forms';
import { FirstNameMask, CurrencyMask } from '../../../forms/input-masks';
import { convertToNumber } from '../../../shared/helper-functions';
import { ClaimantNavigation } from '../../common/navigation/claimant-navigation';

const otherInformation = function ({
    classes,
    form,
    index,
    handleNext,
    handleBackStepper,
    editable,
    stepsLength,
    claimantIndex,
}) {
    const prefix = `claimants[${claimantIndex}].`;
    return (
        <Grid container spacing={10} justify="flex-start">
            <Grid item xs={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
                <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                <Typography className={classes.stepHeader}>Other Information</Typography>
            </Grid>
            <Grid item xs={12} lg={12}>
                <FixedWidthButtonGroup
                    label="Is the dependant a beneficiary of the deceased will?"
                    name={`${prefix}beneficiaryOfWill`}
                    options={['Yes', 'No']}
                    disabled={!editable}
                />
            </Grid>
            <WhenFieldChanges
                field={`${prefix}beneficiaryOfWill`}
                becomes="No"
                set={`${prefix}valueOfInheritance`}
                to={null}
            />
            <Condition when={`claimants[${claimantIndex}].beneficiaryOfWill`} is="Yes">
                <True>
                    <Grid item xs={12}>
                        <OutlinedTextField
                            name={`${prefix}valueOfInheritance`}
                            label="Approximate value of inheritance"
                            mask={CurrencyMask}
                            parse={convertToNumber}
                            disabled={!editable}
                        />
                    </Grid>
                </True>
            </Condition>
            <Grid item xs={12} style={{ marginTop: '24px' }}>
                <FixedWidthButtonGroup
                    label="Did the dependant receive any benefits other
                                        than those now becoming due as a result of the death of the member
                                        from the Lifestyle Retirement Annuity Fund, Lifestyle Retirement
                                        Preserver Pension Fund, or Lifestyle Retirement Preserver Provident Fund
                                         (i.e. benefits from other Pension/Provident, Retirement Funds, other insurance policies etc.)?"
                    name={`${prefix}receivedOtherBenefits`}
                    options={['Yes', 'No']}
                    disabled={!editable}
                />
            </Grid>
            <WhenFieldChanges
                field={`${prefix}receivedOtherBenefits`}
                becomes="No"
                set={`${prefix}otherBenefits`}
                to={null}
            />
            <Condition when={`${prefix}receivedOtherBenefits`} is="Yes">
                <True>
                    <Grid item xs={12}>
                        <OutlinedTextField
                            name={`${prefix}otherBenefits.insurer`}
                            label="Name of insurer/financial institution"
                            mask={FirstNameMask}
                            disabled={!editable}
                        />
                        <OutlinedTextField
                            name={`${prefix}otherBenefits.amountReceived`}
                            label="Amount received"
                            mask={CurrencyMask}
                            parse={convertToNumber}
                            disabled={!editable}
                        />
                    </Grid>
                </True>
            </Condition>
            <ClaimantNavigation
                form={form}
                handleBackStepper={handleBackStepper}
                handleNext={handleNext}
                prefix={`claimant-${claimantIndex}-information`}
            />
        </Grid>
    );
};

export const OtherInformation = withStyles(styles)(otherInformation);
