import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from '../../../styles/styles';
import { getDisplayDate, getLabel } from '../../../shared/helper-functions';
import { LabelValue } from '../../common/label-value';
import { IdentitySummary } from '../../common/identity-details-summary';
import { Titles } from '../../../shared/lookups';

const personalDetails = ({ claimant }) => {
    if (!claimant || !claimant.individual) {
        return null;
    }
    const individual = claimant.individual;
    return (
        <React.Fragment>
            <Grid item xs={12} style={{ marginTop: '24px' }}>
                <Typography variant="h6">Personal Details</Typography>
                <hr />
            </Grid>
            <Grid sx={12} sm={12}>
                <LabelValue label="Title" value={getLabel(individual.title, Titles)} />
                <LabelValue label="First Name" value={individual.firstName} />
                <LabelValue label="Surname" value={individual.surname} />
                <LabelValue label="Initials" value={individual.initials} />
                <IdentitySummary identityDetails={individual} />
                <LabelValue label="Gender" value={individual.gender} />
                <LabelValue label="Marital Status" value={individual.maritalStatus} />
                <LabelValue
                    label="Date of Marriage"
                    value={getDisplayDate(individual.dateOfMarriage)}
                />
                <LabelValue
                    label="Marriage Certificate"
                    value={individual.marriageCertificate}
                    isFile={true}
                />
                <LabelValue label="Date of divorce" value={getDisplayDate(individual.dateOfDivorce)} />
                <LabelValue
                    label="Are you a South African resident?"
                    value={individual.isRSAResident}
                />
                <LabelValue label="Country of Residence" value={individual.countryOfResidence} />
                <LabelValue
                    label="Have you officially emigrated?"
                    value={individual.officiallyEmigrated}
                />
                <LabelValue
                    label="Exchange Form"
                    value={individual.emigrationExchangeForm}
                    isFile={true}
                />
            </Grid>
        </React.Fragment>
    );
};

export const PersonalDetailsSummary = withStyles(styles)(personalDetails);
