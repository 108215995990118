import { Grid, Typography, withStyles } from '@material-ui/core';
import * as React from 'react';
import styles from '../../../../styles/styles';
import { LabelValue } from '../../../common/label-value';

const postalAddress = function ({ classes, policyholder, displayPostalAddress }) {
    let isLifeAssured = false;
    let isPolicyHolder = true;

    if (policyholder && policyholder.addressDetails) {
        isLifeAssured = true;
        isPolicyHolder = false;
    } else {
        isPolicyHolder = true;
        isLifeAssured = false;
    }

    if (!isLifeAssured) {
        if (!policyholder || !policyholder.individual || !policyholder.individual.addressDetails) {
            isLifeAssured = true;
            isPolicyHolder = false;
            return null;
        }
    }

    return (
        displayPostalAddress && (<Grid container justify="flex-start">
            <Grid item xs={12}>
                <Typography className={classes.whiteTitle}>Postal Address</Typography>
            </Grid>
            {isPolicyHolder && (policyholder.individual.addressDetails.sameAsResidential === 'Yes' ? (
                <Typography className={classes.customLabel}>Same As Residential</Typography>
            ) : (
                <React.Fragment>
                    <LabelValue
                        label="Address Type"
                        value={policyholder.individual.addressDetails.addressType}
                    />
                    <LabelValue
                        label="Address Line 1"
                        value={policyholder.individual.addressDetails.postalAddressLineOne}
                    />
                    <LabelValue
                        label="Address Line 2"
                        value={policyholder.individual.addressDetails.postalAddressLineTwo}
                    />
                    <LabelValue
                        label="Town/Suburb"
                        value={policyholder.individual.addressDetails.postalAddressSuburb}
                    />
                    <LabelValue label="City" value={policyholder.individual.addressDetails.postalAddressTown} />
                    <LabelValue label="Postal Code" value={policyholder.individual.addressDetails.postalAddressCode} />
                    <LabelValue
                        label="Province"
                        value={policyholder.individual.addressDetails.postalAddressProvince}
                    />
                    <LabelValue label="Country" value={policyholder.individual.addressDetails.postalAddressCountry} />
                </React.Fragment>
            ))}

            {isLifeAssured && (policyholder.addressDetails.sameAsResidential === 'Yes' ? (
                <Typography className={classes.customLabel}>Same As Residential</Typography>
            ) : (
                <React.Fragment>
                    <LabelValue
                        label="Address Type"
                        value={policyholder.addressDetails.addressType}
                    />
                    <LabelValue
                        label="Address Line 1"
                        value={policyholder.addressDetails.postalAddressLineOne}
                    />
                    <LabelValue
                        label="Address Line 2"
                        value={policyholder.addressDetails.postalAddressLineTwo}
                    />
                    <LabelValue
                        label="Town/Suburb"
                        value={policyholder.addressDetails.postalAddressSuburb}
                    />
                    <LabelValue label="City" value={policyholder.addressDetails.postalAddressTown} />
                    <LabelValue label="Postal Code" value={policyholder.addressDetails.postalAddressCode} />
                    <LabelValue
                        label="Province"
                        value={policyholder.addressDetails.postalAddressProvince}
                    />
                    <LabelValue label="Country" value={policyholder.addressDetails.postalAddressCountry} />
                </React.Fragment>
            ))}
        </Grid>
        ));
};

export const PostalAddress = withStyles(styles)(postalAddress);
