import * as React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from '../../../../styles/styles';
import { getAddressLine, getLabel } from '../../../../shared/helper-functions';
import { addressTypes } from '../../../../shared/lookups';
import { LabelValue } from '../../../common/label-value';

const residentialAddress = function ({ classes, claimant }) {
    if (!claimant || !claimant.individual) {
        return null;
    }

    return (
        <Grid container justify="flex-start">
            <Grid item xs={12}>
                <Typography className={classes.whiteTitle}>Residential Address</Typography>
            </Grid>
            <LabelValue
                label="Address Type"
                value={getLabel(claimant.individual.residentialAddressType, addressTypes())}
            />
            <LabelValue
                label={getAddressLine(claimant.individual.residentialAddressType)}
                value={claimant.individual.residentialAddressLineOne}
            />
            <LabelValue
                label="Address Line 2"
                value={claimant.individual.residentialAddressLineTwo}
            />
            <LabelValue
                label="Address Line 3"
                value={claimant.individual.residentialAddressLineThree}
            />
            <LabelValue label="Town/Suburb" value={claimant.individualresidentialAddressSuburb} />
            <LabelValue label="City" value={claimant.individual.residentialAddressTown} />
            <LabelValue label="Postal Code" value={claimant.individual.residentialPostalCode} />
            <LabelValue label="Province" value={claimant.individual.residentialAddressProvince} />
            <LabelValue label="Country" value={claimant.individual.residentialAddressCountry} />
        </Grid>
    );
};

export const ResidentialAddress = withStyles(styles)(residentialAddress);
