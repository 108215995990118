import { Grid, withStyles } from '@material-ui/core';
import React from 'react';
import { OutlinedSearchSelectField, OutlinedTextField } from '../../../forms';
import { NumberMask } from '../../../forms/input-masks';
import { conditionalRequired, requiredValidator } from '../../../forms/validations';
import { addressTypes, Provinces } from '../../../shared/lookups';
import styles from '../../../styles/styles';

const funeralAddressDetails = function ({ editable, prefix = '' }) {
    return (
        <React.Fragment>
            <Grid item xs={12}>
                <OutlinedSearchSelectField
                    name={`${prefix}addressType`}
                    labelprefixed="true"
                    options={addressTypes()}
                    validate={requiredValidator}
                    disabled
                />
            </Grid>
            <Grid item xs={12}>
                <OutlinedTextField
                    name={`${prefix}addressLineOne`}
                    label="Address Line 1"
                    labelprefixed="true"
                    validate={requiredValidator}
                    disabled={!editable}
                />
            </Grid>
            <Grid item xs={12}>
                <OutlinedTextField
                    name={`${prefix}addressLineTwo`}
                    label="Address Line 2"
                    validate={conditionalRequired(hasAddressLineThree)}
                    disabled={!editable}
                />
            </Grid>
            <Grid item xs={12}>
                <OutlinedTextField
                    name={`${prefix}addressLineThree`}
                    label="Address Line 3"
                    disabled={!editable}
                />
            </Grid>
            <Grid item xs={12}>
                <OutlinedTextField
                    name={`${prefix}townSuburb`}
                    label="Town/Suburb"
                    labelprefixed="true"
                    validate={requiredValidator}
                    disabled={!editable}
                />
            </Grid>
            <Grid item xs={12}>
                <OutlinedTextField
                    name={`${prefix}city`}
                    labelprefixed="true"
                    validate={requiredValidator}
                    disabled={!editable}
                />
            </Grid>
            <Grid item xs={12}>
                <OutlinedTextField
                    name={`${prefix}postalCode`}
                    labelprefixed="true"
                    validate={requiredValidator}
                    disabled={!editable}
                    mask={NumberMask}
                />
            </Grid>
            <Grid item xs={12}>
                <OutlinedSearchSelectField
                    name={`${prefix}province`}
                    labelprefixed="true"
                    options={Provinces}
                    validate={requiredValidator}
                    disabled={!editable}
                />
            </Grid>
        </React.Fragment>
    );
};

export function hasAddressLineThree(values) {
    return (
        values &&
        values.deceasedDetails &&
        values.deceasedDetails.addressDetails &&
        values.deceasedDetails.addressDetails.addressLineThree
    );
}

export const FuneralAddressDetails = withStyles(styles)(funeralAddressDetails);
