import { createStyles } from '@material-ui/core/styles';
import globalStyles, { paper as globalPaper } from '../../styles/styles';

export default function styles(theme) {
    return createStyles({
        ...globalStyles(theme),
        modal: modal(),
        modalItem: modalItem(),
        paper: paper(theme),
    });
}

function modal() {
    return {
        outline: 'none',
    };
}

function modalItem() {
    return {
        position: 'absolute',
        top: '50%',
        transform: 'translate(0%, -50%)',
        outline: 'none',
    };
}

function paper(theme) {
    return {
        ...globalPaper(theme),
        maxHeight: '90vh',
        overflowY: 'auto',
        overflowX: 'hidden',
    };
}
