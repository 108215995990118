import * as React from 'react';
import { Grid, Paper, Typography, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import { FixedWidthCheckBoxField } from '../../../forms';
import styles from '../../../styles/styles';
import { NavigationButton } from '../../common/navigation/navigation-button';

const declaration = function ({ classes, form, handleBack, handleSubmit, editable }) {
    return (
        <Paper
            className={classNames(classes.shadedPaper, classes.paperShadowLess)}
            style={{ padding: 0 }}
        >
            <Grid container spacing={0} justify="flex-start">
                <Grid item xs={12} className={classes.grayTitle}>
                    <Typography className={classes.grayTitleText}>
                        Funeral Claim Declaration
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography className={classes.paragraph}>
                        By submitting this form as a Claimant, or the Legal Guardian of a Claimant
                        who is a minor, I confirm the following:{' '}
                    </Typography>
                    <ul className={classes.paragraph}>
                        <li>
                            I am making a claim for the benefits of the policy/ies listed in this
                            form.
                        </li>
                        <li>I have a legal right to claim these benefits</li>
                        <li>
                            I understand that although Liberty has allowed me to complete this form,
                            this does not mean I have a valid claim.
                        </li>
                        <li>
                            I agree that any written statements, affidavits and documents provided
                            in support of this claim will form part of this claim.
                        </li>
                        <li>
                            The information I have provided on this form is true, to the best of my
                            knowledge and belief
                        </li>
                        <li>
                            I have not withheld information that could affect the payment of the
                            claim
                        </li>
                        <li>
                            If I, or anyone acting on my behalf or with my knowledge, have given
                            false information or withheld information, then I will not be entitled
                            to receive the benefits I am claiming for.
                        </li>
                        <li>
                            Should an erroneous overpayment of benefits, loans, and/or investment
                            returns occur under this policy, for whatever reason, such overpaid
                            amounts will become payable to Liberty on demand. Liberty accepts no
                            responsibility if incorrect banking details are provided. For your
                            protection payment will only be effected by Electronic Fund Transfer,
                            this will also ensure faster payment. Payment may only be made to the
                            owner/nominated beneficiary. Should bank details differ to the account
                            details on record, please provide proof of account i.e. a copy of a
                            current bank statement on a bank letterhead or a copy of a printout from
                            the bank with a bank stamp.
                        </li>
                    </ul>
                    <Typography className={classes.paragraph}>
                        Once the benefits have been paid, I agree that Liberty Group Limited (and
                        its successors are no longer responsible for any other claims or liabilities
                        relating to these policy/ies benefits
                    </Typography>
                </Grid>
                <Grid item xs={12} className={classes.grayTitle}>
                    <Typography className={classes.grayTitleText}>
                        Acceptance of Terms and Conditions
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    container
                    justify="flex-start"
                    style={{ padding: 0, marginTop: '24px' }}
                >
                    <Grid item style={{ padding: 0, width: '5%', marginLeft: '1px' }}>
                        <FixedWidthCheckBoxField
                            name="agreedToTermsAndConditions"
                            hideLabel
                            disabled={!editable}
                        />
                    </Grid>
                    <Grid
                        item
                        style={{ padding: 0, width: '95%', marginLeft: '-8px', paddingTop: '4px' }}
                    >
                        <span style={{ fontSize: '16px', lineHeight: '20px' }}>
                            I declare that the answers and statements are true to the best of my
                            knowledge and belief, and that I have withheld no material fact.
                        </span>
                    </Grid>
                </Grid>
                <Grid container justify="flex-end" item xs={12} className={classes.navigationGroup}>
                    <NavigationButton
                        className={classes.buttonSecondary}
                        onClick={() => handleBack(form)}
                        id="previous-section"
                        style={{ width: '213px' }}
                        label="Previous Section"
                    />
                    <NavigationButton
                        id="submit"
                        color="primary"
                        className={classes.button}
                        onClick={() => {
                            sessionStorage.setItem('canGoToNextPage', 'true');
                            handleSubmit(form);
                        }}
                        label={editable ? 'Submit' : 'Return To Dashboard'}
                    />
                </Grid>
            </Grid>
        </Paper>
    );
};

export const Declaration = withStyles(styles)(declaration);
